import React, { useEffect, useState } from 'react'
import { handleLink } from '../../../modules/utils';
import TopNavPage2 from '../../topNav2';
import FooterPage from '../../footer';
import { getExDPWebs } from '../../../services/ExDPWebService';
import { IExDPWeb } from '../../../interfaces/ExDPWeb';

import { buildAnsStateDPByNoArray, getAnsDPByNo, getAnsQDPByNoArray, getDPQAByNo, getQuestionDPByNoArray } from '../../../services/ExDPQAService';
import IAnsStateDP from '../../../interfaces/AnsStateDP';
import IQuestionDP from '../../../interfaces/QuestionDP';
import IAnsQDP from '../../../interfaces/AnsQDP';
import { getDPLearnTaskByNo } from '../../../services/ExDPService';
import DispDPAns from './dispdpans';
import DPQAPage from './dpqa';
import { getLearnRecVal } from '../../../modules/SessionTestCnt';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';


//type IDPPracticeProp=RouteComponentProps< {
   // group:string;
   // no: string
//}>;

interface IDPPracticeProp {
}

interface contentType{
    type:string;
    title:string;
    innerHtml:string;
}
    


const DPPracticePage: React.FunctionComponent<IDPPracticeProp> = props => {

    const [currTab,setCurrTab]=useState(0);
    const [ExDPWebs,setExDPWebs]=useState<IExDPWeb[]>([]);
    const [ansStateDP,setAnsStateDP]=useState<IAnsStateDP[][]>([[]]);
    const [questionDP,setQuestionDP]=useState<IQuestionDP[][]>([[]]);
    const [ansQDP,setAnsQDP]=useState<IAnsQDP[]>([]);
    const [finishFlag,setFinishFlag]=useState(false);
    const [learnTask,setLearnTask]=useState("");

    const handleTab=(idx:number)=>{
        if(idx!==currTab)
        {
            setCurrTab(idx); 
        }
    }    

    const InitData=()=>{
        getDPQAByNo(""+sessionStorage.getItem("DPNo")).then(data=>{
            setQuestionDP(getQuestionDPByNoArray(data));
            setAnsQDP(getAnsQDPByNoArray(data));
            setAnsStateDP(buildAnsStateDPByNoArray(data))
        })
                                                      
    }
    const getOpMode=():string=>{
        if(sessionStorage.getItem("DPGroup")==="Pirls")
            return "QOri" // Quesiton button to switch pages
        else
            return "TOri" // Tab button to switch pages
    }

    const mainContent=()=>{

        const renderPart = (part: string, index: number,mode:number) => {
            const aTagMatch = part.match(/<a[^>]*data-content="([^"]*)"[^>]*title="([^"]*)"[^>]*>(.*?)<\/a>/);
            if (aTagMatch) {
                const dataContent = aTagMatch[1]; // Extract 'data-content' attribute
                const title = aTagMatch[2];       // Extract 'title' attribute
                const innerText = aTagMatch[3];   // Extract inner text of the <a> tag
    
                const popover = (
                    <Popover id={`popover-${index}`}>
                        <Popover.Header as="h3">{title}</Popover.Header>
                        <Popover.Body>{dataContent}</Popover.Body>
                    </Popover>
                );
    
                // Replace <a> tag with OverlayTrigger that shows the popover
                return (
                    <div key={index} className="overlay-trigger-wrapper">
                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{innerText}</span>
                        </OverlayTrigger>
                    </div>
                );
            } else {
                // Regular HTML part
                return (
                    (index===0) && (mode===0)? 
                    <>
                    <div className="mx-auto">
                        <img src={"./assets/img/PirlsImage/"+ExDPWebs[currTab].Banner.trim()} style={{maxWidth:'100%', borderRadius:'16px'}}/>                
                    </div>
                    <div key={index} className="html-part-wrapper" dangerouslySetInnerHTML={{ __html: part }} />
                    </> :
                    <div key={index} className="html-part-wrapper" dangerouslySetInnerHTML={{ __html: part }} />
                );
            }
        };

        if(ExDPWebs.length!==0)
        {
            if(ExDPWebs[currTab].Model==="W")
            {
                const parts = ExDPWebs[currTab].HTML.split(/(<a[^>]*data-content="[^"]*"[^>]*title="[^"]*"[^>]*>.*?<\/a>)/).filter(Boolean);
                console.log(parts);
                if(ExDPWebs[currTab].Banner!==null)
                {
                    return (
                        <div>
                        {parts.map((part, index) => renderPart(part, index, 0))}
                        </div>
                    )
                }
                else
                {
                    return (
                        <div>
                        {parts.map((part, index) => renderPart(part, index, 1))}
                        </div>
                    )
                }
            }
            else
            {
                return (
                    <div className="mx-auto">
                        <img src={"./assets/img/PirlsImage/"+ExDPWebs[currTab].Banner.trim()} alt="" style={{maxWidth:'100%', borderRadius:'16px'}} />                  
                    </div>
                )
            }
        }
    }

    const domContent = (htmlScr: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlScr, 'text/html');
        const links = Array.from(doc.querySelectorAll('a[data-content][title]'));
        let lastNodeProcessed = doc.body.firstChild;
        const contentElements = [];
    
        while (lastNodeProcessed) {
            if (lastNodeProcessed.nodeType === Node.ELEMENT_NODE && links.includes(lastNodeProcessed as HTMLElement)) {
                // Process the link as OverlayTrigger
                const link = lastNodeProcessed as HTMLElement;
                const dataContent = link.getAttribute('data-content') || '';
                const title = link.getAttribute('title') || '';
                const innerText = link.textContent || '';
    
                const popover = (
                    <Popover id="popover">
                        <Popover.Header as="h3">{title}</Popover.Header>
                        <Popover.Body>{dataContent}</Popover.Body>
                    </Popover>
                );
    
                contentElements.push(
                    <OverlayTrigger key={title} trigger="click" placement="right" overlay={popover}>
                        <span style={{ textDecoration: 'underline', cursor: 'pointer', color:'#46A1A3' }}>{innerText}</span>
                    </OverlayTrigger>
                );
            } else {
                // Process regular content
                const content = lastNodeProcessed.nodeType === Node.ELEMENT_NODE
                    ? (lastNodeProcessed as HTMLElement).outerHTML
                    : lastNodeProcessed.textContent;
                contentElements.push(<span key={lastNodeProcessed.textContent} dangerouslySetInnerHTML={{ __html: content || '' }} />);
            }
            lastNodeProcessed = lastNodeProcessed.nextSibling;
        }
    
        return <>{contentElements}</>;
    };

    const triggerContent = (htmlScr: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlScr, 'text/html');
        const links = Array.from(doc.querySelectorAll('a[data-content][title]'));
        let lastNodeProcessed = doc.body.firstChild;
        let fullHtml = '';

        while (lastNodeProcessed) {
            if (lastNodeProcessed.nodeType === Node.ELEMENT_NODE && links.includes(lastNodeProcessed as HTMLElement)) {
                const link = lastNodeProcessed as HTMLElement;
                const dataContent = link.getAttribute('data-content') || '';
                const title = link.getAttribute('title') || '';
                const innerText = link.textContent || '';

                fullHtml += `<span class="pointer custom-popover-trigger" data-content="${dataContent}" title="${title}">${innerText}</span>`;
            } else {
                fullHtml += lastNodeProcessed.nodeType === Node.ELEMENT_NODE
                    ? (lastNodeProcessed as HTMLElement).outerHTML
                    : lastNodeProcessed.textContent || '';
            }
            lastNodeProcessed = lastNodeProcessed.nextSibling;
        }

        return fullHtml;
    };

    const extractFromDIV = (htmlScr: string):string => {
        const regex = /<div>(.*?)<\/div>/;
        const match = htmlScr.match(regex);
        return match ? match[1] : '';
    }

    const replaceBR = (htmlScr: string,space:string):string => {
        if (space === 'n') {
            return htmlScr.replace(/<br>/g, '');
        }
        else
        {
            return htmlScr.replace(/<br>/g, '\n');
        }
    }

    const replaceCR = (htmlScr: string):string => {
            return htmlScr.replace(/\n/g, '');
    }


    const parseContent = (htmlScr: string):contentType[] => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlScr, 'text/html');
        
        const links = Array.from(doc.querySelectorAll('a[data-content][title]'));

        let lastNodeProcessed = doc.body.firstChild;
        let allcontent:contentType[] = [];

        while (lastNodeProcessed) {
            if (lastNodeProcessed.nodeType === Node.ELEMENT_NODE && links.includes(lastNodeProcessed as HTMLElement)) {
                const link = lastNodeProcessed as HTMLElement;
                const dataContent = link.getAttribute('data-content') || '';
                const title = link.getAttribute('title') || '';
                const innerText = link.textContent || '';
                let iText=extractFromDIV(dataContent);
                iText=replaceBR(iText,'n');
                allcontent.push({type:"trigger",title:innerText,innerHtml:iText});

            } else {
                let normalText="";
                if(lastNodeProcessed.nodeType === Node.ELEMENT_NODE)
                {
                    const oHtml=(lastNodeProcessed as HTMLElement).outerHTML;
                    if(!oHtml.includes("<input"))
                    {
                        allcontent.push({type: "origin", title: "", innerHtml: (lastNodeProcessed as HTMLElement).outerHTML});                  
                    }

                }
                else
                {
                    normalText=lastNodeProcessed.textContent!;
                    if(normalText.length!==0)
                    {
                        //normalText=replaceCR(normalText).trim();
                        //normalText=normalText.trim();
                        if((normalText.match(/^\n/))&& !normalText.includes("<input"))  
                        {
                           // if(allcontent[allcontent.length-1].type!=="trigger")
                           // {
                           //     allcontent.push({type: "origin", title: "", innerHtml: "<br>"});
                           // }
                            normalText=replaceCR(normalText);
                            allcontent.push({type: "normal", title: "", innerHtml: normalText});

                        }
                        else
                        {
                            if(normalText.length!==0)
                            {
                                allcontent.push({type: "normal", title: "", innerHtml: normalText});
                            }
                        }

                    }
                }
            }
            lastNodeProcessed = lastNodeProcessed.nextSibling;
        }
        console.log(allcontent);
        return allcontent;
    };
    
    const dpContent=()=>{
        const regex = /<a[^>]*data-content="([^"]*)"[^>]*title="([^"]*)"[^>]*>(.*?)<\/a>/g;
        let match;
        let lastIndex = 0;
        let renderedContent:JSX.Element[] = [];

        const renderPart=()=>{
            while ((match = regex.exec(ExDPWebs[currTab].HTML)) !== null) {
                // Text before the <a> tag
                if (match.index > lastIndex) {
                    renderedContent.push(
                        <div key={lastIndex} className="html-part-wrapper" dangerouslySetInnerHTML={{ __html: ExDPWebs[currTab].HTML.slice(lastIndex, match.index) }} />
                    );
                }
        
                const dataContent = match[1];
                const title = match[2];
                const innerText = match[3];
        
                const popover = (
                    <Popover id={`popover-${lastIndex}`}>
                        <Popover.Header as="h3">{title}</Popover.Header>
                        <Popover.Body>{dataContent}</Popover.Body>
                    </Popover>
                );
        
                renderedContent.push(
                    <div key={match.index} className="overlay-trigger-wrapper">
                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                            <span className="pointer" style={{ textDecoration: 'underline', cursor: 'pointer' }}>{innerText}</span>
                        </OverlayTrigger>
                    </div>
                );
        
                lastIndex = regex.lastIndex;
            }
        
            // Add any remaining text after the last <a> tag
            if (lastIndex < ExDPWebs[currTab].HTML.length) {
                renderedContent.push(
                    <div key={lastIndex} className="html-part-wrapper" dangerouslySetInnerHTML={{ __html: ExDPWebs[currTab].HTML.slice(lastIndex) }} />
                );
            }
            console.log(renderedContent);
        }
        if(ExDPWebs.length!==0)
        {
            if(ExDPWebs[currTab].Model==="W")
            {
                const parts = ExDPWebs[currTab].HTML.split(/(<a[^>]*data-content="[^"]*"[^>]*title="[^"]*"[^>]*>.*?<\/a>)/).filter(Boolean);
                console.log(parts);
                if(ExDPWebs[currTab].Banner!==null)
                {
                    renderedContent.push(
                        <div className="mx-auto">
                            <img src={"./assets/img/PirlsImage/"+ExDPWebs[currTab].Banner.trim()} style={{maxWidth:'100%', borderRadius:'16px'}}/>                
                        </div>
                    )
                    renderPart();
                    return (
                        <div>
                        {renderedContent}
                        </div>
                    )
                }
                else
                {
                    renderPart();
                    return (
                        <div>
                        {renderedContent}
                        </div>
                    )
                }
            }
            else
            {
                return (
                    <div className="mx-auto">
                        <img src={"./assets/img/PirlsImage/"+ExDPWebs[currTab].Banner.trim()} alt="" style={{maxWidth:'100%', borderRadius:'16px'}} />                  
                    </div>
                )
            }
        }
    }


    const OverlyPopup = (title:string,content:string,link:string) => {

    return (
        <OverlayTrigger trigger="hover" placement="bottom"
        overlay={
            <Popover>
                <Popover.Header><div className='text-lg text-center'>{title}</div></Popover.Header>
                <Popover.Body><div className='text-lg text-justify' dangerouslySetInnerHTML={{ __html: content}}></div></Popover.Body>
            </Popover>
        }>
        <span className="pointer text-teal fw-bolder" style={{color:'#46A1A3'}}>{link}</span>
        </OverlayTrigger>)
    
    }

    const addTrigger=()=>{
        // Your code to execute after DOM is loaded
        const popoverTriggers = document.querySelectorAll('.custom-popover-trigger');
    
        popoverTriggers.forEach(trigger => {
            trigger.addEventListener('click', function() {
                const content = trigger.getAttribute('data-content');
                const title = trigger.getAttribute('title');
    
                // Create and show the popover
                const popover = document.createElement('div');
                popover.className = 'custom-popover';
                popover.innerHTML = `<h3>${title}</h3><p>${content}</p>`;
    
                // Position and display the popover
                // ... Add positioning logic based on 'this' element's position ...
    
                document.body.appendChild(popover);
    
                // Calculate the position
                const triggerRect = trigger.getBoundingClientRect();
                popover.style.position = 'absolute';
                popover.style.left = `${triggerRect.left + window.scrollX}px`;
                popover.style.top = `${triggerRect.bottom + window.scrollY + 10}px`; // 10px below the trigger element
    
                // Hide the popover when clicking elsewhere
                document.addEventListener('click', function(event) {
                    if (!popover.contains(event.target as Node) && !trigger.contains(event.target as Node)) {
                        popover.remove();
                    }
                }, { once: true });
    
                // Optionally, add logic to hide the popover when clicking elsewhere
            });
        });

    }


    useEffect(()=>{
        getExDPWebs(""+sessionStorage.getItem("DPNo")).then(data=>setExDPWebs(data));
        getDPLearnTaskByNo(""+sessionStorage.getItem("DPNo")).then(data=>setLearnTask(data));
        InitData();   


    },[])

    useEffect(()=>{
    /*    if(ExDPWebs.length!==0)
        {
            let leftContent=document.getElementById('left-content');
            if(leftContent!==null)
            {
                if(ExDPWebs[currTab].Model==="W")
                {
                    const renderedHtml = triggerContent(ExDPWebs[currTab].HTML);
                   // console.log(renderedHtml);
                    if(ExDPWebs[currTab].Banner!==null)
                    {
                        
                        leftContent.innerHTML=`<div className="mx-auto">
                        <img src="./assets/img/PirlsImage/${ExDPWebs[currTab].Banner.trim()}" style="max-width:100%; border-radius:16px "/>                
                        </div>`+renderedHtml;
                    }
                    else
                    {
                        leftContent.innerHTML=renderedHtml;
                    }
                }
                else
                {
                    leftContent.innerHTML=`<div className="mx-auto">
                                           <img src="./assets/img/PirlsImage/${ExDPWebs[currTab].Banner.trim()}" alt="" style="max-width:100%; border-radius:16px "/>                  
                                           </div> `
                
                }
                let anTag=document.getElementsByClassName("anotherTag");
                if(anTag.length!==0) 
                {
                    for(let i=0;i<anTag.length;i++) anTag[i].className=""+"pointer text-blue"
                }
            }
        }

        window.addEventListener('load', addTrigger);

           /*    if(ExDPWebs.length!==0)
        {
            let leftContent=document.getElementById('left-content');
            if(leftContent!==null)
            {
                if(ExDPWebs[currTab].Model==="W")
                {
                    if(ExDPWebs[currTab].Banner!==null)
                    {
                        leftContent.innerHTML=`<div className="mx-auto">
                                               <img src="./assets/img/PirlsImage/${ExDPWebs[currTab].Banner.trim()}" style="max-width:100%; border-radius:16px "/>                
                                               </div>`+ExDPWebs[currTab].HTML;
                    }
                    else
                    {
                        leftContent.innerHTML=ExDPWebs[currTab].HTML;
                    }
                 
                    let anTag=document.getElementsByClassName("anotherTag");
                    if(anTag.length!==0) 
                    {
                        for(let i=0;i<anTag.length;i++) anTag[i].className=""+"pointer text-blue"
                    }

                    /*leftContent.querySelectorAll('a[data-toggle]').forEach(aTag => {
                        const span = document.createElement('span');
                        span.innerHTML = aTag.innerHTML;
                  
                        // Copy all attributes from the a tag to the span, except for 'href'
                        Array.from(aTag.attributes).forEach(attr => {
                            switch (attr.name) {
                                case 'data-toggle':
                                    span.setAttribute('data-bs-toggle', attr.value);
                                    break;
                                case 'data-trigger':
                                    span.setAttribute('data-bs-target', 'hover focus');
                                    break;
                                case 'data-html':
                                    span.setAttribute('data-bs-html', attr.value);
                                    break;
                                case 'data-placement':
                                    span.setAttribute('data-bs-placement', attr.value);
                                    break;
                                case 'data-content':
                                    span.setAttribute('data-bs-content', attr.value);
                                    break;
                                case 'href':
                                    break;
                                default:
                                    span.setAttribute(attr.name, attr.value);
                                    break;
                            }

                        });
                        span.setAttribute('data-bs-container', 'body');
                        // Add a specific class to the span
                        span.classList.add('pointer');
                  
                        aTag.parentNode?.replaceChild(span, aTag);
                      }); */
             /*         leftContent.querySelectorAll('a[data-toggle]').forEach(aTag => {
                  
                        // Copy all attributes from the a tag to the span, except for 'href'
                        Array.from(aTag.attributes).forEach(attr => {
                            switch (attr.name) {
                                case 'data-toggle':
                                    {
                                        aTag.removeAttribute('data-toggle');
                                        aTag.setAttribute('data-bs-toggle', attr.value);
                                        break;
                                    }
                                case 'data-trigger':
                                    {
                                        aTag.removeAttribute('data-trigger');
                                        aTag.setAttribute('data-bs-trigger', 'focus');
                                        break;
                                    }
                                case 'data-html':
                                    {
                                        aTag.removeAttribute('data-html');
                                        aTag.setAttribute('data-bs-html', attr.value);
                                        break;
                                    }
                                case 'data-placement':
                                    {
                                        aTag.removeAttribute('data-placement');
                                        aTag.setAttribute('data-bs-placement', attr.value);
                                        break;
                                    }
                                case 'data-content':
                                    {
                                        aTag.removeAttribute('data-content');
                                        aTag.setAttribute('data-bs-content', attr.value);
                                        break;
                                    }
                                default:
                                    aTag.setAttribute(attr.name, attr.value);
                                    break;
                            }

                        });
                        aTag.setAttribute('data-bs-container', 'body');
                        // Add a specific class to the span
                        ;
                      });




                } 
                else
                {
                    leftContent.innerHTML=`<div className="mx-auto">
                                           <img src="./assets/img/PirlsImage/${ExDPWebs[currTab].Banner.trim()}" alt="" style="max-width:100%; border-radius:16px "/>                  
                                           </div> `
                }
            }
        }    */
    },[ExDPWebs,currTab])

    return (
        <>
            <div style={{backgroundColor:'#FCF9F3'}}>
                <TopNavPage2 />
                <div id="test" className="container d-flex flex-column flex-xl-row align-items-center mb-2">
                    <div className="d-flex align-items-start align-items-sm-center py-2 order-2 order-xl-1">
                        {
                            (sessionStorage.getItem("DPGroup")==="DP") ?
                                <img src={'./assets/img/dp/'+sessionStorage.getItem("DPImage")} className="me-2" style={{width:'80px'}}/>:    
                                <img src='./assets/img/dp/Sdgs-Love.png' className="me-2" style={{width:'80px'}}/> 
                        }
                        <div>
                            <p className="mb-1">右欄有學習任務，請閱讀後回答問題。</p>                            
                            {((sessionStorage.getItem("LoginStatus")==="0") || (sessionStorage.getItem("LoginStatus")===null) || (sessionStorage.getItem("LoginStatus")==="2")) ?
                            <div>
                                <span className="badge badge-danger">您未登入，故不會儲存本次練習結果。</span>
                            </div> : 
                            <div>
                                <span className="badge badge-secondary me-2">已學習DP文章數：{getLearnRecVal("sCountDP")}</span>
                                <span className="badge badge-secondary">本篇文章學習次數 :{sessionStorage.getItem("DPCnt")} </span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="ms-0 ms-xl-auto order-1 order-xl-2 my-3">
                        <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/dptest')}>
                        {(sessionStorage.getItem("DPGroup")==="DP") && <i className="fas fa-chevron-left">返回 多文本題組</i>}
                        {(sessionStorage.getItem("DPGroup")==="Pirls") && <i className="fas fa-chevron-left">返回 課文主題延伸</i>}
                        {(sessionStorage.getItem("DPGroup")==="SDGs") && <i className="fas fa-chevron-left">返回 SDGs</i>}
                                                                       
                        </button>
                    </div>
                </div>
            </div>
            <div className="section pt-0 mt-54">
                <div className="container">
                    <div className="text-center">
                        <span style={{fontSize:'30px', paddingRight:'4px', color:'#D13521'}}>{sessionStorage.getItem("DPNo")}</span>
                        <span style={{fontSize:'18px', color:'#000'}}>{sessionStorage.getItem("DPTopic")}</span>
                    </div>
                    <div id="start">
                        <div className="card">
                            <div className="card-body" style={{textAlign: 'left'}}>
                                <div className="tab-content" id="dpContent">
                                    <div className="tab-pane fade show active" id="deep" role="tabpanel" aria-labelledby="deep-tab">
                                        <>
                                        {
                                        (ExDPWebs.length>0 && ExDPWebs[currTab].Model==="W") ?
                                        <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth:'440px'}}>
                                            <ul className="nav nav-pills nav-fill flex-nowrap"
                                                style={{background:'#9ad6d742'}} id="dp50" role="tablist">
                                                <>
                                                {
                                                    ExDPWebs.filter(data=>data.Model==="W").map((item)=>{
                                                        if(item.PageNo-1===currTab)
                                                        {
                                                            return <li className="nav-item">
                                                                <a className="nav-link mb-0 font-weight-bold text-sm active" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                    href="" role="tab" aria-controls="" aria-selected="true" onClick={()=>handleTab(item.PageNo-1)}>
                                                                    <span className="text-second">{item.Tab}</span></a>
                                                                </li>
                                                        }
                                                        else
                                                        {
                                                            return <li className="nav-item">
                                                                <a className="nav-link mb-0 font-weight-bold text-sm" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                    href="" role="tab" aria-controls="" aria-selected="false" onClick={()=>handleTab(item.PageNo-1)}>
                                                                    <span className="text-second">{item.Tab}</span></a>
                                                                </li>
                                                        }
                                                    })

                                                }
                                                </>
                                            </ul>
                                        </div> :null
                                        }
                                        </>
                                        <div className="tab-content my-4 me-2" id="dpContent">

                                            <div className="row align-items-start text-justify">
                                                <div className="col-12 col-lg-8 pe-4" id="left-content" style={{fontSize:'20px'}}>
                                                {(ExDPWebs.length>0)?
                                                    (ExDPWebs[currTab].Model==="W")?
                                                        (ExDPWebs[currTab].Banner!==null)?
                                                            <> 
                                                            <div className="mx-auto">
                                                                <img src={"./assets/img/PirlsImage/"+ExDPWebs[currTab].Banner.trim()} style={{maxWidth:'100%', borderRadius:'16px'}}/>                                                                            
                                                            </div>
                                                            {parseContent(ExDPWebs[currTab].HTML).map((item)=>{
                                                                if(item.type==="trigger")
                                                                {
                                                                    return OverlyPopup(item.title,item.innerHtml,item.title);
                                                                }
                                                                else
                                                                {
                                                                    if(item.type==="origin")
                                                                    {
                                                                        return <div className="mx-auto" dangerouslySetInnerHTML={{ __html: item.innerHtml }} />
                                                                    }
                                                                    else
                                                                    {
                                                                        return item.innerHtml;
                                                                    }
                                                                }})
                                                            }
                                                            </> :
                                                            <>
                                                            {parseContent(ExDPWebs[currTab].HTML).map((item)=>{
                                                                if(item.type==="trigger")
                                                                {
                                                                    return OverlyPopup(item.title,item.innerHtml,item.title);
                                                                }
                                                                else
                                                                {
                                                                    if(item.type==="origin")
                                                                    {
                                                                        return <div className="mx-auto" dangerouslySetInnerHTML={{ __html: item.innerHtml }} />
                                                                    }
                                                                    else
                                                                    {
                                                                        return item.innerHtml;
                                                                    }
                                                                }})
                                                            }
                                                            </>
                                                        :
                                                        (ExDPWebs[currTab].Banner!==null) &&
                                                        <div className="mx-auto">
                                                            <img src={"./assets/img/PirlsImage/"+ExDPWebs[currTab].Banner.trim()} style={{maxWidth:'100%', borderRadius:'16px'}}/>                                                                            
                                                        </div>                                                
                                                    : null    
                                                }                                                    
                                                
                                                </div>                                                       
                                                <DPQAPage 
                                                    ArticleNo={""+sessionStorage.getItem("DPNo")}
                                                    PageNo={currTab}
                                                    questionDP={questionDP}
                                                    ansStateDP={ansStateDP}
                                                    ansQDP={ansQDP}
                                                    LearnTask={learnTask}
                                                    finishFlag={false}
                                                    currTab={currTab}
                                                    QMode={(ExDPWebs.length>0)?ExDPWebs[currTab].Model:""}
                                                    OPMode={getOpMode()}
                                                    SetAnsStateDP={setAnsStateDP}
                                                    SetAnsQDP={setAnsQDP}
                                                    SetFinishFlag={setFinishFlag}
                                                    SetCurrTab={setCurrTab}
                                                />
                                        </div>
                                    </div>
                                    <>
                                    {
                                    (ExDPWebs.length>0 && ExDPWebs[currTab].Model==="W") ?
                                    <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth:'440px'}}>
                                        <ul className="nav nav-pills nav-fill flex-nowrap"
                                            style={{background:'#9ad6d742'}} id="dp50" role="tablist">
                                            <>
                                            {
                                                ExDPWebs.filter(data=>data.Model==="W").map((item)=>{
                                                    if(item.PageNo-1===currTab)
                                                    {
                                                        return <li className="nav-item">
                                                            <a className="nav-link mb-0 font-weight-bold text-sm active" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                href="" role="tab" aria-controls="" aria-selected="true" onClick={()=>handleTab(item.PageNo-1)}>
                                                                <span className="text-second">{item.Tab}</span></a>
                                                            </li>
                                                    }
                                                    else
                                                    {
                                                        return <li className="nav-item">
                                                            <a className="nav-link mb-0 font-weight-bold text-sm" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                href="" role="tab" aria-controls="" aria-selected="false" onClick={()=>handleTab(item.PageNo-1)}>
                                                                <span className="text-second">{item.Tab}</span></a>
                                                            </li>
                                                    }
                                                })

                                            }
                                            </>

                                        </ul>
                                    </div>:null
                                    }
                                    </>
                                    </div>
                                </div>
                            </div>
                        </div>                  
      
                        {finishFlag && <DispDPAns ansQDP={ansQDP} />}
                    
                    </div>
                </div>
            </div>
            
            <FooterPage />
        </>

    );
}

export default DPPracticePage;