import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import IAnsPPX from '../../interfaces/AnsPPX'
import { IExEssayTopic } from '../../interfaces/ExEssayTopic'
import IPageProps from '../../interfaces/page'
import { getLearnRecCurrVal, getLearnRecCurrValStr, getLearnRecVal, newLearnRecCurr, setLearnRecCurrValStr, setLearnRecVal } from '../../modules/SessionTestCnt'
import { genFerrisImageURL, handleLink, padZero } from '../../modules/utils'
import { getExEssayQADetailByNo, getExEssayTopicByLevelandType } from '../../services/ExEssayService'
import FooterPage from '../footer'
import TopNavPage2 from '../topNav2'
import Excel from 'exceljs';
import FileSaver from 'file-saver'
import { AddExStuPP6, AddExStuRec } from '../../services/ExStuRecService'
import { IExStudent } from '../../interfaces/ExStudent'

const PPTestPage4: React.FunctionComponent<IPageProps> = props => {
    
    const [refresh,setRefresh]=useState(false); 
    const [activePP4,setActivePP4]=useState(false);
    const [hardLevel,setHardLevel]=useState(1);
    const [reHardLevel,setReHardLevel]=useState(1);
    const [currTopic,setCurrTopic]=useState(-1);
    const [essayTopics,setEssayTopics]=useState<IExEssayTopic[]>([])
    const [ansListPP4,setAnsListPP4]=useState<IAnsPPX[]>([]);
    const [ansPP4,setAnsPP4]=useState<IAnsPPX>({
        No:'',
        Sno:0,
        Content:'',
        Abstract:'',
        InpAbstract:'',
        KeyWordQuestion:'',
        KeyWords:[],
        KeyWordsAns:'',
        InpKeyWordsAns:''
    });
    const [selectedPP4,setSelectedPP4]=useState(false);

    const [lCountPP4,setlCountPP4]=useState(0);

    const [ReCurrTopic,setReCurrTopic]=useState(-1);
    const [ReEssayTopics,setReEssayTopics]=useState<IExEssayTopic[]>([])

    const getDateTimestring=(timestamp:number):string=>{
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    const exportExcel=async ()=>{

        const wb=new Excel.Workbook();
        const ws=wb.addWorksheet('讀出滋味-作答');
        ws.columns=[
            {key:'Sno', header:'編號',width:10},
            {key:'KeyWordsAns', header:'關鍵字',width:30},
            {key:'InpKeyWordsAns', header:'作答的關鍵字',width:30}
        ];       
        ansListPP4.forEach((item)=>{
            let rowItem={
                Sno:item.Sno,
                KeyWordsAns:genKeyWordList(item.KeyWords,item.KeyWordsAns.trim()),
                InpKeyWordsAns:genKeyWordList(item.KeyWords,item.InpKeyWordsAns)
            }
            ws.addRow(rowItem);
        });
        let stuInfo="";
        if(sessionStorage.getItem("LoginStatus")==="1")
        {
            const loginStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!);
            stuInfo=loginStu.StuNo.trim()+"-"+loginStu.StuName.trim();
        }

        await wb.xlsx.writeBuffer().then(buffer=> FileSaver.saveAs(new Blob([buffer]), `${stuInfo}-讀出滋味作答-${getDateTimestring(Date.now())}.xlsx`))
        
    }

    const handleReHardLevel=async(lvl:number)=>{
        if(reHardLevel===lvl) return
        setReEssayTopics(await getExEssayTopicByLevelandType(lvl,'B'));
        setReHardLevel(lvl);        
        setReCurrTopic(-1);  
    }

    const RestReHardLevel=async ()=>{
        setReHardLevel(1);
        setReCurrTopic(-1);
        setReEssayTopics(await getExEssayTopicByLevelandType(1,'B'));
    }
    
    const handleReLevelSelect=async ()=>{
        if(ReCurrTopic===-1) return        
        setCurrTopic(ReCurrTopic);
        setEssayTopics(ReEssayTopics);
        setHardLevel(reHardLevel);
        setAnsListPP4([]);  
        setReHardLevel(1);
        setReCurrTopic(-1);
        setReEssayTopics(await getExEssayTopicByLevelandType(1,'B'));
        setSelectedPP4(false);
    }

    const getStar=(lvl:number):string=>{
        let starStr='';
        switch (lvl){
            case 1:
                starStr='★☆☆'
                break;
            case 2:
                starStr='★★☆'
                break;
            case 3:
                starStr='★★★'
                break;
        }
        return starStr;
    }

    const handleHardLevel=async (lvl:number)=>{
        setEssayTopics(await getExEssayTopicByLevelandType(lvl,'B'));
        setHardLevel(lvl);
        setCurrTopic(-1);        
    }

    const startPP4= async()=>{
        if(currTopic===-1) return
        setActivePP4(true);
        setReHardLevel(1);
        setReCurrTopic(-1);
        setReEssayTopics(await getExEssayTopicByLevelandType(1,'B'));
        //processAnsListPP4();        
        getAnsPP4();
    }

    const handlePrevAnsPP4=()=>{
        if(currTopic===0) return        
        setCurrTopic(currTopic-1);
        setSelectedPP4(false);
    }

    const handleNextAnsPP4= ()=>{
        if(currTopic===essayTopics.length-1) return
        setCurrTopic(currTopic+1);
        setSelectedPP4(false);
    }

    const updateAnsListPP4=()=>{
        let found=ansListPP4.findIndex((obj)=>{
            return obj.Sno===essayTopics[currTopic]?.Sno
        })
        if(found===-1)
        {
            ansListPP4.push(ansPP4)
            if(ansListPP4.length>1)
            {
                ansListPP4.sort((a,b)=>(a.Sno < b.Sno) ? -1:1);
            }
            setAnsListPP4(ansListPP4);
        }
        else
        {
            let tmp=ansListPP4;
            tmp[found].InpKeyWordsAns=ansPP4.InpKeyWordsAns;
            setAnsListPP4(tmp);
            setlCountPP4(countAnsPP4());
            //setLearnRecVal("lCountPP4",countAnsPP4());
        }

    }

    const countAnsPP4=():number=>{
        let cnt=0;
        ansListPP4.map((item,i)=>{
            if(item.InpKeyWordsAns!=="") cnt++;
        })
        return cnt;

    }

    const processAnsListPP4= async()=>{
        let found=ansListPP4.findIndex((obj)=>{
            return obj.Sno===essayTopics[currTopic]?.Sno
        })
        if(found!==-1)
        {
            setAnsPP4(ansListPP4[found]);
            setRefresh(true);
        }
        else
        {
            let tmp=await getExEssayQADetailByNo(essayTopics[currTopic]?.No)
            setAnsPP4(tmp);
            ansListPP4.push(tmp)
            if(ansListPP4.length>1)
            {
                ansListPP4.sort((a,b)=>(a.Sno < b.Sno) ? -1:1);
            }
            setAnsListPP4(ansListPP4);    
        }    
    }

    const getAnsPP4= async()=>{
        let found=ansListPP4.findIndex((obj)=>{
            return obj.Sno===essayTopics[currTopic]?.Sno
        })
        if(found!==-1)
        {
            setAnsPP4(ansListPP4[found]);
            setRefresh(true);
        }
        else
        {
            let tmp=await getExEssayQADetailByNo(essayTopics[currTopic]?.No)
            setAnsPP4(tmp);   
        }
    
    }

    const handleKeyWordSelect=(sel:number)=>{

        let sortKeyWords=ansPP4?.InpKeyWordsAns;
        const key=sel.toString().trim();
        if(sortKeyWords.includes(key))
        {
            sortKeyWords=sortKeyWords.split(key).join('');
        }
        else
        {
            sortKeyWords=sortKeyWords+sel.toString();
            sortKeyWords=sortKeyWords.split("").sort().join("");
        }
        setAnsPP4({
            ...ansPP4,
            InpKeyWordsAns:sortKeyWords
        })
    }

    const handleAnsPP4=()=>{
        if(ansPP4.InpKeyWordsAns==='') return;
        setSelectedPP4(true);
    }

    const findTopic=(idx:number):string=>{
        const found=essayTopics.findIndex((obj)=>{
            return idx===obj.Sno
        });
        if(found===-1) return '';
        return essayTopics[found].Topic;
    }

    const genKeyWordList=(kwList:string[],ansStr:string):string=>{
        let result=''
        for(var i=0;i<ansStr.length;i++)
        {
            result=result+kwList[Number(ansStr.charAt(i))-1];
            if(i!==ansStr.length-1)
            {
                result=result+","
            }
        }
        return result
    }

    const chooseCurrTopic=(idx:number)=>{
        setCurrTopic(idx);
        setSelectedPP4(false);
    }

    useEffect(()=>{
        processAnsListPP4();
    },[currTopic])

    useEffect(()=>{
        if(selectedPP4)
        {
            setLearnRecVal("sCountPP6",getLearnRecVal("sCountPP6")+1);
            AddExStuPP6(Number(sessionStorage.getItem("LoginID")),ansPP4.No,genKeyWordList(ansPP4.KeyWords,ansPP4.InpKeyWordsAns));
            newLearnRecCurr(Number(sessionStorage.getItem("LoginID")),"PP6",ansPP4.No);
            setLearnRecCurrValStr("TQno",ansPP4.No);
            AddExStuRec(
                getLearnRecCurrVal("userID"),
                getLearnRecCurrValStr("TType"),
                getLearnRecCurrValStr("TQno"),
                getLearnRecCurrVal("sCorrect"),
                getLearnRecCurrVal("sWrong"),
                getLearnRecCurrVal("sSecond"),)
            sessionStorage.setItem("LearnRecCurr","");   
            
            
            updateAnsListPP4();
            setRefresh(true);

        }
    },[selectedPP4])

    useEffect(()=>{
        if(refresh) setRefresh(false);
    },[refresh])

    useEffect(()=>{
        getExEssayTopicByLevelandType(1,'B').then(data=>setEssayTopics(data))
        //setLearnRecVal("lCountPP4",0);
    },[])

    

    
    return (
            <>
            <div style={{ backgroundColor: '#FCF9F3' }}>
                <div><TopNavPage2 /></div>
                <div className="container d-flex flex-column flex-md-row align-items-center mb-2">
                    <div className="d-flex align-items-start align-items-sm-center py-2 order-2 order-md-1">
                        <img src="./assets/img/PP-04.svg" className="me-2" style={{ width: '80px' }} />
                        <div>
                            <p className="mb-1">閱讀短文，讀出文章背後的滋味。</p>
                            {(sessionStorage.getItem("LoginStatus")==="0") || (sessionStorage.getItem("LoginStatus")===null) || (sessionStorage.getItem("LoginStatus")==="2") ?
                                <div>
                                    <span className="badge badge-danger">您未登入，故不會儲存本次練習結果。</span>
                                </div> :
                                <div>
                                    <span className="badge badge-secondary">已學習短文數：{getLearnRecVal("sCountPP6")}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="ms-auto order-1 order-md-2 my-3">
                        <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/pptest')}>
                            <i className="fas fa-chevron-left"></i>
                            返回 PP 基礎讀寫
                        </button>
                    </div>
                </div>
                <div className="section pt-0">
                    <div className="container">
                        {!activePP4 &&
                        <div id="info">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="card text-center">
                                        <div className="card-header">
                                            <h5>請先選擇短文的「難易度」</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="nav-wrapper position-relative end-0 mb-5">
                                                {/*<button type="button" className={["btn btn-round mx-1", hardLevel===1?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleHardLevel(1)} >★☆☆</button>
                                                <button type="button" className={["btn btn-round mx-1", hardLevel===2?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleHardLevel(2)} >★★☆</button>
                                                <button type="button" className={["btn btn-round mx-1", hardLevel===3?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleHardLevel(3)} >★★★</button>*/}
                                                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="one" aria-selected="true" onClick={()=>handleHardLevel(1)}>
                                                            ★☆☆
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="two" aria-selected="false" onClick={()=>handleHardLevel(2)}>
                                                            ★★☆
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="three" aria-selected="false" onClick={()=>handleHardLevel(3)}>
                                                            ★★★
                                                        </a>
                                                    </li>
                                                </ul> 
                                            </div>
                                            <Dropdown className="dropdown mx-auto mb-2" style={{width:'fit-content'}}>
                                                <Dropdown.Toggle className="btn-outline-secondary btn-round btn-lg">
                                                    { (currTopic===-1) && <>選擇從哪篇短文開始</> }
                                                    { (currTopic!==-1) && padZero(essayTopics?.[currTopic].Sno,2)+'.'+essayTopics?.[currTopic].Topic+'........'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{maxHeight:400,overflow:'auto'}}>
                                                    {
                                                        essayTopics?.map((item,i)=>{
                                                            return <Dropdown.Item key={item.Sno} onClick={()=>setCurrTopic(i)}>{padZero(item.Sno,2)}.{item.Topic}......</Dropdown.Item>
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/*
                                            <div className="dropdown mx-auto" style={{width:'fit-content'}}>
                                                <button type="button" className="btn btn-outline-secondary btn-round btn-lg dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                { (currTopic===-1) && <>選擇從哪篇短文開始</> }
                                                { (currTopic!==-1) && padZero(essayTopics?.[currTopic].Sno,2)+'.'+essayTopics?.[currTopic].Topic+'........'}  
                                                </button>
                                                <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                                {
                                                        essayTopics?.map((item,i)=>{
                                                        return <li><a className="dropdown-item border-radius-md" onClick={()=>setCurrTopic(i)} >{padZero(item.Sno,2)}.{item.Topic}......</a></li>    
                                                        })
                                                }
                                                </ul>
                                            </div> */}
                                            <button type="button" className="btn btn-round btn-primary btn-lg" onClick={()=>startPP4()}>開始練習</button>
                                            <hr className="horizontal dark"/>
                                            <ul className="list-group list-group-flush mt-2 text-start">
                                                <li className="list-group-item">1.「讀出滋味」在閱讀時要進行推論。</li>
                                                <li className="list-group-item">2.沒有絕對的標準答案，各題的參考答案為的是幫助思考，你可以不同意。</li>
                                                <li className="list-group-item">3.選出答案後更重要的是問自己「為什麼」，依據文本回答，讓自己言之有理。</li>
                                                <li className="list-group-item">4.請用桌機、筆電或平板(橫放)閱讀，不建議用手機。</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {activePP4 &&
                        <div id="start" >
                            <div className="card">
                                <div className="card-body" style={{textAlign:'left'}}>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <div className="py-3">
                                                <label className="mb-3">閱讀下面的短文</label>
                                                <p className="lead">{ansPP4?.Content}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="py-3">
                                                <button className="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#lvModal">{getStar(hardLevel)}（重選難易度）</button>
                                                <Dropdown className="dropdown mx-auto" style={{width:'fit-content'}}>
                                                    <Dropdown.Toggle className="btn btn-outline-secondary btn-round dropdown-toggle text-wrap">
                                                        {padZero(essayTopics?.[currTopic].Sno,2)+'.'+essayTopics?.[currTopic].Topic+'........'}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu px-2 py-3">
                                                        {
                                                            essayTopics?.map((item,i)=>{
                                                                return <Dropdown.Item className="dropdown-item border-radius-md" key={item.Sno} onClick={()=>chooseCurrTopic(i)}>{padZero(item.Sno,2)}.{item.Topic}......</Dropdown.Item>
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <label className="mb-3">{ansPP4?.KeyWordQuestion}</label>
                                                <div>
                                                {
                                                        ansPP4?.KeyWords.map((item,i)=>{
                                                            const key=(i+1).toString()
                                                            if(item){
                                                                if(!ansPP4.InpKeyWordsAns.includes(key))
                                                                {                                                                
                                                                    return <button type="button" className="btn btn-outline-secondary" onClick={()=>handleKeyWordSelect(i+1)}>{item}</button>
                                                                }
                                                                else
                                                                {
                                                                    return <button type="button" className="btn btn-secondary" onClick={()=>handleKeyWordSelect(i+1)}>{item}</button>
                                                                }
                                                            }
                                                            else
                                                            {
                                                                return <></>
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center flex-column">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-round btn-primary my-3" disabled={!selectedPP4} onClick={()=>handlePrevAnsPP4()}>回上一篇</button> 

                                    <button type="button" className="btn btn-round btn-primary btn-lg m-3 px-5 w-fc" disabled={selectedPP4} onClick={()=>handleAnsPP4()}>確定</button>
                                    
                                    <button className="btn btn-round btn-primary my-3" disabled={!selectedPP4} onClick={()=>handleNextAnsPP4()}>到下一篇</button> 

                                </div>
                                <button type="button" className="btn btn-link" onClick={()=>handleLink('/pptest')}>結束練習</button>
                            </div>
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-lg-8">
                                            <div className="text-center">
                                                {/*<button className="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#lvModal">{getStar(hardLevel)}（重選難易度）</button>*/}
                                                {/*<div className="dropdown mx-auto" style={{width:'fit-content'}}>
                                                    <button type="button" className="btn btn-outline-secondary btn-round dropdown-toggle text-wrap" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {padZero(essayTopics?.[currTopic].Sno,2)+'.'+essayTopics?.[currTopic].Topic+'........'}
                                                    </button>
                                                    <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        essayTopics?.map((item,i)=>{
                                                        return <li><a className="dropdown-item border-radius-md" onClick={()=>handleTopicChange(i)} >{padZero(item.Sno,2)}.{item.Topic}......</a></li>    
                                                        })
                                                    }
                                                    </ul>
                                                </div> */}
                                                {/*<Dropdown className="dropdown mx-auto" style={{width:'fit-content'}}>
                                                    <Dropdown.Toggle className="btn btn-outline-secondary btn-round dropdown-toggle text-wrap">
                                                        {padZero(essayTopics?.[currTopic].Sno,2)+'.'+essayTopics?.[currTopic].Topic+'........'}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu px-2 py-3">
                                                        {
                                                            essayTopics?.map((item,i)=>{
                                                                return <Dropdown.Item className="dropdown-item border-radius-md" key={item.Sno} onClick={()=>chooseCurrTopic(i)}>{padZero(item.Sno,2)}.{item.Topic}......</Dropdown.Item>
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                    </Dropdown>*/}
                                            </div>
                                            {/*<hr className="horizontal dark"/>*/}
                                            <div className="accordion" id="accordionRental">
                                                <div className="accordion-item mb-3">
                                                   {/* <h5 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button text-dark collapsed px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            參考答案
                                                            <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                            <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                        </button>
                                                </h5> */}
                                                    <div className="d-flex justify-content-between align-items-center accordion-header" id="headingOne">
                                                        <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{width:'auto'}}>
                                                            <h5 className="my-0 me-4">參考答案</h5>
                                                            <i className="collapse-close fas fa-angle-up pt-0 position-absolute end-0 fs-22"
                                                            aria-hidden="true"></i>
                                                            <i className="collapse-open fas fa-angle-down pt-0 position-absolute end-0 fs-22"
                                                            aria-hidden="true"></i>
                                                        </button>
                                                        <a className="btn btn-outline-secondary btn-round text-nowrap mb-0" type="button" onClick={()=>exportExcel()}>
                                                        下載全部作答結果
                                                        </a>
                                                    </div>
                                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionRental" >
                                                    {
                                                        /*ansListPP4.map((item,i)=>{
                                                            return <div className="bg-gray-300 border-radius-lg p-2 my-4">
                                                                        <p className="text-sm font-weight-bold pt-2">{padZero(item.Sno,2)} {findTopic(item.Sno)}</p>
                                                                        <hr className="horizontal dark"/>
                                                                        <p className="text-secondary text-sm font-weight-bold mb-0">您寫的答案</p>
                                                                        <p>{genKeyWordList(item.KeyWords,item.InpKeyWordsAns)}</p>
                                                                        <p className="text-secondary text-sm font-weight-bold mb-0">建議答案</p>
                                                                        <p>{genKeyWordList(item.KeyWords,item.KeyWordsAns.trim())}</p>
                                                                    </div>

                                                        })*/
                                                        selectedPP4 &&
                                                        <div className="bg-gray-300 border-radius-lg p-2 my-4">
                                                            <p className="text-sm font-weight-bold pt-2">{padZero(ansPP4.Sno,2)} {findTopic(ansPP4.Sno)}</p>
                                                            <hr className="horizontal dark"/>
                                                            <p className="text-secondary text-sm font-weight-bold mb-0">您寫的答案</p>
                                                            <p>{genKeyWordList(ansPP4.KeyWords,ansPP4.InpKeyWordsAns)}</p>
                                                            <p className="text-secondary text-sm font-weight-bold mb-0">建議答案</p>
                                                            <p>{genKeyWordList(ansPP4.KeyWords,ansPP4.KeyWordsAns.trim())}</p>
                                                        </div>

                                                    }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(sessionStorage.getItem("LoginStatus")==="1") &&
                                        <>
                                        <div className="col-12 col-lg-4">
                                            <hr className="horizontal dark mt-3 d-lg-none"/>
                                            <div className="col-12 d-flex flex-column flex-sm-row flex-lg-column align-items-center">
                                                <img src={genFerrisImageURL(getLearnRecVal("sCountPP6"),3,10)} className="w-100 w-sm-50 w-lg-100"/>
                                                    完成10篇短文，晉一級並點亮一個車廂，最高為六級。
                                            </div>
                                        </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>    
                <FooterPage />
                {/*<!-- 難易度 --> */}
                <div className="modal fade" id="lvModal" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">重選難易度</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body pb-3">
                                <div className="nav-wrapper position-relative mb-2 text-center">
                                    <ul className="nav nav-pills nav-fill p-1 mb-3" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="one" aria-selected="true" onClick={()=>handleReHardLevel(1)}>
                                                ★☆☆
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="two" aria-selected="false" onClick={()=>handleReHardLevel(2)}>
                                                ★★☆
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="three" aria-selected="false" onClick={()=>handleReHardLevel(3)}>
                                                ★★★
                                            </a>
                                        </li>
                                    </ul>
                                    {/*
                                    <button type="button" className={["btn btn-round mx-1", reHardLevel===1?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleReHardLevel(1)} >★☆☆</button>
                                    <button type="button" className={["btn btn-round mx-1", reHardLevel===2?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleReHardLevel(2)} >★★☆</button>
                                    <button type="button" className={["btn btn-round mx-1", reHardLevel===3?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleReHardLevel(3)} >★★★</button>*/}
                                    <Dropdown className="dropdown mx-auto mb-2" style={{width:'fit-content'}}>
                                        <Dropdown.Toggle className="btn-outline-secondary btn-round btn-lg">
                                            { (ReCurrTopic===-1) && <>選擇從哪篇短文開始</> }
                                            { (ReCurrTopic!==-1) && padZero(ReEssayTopics?.[ReCurrTopic].Sno,2)+'.'+ReEssayTopics?.[ReCurrTopic].Topic+'........'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{maxHeight:400,overflow:'auto'}}>
                                            {
                                                ReEssayTopics?.map((item,i)=>{
                                                    return <Dropdown.Item key={item.Sno} onClick={()=>setReCurrTopic(i)}>{padZero(item.Sno,2)}.{item.Topic}......</Dropdown.Item>
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                {/*<ul className="nav nav-pills nav-fill p-1 mb-3" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="one" aria-selected="true" onClick={()=>handleReHardLevel(1)}>
                                            ★☆☆
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="two" aria-selected="false" onClick={()=>handleReHardLevel(2)}>
                                            ★★☆
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="three" aria-selected="false" onClick={()=>handleReHardLevel(3)}>
                                            ★★★
                                        </a>
                                    </li>
                                </ul>
                                <div className="dropdown mx-auto" style={{width:'fit-content'}}>
                                    <button type="button" className="btn btn-outline-secondary btn-round dropdown-toggle text-wrap" data-bs-toggle="dropdown" aria-expanded="false">
                                    { (ReCurrTopic===-1) && <>選擇從哪篇短文開始</> }
                                    { (ReCurrTopic!==-1) && padZero(ReEssayTopics?.[ReCurrTopic].Sno,2)+'.'+ReEssayTopics?.[ReCurrTopic].Topic+'........'}  
                                    </button>
                                    <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                    {
                                        ReEssayTopics?.map((item,i)=>{
                                            return <li><a className="dropdown-item border-radius-md" onClick={()=>setReCurrTopic(i)} >{padZero(item.Sno,2)}.{item.Topic}......</a></li>    
                                        })
                                    }
                                    </ul>
                                </div> */}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>RestReHardLevel()}>取消</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>handleReLevelSelect()}>確定</button>
                                </div>
                            </div>    
                        </div>
                    </div>       

                </div>
            </div>
        </>
    );
}

export default PPTestPage4;