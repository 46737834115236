/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import FooterPage from './footer';
import TopNavPage1 from './topNav1';

const TutorialPage: React.FunctionComponent = props => {
    return (
        <>
        <TopNavPage1 />
        <div className="section pt-3 pt-lg-5">
            <div className="container px-3 px-md-4">
            <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                <h5 className="title mb-3 me-3">數位讀寫資源</h5>
                <h5 className="order-2 order-md-1 mb-3">教學資源</h5>
                <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width:'232px'}}>
                <a href="/tutsite" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
                    好站推薦</a>
                </div>
            </div>
            <div className="card p-3 p-md-4">
                <div className="text-second bg-second text-center border-radius-sm mb-3 fw-bolder" style={{width:'240px'}}>
                數位讀寫相關文章和簡報
                </div>
                <ol>
                <li><a href="https://www.parenting.com.tw/article/5078894" target="_blank"
                    className="text-second">柯華葳著，<b>培養網路自學力，從有目的的搜尋開始</b>，原載於親子天下105期，2019.3.1</a></li>
                <li><a href="https://www.parenting.com.tw/article/5075968" target="_blank"
                    className="text-second">柯華葳著，<b>培養孩子網路上的自學力</b>，原載於親子天下97期，2018.1.1</a></li>
                <li><a href="https://reading.cw.com.tw/trend-article/90" target="_blank"
                    className="text-second">柯華葳著，<b>培養數位閱讀素養就是培養數位自學力，第一步從有目的的搜尋開始</b>，原載於天下雜誌，2018.12.21</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut4" className="text-second">柯華葳著，<b>由ePIRLS
                        看自主學習</b>，2019年</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut5"
                    className="text-second">柯華葳著，<b>數位線上閱讀素養教學手冊</b>，2019年</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut6"
                    className="text-second">林玫伶/文，<b>數位閱讀於專題探究學習的應用</b>，2019年</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut7" className="text-second">林玫伶/文，<b>數位讀寫過五關
                        指導學習突破點</b>，原載於國語日報第13版，2018.6.13</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut8"
                    className="text-second">林玫伶/文，<b>柯老師紀念專輯之三：發揮前瞻眼光　推動數位閱讀</b>，原載於國語日報，2021.11.16</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut9" className="text-second">李宗怡/文圖，<b>組成探險隊
                        尋覓火星研究主題(一)</b>，原載於國語日報第14版，2018.9.22</a></li>
                <li><a href="javascript:" data-bs-toggle="modal" data-bs-target="#tut10" className="text-second">李宗怡/文圖，<b>組成探險隊
                        尋覓火星研究主題(二)</b>，原載於國語日報第14版，2018.9.23</a></li>
                <li><a href="https://www.books.com.tw/products/0010816508" target="_blank"
                    className="text-second">班‧赫柏德著，洪翠薇譯，<b>《數位世界的孩子4：為什麼我要善用社群媒體？》</b>，親子天下出版，2019年。</a></li>
                <li><a href="https://www.books.com.tw/products/0010817194" target="_blank"
                    className="text-second">市村均文，賴庭筠譯，<b>《破解! 傳達的技倆：假新聞.偽科學》</b>，幼獅出版，2019年。</a></li>
                <li><a href="https://sites.google.com/site/reading8learning01/pirls/pirls-2016" target="_blank"
                    className="text-second">科技部科教發展及國際合作司、教育部國民及學前教育署、國立中央大學學習與教學研究所主辦，<b>《數位閱讀素養學習活動手冊》</b>，2014.12</a></li>
                </ol>
            </div>

            <div className="card p-3 p-md-4 mt-5">
                <div className="text-second bg-second text-center border-radius-sm mb-3 fw-bolder" style={{width:'240px'}}>
                數位學習工具
                </div>
                <ul className="row">
                <li className="col-12 col-md-6 col-lg-4"><a href="https://www.xmind.net/" target="_blank"
                    className="text-second">Xmind-心智圖繪製工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://coggle.it/" target="_blank"
                    className="text-second">Coggle-線上心智圖繪製工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://pdfcandy.com/" target="_blank"
                    className="text-second">pdfcandy-線上 PDF 工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://reurl.cc/main/tw" target="_blank"
                    className="text-second">reurl-線上縮短網址工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://evernote.com/intl/zh-tw/" target="_blank"
                    className="text-second">Evernote-筆記工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://www.onenote.com" target="_blank"
                    className="text-second">OneNote-筆記工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://www.slideshare.net/" target="_blank"
                    className="text-second">Slideshare-線上簡報分享</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://app.getpocket.com/" target="_blank"
                    className="text-second">Pocket-線上稍後閱讀工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://www.timetoast.com/" target="_blank"
                    className="text-second">Timetoast-歷史事件時間軸</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://feedly.com/" target="_blank"
                    className="text-second">Feedly-RSS線上閱讀工具</a> </li>
                <li className="col-12 col-md-6 col-lg-4"><a href="https://www.zuvio.com.tw/" target="_blank"
                    className="text-second">Zuvio-課堂即時互動工具</a> </li>
                </ul>
            </div>

            <div className="card p-3 p-md-4 mt-5">
                <div className="text-second bg-second text-center border-radius-sm mb-4 fw-bolder" style={{width:'240px'}}>
                線上探究(EP)各階段教學
                </div>
                <div className="row">
                <div className="col-lg-6 mb-5">
                    <div className="embed-responsive embed-responsive-16by9">
                    <span id="LblSlide1">
                        <iframe src="//www.slideshare.net/slideshow/embed_code/key/HL4VzukgbKTTV9"
                        className="w-100 border-radius-lg" style={{border:'1px solid #CCC', height:'400px'}} allowFullScreen></iframe>
                    </span>
                    </div>
                </div>
                <div className="col-lg-6 mb-5">
                    <div className="embed-responsive embed-responsive-16by9">
                    <span id="LblSlide2">
                        <iframe src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd"
                        className="w-100 border-radius-lg" style={{border:'1px solid #CCC', height:'400px'}} allowFullScreen></iframe>
                    </span>
                    </div>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-6 mb-5">
                    <div className="embed-responsive embed-responsive-16by9">
                    <span id="LblSlide3">
                        <iframe src="//www.slideshare.net/slideshow/embed_code/key/g8zXWc7qtfNNjn"
                        className="w-100 border-radius-lg" style={{border:'1px solid #CCC', height:'400px'}} allowFullScreen></iframe>

                    </span>
                    </div>
                </div>
                <div className="col-lg-6 mb-5">
                    <div className="embed-responsive embed-responsive-16by9">
                    <span id="LblSlide4">
                        <iframe src="//www.slideshare.net/slideshow/embed_code/key/r5peeTpp761fAh"
                        className="w-100 border-radius-lg" style={{border:'1px solid #CCC', height:'400px'}} allowFullScreen></iframe>
                    </span>
                    </div>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-6 mb-5">
                    <div className="embed-responsive embed-responsive-16by9">
                    <span id="LblSlide5">
                        <iframe src="//www.slideshare.net/slideshow/embed_code/key/sF3WTJjXayexwq"
                        className="w-100 border-radius-lg" style={{border:'1px solid #CCC', height:'400px'}} allowFullScreen></iframe>
                    </span>
                    </div>
                </div>
                <div className="col-lg-6 mb-5">
                    <div className="embed-responsive embed-responsive-16by9">
                    <span id="LblSlide6">
                        <iframe src="//www.slideshare.net/slideshow/embed_code/key/8DYUbEJVfM11n2"
                        className="w-100 border-radius-lg" style={{border:'1px solid #CCC', height:'400px'}} allowFullScreen></iframe>
                    </span>
                    </div>
                </div>
                </div>

            </div>

            </div>
        </div>

        <FooterPage/>
        </>
    );
}

export default TutorialPage;