import Modal from 'react-bootstrap/Modal'
import { handleLinkWithBlank } from '../../modules/utils';
import { useState } from 'react';

interface ModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    //setOpenNotifyModal: (val:boolean) => void;
    setReply: (val:boolean) => void;
    notification:string;
  }
  
  export const NotifyModal: React.FC<ModalProps> = (props) => {
    const handleSetReply=(val:boolean)=>{
        console.log('in notify modal'+val)
        props.setReply(val);
        props.onClose();
    }
  
    return <Modal show={props.isOpen} onHide={props.onClose}>
                <Modal.Header> 
                    <Modal.Title><h5 className="mx-auto">{props.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'>{props.notification}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" style={{marginLeft:'10px'}} onClick={()=>handleSetReply(false)}>取消</button>
                    <button type="button" className="btn btn-primary" style={{marginLeft:'10px'}} onClick={()=>handleSetReply(true)}>繼續</button>
                </Modal.Footer>
            </Modal>

  };