import React, { useContext, useEffect, useRef, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { handleLink } from '../../modules/utils';
import ApplyFunctionBar from './component/applyFunctionBar';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';
import { alertEx, currentClass, currentClassForBtn, getLoginInfo } from './tool/helper';
import { IApplyFormData, IupdateApply as IUpdateApply } from '../../interfaces/EpModal';
import { UpdateApplyBaseInfo, getAllSchool, getExSchoolAreaByCity, getExSchoolByCityArea, getExSchoolCity } from '../../services/EP/EpApplyHomeService';
import { BSModal } from '../../components/modal/bsmodal';
import { format } from 'path';
import {MyContext}  from '../home'
import { getDrControlByClassControlNow, getDrControlByClassNow } from '../../services/EP/EPbtnEableService';
import Schedule from './component/schedule';
import StepGuide from './component/StepGuide';
/**錯誤訊息 */
interface Errors {
    ApplyName: string;
    classnum: string;
    Title: string;
    Tel: string;
    Mobile: string;
    Email: string;
    SchoolNo: string;
    SchoolName: string;
    SchoolFullName: string;
    PubPri: string;
    City: string;
    Area: string;
    Address: string;
    password?: string;
}

interface SchoolData {
    uid: number;
    SchoolID: number;
    SchoolNo: string;
    SchoolName: string;
    SchoolName2: string;
    Type: string;
    Type2: string;
    City: string;
    City1: string;
    Area: string;
    Address: string;
    Tel: string;
    web: string;
}
interface SchoolInfo {
    uid: number;
    SchoolID: number;
    SchoolNo: string;
    SchoolName: string;
    SchoolName2: string;
    Type: string;
    Type2: string;
    City: string;
    City1: string;
    Area: string;
    Address: string;
    Tel: string;
    web: string;
  }

// /epApplyHome
/**epApplyHome.html  【6-4-3	承辦人主頁】*/
const EpApplyHome: React.FunctionComponent<IPageProps> = (props) => {

    const context = useContext(MyContext);
   
    const [pageCtrlCode ,setPageCtrlCode]= useState("6-4-3")
    /** 表單裡面欄位 */
    const [formData, setFormData] = useState<IApplyFormData>({} as IApplyFormData);
    const [applyInfo, setApplyInfo] = useState<IApplyFormData>({} as IApplyFormData);
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [refresh, setRefresh] = useState<boolean>();
    const [openErrModal, setOpenErrModal] = useState(false);
    const [cityList, setCityList] = useState<{City:string }[]>([]);
    const [areaList, setAreaList] = useState<{ Area: string }[]>([]);
    const [schoolList, setSchoolList] = useState< SchoolInfo[]>([]);
    const [errMsg, setErrMsg] = useState('');
    const [selectArea, setSelectArea] = useState<string>('');
    const [selectCity, setSelectCity] = useState<string>('');
    const [selectSchool, setSelectSchool] = useState<SchoolInfo>();
    const [errors, setErrors] = useState<Errors>({
        ApplyName: '',
        classnum: '',
        Title: '',
        Tel: '',
        Mobile: '',
        Email: '',
        SchoolNo: '',
        SchoolName: '',
        SchoolFullName: '',
        PubPri: '',
        City: '',
        Area: '',
        Address: ''
    });
    useEffect(() => {
    
        console.log('setCurrentUserBaseInfo', formData);
     
    }, [formData, errMsg]);
    

    useEffect(()=>{

        getExSchoolAreaByCitys(selectCity) 
    },[selectCity])

    const getExSchoolAreaByCitys = async (selectCity:string  ) =>{
      let rsp = await  getExSchoolAreaByCity(selectCity) as {Area:string }[]
      setAreaList(rsp)
        console.log("rsparea..",rsp) 

    }

    useEffect(()=>{

        getExSchoolByCityAreas() 
    },[selectArea])

    const getExSchoolByCityAreas = async ( ) =>{
      let rsp = await  getExSchoolByCityArea(selectCity,selectArea) as SchoolInfo[]
      setSchoolList(rsp)
    }

    /** 初始化 */
    useEffect(() => {
        /** 取得 session */
        let currentLoginInfo1 = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo1);

        //  如果是申請人
        if (currentLoginInfo?.LoginID) {
            if (currentLoginInfo.LoginStatus === 'DrApply')
                // 申請人
                fetchDataList(currentLoginInfo?.LoginID);
        }
    }, [refresh]);

    /** 初始化 */
    useEffect(() => {
        /** 取得 session */
        if (currentLoginInfo?.LoginID) {
            if (currentLoginInfo.LoginStatus === 'DrApply')
                // 申請人
                fetchDataList(currentLoginInfo?.LoginID);
        }
    }, [currentLoginInfo]);

    useEffect(() => {
        let currentLoginInfo1 = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo1);
    }, []);

    const fetchDataList = async (LoginID: string) => {
        await  getExSchoolCitys ();
        const formDataParam = await getEPApplyByLoginID(LoginID);
   
    };

    const getExSchoolCitys = async ()=>{

     let rsp =  await  getExSchoolCity()  as {City:string}[]
    setCityList(rsp)
        
    }
    /**檢查資料正確性 */
    const checkHasError = async () => {
        let noError = true;
        let errMsg = '';

        if (formData?.ApplyName.trim() === '') {
            noError = false;
            errMsg = errMsg + '承辦人姓名不可空白,';
            setErrorsByAttr('ApplyName', '承辦人姓名不可空白');
        }
        if (formData?.ApplyName.trim().length > 10) {
            noError = false;
            setErrorsByAttr('ApplyName', '承辦人姓名不可超過10碼');
            errMsg = errMsg + '姓名或暱稱不可超過10碼,';
        }

        // 檢查手機
        if (formData.Mobile.trim() === '') {
            noError = false;
            errMsg = errMsg + '手機不可空白,';
            setErrorsByAttr('ApplyName', '申請人不可空白');
        }

        //檢查密碼
        if (!formData?.Password || formData?.Password?.trim() === '') {
            noError = false;
            errMsg = errMsg + '密碼不可空白,';
        } else {
            const regChk: RegExp = /^([a-zA-Z0-9]){6,}/;
            if (!regChk.test(formData.Password.trim())) {
                noError = false;
                errMsg = errMsg + '密碼設定錯誤(至少6碼並為英數字),';
            }
        }

        //檢查email
        if (formData.Email.trim() === '') {
            noError = false;
            errMsg = errMsg + 'Email不可空白,';
        }
        if (formData.Email.trim() !== '') {
            const emailChk: RegExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
            if (!emailChk.test(formData.Email.trim())) {
                noError = false;
                errMsg = errMsg + 'Email格式錯誤,';
            }
        }

        if (noError) {
        } else {
            setOpenErrModal(!noError);
            errMsg = errMsg.substring(0, errMsg.length - 1) + '。';
            setErrMsg(errMsg);
        }
        return errMsg;
    };

    /** 更新資料項目 */
    const handleUpdate = async () => {
        //檢查資料 formData 資料
        let errorMsg = await checkHasError();

        if (!errorMsg) {
            try {
                const updateDate = {
                    applyName: formData?.ApplyName?.trim(),
                    title: formData?.Title?.trim() || '',
                    classnum: formData?.classnum ? formData?.classnum : '',
                    tel: formData?.Tel?.trim() || '',
                    mobile: formData?.Mobile?.trim() || '',
                    email: formData?.Email?.trim() || '',
                    password: formData?.Password?.trim(),
                    type: selectSchool?.Type || '',
                    remark: formData?.Remark?.trim() || '',
                    city: formData.City || selectSchool?.City?.trim() || '',
                    area: formData.Area || selectSchool?.Area?.trim() || '',
                    schoolNo: formData.SchoolNo || selectSchool?.SchoolNo?.trim() || '',
                    schoolName: formData.SchoolName || selectSchool?.SchoolName?.trim() || '',
                    schoolFullName: formData.SchoolFullName || selectSchool?.SchoolName2?.trim() || '',
                    pubPri: formData.PubPri || selectSchool?.Type2.trim() || '',
                    address: formData.Address || selectSchool?.Address?.trim() || ''
                } as IUpdateApply;

                if (formData?.ApplyNo) {
                    await UpdateApplyBaseInfo(updateDate, formData?.ApplyNo);
                }
                // window.location.reload();

                setRefresh(!refresh)
                alert('更新完成!\n 請點擊「指導老師資料」及「小隊基本資料」接續完成帳號建立。');
            } catch (ex) {
                console.log('錯誤  : ' + JSON.stringify(ex));
            }
        }
    };

    /** */
  const  oncityChange =(e:any) =>{
    setSelectCity(e.target.value)
    setFormDataOnChange(e, 'City') ;

  }
    const renderDropDownCity = () => {
        return (
            <>
                <select className="form-control fs-16" onChange={(e) =>oncityChange(e)}>
                    <option value="0">選擇縣市</option>
                    {cityList?.map((item) => (
                        <option value={item.City} selected={formData?.City === item.City}>
                            {item.City}
                        </option>
                    ))}
                </select>
            </>
        );
    };

    const onAreaChange =(e:any)=>{

        setSelectArea(e.target.value);
        setFormDataOnChange(e, 'Area')

    }
    const renderDropDownAreas = () => {
        return (
            <>
                <select className="form-control fs-16" onChange={(e) =>onAreaChange(e)}>
                    <option value="0">選擇地區</option>
                    {areaList?.map((item) => (
                        <option value={item.Area} selected={formData?.Area === item.Area}>
                            {item.Area}
                        </option>
                    ))}
                </select>
            </>
        );
    };

    /** */
  
    const onSchoolChange= (e:any)=>{
        const tar =schoolList.find(x=>x.SchoolName.trim()===e.target.value.trim()) as SchoolInfo
        setSelectSchool( tar)
        setFormData
        ({ ...formData, SchoolName: tar?.SchoolName ,
             Address: tar?.Address ,
             SchoolFullName:tar?.SchoolName2 } as IApplyFormData);
    }   

    const renderDropDownSchool = () => {
        return (
            <>
                <select className="form-control fs-16" onChange={(e) =>{  onSchoolChange(e)}}>
                    <option value="0">選擇學校</option>
                    {schoolList?.map((item) => (
                        <option value={item.SchoolName} selected={formData.SchoolName === item.SchoolName}>
                            {item.SchoolName}
                            
                        </option>
                    ))}
                </select>
            </>
        );
    };

    /** 設定錯誤訊息*/
    const setErrorsByAttr = (attr: string, msg: string) => {
        setErrors({ ...errors, [attr]: msg } as Errors);
    };
    /** 當設定畫面有改變時*/
    const setFormDataOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string) => {
        setFormData({ ...formData, [attr]: e.target.value } as IApplyFormData);
    };

    /** 取得登入者基本資訊 (申請者)*/
    const getEPApplyByLoginID = async (loginID: string) => {
        let rsp = (await getEPApplyByNo(loginID)) as IApplyFormData[];
        setSelectCity(rsp[0].City )
        setSelectArea(rsp[0].Area)
        let tar =schoolList.find(x=>x.SchoolName==rsp[0].SchoolName)
        setSelectSchool(tar)
        setFormData(rsp[0] as IApplyFormData);
        //
        debugger
        // alert ("setAppleyInfo.."+JSON.stringify(rsp[0] ))
        context?.setApplyInfoShare(rsp[0] as IApplyFormData)
        return rsp[0];
    };

    return (
        <>
            <TopNavPage1 />

            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <ApplyFunctionBar crtlInfoList={context?.crtlInfoList}/>
                    </div>
                    {/* <Schedule/> */}
                    <StepGuide/>
                    <div className="text-center h5 mt-4">承辦人主頁</div>
                    <div className="text-muted fs-18 fw-bold">承辦人資料修改說明</div>
                    <ol>
                        <li>本頁面填妥儲存後，請點擊右上角「指導老師資料」增加老師資料後，再新增「小隊基本資料」。</li>
                        <li>承辦人若申請時組別選擇錯誤，重新申請取得編號即可。</li>
                        <li>選取的學校資料來自教育部資料庫，學校全銜和地址可自行更改，學校簡稱若需更改或貴校不在資料庫內，請與基金會聯絡。</li>
                        <li>學校全銜和地址若非空白，「選擇學校」後不會自動填入。</li>
                        <li>學校全銜將呈現於獲獎獎狀及參賽證明書上，請確實填寫。</li>
                        <li> 閱讀文化基金會聯絡專線：(049) 256-6102 分機 28，或來信至電子郵件信箱：roep.twnread@gmail.com。</li>
                     
                    </ol>

                    <hr className="horizontal dark" />
                    <div className="card p-4">
                        <div className="text-second mx-auto fw-bold">承辦人編號：{formData?.ApplyNo}</div>
                        <form role="form text-left">
                            <div className="row mx-auto">
                                <div className="col-12 col-md-3 mt-3">
                                    <label className="fs-14">承辦人</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control fs-16" onChange={(e) => setFormDataOnChange(e, 'ApplyName')} value={formData?.ApplyName} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 mt-3">
                                    <label className="fs-14">承辦人職稱</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control fs-16" value={formData?.Title} onChange={(e) => setFormDataOnChange(e, 'Title')} />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 mt-3">
                                    <label className="fs-14">學校</label>
                                    <div className="d-flex">
                                        <div className="w-100">{renderDropDownCity()}</div>
                                        <div className="w-100 mx-3">{renderDropDownAreas()}</div>
                                        <div className="w-100">{renderDropDownSchool()} </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 mt-3">
                                    <label className="fs-14">普通班班級數</label>
                                    <div className="input-group align-items-center">
                                        <input
                                            type="number"
                                            min="1"
                                            max="100"
                                            className="form-control fs-16"
                                            placeholder=""
                                            value={formData?.classnum}
                                            onChange={(e) => setFormDataOnChange(e, 'classnum')}
                                        />{' '}
                                        班
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 mt-3">
                                    <label className="fs-14">承辦人密碼</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control fs-16" value={formData?.Password} onChange={(e) => setFormDataOnChange(e, 'Password')} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <label className="fs-14">學校簡稱</label>
                                    <div className="mt-1">
                                  
                                    {formData?.SchoolName }
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 mt-3">
                                    <label className="fs-14">承辦人手機</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control fs-16"
                                            value={formData?.Mobile}
                                            onChange={(e) => setFormData({ ...formData, Mobile: e.target.value } as IApplyFormData)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 mt-3">
                                    <label className="fs-14">承辦人電話(分機)</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control fs-16" value={formData?.Tel} onChange={(e) => setFormDataOnChange(e, 'Tel')} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <label className="fs-14">學校全銜</label>
                                    <div className="input-group">
                                        <input 
                                            disabled
                                            type="text"
                                            className="form-control fs-16"
                                            onChange={(e) => setFormDataOnChange(e, 'SchoolFullName')}
                                            value={formData?.SchoolFullName}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 mt-3">
                                    <label className="fs-14">承辦人Email</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control fs-16" placeholder="輸入Email" value={formData?.Email} onChange={(e) => setFormDataOnChange(e, 'Email')} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <label className="fs-14">學校地址</label>
                                    <input 
                                    disabled
                                     type="text" className="form-control fs-16" 
                                    value={ formData?.Address} 
                                    onChange={(e) => setFormDataOnChange(e, 'Address')} />
                                </div>

                                <div className="col-12 col-md-6 mt-3">
                                    <label className="fs-14">備註</label>
                                    <div className="input-group">
                                        <textarea
                                            rows={2}
                                            className="form-control fs-16"
                                            placeholder="輸入備註"
                                            onChange={(e) => setFormDataOnChange(e, 'Remark')}
                                            defaultValue={formData?.Remark}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="btn text-second bg-second my-4 float-end" style={{ width: '70px' }} onClick={() => handleUpdate()}>
                                更新
                            </div>
                        </form>
                    </div>

                    <div className="pt-3 mt-4"></div>
                </div>
            </div>
            <BSModal title={'基本資料欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {errMsg}
            </BSModal>
            <FooterPage />
        </>
    );
};
export default EpApplyHome;
