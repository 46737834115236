import React, { useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import { handleLink } from '../../modules/utils';
import { getExStudentByGmail, getExStudentByStuNoPwd, getExStudentByiSchool, getExTeacherByGmail, getExTeacherByTNoPwd, getExTeacherByiSchool, updateGmailExStudent, updateGmailExTeacher } from '../../services/ExStuTeService';
import FooterPage from '../footer';
import { BSModal } from '../../components/modal/bsmodal';
import { getExStuTestCntBySid } from '../../services/ExStuRecService';
import { getCookie, removeCookie } from 'typescript-cookie';
import { IExStudent } from '../../interfaces/ExStudent';
import { getMyip } from '../../services/AdSessionService';
import { addAdLogin } from '../../services/AdLoginService';
import { IExTeacher } from '../../interfaces/ExTeacher';

const LoginNTPCPage: React.FunctionComponent<IPageProps> = (props) => {
 
    // 首次載入時，檢查是否有ischool登入資料
    useEffect(() => {
        if (getCookie('profile') !== undefined) {
            const gInfo: {
                state: string;
                profile:{
                    uuid: string;
                    firstName: string;
                    lastName: string;
                    language: string;
                    mail: string;
                }
            } = JSON.parse(getCookie('profile')!);
            //sessionStorage.setItem('GoogleProfile', getCookie('profile')!);
            removeCookie('profile');
            // 使用Gmail 查詢帳號
    
            if (gInfo.profile.mail.trim() !== '') {
                const fetchdata= async ()=>
                {
                
                    const stu= await getExStudentByiSchool(gInfo.profile.mail.trim());

                    const te= await getExTeacherByiSchool(gInfo.profile.mail.trim())

                    console.log("stu:"+stu);

                    console.log("te:"+te);

                    const mode=gInfo.state.split(':');                    

                    if((stu!==undefined)&& (te===undefined)) //使用綁定帳號登入學生帳號
                    {
                        
                        if(mode[0]==='0')
                        {
                            sessionStorage.setItem('LoginStatus', '1');
                            sessionStorage.setItem('LoginID', stu.Sid.toString());
                            sessionStorage.setItem('LoginSchoolNo', stu.SchoolNo);
                            sessionStorage.setItem('LoginStudent', JSON.stringify(stu));
                            sessionStorage.setItem('LearnRec', JSON.stringify(await getExStuTestCntBySid(stu.Sid)));
                            getMyip().then(data => {
                                if (data.ip !== '::1') {
                                    const ippart=data.ip.split(":")
                                    addAdLogin(stu.Sid.toString(),'E',ippart[3]);
                                }
                            })
                            sessionStorage.removeItem('iSchoolInfo');                          

                        }
                        if((mode[0]==='1') && (stu.Sid.toString().trim()!==mode[1].trim())) //其他學生綁定帳號
                        {
                            sessionStorage.setItem('ErrorBindiSchool','本帳號由其他學生綁定');                            
                        }
                        if(mode[0]==='2')  //老師綁定帳號
                        {
                            sessionStorage.setItem('ErrorBindiSchool','本帳號由其他學生綁定');                            
                        }

                        handleLink('/');       
                    }

                    if((stu===undefined)&& (te!==undefined)) //登入教師帳號
                    {
                        if(mode[0]==='0')
                        {
                            sessionStorage.setItem('LoginStatus', '2');
                            sessionStorage.setItem('LoginID', te.Tid.toString());
                            sessionStorage.setItem('LoginSchoolNo', te.SchoolNo);
                            sessionStorage.setItem("LoginTeacher",JSON.stringify(te));
                            getMyip().then(data => {
                                if (data.ip !== '::1') {
                                    const ippart=data.ip.split(":")
                                    addAdLogin(te.Tid.toString(),'T',ippart[3]);
                                }
                            })
                            sessionStorage.setItem('ErrorBindiSchool','');     
                            sessionStorage.removeItem('iSchoolInfo');
                        }

                        if((mode[0]==='2') && (te.Tid.toString().trim()!==mode[1].trim())) //其他老師綁定帳號
                        {
                            sessionStorage.setItem('ErrorBindiSchool','本帳號由其他老師綁定');                            
                        }
                        if(mode[0]==='1')  //學生綁定帳號
                        {
                            sessionStorage.setItem('ErrorBindiSchool','本帳號由其他老師綁定');                            
                        }

                        handleLink('/');
                    }
                    if((stu===undefined)&& (te===undefined)) //無綁定帳號
                    {
                        if(mode[0]==='0')
                        {
                            sessionStorage.setItem('iSchoolInfo', JSON.stringify(gInfo.profile));
                            sessionStorage.setItem('ErrorBindiSchool','');  
                            handleLink('/relogin-ntpc');                       
                        }
                        if(mode[0]==='1')
                        {
                            const loginStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!);
                            loginStu.Gmail=gInfo.profile.mail.trim();
                            sessionStorage.setItem("LoginStudent",JSON.stringify(loginStu));
                            await updateGmailExStudent(loginStu.Sid.toString(),gInfo.profile.mail.trim());
                            sessionStorage.setItem('ErrorBindiSchool','');  
                            sessionStorage.removeItem('iSchoolInfo');                              
                            handleLink('/');
                        }
                        if(mode[0]==='2')
                        {
                            const loginTea:IExTeacher=JSON.parse(sessionStorage.getItem("LoginTeacher")!);
                            loginTea.Gmail=gInfo.profile.mail.trim();
                            sessionStorage.setItem("LoginTeacher",JSON.stringify(loginTea));
                            await updateGmailExTeacher(loginTea.Tid.toString(),gInfo.profile.mail.trim());
                            sessionStorage.setItem('ErrorBindiSchool','');  
                            sessionStorage.removeItem('iSchoolInfo');                              
                            handleLink('/');
                        }
                    }
                }
                fetchdata();

            }
        }
    }, []);


    return (
        <>         
        </>
    );
};

export default LoginNTPCPage;


