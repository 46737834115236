import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import IAnsPPX from '../interfaces/AnsPPX';
import IAnsPP3 from '../interfaces/AnsPPX';
import { IExEssayDetail } from '../interfaces/ExEssayDetail';
import { IExEssayTopic } from '../interfaces/ExEssayTopic';
import { IExQA } from '../interfaces/ExQA';

export const getExEssayTopicByLevelandType=(level:number,type:string)=>{
    const url=config.server.url+`/api/ExEssay/topic/level/${level}/type/${type}`
    return axios.get(url).then((res)=>{
        const rest:IExEssayTopic[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExEssayQADetailByNo=async (no:string):Promise<IAnsPP3>=>{
    const resExEssay=await getExEssayDetailByNo(no);
    const resExQA=await getExQAByNo(no);
    
    const result:IAnsPPX={
        No:resExEssay.No,
        Sno:resExEssay.Sno,
        Content:resExEssay.Content,
        Abstract:resExEssay.Abstract,
        InpAbstract:'',
        KeyWordQuestion:resExQA.Question,
        KeyWords:[resExQA.Term1,resExQA.Term2,resExQA.Term3,resExQA.Term4,resExQA.Term5,resExQA.Term6,resExQA.Term7,resExQA.Term8],
        KeyWordsAns:resExQA.Answer,
        InpKeyWordsAns:''
    }
    return result;
}
export const getExEssayDetailByNo=(no:string)=>{
    const url1=config.server.url+`/api/ExEssay/No/${no}`
    return axios.get(url1).then((res)=>{
        const resExEssay:IExEssayDetail=res.data[0];
        return resExEssay;

    })
    .catch((error:AxiosError) => {
        throw error;
    });
}    

export const getExQAByNo=(no:string)=>{
    const url2=config.server.url+`/api/ExQA/No/${no}`
    return axios.get(url2).then((res)=>{
        const resExQA:IExQA=res.data[0];
        return resExQA;             
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
