import Modal from 'react-bootstrap/Modal'

interface ModalProps {
    title: string;
    isOpen: boolean;
    setOpenModal: (val:boolean) => void;
    children?:React.ReactNode
  }
  
  export const BSModal: React.FC<ModalProps> = (props) => {
  
  
    return <Modal show={props.isOpen} onHide={()=>props.setOpenModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="mx-auto">{props.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'>{props.children}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary m-0" onClick={()=>props.setOpenModal(false)}>關閉</button>
                </Modal.Footer>
            </Modal>

  };