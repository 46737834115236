import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExSchool } from '../interfaces/ExSchool';

export const getExSchoolCities=()=>{
    const url=config.server.url+`/api/ExSchool/city`
        return axios.get(url).then((res)=>{       
        let rest:string[]=[]
        const temp:{City:string}[]=res.data;
        temp.forEach(item=>{
            rest.push(item.City)
        })           
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });}

export const getExSchoolAreaByCity=(city:string)=>{
    const url=config.server.url+`/api/ExSchool/area/city/${city}`
    return axios.get(url).then((res)=>{
        let rest:string[]=[]
        const temp:{Area:string}[]=res.data;        
        temp.forEach(item=>{
            rest.push(item.Area)
        })
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });}

export const getExSchoolAreaByCityArea=(city:string,area:string)=>{
    const url=config.server.url+`/api/ExSchool/city/${city}/area/${area}`
    return axios.get(url).then((res)=>{
        const rest:IExSchool[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });}
    




