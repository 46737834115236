import React, { useEffect, useState } from 'react'
import IAdNews from '../../interfaces/AdNews';
import IPageProps from '../../interfaces/page'
import { getAdNewsByKeyword } from '../../services/AdNewsService';
import FooterPage from '../footer';
import TopNavPage1 from '../topNav1';
import TopNavPage2 from '../topNav2';

const AllNews: React.FunctionComponent<IPageProps> = props => {
    const [adNews,setAdNews]=useState<IAdNews[]>([])
    const [refresh,setRefresh]=useState(false);
    const [entPressed,setEntPressed]=useState(false);
    const [inpKeyword,setInpKeyword]=useState("")

    const inpAdNews=(e:React.FormEvent<HTMLInputElement>)=>{
      setInpKeyword(e.currentTarget.value.replaceAll("/","%2F"));
    }

    const filterAdNews=()=>{ 
      if(inpKeyword==="")
      {
        changeAllNews('*');
      }
      else
      {
        changeAllNews(inpKeyword.trim());
      }
    }

    const changeAllNews=(key:string)=>{
      getAdNewsByKeyword(key).then(data=>{
        setAdNews(data);
      });
      
    }
   
    const handleKeyDown=(e: React.KeyboardEvent<HTMLElement>)=>{
      if(e.key==="Enter") setEntPressed(true);
      
    }

    const handleSearch=()=>{
      setEntPressed(true);
    }

    useEffect(()=>{
      if(entPressed)
      {
        if(inpKeyword==="")
        {
          getAdNewsByKeyword('*').then(data=>{
            setAdNews(data);
          });
        }
        else
        {
          getAdNewsByKeyword(inpKeyword.trim()).then(data=>{
            setAdNews(data);
          });
        }
        setEntPressed(false);        
      }
    },[entPressed])

    useEffect(()=>{
      getAdNewsByKeyword('*').then(data=>{
        setAdNews(data);
        data.map((item,i)=>{
          let innerBody=document.getElementById('context-'+i.toString());
          if(innerBody!==null) innerBody.innerHTML=item.Context;
        })
      })
      console.log('--in load page--')
      console.log(adNews);

    },[])

    useEffect(()=>{
      if(adNews.length>0)
      {
          console.log(adNews);
          adNews.map((item,i)=>{
            let innerBody=document.getElementById('context-'+i.toString());
            if(innerBody!==null) innerBody.innerHTML=item.Context;
          })
      }
    },[adNews])


    return (
      <>
        <TopNavPage1/>
        <div className="section pt-3 pt-lg-5">
          <div className="container">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <h5 className="title mb-3">最新消息<small className="ms-2">NEWS</small></h5>
              <div className="form-group flex-grow-1 float-end" style={{maxWidth:'410px'}}>
                <div className="input-group">
                  <span className="input-group-text"><span className="material-icons-outlined text-muted" onClick={()=>handleSearch()}>search</span></span>
                  <input className="form-control ps-3" placeholder="搜尋消息" type="text" onChange={inpAdNews} onKeyDown={handleKeyDown}/>
                </div>
              </div>
            </div>
            <div className="card p-2">
              <div className="table-responsive">
                <table className="table table-striped table-hover align-items-center mb-0">
                  <thead className="">
                    <tr style={{color:'#5c71a2'}}>
                      <th className="text-sm font-weight-bolder text-nowrap p-2 text-center">置頂</th>
                      <th className="text-sm font-weight-bolder text-nowrap p-2">日期</th>
                      <th className="text-sm font-weight-bolder text-nowrap px-2 px-md-3">內容</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                     adNews.map((data,i)=>{
                        return <tr>
                                  <td className="text-center py-3"><i className={(data.TopNews==='1')?"fas fa-thumbtack text-warning pointer":"fas fa-thumbtack text-gray transform-rotate pointer" }></i></td>
                                  <td className="text-sm">{data.SendDate}</td>
                                  <td className="text-dark text-wrap py-3" id={'context-'+i.toString()}></td>
                                </tr>
                     }) 
                    }
                  </tbody>
                </table>
              </div>
            </div>  
          </div>
        </div>
        <FooterPage/>
      </>
    );
}
export default AllNews;
