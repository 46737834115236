

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import { OpinCode, alertEx, currentClass, fetchDataSubTopic, getLoginInfo, getModalConfigs, setSessionStudentDisplayInfo, Action, geIndexBytOpinionCode, IOpinionForModal, IsubTopicForModal, replaceNewline, formatDateTime, getStart, SummaryFileName, Export2Word, TutorialModal, getFileNameString, showJsonObj } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import Schedule from './component/schedule';
import { IOpinion, ISearchDataSummary, IsubTopic, Param11OrPost11, Summary, subTopicParam } from '../../interfaces/EpModal';
import { getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';

import { deleteSubSummaryByUid, getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateSubSummaryByGroupSubSumNo } from '../../services/EP/OpinionService';
import SubTopicTable from './component/subTopicTable';
import SummaryModal from './component/SummaryModal';
import EditModal from './component/EditModal';
import { FileUploader } from '../../components/fileUploader';
import { checkFileExists } from '../../services/DrFileService';



export interface StudentDisplay {

  ApplyName: string
  ApplyNo: string
  ApplyTitle: string
  GroupName: string
  GroupNo: string
  SchoolName: string //"國語實小        "
  StuName: string  //"趙小一       "
  StuNo: string //  "FA001-01-1"
  TeaName: string // "李老師       "
  TeaNo: string //""
  TeaTitle: string // 
  Team: {
    TStuName: string,
    TStuNo: string
    checked: boolean
  }[]
  Teachers: {
    TeaNo: string, TeaName: string, TeaTitle: string

  }[]
}




// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpStudLevel3: React.FunctionComponent<IPageProps> = () => {

  const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(true);
  const [ishModalOpen, setIsModalOpen] = useState(false)
  // 根据 isVisible 变量的值决定要应用的 CSS 类名
  const containerClassName = isVisible ? 'visible' : 'hidden';
  const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
  const [currentLoginInfo, setCurrentLoginInfo] = useState<any>()
  const [studentInfo, setStudentInfo] = useState<StudentDisplay>()
  const [isReload, setIsReload] = useState<boolean>(true);
  const [opinion, setOpinion] = useState<IOpinion>();
  const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>();
  const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
  const [currentSubTopic, setCurrentSubTopic] = useState<string>('')
  const [currentSummary, setCurrentSummary] = useState<Summary>()
  const [currentMode, setCurrentMode] = useState<'新增' | '編輯' | ''>('')
  const [searchDataList, setSearchDataList] = useState<ISearchDataSummary[]>();
  const [isFilePathValid, setIsFilePathValid] = useState<boolean>(false);
  const [isPictureExist ,setIsPictureExist] =useState (false)


    useEffect(()=>{
        getIsFileExist()


    },[opinion])
   

   const getIsFileExist = async  () =>{
        const rsp  = await checkFileExists( `/uploads/subTopicSummary${currentClass}/${studentInfo?.GroupNo}`,"subTopic_summary_pdf.pdf","")
        
        if(rsp =='檔案已存在.'){
            setIsPictureExist(true)
            // alert("檔案已存在")
        } else {
            // alert("檔案不存在")
            setIsPictureExist(false)
        }
    }
  useEffect(() => {
    fetchdataStudentInfo();
    FetchDataOpinion();


  }, []);
  useEffect(() => {
    fetchdataStudentInfo();
    FetchDataOpinion();
    fetchDataSubSummarySubCollectByGroupNo()
    checkFilePath ()

  }, [isReload]);


  useEffect(() => {
    fetchDataSubTopic();
    fetchDataSubSummarySubCollectByGroupNo()
  }, [studentInfo, isReload]);

  const reload = async () => {
    await fetchdataStudentInfo();
    await FetchDataOpinion();
  };

  /** 取得summery  */
  const fetchDataSubSummarySubCollectByGroupNo = async () => {
    // alert ('sss')
    if (studentInfo?.GroupNo) {

      let rsp = (await getSubSummarySubCollectByGroupNo(1, studentInfo?.GroupNo)) as ISearchDataSummary[];
      setSearchDataList(rsp || []);
      console.log("setSearchDataList 4 ", rsp);
    }


  };

  /** 取得登入資料 */
  const fetchdataStudentInfo = async () => {
    /** 取得登入資料 */
    try {
      let currentLoginInfo = getLoginInfo();
      setCurrentLoginInfo(currentLoginInfo);
      const studentInfo = (await getDrGroupByStuNoClass(currentClass, currentLoginInfo.LoginID)) as StudentDisplay; //as StudentInfo
      console.log('currentLoginInfo', currentLoginInfo);
      setStudentInfo(studentInfo);
    } catch (ex) {
      console.log('取得學生資料發生錯誤! ', ex);
    }
  };
  /** */
  const FetchDataOpinion = async () => {
    let currentLoginInfo = await getLoginInfo();
    setCurrentLoginInfo(currentLoginInfo);
    const rsp = (await getOpinionByStuNo(currentLoginInfo.LoginID)) as IOpinion[];
    console.log('opinion....', rsp)
    const opinion = rsp[0];
    if (opinion) {
      setOpinion(opinion);
    }
  };


  /** 取得子題資料*/
  const fetchDataSubTopic = async () => {
    if (studentInfo?.GroupNo) {
      let subTopicbefore = (await getSubTopicByGroupNo(0, studentInfo?.GroupNo)) as IsubTopic[];
      console.log('subTopicbefore 子題(回覆前)', subTopicbefore);
      setSubTopicBeforeFeedbackList(subTopicbefore);
    }
  };

  /**  */
  const Delete = async (summaryID: string) => {
    if (window.confirm('確定要刪除嗎?')) {
      alertEx(summaryID)
      try {
        await deleteSubSummaryByUid(summaryID)
        setIsReload(!isReload)
      } catch (ex) {
        alert('刪除發生錯誤 :' + JSON.stringify(ex))

      }

    }
  }
  const checkFilePath = async () : Promise<boolean> => {
    try {
      const response = await fetch(`/uploads/subTopicSummary${currentClass}/${studentInfo?.GroupNo}/${"subTopic_summary_pdf.pdf"}`);
      if (response.ok) {
        // 如果路径存在且请求成功，则设置状态为 true
       return true
      } else {
        // 如果路径不存在或请求失败，则设置状态为 false
       return false 
      }
    } catch (error) {
      // 捕获异常，并设置状态为 false
     return false
    }
  };

  const openEditModal = async (opinCode: OpinCode, action: Action, param11?: Param11OrPost11, subTopic?: IsubTopic, enable?: boolean) => {
    if (enable) {
      return
    }
    let modalConfig = getModalConfigs(opinCode, action, 1);
    debugger
    let params = {
      UID: undefined,
      ParamType: modalConfig?.sourceType,
      GroupNo: studentInfo?.GroupNo,
      MemberNo: studentInfo?.StuNo,
      DataA: '',
      DataB: '',
      DataC: ''
    } as paramsCreateOpinionSubTopic;

    setFormData(params);
    /**  彈出視窗設定檔*/
    if (studentInfo) {
      if (action === '新增') {
        params = {
          action: '新增',
          UID: undefined,
          ParamType: modalConfig?.sourceType,
          GroupNo: studentInfo?.GroupNo,
          MemberNo: studentInfo?.StuNo,
          DataA: '',
          DataB: '',
          DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        //需要有設定檔 跟 修改對象 ID
      } else if (action === '修改') {
        // fectchData todo
        if (modalConfig) {
          //如果沒有 傳進來的物件
          if (param11 && !subTopic) {
            // params
            params = {
              action: '修改',
              UID: param11.OpinionID,
              ParamType: modalConfig.sourceType,
              GroupNo: studentInfo?.GroupNo,
              MemberNo: studentInfo?.StuNo,
              DataA: param11.DataA,
              DataB: param11.DataB,
              DataC: '',
              termsStringList: param11.DataB.split('、')
            } as paramsCreateOpinionSubTopic;
            setFormData(params);
          } else if (!param11 && subTopic) {
            // 沒有物件
            try {
              params = {
                action: '修改',
                UID: subTopic.uid,
                ParamType: modalConfig.sourceType,
                GroupNo: studentInfo?.GroupNo,
                MemberNo: studentInfo?.StuNo,
                SubNo: subTopic.SubNo + '',
                SubName: subTopic.SubName,
                Description: subTopic.Description
              } as paramsCreateOpinionSubTopic;

              setFormData(params);
            } catch (ex) {
              alertEx('ex:' + JSON.stringify(ex));
            }
          }
        }
      } else if (action === '新增或修改') {
        debugger
        if (modalConfig) {
          // 有moaal 設定檔
          if (!param11 && !subTopic) {
            // topic
            try {
              let optionCode = geIndexBytOpinionCode(opinCode);
              let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, studentInfo?.GroupNo)) as any[];
              if (rsp.length > 0) {
                // 原本有資料 設定修改
                params = {
                  action: '修改',
                  UID: rsp[0].OpinionID || rsp[0].uid,
                  ParamType: modalConfig.sourceType,
                  GroupNo: studentInfo?.GroupNo,
                  MemberNo: studentInfo?.StuNo,
                  DataA: rsp[0].DataA || null,
                  DataB: rsp[0].DataB || null,
                  DataC: rsp[0].DataC || null
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
              } else {
                // 沒有資料就設定  新增
                params = {
                  action: '新增',
                  UID: undefined,
                  ParamType: modalConfig?.sourceType,
                  GroupNo: studentInfo?.GroupNo,
                  MemberNo: studentInfo?.StuNo,
                  DataA: '',
                  DataB: '',
                  DataC: ''
                } as paramsCreateOpinionSubTopic;
                setFormData(params);
              }
            } catch (ex) {
              alertEx('ex:' + JSON.stringify(ex));
            }
          } else if (!param11 && subTopic) {
          } else if (param11 && !subTopic) {
            //opinion 一對一
          }
        }
      } else if (action === '刪除') {
      }
    }

    // console.log( getModalConfigs(stage,modalName) )
    if (getModalConfigs(opinCode, action, 1)) {
      setCurrentShowModal(getModalConfigs(opinCode, action, 1));
    }
    setIsAddRsearchModalOpen(true)
  };


  const setModalConfigAndOpen = (mode: any, currentSummary: Summary | any, subNo: string) => {
    setCurrentMode(mode)
    setIsModalOpen(true)
    setCurrentSummary(currentSummary)
    setCurrentSubTopic(subNo)


  }

  const handleError = (error:any) => {
    console.error('PDF 檔案載入失敗', error);
    // 您可以根據需要顯示錯誤訊息或預設圖片
    // 例如：
    alert('PDF 檔案載入失敗，請稍後再試');
    // 或
    <div>
      <p>PDF 檔案載入失敗</p>
      <img src="https://placehold.co/300x200" alt="預設圖片" />
    </div>
  };
  

  


  return (
    <>
  <TopNavPage1 />
    
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">專題探究競賽</h5>

            <StudentFunctionBar />
          </div>


          <div className="text-center h5 mt-4">階段3和4</div>
          <div>  {studentInfo?.SchoolName.trim()}  {studentInfo?.GroupName.trim()} ({studentInfo?.GroupNo}) - {studentInfo?.StuName} ({studentInfo?.StuNo})</div>
          <div id="title-info"  hidden>
                     {studentInfo?.SchoolName.trim()} {studentInfo?.GroupName.trim()}({studentInfo?.GroupNo})

                    </div>
          <div className="accordion" id="accordionRental">
            <div className="accordion-item">
              <div className="card bd-callout bd-callout-danger">
                <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#level3" aria-expanded="true" aria-controls="level3">
                  <h4 className="my-0 me-3">階段3、蒐尋瀏覽資料</h4>
                  <div className="text-second font-weight-bold">(階段3和4，請於 <span>112/05/25</span> 前完成)</div>
                  <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                </button>
              </div>

              <div className="collapse show" id="level3" data-bs-parent="#accordionRental">
                <div className="bd-callout mt-0">
                  <div className="text-second font-weight-bold">進行階段3和4之前，請先至「階段1和2(回饋後)」頁面，完成資料的確認和填寫是否修改的原因。</div>
                  <div className="d-flex flex-row flex-wrap justify-content-between align-items-center px-4 pt-3">
                    <div>
                      <div>網路像大海一樣浩瀚無邊，要學習「有目的」的蒐尋資料。</div>
                      <div>各隊訂的「子題」就是初步的「目的」，試著下關鍵字詞，看看找到什麼。</div>
                      <div>選擇資料請記得，要判斷該筆資料的可信度。</div>
                      <div>並且，不要整段複製貼上，消化整理過的才算真正讀通。</div>
                    </div>
                    <div>
                      <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal"
                        data-bs-target="#teach3">
                        怎樣蒐集資料
                      </div>
                      <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal"
                        data-bs-target="#teach3-2">
                        怎樣判斷資料的可信度
                      </div>
                      <div className="btn text-second bg-second mb-0 pointer " onClick={() => Export2Word('stage3-after', '階段3、蒐尋瀏覽資料',getFileNameString(undefined ,studentInfo))} >
                        匯出檔案
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card p-4 pt-3" id ="stage3-after">
                  <h5 className="fw-bolder pt-3">3-1 運用關鍵字詞蒐尋</h5>
                  <ul>
                    <li>請列出每個子題時，分別使用哪些關鍵字詞蒐尋資料。</li>
                    <li>每個子題列出1~3個關鍵字詞即可。</li>
                    <li>每個關鍵字詞之間用小寫逗號(,)分開。</li>
                    <li>這裡全體隊員均可編輯。</li>
                  </ul>
                  <div className="fw-bolder text-second pb-2">本隊探究題目：
                    <span className="text-primary"> <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '') }}></div>
                  </span>
                  </div>


                  <SubTopicTable
                    role ={'DrStudent'}  
                    groupNo ={studentInfo?.GroupNo}
                    isfeedback={1} 
                    studentInfo={studentInfo}
                    setStudentInfo={setStudentInfo} 
                    currentLoginInfo={currentLoginInfo} />


                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">3-2 蒐集瀏覽資料</h5>
                  <ul>
                    <li>請列出探究每個子題時所蒐集的資料，資料筆數不限。</li>
                    <li>蒐集的資料內容，請盡量用條列式整理重點。</li>
                    <li>請記錄資料來源，如果是網站，請列出網站名稱及標題和網址；如果是紙本資料，請列出作者/年份/篇名/書名。</li>
                    <li>請以1~5顆星判斷資料的可信度，並簡單寫出理由。</li>
                    <li>輸入結束，可按【重整並查看已輸入的瀏覽資料】看輸入結果。</li>
                    <li>這裡全體隊員均可編輯。</li>
                  </ul>


                  <div className="accordion" id="searchData">
                    <div className="p-3">
                     {''} {searchDataList?.map((searchData,index) => (
                        <div className="accordion-item">
                          <button
                            className="collapsed accordion-button p-0 border-bottom mb-2"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse"+index}
                            aria-expanded="false"
                            aria-controls={"#collapse"+index}
                          >
                            <div className="fw-bolder text-second p-2">
                              子題{searchData.SubNo},{searchData.SubName}{' '}
                            </div>
                            <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-18 me-3" aria-hidden="true"></i>
                            <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-18 me-3" aria-hidden="true"></i>
                          </button>

                          <div id={"collapse"+index} className="accordion-collapse bg-white border-bottom collapse" aria-labelledby="heading1" data-bs-parent="#searchData">
                            <div className="accordion-body">
                              <div className="accordion-body text-end">
                                <div className="btn text-second bg-second mb-0 pointer" onClick={() => setModalConfigAndOpen('新增', null, searchData.SubNo)}>
                                  <span className="material-icons-outlined align-middle">add</span>
                                  <span data-bs-toggle="modal" data-bs-target="#editModal3-2" >新增重點整理</span>
                                </div>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                      <th className="p-2 text-nowrap">重點整理</th>
                                      <th className="p-2 text-nowrap">資料來源</th>
                                      {true ? (<th className="p-2 text-nowrap print-remove" >功能</th>) : (<></>)}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchData?.Summaries?.map((summary, index) => (
                                      <tr>
                                        <td className="text-wrap">
                                          <div className="my-2" dangerouslySetInnerHTML={{ __html: replaceNewline(summary.Summary || '') }}></div>
                                        </td>
                                        <td className="text-wrap">
                                          {summary.Collects.map((source) => {

                                            if (source.CollectID) {
                                              return (
                                                <div className="my-2">
                                                  <a href={source.Web} target="_blank" className="text-dgreen">
                                                    {source.Source}
                                                  </a>
                                                  <div>{source.Credibility ? '可信度：' : ''}{getStart(source.Credibility)}</div>
                                                  <div>{source.Reason}</div>
                                                </div>
                                              )

                                            }
                                          })}

                                        </td>
                                        <td className="text-nowrap">
                                          <span className="material-icons text-dark pointer me-3"
                                            onClick={() => { Delete(summary.SummaryID) }} title="刪除">delete_outline</span>
                                          <span className="material-icons text-dark pointer" data-bs-toggle="modal"
                                            data-bs-target="#editModal3-2" title="編輯" onClick={() => setModalConfigAndOpen('編輯', summary, searchData.SubNo)}>edit</span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>


                                <table id="ref-source" className="table table-striped mb-0" hidden>
                                  <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                      <th className="p-2 text-nowrap">重點整理</th>
                                      <th className="p-2 text-nowrap">資料來源</th>
                                      <th className="p-2 text-nowrap">日期</th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>


               
                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">3-3 困難、解決與新學習</h5>
                  <ul>
                    <li>請全體隊員共同回顧在「階段3」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                    <li>這裡全體隊員均可編輯。</li>
                  </ul>
                  <div className="concard p-3">
                    <span className="material-icons float-end pointer" data-bs-toggle="modal" data-bs-target="#editModal3-3"
                      title="編輯" onClick={() => openEditModal('3_3困難、解決與新學習', '新增或修改')}>edit</span>
                    <div className="fw-bolder text-second pt-3 pb-2">我們在「階段3」遇到的困難：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataA || '') }}></div>
                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataB || '') }}></div>
                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataC || '') }}></div>
                    <div className="pt-3 pb-2">
                      <span className="fw-bolder text-second">日期：</span>   <span>{formatDateTime(opinion?.Post33_UpdateDate)}</span>
                    </div>
                  </div>


                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">階段3，指導老師留言</h5>
                  <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                  <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post39_DataA || '') }}></div>
                  <div className="pt-3 pb-2">
                    <span className="fw-bolder text-second">日期：</span>   <span>{formatDateTime(opinion?.Post39_UpdateDate)}</span>
                  </div>

                </div>
              </div>
            </div>

            <div className="accordion-item">
              <div className="card bd-callout bd-callout-danger">
                <button className="collapsed accordion-button text-dark p-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#level4" aria-expanded="false" aria-controls="level4">
                  <h4 className="my-0 me-3">階段4、整合組織資料</h4>
                  <div className="text-second font-weight-bold">(階段3和4，請於 <span>112/05/25</span> 前完成)</div>
                  <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                </button>
              </div>

              <div className="collapse" id="level4" data-bs-parent="#accordionRental">
                <div className="bd-callout mt-0">
                  <div className="d-flex flex-row flex-wrap justify-content-between align-items-center px-4">
                    <div>
                      <div>各隊擁有一些整理過且可信的資料，就像有了優良食材，可以準備烹調了。</div>
                      <div>請運用這些資料，用文字或圖或表格，先回答每一個「子題」。</div>
                      <div>再把探究每一個子題的結果，歸納成「探究題目」的結論與發現。</div>
                      <div>這個階段很有挑戰性，加油！</div>
                    </div>
                    <div>
                      <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal"
                        data-bs-target="#teach4">
                        怎樣歸納統整資料
                      </div>
                      <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal"
                        data-bs-target="#teach4-2">
                        怎樣提出結論或發現
                      </div>
                      <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('stage4-after', '階段4、整合組織資料' ,getFileNameString(undefined ,studentInfo))}>
                        匯出檔案
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card p-4 pt-3 " id="stage4-after">
                  {/* {JSON.stringify(isFilePathValid)} */}
                  <h5 className="fw-bolder pt-3">4-1 提出各子題小結</h5>
                  <ul>
                    <li>歸納前面階段蒐集到的資料，提出小結，回答每一個子題。</li>
                    <li>可以使用文字或圖表，比較、對照、分析這些資料。</li>
                    <li>每一個子題，都要提出小結。</li>
                    <li>請下載<a href="/assets/doc/4-1提出各子題小結.docx" target="_blank" className="text-green fw-bold">
                      <span>「4-1提出各子題小結」word檔</span></a>，輸入完成後，存成pdf檔並上傳。</li>
                    <li>重新上傳會覆蓋原檔，若畫面仍為原檔，可按 crtl+F5 重整。</li>
                  </ul>
                  {/* {`/subTopicSummary${currentClass}/${studentInfo?.GroupNo}/${"subTopic_summary_pdf.pdf"}`} */}
                 
                    {/* <object data="https://eliteracy.twnread.org.tw/Uploads/111/pdf4_1/Step4-EA011-01新路國小.pdf" */}
                    
                    {isPictureExist ?( <div className="modal-body text-center my-3 px-0" style={{ height: '70vh', maxHeight: '530px', overflowY: 'auto' }}> <object data={`/uploads/subTopicSummary${currentClass}/${studentInfo?.GroupNo}/${"subTopic_summary_pdf.pdf"}?v=${new Date().getSeconds()}`}
                      type="application/pdf" width="95%" style={{ height: '64vh', overflowX: 'auto' }}></object>     </div>):(  <p>-檔案未上傳- </p>)}
                    {/* <object data={`/uploads/subTopicSummary${currentClass}/${studentInfo?.GroupNo}/${"subTopic_summary_pdf.pdf"}?v=${new Date().getSeconds()}`}
                      type="application/pdf" width="95%" style={{ height: '64vh', overflowX: 'auto' }}>
                    <p>文件路径为空，无法显示内容。</p>
                    </object> */}


  {/* {isFilePathValid ? (
  <object   
    data={`/uploads/subTopicSummary${currentClass}/${studentInfo?.GroupNo}/${"subTopic_summary_pdf.pdf"}`}
    type="application/pdf"
    width="95%"
    style={{ height: '64vh', overflowX: 'auto' }}
  >
   
    Your PDF content here...
  </object>
) : (
  <div>

    No PDF file available.
  </div>
)} */}
             
                  <div className="text-second  mx-auto pb-3">
                    <FileUploader
                      fileType="application/pdf"
                      uploaderDir={`/subTopicSummary${currentClass}/${studentInfo?.GroupNo}`}
                      buttonStr="上傳子題小結檔案"
                      setIsReload={setIsReload} isReload={isReload}
                      NewFileName={SummaryFileName}
                    />
                  </div>
                  {/* <div className="btn text-second bg-second mx-auto pb-3">上傳子題小結檔案</div> */}
                  {/* <!-- <a className="text-second mx-auto" href="https://eliteracy.twnread.org.tw/Uploads/111/pdf4_1/Step4-FA001-01國語實小.pdf" target="_blank"> 子題小結</a> --> */}

                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">4-2 提出探究題目的結論</h5>
                  <ul>
                    <li>歸納所有子題的小結，請小隊深入討論，提出你們的結論和發現。</li>
                    <li>結論要能回答最初的探究題目。</li>
                    <li>對於結論，有沒有可能的限制？有的話請提出來。</li>
                    <li>這裡全體隊員均可編輯。</li>
                  </ul>
                  <div className="concard p-3">
                    <span className="material-icons float-end pointer" data-bs-toggle="modal" data-bs-target="#editModal4-2"
                      title="編輯"
                      onClick={() => openEditModal('4_2提出結論與發現', '新增或修改')}
                    >edit</span>
                    <div className="fw-bolder text-second pt-3 pb-2">提出結論或發現：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post42_DataB || '') }}></div>

                    <div className="fw-bolder text-second pt-3 pb-2">我們的結論可能的限制：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post42_DataC || '') }}></div>

                    <div className="pt-3 pb-2">
                      <span className="fw-bolder text-second">日期：</span><span>{formatDateTime(opinion?.Post42_UpdateDate)}</span>
                    </div>
                  </div>
                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">4-3 比較探究結論與先前的預測</h5>
                  <ul>
                    <li>比較「階段1-3」時的預測，和最後的結論有什麼不同。</li>
                    <li>這裡全體隊員均可編輯。</li>
                  </ul>
                  <div className="p-3">
                    <div className="fw-bolder text-second">探究前的預測：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post13_DataB || '') }}></div>

                  </div>
                  <div className="concard p-3">
                    <span className="material-icons float-end pointer" data-bs-toggle="modal" data-bs-target="#editModal4-3"
                      title="編輯" onClick={() => openEditModal('4_3比較探究結論', '新增或修改')}
                    >edit</span>

                    <div className="fw-bolder text-second pt-3 pb-2"> 與最後的結論相比較：</div>
                
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post43_DataB || '') }}></div>

                    <div className="pt-3 pb-2">
                      <span className="fw-bolder text-second">日期：</span><span>{formatDateTime(opinion?.Post43_UpdateDate)}</span>
                    </div>
                  </div>
                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">4-4 困難、解決與新學習</h5>
                  <ul>
                    <li>請全體隊員共同回顧在「階段4」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                    <li>這裡全體隊員均可編輯。</li>
                  </ul>
                  <div className="concard p-3">
                    <span className="material-icons float-end pointer" data-bs-toggle="modal" data-bs-target="#editModal4-4"
                      title="編輯" onClick={() => openEditModal('4_4困難、解決與新學習', '新增或修改')}   >edit</span>
                    <div className="fw-bolder text-second pt-3 pb-2">我們在「階段4」遇到的困難：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataA || '') }}></div>
                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataB || '') }}></div>

                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataC || '') }}></div>

                    <div className="pt-3 pb-2">
                      <span className="fw-bolder text-second">日期：</span><span>{formatDateTime(opinion?.Post44_UpdateDate)}</span>
                    </div>
                  </div>


                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">階段4，指導老師留言</h5>
                  <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                  <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post49_DataA || '') }}></div>
        
                  <div className="pt-3 pb-2">
                    <span className="fw-bolder text-second">日期：</span><span>{formatDateTime(opinion?.Post49_UpdateDate)}</span>
                  </div>


                </div>
              </div>

            </div>

            <div className="pt-3 mt-4"></div>
            < SummaryModal ishModalOpen={ishModalOpen}
              setIsModalOpen={setIsModalOpen}
              groupNo={studentInfo?.GroupNo || ''}
              Summary={currentSummary}
              modeProp={currentMode}
              subNo={currentSubTopic}
              onFinish={() => { setIsReload(!isReload) }}
            />

            <EditModal
              groupNo={studentInfo?.GroupNo}
              isAddRsearchModalOpen={isAddRsearchModalOpen}
              setIsAddRsearchModalOpen={setIsAddRsearchModalOpen}
              currentShowModal={currentShowModal}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              formData={formData}
              setFormData={setFormData}
              onFinish={() => setIsReload(!isReload)}
            />
          </div>
        </div>
      </div>
      <FooterPage />
      <TutorialModal
        id="teach3"
        title="階段3 教學 - 怎樣選擇題目"
        src="//www.slideshare.net/slideshow/embed_code/key/g8zXWc7qtfNNjn"
        href="//www.slideshare.net/LoveBook2/3202301pptx"
      />
      <TutorialModal
        id="teach3-2"
        title="階段3 教學 - 怎樣判斷資料的可信度"
        src="//www.slideshare.net/slideshow/embed_code/key/r5peeTpp761fAh"
        href="//www.slideshare.net/LoveBook2/4202301pptx"
      />
      <TutorialModal
        id="teach4"
        title="階段4 教學 - 怎樣歸納統整資料"
        src="//www.slideshare.net/slideshow/embed_code/key/sF3WTJjXayexwq"
        href="//www.slideshare.net/LoveBook2/5202301pptx"
      />
      <TutorialModal
        id="teach4-2"
        title="階段4 教學 - 怎樣提出結論或發現"
        src="//www.slideshare.net/slideshow/embed_code/key/8DYUbEJVfM11n2"
        href="//www.slideshare.net/LoveBook2/6202301pptx"
      />
    </>

  )


}

export default EpStudLevel3;

