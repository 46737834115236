import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TeacherFunctionBar from './component/teacherFunctionBar';
import FooterPage from '../footer';
import TopNavPage1 from '../topNav1';
import { Export2Word, ReacherArPictureName_before, currentClass, downloadImage, formatDate, formatDateTime, getFileNameString, getLoginInfo, replaceNewline } from './tool/helper';
import { getDrGroupByTeaNo, getOpinionByGroupOpIdx } from '../../services/EP/EpApplyTeacherService';
import { IApplyTopic, IOpinion, ISearchDataSummary } from '../../interfaces/EpModal';
import { getOpinionByGroupNo, getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo, updateOpinionByGroupOpIdx } from '../../services/EP/EpApplyExploreService';
import { Modal } from 'react-bootstrap';
import { createOpinion, paramsCreateOpinionSubTopic } from '../../services/EP/OpinionService';

interface TeacherGroupInfo {
    FlagOK: boolean;
    GroupName: string;
    GroupNo: string;
    IsEnterFinal: boolean;
    PptDate: string;
    SchoolName: string;
    Team: { StuNo: string; StuName: string }[];
}

interface editSectionMode {
    stage1Open: boolean;

    stage2Open: boolean;
}
const EpTeachLevel1: React.FunctionComponent<IPageProps> = () => {
    const [mode, setMode] = useState<string>('');
    // const [mode,setMode] =useState<string>('')
    /** 子題 回饋前 */
    const [subTopicBeforeFeedback, setSubTopicBeforeFeedback] = useState<IApplyTopic[]>();

    const [formDataStage1TeacherFB_pre19, setFormDataStage1TeacherFBpre19] = useState<string>();
    const [formDataStage1TeacherFB_pre29, setFormDataStage1TeacherFBpre29] = useState<string>();
    const [subTopicAfterFeedback, setSubTopicAfterFeedback] = useState<IApplyTopic[]>();



    const [opinion, setOpinion] = useState<IOpinion>();
    const [stage1Open, setStage1Open] = useState<boolean>(false);
    const [stage2Open, setStage2Open] = useState<boolean>(false);

    const [reload, setReload] = useState<boolean>(false);
    const [currentGroupNo, setCurrentGroupNo] = useState<string>('EA011-01');

    /** */

    const [teaccherGroupInfoList, setTeacherGroupInfoList] = useState<TeacherGroupInfo[]>([]);
    const [selectedGroup, setSelectGroupInfo] = useState<TeacherGroupInfo | undefined>();
    useEffect(() => {
        fetchTeacherGroups();
    }, []);
    const fetchTeacherGroups = async () => {
        let currentLoginInfo = getLoginInfo();
        console.log('fetchData...', currentLoginInfo.LoginID.trim());
        const rsp = (await getDrGroupByTeaNo(currentClass, currentLoginInfo.LoginID.trim())) as any;
        if (rsp) {
            console.log('rsp---getDrGroupByTeaNo', rsp?.Groups);
            setTeacherGroupInfoList(rsp?.Groups);
            setSelectGroupInfo(rsp?.Groups[0]);
        }
    };



    /** 初始化取資料 */
    useEffect(() => {
        // 取得所有資料
        fetchDataOpinion();
        //取得 子題
        fetchDataSubTopic();

        // 取得小隊
    }, [selectedGroup, reload]);

    const fetchDataOpinion = async () => {
        if (selectedGroup) {
            /** 依次取回整個opionion 的資料  */
            if (mode == '開發') {
                let rsp = (await getOpinionByGroupNo('EA011-01')) as any;
                let opinions = (await getOpinionByGroupNo('EA011-01')) as IOpinion[];
                console.log('opinion ', rsp[0]);
                let opinion = opinions[0];
                setFormDataStage1TeacherFBpre19(opinion.Pre19_DataA);
                setFormDataStage1TeacherFBpre29(opinion.Pre29_DataA);
                setOpinion(opinions[0]);
            } else {
                if (selectedGroup.GroupNo) {
                    let rsp = (await getOpinionByGroupNo(selectedGroup.GroupNo)) as any;
                    let opinions = (await getOpinionByGroupNo(selectedGroup.GroupNo)) as IOpinion[];
                    console.log('opinion rsp ', rsp[0]);
                    let opinion = opinions[0];
                    setFormDataStage1TeacherFBpre19(opinion.Pre19_DataA);
                    setFormDataStage1TeacherFBpre29(opinion.Pre29_DataA);
                    setOpinion(opinions[0]);
                    console.log('formDataStage1TeacherFB_pre19' + formDataStage1TeacherFB_pre19);
                }
                try {
                    let teacher1 = await fetchDataindex(0, '19', selectedGroup.GroupNo);
                    console.log('teacher1', teacher1);
                    let teacher2 = await fetchDataindex(0, '29', selectedGroup.GroupNo);
                    console.log('teacher2', teacher2);
                } catch (ex) {
                    alert('發生錯誤');
                }
            }
        }
    };

    const fetchDataindex = async (isfeedback: 0 | 1, index: string, groupNo: string) => {
        try {
            let data = (await getOpinionByGroupOpIdx(isfeedback, index, groupNo)) as any;

            return data[0];
        } catch (ex) {
            alert(ex);
        }
    };

    const saveStage1TeacherfeedBack = async () => {
        let param = {
            DataA: formDataStage1TeacherFB_pre19 || '',
            DataB: null,
            DataC: null
        };

        if (selectedGroup?.GroupNo) {
            let teacher1 = await fetchDataindex(0, '19', selectedGroup?.GroupNo);
            console.log('teacher1', teacher1);
            if (teacher1) {
                await updateOpinionByGroupOpIdx(param, '0', '19', selectedGroup?.GroupNo);
            } else {
                let params = { GroupNo: selectedGroup?.GroupNo, DataA: formDataStage1TeacherFB_pre19, DataB: '', DataC: '' } as paramsCreateOpinionSubTopic;
                await createOpinion(params, 0, '19');

            }
            alert('老師留言更新成功!');
            setStage1Open(false);
            setReload(!reload);
        } else {
            alert('發生錯誤!');
        }
    };

    /** 儲存  */
    const saveStage2TeacherfeedBack = async () => {
        let param = {
            DataA: formDataStage1TeacherFB_pre29 || '',
            DataB: null,
            DataC: null
        };

        if (selectedGroup?.GroupNo) {
            let teacher2 = await fetchDataindex(0, '29', selectedGroup.GroupNo);
            if (teacher2) {
                await updateOpinionByGroupOpIdx(param, '0', '29', selectedGroup?.GroupNo); // service 回覆前老師回覆
            } else {
                let params = { GroupNo: selectedGroup?.GroupNo, DataA: formDataStage1TeacherFB_pre29, DataB: '', DataC: '' } as paramsCreateOpinionSubTopic;
                await createOpinion(params, 0, '29');
            }
            alert('老師留言更新成功!');
            setStage2Open(false);
            setReload(!reload);
        } else {
            alert('發生錯誤');
        }
    };

    /** 取得子題資料*/
    const fetchDataSubTopic = async () => {
        //取得回饋前 子題
        if (mode == '開發') {
            let subTopicbefore = (await getSubTopicByGroupNo(0, currentGroupNo)) as IApplyTopic[];

            console.log('subTopicbefore 子題(回覆前)', subTopicbefore);
            setSubTopicBeforeFeedback(subTopicbefore);

            //取得回饋後子題
            let subTopicAfter = (await getSubTopicByGroupNo(1, currentGroupNo)) as IApplyTopic[];

            console.log('subTopicAfter 子題(回覆後)', subTopicAfter);
            setSubTopicAfterFeedback(subTopicAfter);
        } else {
            if (selectedGroup?.GroupNo) {
                let subTopicbefore = (await getSubTopicByGroupNo(0, selectedGroup?.GroupNo)) as IApplyTopic[];

                console.log('subTopicbefore 子題(回覆前)', subTopicbefore);
                setSubTopicBeforeFeedback(subTopicbefore);

                //取得回饋後子題
                let subTopicAfter = (await getSubTopicByGroupNo(1, selectedGroup?.GroupNo)) as IApplyTopic[];

                console.log('subTopicAfter 子題(回覆後)', subTopicAfter);
                setSubTopicAfterFeedback(subTopicAfter);
            }
        }
    };

    return (
        <>
            <TopNavPage1 />
            <div className="">
                <div className="gotop text-second position-fixed px-3 py-2" title="Go Top">
                    <span className="material-icons-outlined py-2">keyboard_double_arrow_up</span>
                </div>
            </div>
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>

                        <TeacherFunctionBar />
                    </div>



                    <div id="title-info" style={{ display: "none" }} >
                        {selectedGroup?.SchoolName.trim()}-{selectedGroup?.GroupNo.trim()}-{selectedGroup?.GroupName.trim()}
                    </div>
                    <div className="text-center h5 mt-4">階段1和2</div>
                    <div className="dropdown" style={{ width: 'fit-content' }}>
                        <button
                            className="btn text-second bg-second dropdown-toggle mb-3"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ minWidth: '200px' }}
                        >
                            <span  > {selectedGroup?.GroupName} </span>
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {teaccherGroupInfoList?.map((groupInfo) => (
                                <li>
                                    <div className="dropdown-item d-flex" onClick={() => setSelectGroupInfo(groupInfo)}>
                                        {groupInfo.GroupName}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        小隊成員：
                        <span>{selectedGroup?.Team.map((stu) => stu.StuName).join("/")}</span>
                    </div>

                    <div className="accordion" id="accordionRental">
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#level1" aria-expanded="true" aria-controls="level1"
                                    data-bs-offset="50">
                                    <h4 className="my-0 me-3">階段1、決定探究題目</h4>
                                    <div className="text-second font-weight-bold">
                                        (階段1和2，請於 <span>112/03/24</span> 前完成)
                                    </div>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div className="collapse " id="level1" data-bs-parent="#accordionRental">
                                <div className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3 mt-0 mb-3">
                                    <div>
                                        <div>「訂題目」是個說難不難、說簡單也不簡單的事。</div>
                                        <div>希望每個隊員都能想想自己想探究的題目，啟動好奇心和求知欲。</div>
                                        <div>小隊再透過共同討論決定題目，並預測探究最後可能的結果。</div>
                                        <div>一起進入數位閱讀專題探究的世界吧！GO！</div>
                                    </div>
                                    <div>
                                        <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach1">
                                            怎樣選擇題目
                                        </div>
                                        <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-11', '階段1、', getFileNameString(selectedGroup ,undefined))}>
                                            匯出檔案
                                        </div>
                                    </div>
                                </div>
                                <div className="card p-4 pt-3" id="print-section-11">
                                   
                                    <div className="d-flex flex-wrap align-items-end">
                                        <ul>
                                            <li>每個學生最少提出一個題目，最好不超過3個。不能編輯其他成員的資料。</li>
                                            <li>
                                                此階段希望每個學生都能先提出<b>「自己」「想探究」</b>的題目，並說明理由。
                                            </li>
                                            <li className="text-danger">112年3月3日公布報名完成名單後，開始第1階段和第2階段的資料上傳</li>
                                        </ul>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped mb-0">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                    <th className="p-2 text-nowrap">成員</th>
                                                    <th className="p-2 text-nowrap">我想探究的題目</th>
                                                    <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {opinion?.Pre11.map((item) => (
                                                    <tr>
                                                        <td className="text-nowrap">{item.StuName}</td>
                                                        <td >{item.DataA}</td>
                                                        <td style={{ width: "300px" }}>{item.DataB}</td>
                                                        <td>{formatDate(item.UpdateDate)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                    <ul>
                                        <li>請全體隊員共同討論後，決定一個題目。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">
                                            我們決定探究的題目：
                                            <span className="text-primary" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataA || '') }}>
                                                {/* 越南新住民媽媽如何融入臺灣的社會生活？ */}
                                            </span>
                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataB || '') }}>

                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre12_UpdateDate)}</span>
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">最後編輯：</span>
                                            <span>
                                                {/* 2023/02/20 下午 03:39:00  */}
                                                {replaceNewline(opinion?.Pre12_StuName)}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                    <ul>
                                        <li>請全體隊員共同討論後，預測可能的結果。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">我們預測可能的結果是：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre13_DataB || '') }}>

                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre13_UpdateDate)}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                    <ul>
                                        <li>請全體隊員共同回顧在「階段1」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段1」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataA || '') }}>

                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataB || '') }}>

                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataC || '') }}>

                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre14_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                    <ul>

                                    </ul>
                                    <div className="conRedcard p-3">
                                        <span className="material-icons float-end pointer" data-bs-toggle="modal" data-bs-target="#editModal1" title="編輯" onClick={() => setStage1Open(true)}>
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>

                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre19_DataA || '(尚未留言)') }}>
                                            {/* 學校裡的新住民子女學生人數大約在70人上下，其中包含許多不同的國家，題目中有些想討論『新住民媽媽』，有些想討論『越南新住民媽媽』，為什麼最後題目會將對象定為『越南新住民媽媽』？ */}
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre19_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item" >
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="accordion-button text-dark collapsed p-0"
                                    data-bs-offset="50"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level2"
                                    aria-expanded="false"
                                    aria-controls="level2"
                                >
                                    <h4 className="my-0 me-2">階段2、規劃探究架構</h4>
                                    <div className="text-second font-weight-bold">
                                        (階段1和2，請於 <span>112/03/24</span> 前完成)
                                    </div>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="collapse" id="level2" data-bs-parent="#accordionRental" style={{ overflowY: "hidden" }}>
                                <div className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3 mt-0 mb-3">
                                    <div>
                                        <div>東西太大咬不下時，我們會把它切成小塊好入口。</div>
                                        <div>專題探究也一樣，要把題目分成幾個子題，找資料才方便。</div>
                                        <div>每個子題可能還包括一些重要的元素，試著把架構畫出來！</div>
                                        <div>有了架構圖，探究時就不會迷路啦！</div>
                                    </div>
                                    <div>
                                        <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach2">
                                            怎樣規劃探究架構
                                        </div>
                                        <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-21', '階段2、',getFileNameString(selectedGroup , undefined))}>
                                            匯出檔案
                                        </div>
                                    </div>
                                </div>
                                <div className="card p-4 pt-3" id="print-section-21">
                                    <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                    <ul>
                                        <li>把你們探究的題目再細分成若干個子題。</li>
                                        <li>建議細分成3~4個子題。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>

                                    <div className="d-flex flex-wrap align-items-end">
                                        <div className="fw-bolder text-second pt-3 pb-2">
                                            本隊探究題目：
                                            <span className="text-primary">{opinion?.Pre12_DataA}</span>
                                        </div>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second">
                                                    <th className="p-2">#</th>
                                                    <th className="p-2 text-nowrap">子題名稱</th>
                                                    <th className="p-2 text-nowrap">負責隊員</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subTopicBeforeFeedback?.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {/* 越南跟臺灣的飲食文化有哪些相異處？ */}
                                                            {item.SubName}
                                                        </td>
                                                        <td className="text-nowrap">{item.Description}</td>
                                                        <td>{formatDate(item.UpdateDate)}</td>
                                                    </tr>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-2 繪製探究架構</h5>
                                    <ul>
                                        <li>使用你熟悉的軟體繪製，如 Powerpoint、Xmind、Cacoo等。手繪後拍照上傳亦可，要清晰並轉正。</li>
                                        <li>存成副檔名為jpg的檔案後上傳。</li>
                                        <li>重傳檔案後若沒有立即更新圖片，請重整網頁(Ctrl+F5)，或清除瀏覽器歷史記錄的快取圖片。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="rounded-3 mx-auto p-3 imgborder text-center">
                                        <div className="d-flex justify-content-center align-items-start">
                                            <img 
                                                className="print-remove imgs"
                                                src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`}
                                                alt=""
                                                width="500"
                                              
                                            />

                                        </div>
                                        <div  className='mt-3'>
      <button 
                                                    onClick={() => downloadImage(`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}                                                        className="btn btn-secondary" 
                                                        data-bs-dismiss="modal" 
                                                     >
                                                     下載架構圖
                                        </button>

                                        </div>
                                  
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                    <ul>
                                        <li>請全體隊員共同回顧在「階段2」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段2」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataA || '') }}>

                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataB || '') }}>

                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataC || '') }}>

                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>2023/02/20 下午 03:39:00</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                    <ul>
                                        <li>若需提醒或鼓勵學生，可於此處留言。無則免填。</li>
                                        <li>兩位指導老師在同一編輯框，可自行註明由哪位老師編輯。</li>
                                        <li>字數請盡量在300字以內。</li>
                                    </ul>
                                    <div className="conRedcard p-3">
                                        <span className="material-icons float-end pointer" data-bs-toggle="modal" data-bs-target="#editModal2" onClick={() => setStage2Open(true)} title="編輯">
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre29_DataA || '') }}>{/* 學校裡的新住民子女學生人數大約在70人上下 */}</div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre29_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-3 mt-4"></div>
                </div>
            </div>
            <div w3-include-html="footer.html"></div>

            {/* 改寫 1 */}
            <Modal show={stage1Open} onHide={() => setStage1Open(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="mx-auto">階段1 指導老師留言</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="  pb-4">
                    <form role="form text-left">
                        <div className="row">
                            <div className="col-12">
                                <label className="">留言</label>
                                <div className="input-group">
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        placeholder=""
                                        value={formDataStage1TeacherFB_pre19 || ''}
                                        onChange={(e) => setFormDataStage1TeacherFBpre19(e.target.value)}
                                    >
                                        {/* 學校裡的新住民子女學生人數大約在70人上下，其中包含許多不同的國家，題目中有些想討論『新住民媽媽』，有些想討論『越南新住民媽媽』，為什麼最後題目會將對象定為『越南新住民媽媽』？ */}
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-12"></div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setStage1Open(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => saveStage1TeacherfeedBack()}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>

            {/* 改寫2  */}
            <Modal show={stage2Open} onHide={() => setStage2Open(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="mx-auto">階段2 指導老師留言</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto  px-3 pb-4">
                    <form role="form text-left">
                        <div className="row">
                            <div className="col-12">
                                <label className="">留言</label>
                                <div className="input-group">
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        placeholder=""
                                        //  value={opinion?.Pre19_DataA||''}
                                        value={formDataStage1TeacherFB_pre29 || ''}
                                        onChange={(e) => setFormDataStage1TeacherFBpre29(e.target.value)}
                                    >
                                        {/* 學校裡的新住民子女學生人數大約在70人上下，其中包含許多不同的國家，題目中有些想討論『新住民媽媽』，有些想討論『越南新住民媽媽』，為什麼最後題目會將對象定為『越南新住民媽媽』？ */}
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <label className="mt-3">日期：</label>
                                <span>{formatDateTime(new Date())}</span>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setStage2Open(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => saveStage2TeacherfeedBack()}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>
            {/* <!-- 2 指導老師留言 --> */}
            {/* <div className="modal fade" id="editModal2" tabIndex={1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '600px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">階段2 指導老師留言</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-3 pb-4">
                            <form role="form text-left">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="">留言</label>
                                        <div className="input-group">
                                            <textarea rows={3} className="form-control" placeholder="" >
                                               
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="mt-3">日期：</label>
                                        <span>2023/02/20 下午 03:39:00</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                取消
                            </button>
                            <button type="button" className="btn btn-primary">
                                儲存
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <!-- 階段1 教學 --> */}
            <div className="modal fade" id="teach1" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段1 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/HL4VzukgbKTTV9"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/1202301pptx" title="數位閱讀專題探究競賽 - 怎樣選擇題目" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣選擇題目
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段2 教學 --> */}
            <div className="modal fade" id="teach2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段2 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/2202301pptx" title="數位閱讀專題探究競賽 - 怎樣規劃探究架構" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣規劃探究架構
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <FooterPage />
        </>
    );
};

export default EpTeachLevel1;
