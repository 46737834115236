import ISDGs from "../interfaces/SDGS";

export const sdgsList:ISDGs[]=[
    {
        idx:'01',
        goal:'消除貧窮'
    },
    {
        idx:'02',
        goal:'消除飢餓'
    },
    {
        idx:'03',
        goal:'健康與福祉'
    },
    {
        idx:'04',
        goal:'教育品質'
    },
    {
        idx:'05',
        goal:'性別平等'
    },
    {
        idx:'06',
        goal:'淨水與衛生'
    },
    {
        idx:'07',
        goal:'可負擔及乾淨的能源'
    },
    {
        idx:'08',
        goal:'合適的工作及經濟成長'
    },
    {
        idx:'09',
        goal:'工業化、創新及基礎建設'
    },
    {
        idx:'10',
        goal:'減少不平等'
    },
    {
        idx:'11',
        goal:'永續城鄉'
    },
    {
        idx:'12',
        goal:'責任消費及生產'
    },
    {
        idx:'13',
        goal:'氣候行動'
    },
    {
        idx:'14',
        goal:'保育海洋生態'
    },
    {
        idx:'15',
        goal:'保育陸域生態'
    },
    {
        idx:'16',
        goal:'和平、正義及健全制度'
    },
    {
        idx:'17',
        goal:'多元夥伴關係'
    },
    
];

