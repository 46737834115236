import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExDPSource } from '../interfaces/ExDPSource';

export const getExDPSourceByGroup=(group:string)=>{
    const url=config.server.url+`/api/ExDPSource/group/${group}`
    return axios.get(url).then((res)=>{
        const rest:IExDPSource[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });}





