import config from '../config/config';

export const uploadFile = async (uploadUrl: string, file: File,newFileName:string) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    const formData = new FormData();
    formData.append('file', file);
    if(file.size>MAX_FILE_SIZE)
    {
        return 413;
    }

    const url=config.server.url+`/api/DrFile/upload?dir=${uploadUrl}&newFileName=${newFileName}`;
    try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData
         
        });
        const responseBody = await response.status; // Read response body as text
        console.log("responseBody",responseBody)
        return responseBody;
    } catch (error) {
        console.error('檔案上傳失敗:', error);
    }
}

export const checkFileExists = async (uploadUrl: string, filename: string,mode:string) => {
    const url = config.server.url + `/api/DrFile/checkfile?dir=${uploadUrl}&filename=${filename}&mode=${mode}`;
    try {
        const response = await fetch(url);
        const responseBody = await response.text(); // Read response body as text
        return responseBody;
    } catch (error) {
        console.error('檔案檢查失敗:', error);
    }
}


export const getuploadtime = async (uploadUrl: string, filename: string) => {
    const url = config.server.url + `/api/DrFile/getuploadtime?dir=${uploadUrl}&fname=${filename}`;
    console.log("gogo")
    try {
        const response = await fetch(url);
        const responseBody = await response.text(); // Read response body as text
        return responseBody;
    } catch (error) {
        console.error('檔案檢查失敗:', error);
    }
}


