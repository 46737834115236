import React from 'react'
import { handleLink } from '../../modules/utils';
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';


const TutSitePage: React.FunctionComponent = props => {
    return (
        <>
        <TopNavPage2 />
        <div className="section pt-3">
            <div className="container px-3 px-md-4">
            <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                <h5 className="title mb-3 me-3">數位讀寫資源</h5>
                <h5 className="order-2 order-md-1 mb-3">好站推薦</h5>
                <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width:'232px'}}>
                <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/tutorial')}>
                    <i className="fas fa-chevron-left"></i>
                    返回 數位讀寫資源
                </button>
                </div>
            </div>

            <div className="d-flex flex-wrap my-5">
                <a href="https://cirn.moe.edu.tw/Module/index.aspx?sid=1201" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_pirls.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">PIRLS 2021臺灣計畫官網</div>
                </a>
                <a href="https://pisa.irels.ntnu.edu.tw/" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_pisa.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">臺灣PISA國家研究中心</div>
                </a>
                <a href="https://www.family977.com.tw/index.php?route=choice/program_detail&choice_program_id=85&page=1" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_readpush.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">閱讀推手</div>
                </a>
                <a href="https://cirn.moe.edu.tw/Module/index.aspx?sid=1198" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_KnowClass.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">課文本位閱讀理解教學</div>
                </a>
                <a href="https://timssandpirls.bc.edu/index.html" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_EngPirls.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">Pirls 國際官網</div>
                </a>
                <a href="https://kids.frontiersin.org/" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_youngminds.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">Frontiers for Young Minds</div>
                </a>
                <a href="https://www.hwaweiko.tw/" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web-3Study.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">柯華葳教授閱讀研究中心</div>
                </a>
                <a href="https://flipedu.parenting.com.tw/article/005071" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_flipedu.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">翻轉教育</div>
                </a>
                <div className="col-12 col-lg-3 d-none d-lg-block"></div>
                <a href="https://futurecity.cw.com.tw/article/1867" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web-SDGs.jpg" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">永續發展目標SDGs<br/>(未來城市@天下)</div>
                </a>
                <a href="https://reading.tp.edu.tw/" target="_blank" className="col-6 col-md-4 col-lg-3 tsite">
                <img src="./assets/img/tut/web_taipei.png" alt="" className="mw-100 mb-3"/>
                <div className="fw-bolder h5 mb-0">臺北市深耕閱讀教育網</div>
                </a>

            </div>

            <div className="pt-3 mt-4"></div>
            </div>
        </div>
            

        <FooterPage/>
        </>
    );
}

export default TutSitePage;