import axios, { AxiosError } from 'axios'
import  config from '../../config/config';
import { IExStudent } from '../../interfaces/ExStudent';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { IExGradeClass } from '../../interfaces/ExGradeClass';
import { IExStuLearnRec } from '../../interfaces/ExStuLearnRec';
import { IInsertTeacher, IUpdateTeacher, IupdateApply } from '../../interfaces/EpModal';

//Jean-new
// Get /api/DrTeacher/applyno/:applypno
// const getDrTeacherByApplyNo = asyncHandler(async(req: Request, res: Response) 
/** 依據申請人取得學校資料 */
export const getDrTeacherByApplyNo = async  (applyno:string)=>{
  const url=config.server.url+`/api/DrTeacher/applyno/${applyno}`
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


}
// Update DrTeacher by TeaNo 
// PUT /api/DrTeacher/updno/:updno
// const updateDrTeacherByNo = asyncHandler(async(req: Request, res: Response) => {
// updateDrApplyByNo
export const updateDrTeacherByNo = async (param:IUpdateTeacher ,teacherNo :string)=>{
    //Here
    console.log("UpdateApplyBaseInfo",);
    const url=config.server.url+`/api/DrTeacher/updno/${teacherNo}`
    console.log('url',url)
    return new Promise((resolve, reject) => {
        axios.put(url,param)
          .then(response => {
            console.log("getLoginInfoByAccountAndPass ",response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


   

}


// 新增一名教師 依據申請編號選出最後一筆資料+"-"+英文字母
// Add DrTeacher by ApplyNo
// Post /api/DrTeacher/newapply/applyno/:applyno
// const createDrTeacherByApplyNo = asyncHandler(async(req: Request, res: Response) => {
// post
  export const insertDrTeacherByApplyNo =async (param:IInsertTeacher ,teacherNo :string)=>{

      console.log("UpdateApplyBaseInfo",);
      const url=config.server.url+`/api/DrTeacher/newapply/applyno/${teacherNo}`
      console.log('url',url)
      return new Promise((resolve, reject) => {
          axios.post(url,param)
            .then(response => {
              console.log("getLoginInfoByAccountAndPass ",response.data);
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });

  }
// Delete DrTeacher by TeaNo
// Get /api/DrTeacher/delete/teano/:teano
// const delDrTeacherByNo= asyncHandler(async(req: Request, res: Response) => {
/**刪除老師 */
export const delDrTeacherByNo =async (teacherNo :string)=>{
  const url=config.server.url+`/api/DrTeacher/delete/teano/${teacherNo}`
  return new Promise((resolve, reject) => {
      axios.delete(url)
        .then(response => {
          console.log("getLoginInfoByAccountAndPass ",response.data);
          resolve(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          reject(error); //
        });
    });

  }




// 以教師申請編號取得教師資料
// Get DrTeacher by TeaNo 
// Get /api/DrTeacher/teano/:teano
// const getDrTeacherByNo = asyncHandler(async(req: Request, res: Response) => {
  export const getDrTeacherByNo = async  (teacherNo:string)=>{
    const url=config.server.url+`/api/DrTeacher/teano/${teacherNo}`
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }



  // 以教師編號取得參賽小組資料
// Get DrGroup by teano
// Get /api/DrGroup/teano/:teano
// Response body
/*
{
  "TeaNo": "T001",
  "TeaName": "陳大明",
  "TeaTitle": "教師",
  "ApplyNo": "FD001",
  "ApplyName": "王小明",
  "ApplyTitle": "教師",
  Groups:[
    "GroupNo": "FD001-01",
    "GroupName": "綠色能量",
    "FlagOK": 1,
    "IsEnterFinal": 1,
    "UploadDate": "2021-03-01 12:00:00",
    "PptDate": "2021-03-01 12:00:00",
    "Topic": "綠色能量",    
    "Team":[
      {
        StuNo: "FD001-01-1",
        StuName: "王小明",
      },
      {
        StuNo: "FD001-01-2",
        StuName: "陳小明",
      }
    ]
  ]
}
*/
// const getDrGroupByTeaNo = asyncHandler(async(req: Request, res: Response) => 
 /** 以教師編號取得參賽小組資料 EpTeachLevel  epteacherHome*/
  export const getDrGroupByTeaNo = async  (className :string ,teacherNo:string)=>{
    const url=config.server.url+`/api/DrGroup/class/${className}/teano/${teacherNo}`
    console.log("getDrGroupByTeaNo url",url) ;
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }
  


  // 以小隊編號及意見編碼取得該小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1
  // Get Opinion or OpinionSure by groupno and opidx
  // Get /api/OpinionTable/isfeedback/:isfeedback/opidx/:opidx/groupno/:groupno 
  export const getOpinionByGroupOpIdx = async  (isfeedback :0|1, opidx :string ,groupno:string) =>{
    const url=config.server.url+`/api/OpinionTable/isfeedback/${isfeedback}/opidx/${opidx}/groupno/${groupno}`
    console.log("getDrGroupByTeaNo url",url) ;
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }