import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import IFunData from '../interfaces/FunData';
import IFunDataPart from '../interfaces/FunDataPart';
import IFunSource from '../interfaces/FunSource';


export const getAdSessionCnt=()=>{
    const url=config.server.url+`/api/AdSession/count`

    return axios.get(url).then((res)=>{
        let rest:{Cnt:number}=res.data[0];
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
export const getAdSessionTodayCnt=()=>{
    const url=config.server.url+`/api/AdSession/todaycnt`

    return axios.get(url).then((res)=>{
        const rest:{
            Cnt:number
        }=res.data[0];
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const addAdSession=(
    ip:string,
    )=>{
    const url=config.server.url+`/api/AdSession`
    return axios.post(url,{
        SeDate:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-"),
        IP:ip,
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getMyip=()=>{
    const url=config.server.url+`/auth/myip`

    return axios.get(url).then((res)=>{
        const rest:{
            ip:string
        }=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



