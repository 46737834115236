import React from 'react'
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';


const MemoryKoPage: React.FunctionComponent = props => {
    return (
        <>
        <TopNavPage2/>
        <div className="section pt-3">
            <div className="container px-3 px-md-4">
                <h5 className="order-2 order-md-1 mb-4 text-center" style={{fontSize: '24px', fontWeight:'bold'}}>柯華葳紀念專頁</h5>

                <div className="w-100 w-lg-75 mx-auto">
                    <img src="./assets/img/Memoryko.jpg" alt="" className="w-100 border-radius-lg"/>
                </div>
            <div className="row mt-5">
                <div className="col-lg-6 mb-4">
                <div className="card">
                    <div className="card-header pb-0">
                    <div className="fs-4 text-green fw-bolder mb-3">一、柯教授與閱讀基金會</div>
                    </div>
                    <div className="card-body ps-3 pe-4">
                    <div className="timeline timeline-one-side text-justify" data-timeline-axis-style="dotted">
                        <div className="timeline-block mb-3">
                        <div className="timeline-step">
                            <i className="fs-18 ni ni-tag text-success text-gradient"></i>
                        </div>
                        <div className="timeline-content">
                            <h4 className="text-dark font-weight-bold mb-0">2008</h4>
                            <div className="mt-3 mb-2">
                            9/23 敦請柯華葳教授擔任本會顧問，由廖祿立董事長及陳一誠執行長前往致贈聘書
                            </div>
                        </div>
                        </div>
                        <div className="timeline-block mb-3">
                        <div className="timeline-step">
                            <i className="fs-18 ni ni-tag text-danger text-gradient"></i>
                        </div>
                        <div className="timeline-content">
                            <h4 className="text-dark font-weight-bold mb-0">2017</h4>
                            <div className="mt-3 mb-2">
                            柯華葳教授代表基金會開始出席「愛的書庫」揭牌儀式
                            </div>
                        </div>
                        </div>
                        <div className="timeline-block mb-3">
                        <div className="timeline-step">
                            <i className="fs-18 ni ni-tag text-info text-gradient"></i>
                        </div>
                        <div className="timeline-content">
                            <h4 className="text-dark font-weight-bold mb-0">2018</h4>
                            <div className="mt-3 mb-2">
                            <ul>
                                <li>2/8 本會與玉山基金會合作辦理「玉山數位書箱研習」，清華大學柯華葳教授分享「數位時代閱讀」</li>
                                <li>3/20 本會與柯華葳教授、臺中市政府教育局進行「臺中市數位愛的書庫」籌備會議</li>
                                <li>7/5 本會辦理「發現天賦，成為孩子的伯樂」系列研習，柯華葳教授擔任2018年、2019年講師，分享天賦實例，推廣適性教學</li>
                                <li>9/19 全台第1~9座「數位愛的書庫」於臺中市揭牌成立，數位閱讀教學開始啟動</li>
                                <li>10月起，於福科國中、葫蘆墩國小辦理「數位愛的書庫」系列研習，由柯華葳教授親自主持，與臺北市國語實小林玫伶校長及團隊共同帶領課程</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="timeline-block mb-3">
                        <div className="timeline-step">
                            <i className="fs-18 ni ni-tag text-warning text-gradient"></i>
                        </div>
                        <div className="timeline-content">
                            <h4 className="text-dark font-weight-bold mb-0">2019</h4>
                            <div className="mt-3 mb-2">
                            <ul>
                                <li>1/3 本會與柯華葳教授、林玫伶校長討論數位閱讀推廣合作事宜，並規劃「線上數位閱讀專題探究競賽」</li>
                                <li>4-8月 正式辦理首屆「臺中市108年度數位閱讀專題探究競賽」，並於8/20於國立公共資訊圖書館舉行成果發表及頒獎典禮</li>
                                <li>9月 新竹縣、南投縣、彰化縣「數位愛的書庫」揭牌成立(全台第10~15座)，規劃辦理各縣市「數位閱讀教學趨勢研習暨線上探究競賽說明會」</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="timeline-block mb-3">
                        <div className="timeline-step">
                            <i className="fs-18 ni ni-tag text-primary text-gradient"></i>
                        </div>
                        <div className="timeline-content">
                            <h4 className="text-dark font-weight-bold mb-0">2020</h4>
                            <div className="mt-3 mb-2">
                            <ul>
                                <li>2-7月 擴大辦理「109年度線上數位閱讀專題探究競賽」，開放全台縣市國民中小學參加，並於7/21舉行決賽現場發表及頒獎典禮</li>
                                <li>6-8月
                                本會與柯華葳教授、林玫伶客座助理教授、劉源廣顧問討論「數位讀寫學習實施計畫」，並與清華大學竹師教育學院三研而立中心共同規劃建置「數位讀寫網」網站平臺，召開命題會議，充實網站題庫</li>
                                <li>7月 辦理全台「數位讀寫推廣研習」，由柯華葳教授、林玫伶助理教授及學者團隊，推廣數位讀寫平臺</li>
                                <li>8月 柯華葳教授提議邀請線上探究競賽獲獎隊伍重整作品，讓大家的作品被看見、被討論，期勉學生「叫人不小看你年輕」，於8月起辦理二場寫作坊，2021年完成線上出版，名之為「青春研值」
                                </li>
                                <li>9/18 「數位讀寫網」正式上線，依柯華葳教授規劃為PP、DP、EP等三個讀寫層次，循序漸進又可各自獨立</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="timeline-block">
                        <div className="timeline-step">
                            <i className="fs-18 ni ni-tag text-dark text-gradient"></i>
                        </div>
                        <div className="timeline-content">
                            <h4 className="text-dark font-weight-bold mb-0">2022</h4>
                            <div className="mt-3 mb-2">
                            <ul>
                                <li>2月 「線上數位閱讀專題探究競賽」為紀念柯華葳教授，冠名為「柯華葳線上數位閱讀專題探究競賽」</li>
                                <li>10月
                                於金門縣金鼎國小、西口國小新設全台第38、39座數位書庫揭牌啟用。因柯華葳教授推動數位閱讀最後未竟行程為金門之行，特於金門設置「柯華葳數位愛的書庫」，感念柯教授對金門閱讀教育之重視。(<a
                                    href="https://news.ltn.com.tw/news/life/breakingnews/4080202" target="blank">新聞連結</a>)</li>
                            </ul>
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>

                </div>
                </div>

                <div className="col-lg-6 mb-4">
                <div className="card">
                    <div className="card-header pb-0">
                    <div className="fs-4 text-green fw-bolder mb-3">二、紀念柯教授</div>
                    </div>
                    <div className="card-body text-justify px-4">
                    <div>
                        <div className=""><a href="https://www.hwaweiko.tw/home" target="_blank" className="text-oblue fw-bolder">紀念柯華葳</a></div>
                        <p className="mb-0">
                        書到尾聲，需要一個結論，摘要各章內容，將每一章主要概念間之關係連貫起來，形成巨觀的文本模式。若有讀者想知道全書重點，讀這一篇短文即有個概要。至於情境模式就有勞讀者自己形成了---- 柯華葳 2020.6.1
                        </p>
                        <div className="text-gray2 text-end mb-4">(柯華葳教授閱讀研究中心)</div>
                        
                        <div className=""><a href="https://www.youtube.com/watch?v=qk0i72Xz65M" target="_blank" className="text-oblue fw-bolder">紀念柯華葳教授～感恩的季節， 感恩有妳</a></div>
                        <p className="mb-0">
                        一生為教育熱情奉獻的柯華葳老師，在2020年11月18日離開了我們，老師用她的一生，陪伴了許許多多的父母與教育工作者，了解嬰幼兒的發展與需要，為了孩子美好的未來無私付出。她不只是閱讀教育的推手，更是幼兒發展研究的先鋒，也是信誼在幼教推廣路上亦師亦友的重要夥伴……
                        </p>
                        <div className="text-gray2 text-end mb-4">(信誼基金會)</div>
                        
                        <div className=""><a href="https://opinion.cw.com.tw/blog/profile/441/article/10225" target="_blank" className="text-oblue fw-bolder">浪漫、溫柔的強者─紀念柯華葳老師</a></div>
                        <p className="mb-0">
                        驚聞噩號時，我正要開車。一下子對柯老師的思念和不捨湧上心頭，眼前一片模糊。但，兩分鐘後，我擦乾眼淚，繼續上路。……
                        </p>
                        <div className="text-gray2 text-end mb-4">(曾世杰，原載於獨立評論2020.12.07)</div>
                        
                        <div className=""><a href="https://www.youtube.com/watch?v=OuN5HBJ1mhw" target="_blank" className="text-oblue fw-bolder">臺灣閱讀教育的永續發展-柯華葳教授紀念研討會</a></div>
                        <p className="mb-0">
                        為使柯華葳教授對閱讀教育之永續藍圖能持續推動，清華大學竹師教育學院於2021年3月26日舉辦柯華葳教授紀念研討會，以柯教授過去進行的學術研究議題及實踐閱讀教育理想之脈絡，就「前瞻的閱讀教育」為主旨，邀請國內相關學者就單一議題進行論述……
                        </p>
                        <div className="text-gray2 text-end mb-4">(清華大學竹師教育學院　2021.03.26)</div>
                        
                        <div className=""><a href="https://eliteracy.twnread.org.tw/images/MemoryKo/國語日報-柯老師紀念專輯之一.pdf" target="_blank" className="text-oblue fw-bolder">深耕閱讀理解教學 十年闢出新方向</a></div>
                        <p className="mb-0">
                        親愛的柯華葳教授離開我們一年了，回想過去，能在教授睿智溫暖的指導下，推動有策略的閱讀教學，培養學生自主學習力，真是何其有幸……
                        </p>
                        <div className="text-gray2 text-end mb-4">(楊怡婷，原載於國語日報2021.11.02)</div>
                        
                        <div className=""><a href="https://eliteracy.twnread.org.tw/images/MemoryKo/國語日報-柯老師紀念專輯之二.pdf" target="_blank" className="text-oblue fw-bolder">推動社會科跨域閱讀 助理解文本</a></div>
                        <p className="mb-0">
                        由柯華葳教授主持的「課文本位閱讀理解教學計畫」，自101年開始在全臺展開培訓與輔導工作，剛開始計畫重點以國小國語文領域為主軸，之後逐漸延伸至國中國文……
                        </p>
                        <div className="text-gray2 text-end mb-4">(呂明璜，原載於國語日報2021.11.09)</div>
                        
                        <div className=""><a href="https://eliteracy.twnread.org.tw/images/MemoryKo/國語日報-柯老師紀念專輯之三.pdf" target="_blank" className="text-oblue fw-bolder">發揮前瞻眼光 推動數位閱讀</a></div>
                        <p className="mb-0">
                        2014年，時任國家教育研究院院長的柯華葳教授，同時也是臺灣PIRLS的計畫主持人，鑒於數位閱讀已是學生學習的管道之一，多國致力發展線上閱讀能力課程，教師需要在教學上有所預備……
                        </p>
                        <div className="text-gray2 text-end mb-4">(林玫伶，原載於國語日報2021.11.16)</div>
                        
                        <div className=""><a href="https://youtu.be/IAOgLGaM8y8" target="_blank" className="text-oblue fw-bolder">柯華葳教授逝世週年紀念會_上午場影片</a></div>
                        <div className=""><a href="https://youtu.be/LOQ9EaDVWWg" target="_blank" className="text-oblue fw-bolder">柯華葳教授逝世週年紀念會_下午場影片</a></div>
                        <p className="mb-0">
                        曾志朗院士所召集的柯華葳教授閱讀永續推動辦公室，於2021年11月18日，假國家圖書館舉辦「柯華葳教授逝世週年紀念會」，以「閱讀教育＿往下札根＿向上發展」為主軸，就閱讀教育政策及相關議題進行對話交流……
                        </p>
                        <div className="text-gray2 text-end mb-4">(清華大學柯華葳教授閱讀研究中心　2021.11.18)</div>
                        
                    </div>
                    </div>
                </div>
                </div>

                <div className="col-12 mb-4">
                <div className="card">
                    <div className="card-header pb-0">
                    <div className="fs-4 text-green fw-bolder">三、柯教授與閱讀教育</div>
                    </div>
                    <div className="card-body text-justify px-4">
                    <div className="row">
                        
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.parenting.com.tw/article/5026230" target="_blank" className="text-oblue fw-bolder">閱讀的關鍵，在思考</a></div>
                                <p className="mb-0">
                                台灣加入兩項國際閱讀評比「PIRLS」和「PISA」，不高不低的名次引發各界討論。 擔任台灣PIRLS召集人的中央大學學習與教學研究所教授柯華葳，深入分析名次背後值得注意的危機和隱憂。
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於親子天下，2009.11.5)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://youtu.be/uerRxXV_atA" target="_blank" className="text-oblue fw-bolder">今年幾歲了</a></div>
                                <p className="mb-0">
                                打開學習錦盒系列演講。演講者：中央大學總教學中心主任委員柯華葳老師。主講題目：今年幾歲了。主講大綱：在社會對不同年齡的期許下，有沒有可能忘記年歲，讓四十歲還可以活得像小孩？
                                </p>
                                <div className="text-gray2 text-end mb-4">(中央大學，2010.6.9)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://youtu.be/LytuLGkyKRY" target="_blank" className="text-oblue fw-bolder">播下閱讀種子</a></div>
                                <p className="mb-0">
                                柯華葳教授應邀於大愛電視台「今夜說新聞」節目受訪，暢談父母如何引導孩子閱讀，培養閱讀習慣與興趣……
                                </p>
                                <div className="text-gray2 text-end mb-4">(TzuchiDaAi，2013.02.01)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://youtu.be/zPFhM5alqXI" target="_blank" className="text-oblue fw-bolder">閱讀五說01：讀寫算是素養</a></div>
                                <div className=""><a href="https://youtu.be/skmxz2tPF3c" target="_blank" className="text-oblue fw-bolder">閱讀五說02：閱讀需要學習之閱讀發展與閱讀歷程</a></div>
                                <div className=""><a href="https://youtu.be/s59I3Nn_M3k" target="_blank" className="text-oblue fw-bolder">閱讀五說03：閱讀需要學習之流暢度</a></div>
                                <div className=""><a href="https://youtu.be/o6583vKvYoo" target="_blank" className="text-oblue fw-bolder">閱讀五說04：理解</a></div>
                                <div className=""><a href="https://youtu.be/17TtOrc5d0M" target="_blank" className="text-oblue fw-bolder">閱讀五說05：新素養</a></div>
                                <p className="mb-0">
                                2014年中小學數位閱讀計畫系列課程，邀請柯華葳教授主講「閱讀五說」。閱讀推動那麼久又那麼好，為什麼還要談閱讀？請帶著這個疑問聽下去，聽完後你的疑問是否得到了解決？
                                </p>            
                                <div className="text-gray2 text-end mb-4">(中小學數位輔助學科閱讀計畫)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://youtu.be/ZAQW4EfQIhw" target="_blank" className="text-oblue fw-bolder">《未來Family》創刊發表會 精彩片段</a></div>
                                <p className="mb-0">
                                未來是什麼？未來就是很多的資訊！透過《未來Family》各式各樣的事例，讓父母親知道不是每個人都必須走一樣的道路，然後最後當我們能夠支持父母親的時候，父母親就可以支持他的孩子，靜下心來，一點點一點點來做，那麼我們才能夠翻轉這個社會。
                                </p>
                                <div className="text-gray2 text-end mb-4">(天下文化,2015.7)</div>
                            </div>
                
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.parenting.com.tw/article/5074934" target="_blank" className="text-oblue fw-bolder">賺取未來的本事</a></div>
                                <p className="mb-0">
                                柯華葳教授長期研究孩童閱讀能力與識字歷程，關注閱讀與語文學習等相關研究。2017年美感細胞團隊在雜學 ZA SHARE所舉辦的「未來教科書論壇」，邀請柯華葳教授參與，「課本是給孩子最好的禮物」就是她當時所提出的講題！
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於親子天下93期 2017.09.01)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.parenting.com.tw/article/5075781" target="_blank" className="text-oblue fw-bolder">學習閱讀的兩大基礎</a></div>
                                <p className="mb-0">
                                閱讀的學習，需要搭配兒童身心發展階段給予不同的素材；需要好的語言能力做為跨入閱讀的基礎；更重要的是，需要環境中有閱讀的示範與互動。
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於親子天下 2017.11.29)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.parenting.com.tw/article/5075968" target="_blank" className="text-oblue fw-bolder">培養孩子網路上的自學力</a></div>
                                <p className="mb-0">
                                台灣學生的數位閱讀素養，明顯落後。該重新思考，是否讓學生練習從網路、使用電腦、手機，尋找答案。從散在網路上知識，整合在個人手中，才是新的學習王道。
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於親子天下97期，2018.1.1)</div>
                            </div>
                
                        
                
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.parenting.com.tw/article/5076311" target="_blank" className="text-oblue fw-bolder">國際調查，台灣父母閱讀習慣待加強</a></div>
                                <p className="mb-0">
                                閱讀，從父母開始做起，和孩子一起為興趣而閱讀，當閱讀成習慣，傳遞了一種態度與溫度。言語搆不到的，留給閱讀。
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於親子天下98期 2018.03.01)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.youtube.com/watch?v=dwKvaM8BmjE" target="_blank" className="text-oblue fw-bolder">家庭有書香 孩子愛閱讀</a></div>
                                <p className="mb-0">
                                柯華葳教授應邀於公共電視台「新聞全球話」節目受訪，針對青少年閱讀情形提出看法……
                                </p>
                                <div className="text-gray2 text-end mb-4">(公視新聞網, 2018.06.20)</div>
                            </div>
                
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://reading.cw.com.tw/trend-article/90" target="_blank" className="text-oblue fw-bolder">培養數位閱讀素養就是培養數位自學力</a></div>
                                <p className="mb-0">
                                柯華葳教授長期研究閱讀能力與識字歷程，也是促進國際閱讀素養研究（PIRLS）台灣計畫主持人，提出閱讀教育新挑戰：面對數位時代，必須積極培養學生在網路大海的自學力，大人必須從信念開始改變，才能帶領學生成為有獨立思考的人。
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於天下雜誌 2018.12.21)</div>
                            </div>
                
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.youtube.com/watch?v=geZbhfzX4sI" target="_blank" className="text-oblue fw-bolder">以學與教為經為緯的教育心理學</a></div>
                                <p className="mb-0">
                                中華心理學刊六十週年學術研討會，演講者：柯華葳教授，演講題目：以學與教為經為緯的教育心理學。主辦單位：臺灣心理學會、國立台灣大學心理學暨研究所、國立中正大學心理學系
                                </p>
                                <div className="text-gray2 text-end mb-4">(中華心理學刊,2018.11.10-11)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="http://ec.site.nthu.edu.tw/p/404-1134-165254.php?Lang=zh-tw" target="_blank" className="text-oblue fw-bolder">少怎麼會多 - 談學習</a></div>
                                <p className="mb-0">
                                演講的一開頭，便點出全球教育頂尖的新加坡在2005年已開始推動「少教多學」的教育改革，把教學重點從過往以教師為中心的「多教」，轉向以學生為中心的「多學」，為此次《少即是多》的講題下了一個精闢且易懂的註解。
                                </p>
                                <div className="text-gray2 text-end mb-4">(國立清華大學竹師教育學院 尹書田教育講座)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://youtu.be/HJdMEhg-xvU" target="_blank" className="text-oblue fw-bolder">有了設計的導入，能幫助學生更好理解課本內容</a></div>
                                <p className="mb-0">
                                柯華葳教授長期研究孩童閱讀能力與識字歷程，關注閱讀與語文學習等相關研究。2017年美感細胞團隊在雜學 ZA SHARE所舉辦的「未來教科書論壇」，邀請柯華葳教授參與，「課本是給孩子最好的禮物」就是她當時所提出的講題！
                                </p>
                                <div className="text-gray2 text-end mb-4">(美感細胞團隊 2019.01.23)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://www.parenting.com.tw/article/5078894" target="_blank" className="text-oblue fw-bolder">培養網路自學力，從有目的的搜尋開始</a></div>
                                <p className="mb-0">
                                閱讀素養專家柯華葳，過去長期觀察國際閱讀評比，看到台灣小學生的閱讀力在國際屬資優生，卻無法遷移到網路數位閱讀素養。面對這項網路時代重要的自學力，師長該怎麼做？
                                </p>
                                <div className="text-gray2 text-end mb-4">(原載於親子天下105期，2019.3.1)</div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 p-3">
                                <div className=""><a href="https://youtu.be/Pr0aV9psN4A" target="_blank" className="text-oblue fw-bolder">TALIS高級中等學校臺灣報告</a></div>
                                <p className="mb-0">
                                高級中等學校2018教學與學習國際調查Teaching and Learning International Survey TALIS透過自陳式問卷調查，詢問高級中等學校校長和教師學校工作情況、專業發展進修、教學理念實務、工作得到的回饋與認可，以及學校領導管理等相關訊息調查，並由國家教育研究院進行台灣報告資料分析。
                                </p>
                                <div className="text-gray2 text-end mb-4">(國立臺灣師範大學教育研究與創新中心)</div>
                            </div>
                
                
                    </div>

                    </div>
                </div>
                </div>

                <div className="col-lg-6 mb-4">
                <div className="card">
                    <div className="card-header pb-0">
                    <div className="fs-4 text-green fw-bolder mb-3">四、柯教授著作(關於閱讀)</div>
                    </div>
                    <div className="card-body text-justify px-4">
                    <div>
                        <div className=""><a href="https://www.books.com.tw/products/0010478653?sloc=main" target="_blank" className="text-oblue fw-bolder">閱讀理解策略教學手冊</a></div>
                        <p className="mb-0">
                            柯華葳、幸曼玲、陸怡琮、辜玉旻，教育部，出版日期：2010-04-01
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/WebContent/38689/RFile/38689/106382.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010482667/page/1/idx/2/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">中文閱讀障礙</a></div>
                        <p className="mb-0">
                            方金雅、曾世杰、李俊仁、柯華葳、洪儷瑜、王瓊珠、陳淑麗、陳美芳，心理 ，出版日期：2010-09-13
                        </p>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010536602/page/1/idx/12/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">青春久久1：精選99本文學好書</a></div>
                        <p className="mb-0">
                            陳素燕、柯華葳，國家圖書館，出版日期：2011-11-30
                        </p>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010536604/page/1/idx/13/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">青春久久2：精選99本知識性好書</a></div>
                        <p className="mb-0">
                            陳素燕、柯華葳，國家圖書館，出版日期：2011-11-30
                        </p>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010566686/page/1/idx/11/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">閱讀，動起來4：閱讀策略，可以輕鬆玩：台北VS.香港 一課兩教</a></div>
                        <p className="mb-0">
                            柯華葳、林玫伶、葉煥婷，親子天下，出版日期：2012-11-30
                        </p>
                            
                        <div className="mt-4"><a href="https://cirn.moe.edu.tw/Module/Book/detail.aspx?sid=1198&amp;Menuid=13987&amp;bookId=132" target="_blank" className="text-oblue fw-bolder">課文本位閱讀理解教學：亮點學校成功模式</a></div>
                        <p className="mb-0">
                            教育部國民及學前教育署，出版日期：2016-02
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/5bce9029-c95d-47b3-b7e8-71b755b51699.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://cirn.moe.edu.tw/Module/Book/detail.aspx?sid=1198&amp;Menuid=13987&amp;bookId=133" target="_blank" className="text-oblue fw-bolder">課文本位閱讀理解教學延伸書單與導讀</a></div>
                        <p className="mb-0">
                            柯華葳、陳明蕾，教育部國民及學前教育署，出版日期：2016-02
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/9b13939b-cf21-4104-b42a-e0c3b9bae2a7.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://cirn.moe.edu.tw/Module/Book/detail.aspx?sid=1198&amp;Menuid=13987&amp;bookId=131" target="_blank" className="text-oblue fw-bolder">國民中學課文本位英語朗讀流暢度報告</a></div>
                        <p className="mb-0">
                            國家教育研究院，教育部國民及學前教育署，104學年度施測
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/c3529da4-aeca-4ae0-8306-67e063f19fae.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://www.books.com.tw/products/0010769857?sloc=main" target="_blank" className="text-oblue fw-bolder">閱讀理解策略教學</a></div>
                        <p className="mb-0">
                            柯華葳主編，教育部國民及學前教育署，出版日期：2017-08-01
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/2ea72b63-e579-4ab6-9c26-2fb1fbfc846f.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010771556/page/1/idx/3/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">教出閱讀力(2017年暢銷增修版)</a></div>
                        <p className="mb-0">
                            柯華葳，親子天下，出版日期：2017-11-29
                        </p>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010784359/page/1/idx/4/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">掌握策略快樂閱讀(二刷)</a></div>
                        <p className="mb-0">
                            柯華葳、游婷雅、陳明蕾，教育部國民及學前教育署，出版日期：2017-12-01
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/704c906a-1038-426e-8fd5-7d4a538d5ad4.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/15de58cc-def4-4989-aeb7-18475c6f734e.pdf" target="_blank" className="text-oblue fw-bolder">靜觀古文皆自得</a></div>
                        <p className="mb-0">
                            柯華葳，陳明蕾，賴明欣，教育部國民及學前教育署，出版日期：2019-08
                        </p>
                        <div className=""><a href="https://cirn.moe.edu.tw/Upload/Website/1198/ModuleWorkSheet/13987/RFile/15de58cc-def4-4989-aeb7-18475c6f734e.pdf" target="_blank" className="text-oblue text-sm">全文下載</a></div>
                            
                        <div className="mt-4"><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010879416/page/1/idx/19/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">當課文遇上策略達人：13個推動閱讀的感動實例</a></div>
                        <p className="mb-0">
                            林玫伶、柯華葳，幼獅文化，出版日期：2021-01-01
                        </p>
                            
                                        
                    </div>
                    </div>

                </div>
                </div>

                <div className="col-lg-6 mb-4">
                <div className="card">
                    <div className="card-header pb-0">
                    <div className="fs-4 text-green fw-bolder mb-3">五、柯教授著作(其他)</div>
                    </div>
                    <div className="card-body text-justify px-4">
                    <div>               
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010042603/page/1/idx/18/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">青少年心理學</a></div>
                        <p className="mb-4">
                            王煥琛，柯華葳，心理，出版日期：1999-05-31
                        </p>
                        <div className=""><a href="https://youtu.be/Pr0aV9psN4A" target="_blank" className="text-oblue fw-bolder">學習障礙學生輔導手冊</a></div>
                        <p className="mb-4">
                            教育部特殊教育小組主編，柯華葳編著，國立台南師範學院，出版日期：2000-02
                        </p>
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010164555/page/1/idx/17/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">我會愛</a></div>
                        <p className="mb-4">
                            柯華葳等，遠流，出版日期：2001-07-05
                        </p>
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010246947/page/1/idx/22/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">華語文能力測驗編製：研究與實務</a></div>
                        <p className="mb-4">
                            柯華威，遠流，出版日期：2004-01-10
                        </p>
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010384485/page/1/idx/16/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">人類發展</a></div>
                        <p className="mb-4">
                            林美珍、黃世琤、柯華葳，心理，出版日期：2007-10-01
                        </p>
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010448424/page/1/idx/14/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">實用華語文教學概論</a></div>
                        <p className="mb-4">
                            戴維揚、朱榮智、黃春貴、許怡真、張正男、楊如雪、陳美琪、傅武光、邵曼珣、柯華葳，新學林，出版日期：2009-09-01
                        </p>
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010612233/page/1/idx/21/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">語文數位學習</a></div>
                        <p className="mb-4">
                            柯華葳，高等教育出版社，出版日期：2013-10-01
                        </p>
                        <div className=""><a href="https://search.books.com.tw/redirect/move/key/%E6%9F%AF%E8%8F%AF%E8%91%B3/area/mid/item/0010766563/page/1/idx/1/cat/001/pdf/1/spell/3" target="_blank" className="text-oblue fw-bolder">新課綱想說的事：幼兒園教保活動課程大綱的理念與發展(第二版)</a></div>
                        <p className="mb-4">
                            丘嘉慧、幸曼玲、林玫君、柯華葳、楊金寶、簡淑真、蔡敏玲、郭李宗文、金瑞芝，心理，出版日期：2017-09-01
                        </p>
                    </div>

                    </div>
                </div>
                </div>
                </div>

            </div>
        </div>
        <FooterPage />
        </>

    );
}

export default MemoryKoPage;