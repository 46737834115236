/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react'
import IAnsPP2 from '../../interfaces/AnsPP2';
import IAnsStatePP2 from '../../interfaces/AnsStatePP2';
import { IExSentence } from '../../interfaces/ExSentence';
import IPageProps from '../../interfaces/page'
import { getLearnRecCurrVal, getLearnRecCurrValStr, getLearnRecVal, setLearnRecCurrVal, setLearnRecVal } from '../../modules/SessionTestCnt';
import { calcPercentage, genFerrisImageURL, handleLink } from '../../modules/utils';
import { getAnExSentence, getAnExSentenceByLevel } from '../../services/ExSentencesService';
import { AddExStuPP4, AddExStuRec } from '../../services/ExStuRecService';
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';

const PPTestPage2: React.FunctionComponent<IPageProps> = props => {
    const pp2LearnRec={
        countPP4:getLearnRecVal("sCountPP4"),
        correctPP4:getLearnRecVal("sCorrectPP4"),
        wrongPP4:getLearnRecVal("sWrongPP4")        
    }

    const redirToPPTest=()=>{
        AddExStuRec(
            getLearnRecCurrVal("userID"),
            getLearnRecCurrValStr("TType"),
            getLearnRecCurrValStr("TQno"),
            getLearnRecCurrVal("sCorrect"),
            getLearnRecCurrVal("sWrong"),
            getLearnRecCurrVal("sSecond"),)
            sessionStorage.setItem("LearnRecCurr","");
        handleLink('/pptest');
    }

    const [AnsStatePP2,setAnsStatePP2]=useState<IAnsStatePP2>({
        correctPP2:0,
        wrongPP2:0,
        AnsListPP2:[]
    })

    const [activePP2,setActivePP2]=useState(false);
    const [hardLevel,setHardLevel]=useState('全部');
    const [reHardLevel,setReHardLevel]=useState('全部');
    const [ppExSentence,setPPExSentence]=useState<IExSentence>({
        Sentence:'',
        Answer:'',
        RandomWords:[]
    });
    const [ppInpAnswer2,setPPInpAnswer2]=useState('');
    const [entPP2,setEntPP2]=useState(true);

    const handlePP2=()=>{

        setActivePP2(true);

        getExSentence();
        setReHardLevel(hardLevel);
    }

    const handleReHardLevel=()=>{
        setHardLevel(reHardLevel);
        getExSentence();
    }

    const handlePPInpAnswer2=(e:React.FormEvent<HTMLInputElement>)=>{
        setPPInpAnswer2(e.currentTarget.value);
    }

    const handleKeyDown=(e: React.KeyboardEvent<HTMLElement>)=>{        
        if(e.key==="Enter") handlePPAnswer2()
    }

    
    const getExSentence=async ()=>{
        let lvl=-1;
        switch(hardLevel)
        {
            case '★☆':
                lvl=1;
                break;
            case '★★':
                lvl=2;
                break;
            case '全部':
                lvl=-1
                break;    
        }
               
        let ASent;
        
        
        if (lvl===-1)
        {
            ASent=await getAnExSentence();
        } else {
            ASent=await getAnExSentenceByLevel(lvl);
        }
        const RWords=ASent.Sentence.split("/");
        console.log(RWords);
        if (RWords.length>0)
        {
            let tmp;
            while(ASent.RandomWords.length<RWords.length)
            {
                tmp=RWords[Math.floor(Math.random()*RWords.length)];
                if (!ASent.RandomWords.includes(tmp))
                {
                    ASent.RandomWords.push(tmp);
                }
            }
        }
        setPPExSentence(ASent);
    }
    const specQuestionStr=(strArray:string[]):string=>{
        let retStr="";
        strArray.map((item)=>{
            retStr=retStr+item+" ";
        })
        retStr.trim();
        return retStr;
    }

    const handlePPAnswer2=()=>{
        if(ppInpAnswer2==="") return;

        if(ppExSentence.Answer.trim()===ppInpAnswer2)
        {
            setLearnRecCurrVal("sCorrect",AnsStatePP2.correctPP2+1);        
            AnsStatePP2.AnsListPP2.push({
                Answer:ppExSentence.Answer.trim(),
                InputAns:ppInpAnswer2
            })
            setAnsStatePP2({
                correctPP2:AnsStatePP2.correctPP2+1,
                wrongPP2:AnsStatePP2.wrongPP2,
                AnsListPP2: AnsStatePP2.AnsListPP2
            })
            setLearnRecVal("sCorrectPP4",(pp2LearnRec.correctPP4+1));

            AddExStuPP4(Number(sessionStorage.getItem("LoginID")),specQuestionStr(ppExSentence.RandomWords),ppExSentence.Answer.trim(),ppInpAnswer2,1);
        }
        else
        {
            setLearnRecCurrVal("sWrong",AnsStatePP2.wrongPP2+1);         
            AnsStatePP2.AnsListPP2.push({
                Answer:ppExSentence.Answer.trim(),
                InputAns:ppInpAnswer2
            })
            setAnsStatePP2({
                correctPP2:AnsStatePP2.correctPP2,
                wrongPP2:AnsStatePP2.wrongPP2+1,
                AnsListPP2: AnsStatePP2.AnsListPP2
            })
            setLearnRecVal("sWrongPP4",(pp2LearnRec.wrongPP4+1));
            
            AddExStuPP4(Number(sessionStorage.getItem("LoginID")),specQuestionStr(ppExSentence.RandomWords),ppExSentence.Answer.trim(),ppInpAnswer2,0);
        }
        if(entPP2)
        {
            setLearnRecVal("sCountPP4",(pp2LearnRec.countPP4+1))
            setEntPP2(false);
        }
        getExSentence();
        setReHardLevel(hardLevel);     
        setPPInpAnswer2('');
 
    }
    /*useEffect(()=>{
        console.log("ppExSentence:"+ppExSentence.RandomWords);
    }
    ,[ppExSentence]);*/

    return (
        <>
        <div style={{backgroundColor:'#FCF9F3'}}>
            <div><TopNavPage2/></div>
                <div className="container d-flex flex-column flex-md-row align-items-center mb-2">
                    <div className="d-flex align-items-start align-items-sm-center py-2 order-2 order-md-1">
                        <img src="./assets/img/PP-02.svg" className="me-2" style={{width:'80px'}}/>
                        <div>
                            <p className="mb-1">將字詞組合成完整通順的句子</p>
                            {
                                (sessionStorage.getItem("LoginStatus")==="0") || (sessionStorage.getItem("LoginStatus")===null) || (sessionStorage.getItem("LoginStatus")==="2") ?
                                <div>
                                    <span className="badge badge-danger">您未登入，故不會儲存本次練習結果。</span>
                                </div> :

                                <div>
                                    <span className="badge badge-secondary">學習次數：{pp2LearnRec.countPP4}</span>
                                    <span className="badge badge-secondary">答對句子數：{pp2LearnRec.correctPP4}</span>
                                    <span className="badge badge-secondary">答錯句子數：{pp2LearnRec.wrongPP4}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="ms-auto order-1 order-md-2 my-3">
                        <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>redirToPPTest()}>
                            <i className="fas fa-chevron-left"></i>
                            返回 PP 基礎讀寫
                        </button>
                    </div>
                </div>
            </div>
            <div className="section pt-0">
                <div className="container">
                    {!activePP2 &&
                    <div id="info" >
                        <div className="text-center d-flex justify-content-center align-items-center" style={{minHeight: '300px'}}>
                            <div className="card">
                                <div className="card-header">
                                    <h5>請先選擇句子的「難易度」</h5>
                                </div>
                                <div className="card-body">
                                    <div className="nav-wrapper position-relative end-0 mb-5">
                                        <ul className="nav nav-pills nav-fill p-1 flex-column flex-lg-row" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="three" aria-selected="true" onClick={()=>setHardLevel('全部')}>
                                                    全部
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#" role="tab" aria-controls="one" aria-selected="false" onClick={()=>setHardLevel('★☆')}>
                                                    ★☆
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="two" aria-selected="false" onClick={()=>setHardLevel('★★')}>
                                                    ★★
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button type="button" className="btn btn-round btn-primary btn-lg" onClick={()=>handlePP2()}>開始測驗</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {activePP2&&
                    <div id="start" >
                        <div className="row">
                            <div className="col-12 col-lg-9">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center flex-wrap">
                                            {
                                                ppExSentence.RandomWords.map((item,i)=>{
                                                   return <h1 className="test-word border border-radius-lg p-3 mt-0 me-2">{item}</h1>
                                                })
                                            }
                                        </div>
                                        <input type="text" value={ppInpAnswer2} onChange={handlePPInpAnswer2} className="form-control form-control-lg text-center mt-3"
                                            onPaste={(e)=>{
                                                e.preventDefault()
                                                return false;
                                            }}
                                            onCopy={(e)=>{
                                                e.preventDefault()
                                                return false;
                                            }}
                                            onKeyDown={handleKeyDown}/>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-column">
                                    <button type="button" className="btn btn-round btn-primary btn-lg my-3 px-5 w-fc" onClick={()=>handlePPAnswer2()}>確定</button>
                                    <button type="button" className="btn btn-link" onClick={()=>handleLink('/pptest')}>結束練習</button>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="card bg-light">
                                    <div className="card-body">
                                    <button className="btn btn-secondary w-100 mb-0" data-bs-toggle="modal" data-bs-target="#lvModal">{hardLevel}<br/>（重選難易度）</button>
                                        <div className="d-flex text-center">
                                            <div className="flex-fill mx-1">
                                                <h5 className="font-weight-bolder mb-0 mt-3">{AnsStatePP2.correctPP2}</h5>
                                                <span className="text-sm">答對</span>
                                            </div>
                                            <div className="flex-fill">
                                                <h5 className="font-weight-bolder mb-0 mt-3">{AnsStatePP2.wrongPP2}</h5>
                                                <span className="text-sm">答錯</span>
                                            </div>
                                            <div className="flex-fill">
                                                <h5 className="font-weight-bolder mb-0 mt-3">{calcPercentage(AnsStatePP2.correctPP2,AnsStatePP2.wrongPP2,1)}<sub className="ps-1">%</sub></h5>
                                                <span className="text-sm">答對率</span>
                                            </div>
                                        </div>
                                        <hr className="horizontal dark"/>
                                        <div className="row">
                                            <div className="col-12 accordion" id="accordionRental">
                                                <div className="accordion-item mb-3">
                                                    <h5 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button text-dark collapsed px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{width:'auto'}}>
                                                            <h5 className="my-0 me-2 p-3">已練習的句子</h5>
                                                            <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                            <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                        </button>
                                                    </h5>
                                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionRental" >
                                                        <div className="row">
                                                            {
                                                                AnsStatePP2.AnsListPP2.slice(0).reverse().map((item,i)=>{
                                                                    if(item.Answer===item.InputAns)
                                                                    {
                                                                        return(
                                                                            <div className="col-12 col-md-4 col-lg-12 my-1">
                                                                            <div className="badge badge-circle badge-success">
                                                                                <i className="ni ni-check-bold"></i>
                                                                            </div>
                                                                            {item.Answer}</div>
                                                                        )
                                                                    }
                                                                    else
                                                                    {
                                                                        return(
                                                                            <div className="col-12 col-md-4 col-lg-12 my-1">
                                                                            <div className="badge badge-circle badge-danger">
                                                                            <i className="fas fa-times" aria-hidden="true"></i>
                                                                        </div>
                                                                        {item.InputAns}<span className="text-danger">（{item.Answer}）</span>
                                                                        </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            { (sessionStorage.getItem("LoginStatus")==="1") &&
                                            <>
                                            <hr className="horizontal dark mt-3"/>
                                            <div className="col-12 d-flex flex-column flex-sm-row flex-lg-column align-items-center">
                                                <img src={genFerrisImageURL(AnsStatePP2.correctPP2,1,10)} className="w-100 w-sm-50 w-lg-100"/>
                                                答對10題，晉一級並點亮一個車廂，最高為六級。
                                            </div>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    }
                </div>
            </div>
            <FooterPage />
            {/*<!-- 難易度 --> */}
            <div className="modal fade" id="lvModal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">重選難易度</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body pb-3">
                    <ul className="nav nav-pills nav-fill p-1 flex-column flex-lg-row" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="ee" aria-selected="true" onClick={()=>setReHardLevel('全部')}>
                                全部
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#" role="tab" aria-controls="aa" aria-selected="false" onClick={()=>setReHardLevel('★☆')}>
                            ★☆
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="bb" aria-selected="false" onClick={()=>setReHardLevel('★★')}>
                            ★★
                            </a>
                        </li>    
                    </ul>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>handleReHardLevel()}>確定</button>
                    </div>
                </div>
            </div>
        </div>
                    
        
        
        </>
    );
}

export default PPTestPage2;

