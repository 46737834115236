import React, { useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import { handleLink } from '../../modules/utils';
import { getExStudentByStuNoPwd, getExTeacherByTNoPwd, getUpdExStuLearnRec } from '../../services/ExStuTeService';
import FooterPage from '../footer';
import { BSModal } from '../../components/modal/bsmodal';
import { getExStuTestCntBySid } from '../../services/ExStuRecService';
import config from '../../config/config';
import { addAdLogin } from '../../services/AdLoginService';
import { getMyip } from '../../services/AdSessionService';
import { set } from 'date-fns';
import { ObjectModal } from '../../components/modal/objectmodal';
import TopNavPage1 from '../topNav1';
import { getEPApplyByNo, getLoginInfoByAccountAndPass } from '../../services/EP/EpLoginService';
import { ILoginUserInfo } from '../../interfaces/EpModal';
import { getLoginInfo, setLoginInfoOnSession } from './tool/helper';

//Jean
const EpLoginPage: React.FunctionComponent<IPageProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const [currentApply, setCurrentApply] = useState();
    const [account, setAccount] = useState<string>();
    const [pwd, setPwd] = useState('');
    /** 檢查帳密*/
    const [pressed, setPressed] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [bindMail, setBindMail] = useState('');
    const [openObjectModal, setOpenObjectModal] = useState(false);

    const handleAccInp = (e: React.FormEvent<HTMLInputElement>) => {
        setAccount(e.currentTarget.value);
    };
    const handlePwdInp = (e: React.FormEvent<HTMLInputElement>) => {
        setPwd(e.currentTarget.value);
    };



    useEffect(() => {
        //Jean-new

    }, []);
    /** */

    /** 取得申請人資料 */
    const getEPApplyByNo1 = async (account: string) => {
        let rsp = await getEPApplyByNo(account);

        console.log('rsp', rsp[0]);
        setAccount(rsp);
        return rsp;
    };

    const chkLogin = () => {
        if (account !== '' && pwd !== '') {
            setPressed(true);
            //handleLink("/");
        } else {
            setOpenModal(true);
            if (account === '' && pwd === '') {
                setErrMsg('帳號及密碼不可空白');
            } else {
                if (account === '') setErrMsg('帳號不可空白');
                if (pwd === '') setErrMsg('密碼不可空白');
            }
        }
    };
    /** 檢查輸入後狀態 */
    useEffect(() => {
        if (pressed) {
            const fetchdata = async () => {
                const loginInInfo = getLoginInfo();
                // 以下參考
                if (account) {
                    const accountPwdMeachedList: any[] = ((await getLoginInfoByAccountAndPass(account?.toLowerCase().trim(), pwd.trim())) as any[]) || [];

                    /** 此帳號符合長度 */
                    const accountMatchNum = accountPwdMeachedList.length;
                    if (accountMatchNum === 1) {
                        const userInfo: ILoginUserInfo = accountPwdMeachedList[0];

                        setLoginInfoOnSession(userInfo,);

                        if (userInfo.LoginStatus === 'DrApply') {
                            //申請人
                            handleLink('/epApplyHome');
                        } else if (userInfo.LoginStatus === 'DrTeacher') {
                            handleLink('/epTeachHome');
                            //老師
                            //導向老師頁面
                        } else if (userInfo.LoginStatus === 'DrStudent') {
                            // 學生

                            //導向學生頁面
                            //
                            handleLink('/epStudentHome');
                        } else if (userInfo.LoginStatus === 'Expert') {
                            handleLink('/epExpertHome');
                        }
                    } else if (accountMatchNum === 0) {
                        // 比對不到正確帳號密碼
                        sessionStorage.setItem('LoginStatus', '0');
                        sessionStorage.setItem('LoginName', '');
                        sessionStorage.setItem('LoginID', '');
                        sessionStorage.setItem('BindError', '');
                        setErrMsg('帳號或密碼錯誤');
                        setOpenModal(true);
                        setPressed(false);
                        return
                    } else {
                    }


                    const tmpAccount = await getEPApplyByNo1(account?.toLowerCase().trim());
                    /** 確認帳號是否存在 */
                    if (tmpAccount === undefined) {
                        sessionStorage.setItem('LoginStatus', '0');
                        sessionStorage.setItem('LoginID', '');
                        sessionStorage.setItem('LoginSchoolNo', '');
                        sessionStorage.setItem('BindError', '');
                        setErrMsg('帳號及密碼錯誤');
                        setOpenModal(true);
                        setPressed(false);
                        return;
                    }
                    if (tmpAccount.length > 2) {
                        setErrMsg('有重複帳號');
                    }
                }
            };
            fetchdata();
            // handleLink('/')
        }
    }, [pressed]);

    useEffect(() => {
        sessionStorage.setItem('LoginStatus', '0');
        sessionStorage.setItem('LoginID', JSON.stringify(null));
        sessionStorage.setItem('LoginSchoolNo', JSON.stringify(null));
        sessionStorage.setItem('LoginStudent', JSON.stringify(null));
        sessionStorage.setItem('LearnRec', '');
        sessionStorage.setItem('LoginTeacher', JSON.stringify(null));
        sessionStorage.setItem('LearnRecCurr', '');
        if (sessionStorage.getItem('BindMail') !== null || sessionStorage.getItem('BindMail') !== '') {
            setBindMail(sessionStorage.getItem('BindMail')!);
        }
    }, []);
    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };
    return (


        <>
            <TopNavPage1 />


            <div className="py-5 sidebar-collapse">
                <div className="container" style={{ maxWidth: '500px' }}>
                    <div className="row" style={{ borderRadius: '8px', boxShadow: '0px 0px 10px 5px #d7d7d7' }}>
                        <div className="col-12 order-1 order-md-2 font-weight-bold bg-white logreg login px-4">
                            <h4 className="py-3 text-center text-primary fw-bolder">登入</h4>

                            <div className="tab-content mt-4">
                                <div className="text-center tab-pane fade show active" id="loginep" role="tabpanel" aria-labelledby="loginep-tab">
                                    <div className="mb-4 text-gray fw-normal text-sm">
                                        EP 線上探究競賽登入
                                        <br />
                                        帳號登入(承辦人、學生和指導教師分別以帳號登入)
                                    </div>

                                    {/* 
              <input type="text" className="form-control mb-3" placeholder="請輸入帳號 / Email" onChange={handleAccInp}/>
                                    <div>密碼</div>
                                    <input type="password" className="form-control mb-3" placeholder="請輸入密碼" onChange={handlePwdInp}/> */}

                                    <div>帳號</div>
                                    <input type="text" className="form-control mb-3" placeholder="請輸入帳號" onChange={handleAccInp} value={account} />
                                    <div>密碼</div>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mb-3" placeholder="請輸入密碼" onChange={handlePwdInp} value={pwd} />


                                    <div   style={{ display: 'flex', alignItems: 'center' }}>
                                        <input 
                                            type="checkbox"
                                            id="togglePassword"
                                            checked = {showPassword}
                                            onChange ={handleCheckboxChange}
                                        />
                                        <label   style={{margin:'0px',marginLeft:'5px'}}  htmlFor="togglePassword">
                                            顯示密碼
                                        </label>
                                    </div>
                                    <button className="btn btn-round btn-primary my-4 text-nowrap" onClick={() => chkLogin()}>
                                        登入
                                    </button>
                                    {/* <!-- <hr className="hr1 my-4"> --> */}
                                    <div className="my-4">
                                        <a
                                            onClick={() => {
                                                handleLink('/epNewPage');
                                            }}
                                            className="text-primary"
                                            style={{ borderBottom: '1px solid #46A1A3;', cursor: 'pointer' }}
                                        >
                                            承辦人申請帳號
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BSModal title={'提醒您'} isOpen={openModal} setOpenModal={setOpenModal}>
                {errMsg}
            </BSModal>
            <FooterPage />

            {/* <div w3-include-html="footer.html"></div> */}
        </>
    );
};

export default EpLoginPage;
