/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useState } from 'react'
import { IExDP } from '../../interfaces/ExDP';
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils';
import { getDPByGrpSubj, getDPByGrpSubjSid } from '../../services/ExDPService';

const DPTextPage: React.FunctionComponent<IPageProps> = props => {
    const [subject,setSubject]=useState('全部');
    const [PirlsTopics,setPirlsTopics]=useState<IExDP[]>([])

    const acqPirlsTopic=async (subj:string)=>{
        setSubject(subj)
        if(sessionStorage.getItem("LoginStatus")==="1")
            getDPByGrpSubjSid('Pirls',subj,Number(sessionStorage.getItem("LoginID"))).then(data=>setPirlsTopics(data))
        else
            getDPByGrpSubj('Pirls',subj).then(data=>setPirlsTopics(data))
    }

    const handleDPPractice=async (_exdp:IExDP)=>{
        sessionStorage.setItem("DPGroup","Pirls");
        sessionStorage.setItem("DPNo",_exdp.ArticleNo);
        sessionStorage.setItem("DPTopic",_exdp.DPTopic);
        sessionStorage.setItem("DPImage",_exdp.ArticleImg);
        sessionStorage.setItem("DPCnt",(_exdp.DPCnt)?_exdp.DPCnt.toString():"0");
        handleLink('/dppractice')
    }

    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="1")
            getDPByGrpSubjSid('Pirls','全部',Number(sessionStorage.getItem("LoginID"))).then(data=>setPirlsTopics(data))
        else
            getDPByGrpSubj('Pirls','全部').then(data=>setPirlsTopics(data))

    },[])

    return (
        <>
            {/*<div className="position-relative z-index-2 mx-auto" style={{maxWidth: '640px',marginTop:'20px'}}>
                <div className='flex-row text-center' style={{background:'#9ad6d742', borderRadius:'0.5rem'}}>
                    <button type="button" className={["btn mb-0 ms-0", subject===0?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(0)} >全部</button>
                    <button type="button" className={["btn mb-0 ms-0", subject===1?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(1)} >國語</button>
                    <button type="button" className={["btn mb-0 ms-0", subject===2?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(2)} >自然</button>
                    <button type="button" className={["btn mb-0 ms-0", subject===3?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(3)} >社會</button>
                </div>
            </div>*/}

            {/*<div className='flex-row text-center' style={{marginTop:'20px'}}>
                    <button type="button" style={{width:'100px'}} className={["btn mb-0 ms-0", subject===0?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(0)} >全部</button>
                    <button type="button" style={{width:'100px'}} className={["btn mb-0 ms-0", subject===1?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(1)} >國語</button>
                    <button type="button" style={{width:'100px'}} className={["btn mb-0 ms-0", subject===2?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(2)} >自然</button>
                    <button type="button" style={{width:'100px'}} className={["btn mb-0 ms-0", subject===3?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>setSubject(3)} >社會</button>
            </div> */}


            {/*<div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth: '640px',marginTop:'20px'}}>
                <div className="nav nav-fill flex-row" style={{background:'#9ad6d742', borderRadius:'0.5rem'}} id="dptext" role="tablists">
                    <input type="radio" className="form-check-input d-none" name="color" id="text-all" checked/>
                    <label htmlFor="text-all" className="nav-item mb-0 ms-0">
                        <span className="text-center nav-link text-second font-weight-bold text-sm" id="text-all-tab">全部</span>
                    </label>

                    <input type="radio" className="form-check-input d-none" name="color" id="text-mandarin"/>
                    <label htmlFor="text-mandarin" className="nav-item mb-0 ms-0">
                        <span className="text-center nav-link text-second font-weight-bold text-sm" id="text-mandarin-tab" >國語</span>
                    </label>

                    <input type="radio" className="form-check-input d-none" name="color" id="text-nature"/>
                    <label htmlFor="text-nature" className="nav-item mb-0 ms-0">
                        <span className="text-center nav-link text-second font-weight-bold text-sm" id="text-nature-tab">自然</span>
                    </label>

                    <input type="radio" className="form-check-input d-none" name="color" id="text-society"/>
                    <label htmlFor="text-society" className="nav-item mb-0 ms-0">
                        <span className="text-center nav-link text-second font-weight-bold text-sm" id="text-society-tab">社會</span>
                    </label>

                </div>
            </div> */}

            <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth: '640px',marginTop:'20px'}}>
                <ul className="nav nav-pills nav-fill flex-wrap flex-md-nowrap" style={{background:'#9ad6d742', borderRadius:'0.5rem'}} id="dptext" role="tablists">
                    <li className="nav-item mb-0 ms-0">
                        <a className="text-center nav-link text-second font-weight-bold text-sm active" href="#" data-bs-toggle="tab" id="text-all-tab" role="tab" aria-controls="text-all" aria-selected="true" onClick={()=>acqPirlsTopic('全部')}>全部</a>
                    </li>
                    <li className="nav-item mb-0 ms-0">
                        <a className="text-center nav-link text-second font-weight-bold text-sm " href="#" data-bs-toggle="tab" id="text-mandarin-tab" role="tab" aria-controls="text-mandarin" aria-selected="false" onClick={()=>acqPirlsTopic('國語')}>國語</a>
                    </li>
                    <li className="nav-item mb-0 ms-0">
                        <a className="text-center nav-link text-second font-weight-bold text-sm " href="#" data-bs-toggle="tab" id="text-nature-tab" role="tab" aria-controls="text-nature" aria-selected="false" onClick={()=>acqPirlsTopic('自然')}>自然</a>
                    </li>
                    <li className="nav-item mb-0 ms-0">
                        <a className="text-center nav-link text-second font-weight-bold text-sm " href="#" data-bs-toggle="tab" id="text-society-tab" role="tab" aria-controls="text-society" aria-selected="false" onClick={()=>acqPirlsTopic('社會')}>社會</a>
                    </li>

                </ul>
            </div>

            <div className="tab-content mt-4" id="textContent">
                <div className="card p-2">
                    <div className="table-responsive">
                        <table className="table table-striped table-hover align-items-center mb-0">
                            <thead className="">
                                <tr style={{color:'#5c71a2'}}>
                                <th className="text-sm font-weight-bolder text-nowrap p-2 text-center">題號</th>
                                <th className="text-sm font-weight-bolder text-nowrap p-2">科目</th>
                                <th className="text-sm font-weight-bolder text-nowrap p-2">年級</th>
                                <th className="text-sm font-weight-bolder text-nowrap px-2 px-md-3">題組名稱</th>
                                <th className="p-0"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    PirlsTopics.map((item,i)=>{
                                        return  <tr>
                                                    <td className="text-sm text-dark font-weight-bold text-center my-2">{item.ArticleNo}</td>
                                                    <td className="text-sm">{item.Subject}</td>
                                                    <td className="text-sm">{item.Grade}</td>
                                                    <td className="text-dark text-wrap my-2">{item.DPTopic}</td>
                                                    <td><a type="button" className="btn btn-link mb-0 float-end" onClick={()=> handleDPPractice(item)}>
                                                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </>
    );
}

export default DPTextPage;
