import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IExStudent } from '../../interfaces/ExStudent';
import { updatePwdExStudent, updatePwdExTeacher } from '../../services/ExStuTeService';
import { BSModal } from './bsmodal';
import { IExTeacher } from '../../interfaces/ExTeacher';

interface ModalProps {
    isOpen: boolean;
    setOpenPwdModal: (val: boolean) => void;
}

export const PwdModal: React.FC<ModalProps> = (props) => {
    const [inpPwd, setInpPwd] = useState('');
    const [inpPwdAgain, setInpPwdAgain] = useState('');
    const [loginStu, setLoginStu] = useState<IExStudent>();
    const [loginTeacher, setLoginTeacher] = useState<IExTeacher>();
    const [pressed, setPressed] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [openErrModal, setOpenErrModal] = useState(false);
    const handleSubmit = async () => {
        let noError = true;
        let errMsg = '';
        if (inpPwd === '') {
            noError = false;
            errMsg = errMsg + '密碼不可空白,';
        } else {
            const regChk: RegExp = /^([a-zA-Z0-9]){6,}/;
            if (!regChk.test(inpPwd)) {
                noError = false;
                errMsg = errMsg + '密碼設定錯誤(至少6碼並為英數字),';
            }
        }
        if (inpPwdAgain === '') {
            noError = false;
            errMsg = errMsg + '再次輸入密碼不可空白,';
        } else {
            if (inpPwd !== inpPwdAgain) {
                noError = false;
                errMsg = errMsg + '密碼與再次輸入密碼輸入不符,';
            }
        }

        if (noError) {
            setPressed(true);
            props.setOpenPwdModal(false);
        } else {
            setOpenErrModal(!noError);
            errMsg = errMsg.substring(0, errMsg.length - 1);
            setErrMsg(errMsg);
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('LoginStatus') === '0') return;
        if (sessionStorage.getItem('LoginStatus') === '2') {
            //教師登入
            if (sessionStorage.getItem('LoginTeacher') !== null) {
                const lTea: IExTeacher = JSON.parse(sessionStorage.getItem('LoginTeacher')!);
                setLoginTeacher(lTea);
            }
        } //學生登入
        else {
            if (sessionStorage.getItem('LoginStudent') !== null) {
                const lStu: IExStudent = JSON.parse(sessionStorage.getItem('LoginStudent')!);
                setLoginStu(lStu);
            }
        }
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            if (sessionStorage.getItem('LoginStatus') === '2') {
                //教師登入
                if (sessionStorage.getItem('LoginTeacher') !== null) {
                    const lTea: IExTeacher = JSON.parse(sessionStorage.getItem('LoginTeacher')!);
                    setLoginTeacher(lTea);
                }
            } //學生登入
            else {
                if (sessionStorage.getItem('LoginStudent') !== null) {
                    const lStu: IExStudent = JSON.parse(sessionStorage.getItem('LoginStudent')!);
                    setLoginStu(lStu);
                }
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (pressed) {
            if (sessionStorage.getItem('LoginStatus') === '2') {
                //教師登入
                if (loginTeacher !== undefined) {
                    updatePwdExTeacher(loginTeacher.Tid.toString(), inpPwd);
                }
            } //學生登入
            else {
                if (loginStu !== undefined) {
                    updatePwdExStudent(loginStu.Sid.toString(), inpPwd);
                }
            }
            setPressed(false);
        }
    }, [pressed]);

    return (
        <Modal show={props.isOpen} onHide={() => props.setOpenPwdModal(false)}>
            <Modal.Header>
                <Modal.Title>
                    <h5 className="modal-title">修改密碼</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-3">
                <form role="form text-left">
                    <label>輸入新密碼</label>
                    <div className="input-group mb-3">
                        <input type="password" className="form-control" placeholder="輸入新密碼" onChange={(e) => setInpPwd(e.currentTarget.value)} />
                    </div>
                    <label>再輸入一次</label>
                    <div className="input-group mb-3">
                        <input type="password" className="form-control" placeholder="再輸入一次" onChange={(e) => setInpPwdAgain(e.currentTarget.value)} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => props.setOpenPwdModal(false)}>
                    取消
                </button>
                <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                    儲存
                </button>
            </Modal.Footer>
            <BSModal title={'密碼欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {errMsg}
            </BSModal>
        </Modal>
    );
};
