
import React, { useEffect, useState } from 'react';
import { getDrTeacherByApplyNo } from '../../../services/EP/EpApplyTeacherService';
import { alertEx } from '../tool/helper';
import { getDrTeaGroupByGroupNo } from '../../../services/EP/EPApplyGroupService';



// {"GroupNo":"FA001-01","":"FA001-B","TeaName":"李老師  ","TeaTitle":"圖推老師 "},
interface TeacherInfo {
    GroupNo?: string;
    Password?: string;
    Remark: string;
    TeaCell: string;
    TeaEmail: string;
    TeaName: string;
    TeaNo: string;
    TeaTitle: string;
    uid?: number;
}

interface Option {

    value: string;
    label: string;
}

interface DropdownSelectProps<T> {
    order :0|1 //共兩位老師
    applyNo :string 
    target :"教師清單"
    optionListInput?: T[];
    selectValue: TeacherInfo;
    setSelectTeacher: (target:TeacherInfo) => void;
    groupNo? :string 
    selectTeacher1? :TeacherInfo 
}

// 老師的下拉式選單
function DropdownSelect<T extends Option>(props: DropdownSelectProps<T>) {
    const {order ,groupNo,applyNo,target , optionListInput, selectValue, setSelectTeacher,selectTeacher1 } = props;
    const [optionList ,setOptionList] =useState<TeacherInfo[]>([])
    // const [currenSelectedValue,setCurrenSelectedValue]=useState<TeacherInfo>()
    useEffect(()=>{
        fetchList();

    },[selectTeacher1])

    /** */
    const onSelectChange =  (value:any)=>{
       let target = optionList.filter(x=>x.TeaNo === value)
        if(target)
        setSelectTeacher(target[0])
        // setCurrenSelectedValue(target[0])
    }

    /** */
 const fetchList =async ()=>{
  let  teacherList   = await  getDrTeacherByApplyNo(applyNo) as TeacherInfo[]
  if(groupNo){
      const  orginTeacher  = await   getDrTeaGroupByGroupNo(groupNo) as TeacherInfo[];
    //   teacherList.find()
      setSelectTeacher(orginTeacher[order])


  }
  debugger
  if(selectTeacher1){
    teacherList =teacherList.filter(x=>x.TeaNo!==selectTeacher1.TeaNo)||[];

  }
  setOptionList(teacherList);

    }
    return (
        <div className="input-group">
            <select 
                className="form-control fs-16" onChange={(e) => onSelectChange(e.target.value)}
            >
                <option value="">請選擇</option>
                {optionList.map((option, index) => (
                    <option key={index} value={option.TeaNo}
                    selected={selectValue?.TeaNo === option.TeaNo}   
                    >
                        {option?.TeaName}({option?.TeaNo})
                    </option>
                ))}
            </select>
        </div>
    );
}

export default DropdownSelect;
