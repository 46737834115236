import React, { useEffect, useState, useRef } from 'react'
import IPageProps from '../../interfaces/page'
import TopNavPage2 from '../topNav2';
import FooterPage from '../footer';
import { IExTeacher } from '../../interfaces/ExTeacher';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getAdLoginsByTid, getAdLoginsCntByTid } from '../../services/AdLoginService';
import { IAdLoginStu } from '../../interfaces/AdLoginStuCount';
import { IAdLoginRecord } from '../../interfaces/AdLoginRecord';
import { format,parseISO} from 'date-fns';

const TeacherStuLoginStatPage: React.FunctionComponent<IPageProps> = props => {
  const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();
  const [refresh,setRefresh]=useState(false);

  const [beginDate,setBeginDate]=useState<Date|null>(null);
  const [endDate,setEndDate]=useState<Date|null>(null);

  const [includeStu,setIncludeStu]=useState<IAdLoginStu[]>([]); //教師管理學生群
  const [currIncludeStu,setCurrIncludeStu]=useState(-1); //目前選擇學生

  const convertDateTime=(date_str:string):string=>{
    const dateObject = new Date(date_str);
    const year = dateObject.getUTCFullYear();
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const hours = dateObject.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");
    const seconds = dateObject.getUTCSeconds().toString().padStart(2, "0");
    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDateString;
    }



  const fetchData=async()=>{
    if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
    {
      if (sessionStorage.getItem("LoginTeacher") !== null) {

        const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
        setLoginTeaacher(lTea);

        //取得管理學生登入記錄
        console.log("BDate:"+beginDate?.toDateString());
        let pbdate="";
        let pedate="";
        if(beginDate!==null && beginDate!==undefined)
        {
          pbdate=format(beginDate, 'yyyy-MM-dd');
        }
        else
        {
          pbdate="first"
        }
        if(endDate!==null && endDate!==undefined)
        {
          pedate=format(endDate, 'yyyy-MM-dd');
        }
        else
        {
          pedate="end"
        }


        const adLoginsCnt:IAdLoginStu[]=await getAdLoginsCntByTid(lTea.Tid.toString().trim(),'E',pbdate,pedate);

        const adLoginsStus:IAdLoginRecord[]=await getAdLoginsByTid(lTea.Tid.toString().trim(),'E',pbdate,pedate);
        console.log("adLoginsStus:"+adLoginsStus.length);

        if((adLoginsCnt!==null) && (adLoginsStus!==null))  
        {
          adLoginsCnt.map((item,index)=>{
            const adLogins=adLoginsStus.filter((item2,index2)=>item2.Account===item.Account);
            item.LoginRecs=adLogins;
            
          });
          setIncludeStu(adLoginsCnt);
        }
      }
    }
  }

  const handleBeginDate = (date: Date | null) => {
    // Check if the selected start date is later than the end date
    if (endDate && date && date > endDate) {
      setEndDate(null); // Reset the end date if necessary
    }
    setBeginDate(date);
    setRefresh(true);
  }; 

  const handleEndDate = (date: Date | null) => {
    // Check if the selected start date is later than the end date
    if (beginDate && date && date < beginDate) {
      setBeginDate(null); // Reset the end date if necessary
    }
    setEndDate(date);
    setRefresh(true);
  }; 


  useEffect(() => {
    fetchData();

  }, []);

  useEffect(() => {
    if(refresh)
    {
      fetchData();

      setRefresh(false);
    }
  }, [refresh]);



  return (
  <>
    <TopNavPage2/>
    <div className="section pt-3">
      <div className="container px-3 px-md-4">
        <div className="d-flex align-items-center flex-column flex-lg-row">
          <h5 className="title mb-3 me-3">教師專區</h5>
          <div className="mx-auto ms-auto me-md-0 text-center">
            <a href="/teacheradmin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
              操作說明</a>
            <a href="/teacherstumgn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 " type="button">
              學生管理</a>
            <a href="/teacherstulearn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 " type="button">
              學習記錄</a>
            <a href="/teacherstustat" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 " type="button">
              學習統計</a>
            <a href="/teacherstulogin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active" type="button">
              登入記錄</a>
          </div>
        </div>
      
        <div className="text-center h5 mb-3">學生登入記錄</div>
        <div className="d-flex flex-wrap justify-content-center align-items-center mb-4">
          <div className="dropdown mx-4" style={{width:'fit-content'}}>
            <button className="btn text-second bg-second dropdown-toggle mb-3" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-expanded="false" style={{width:'250px'}}>{(currIncludeStu===-1)? <>全部學生</>:
              <> {includeStu[currIncludeStu].GradeClass.trim()}-{includeStu[currIncludeStu].Seat}號：{includeStu[currIncludeStu].StuNo.trim()}-{includeStu[currIncludeStu].StuName.trim()}</> }</button>
            <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                <li>
                  <div className="dropdown-item d-flex" onClick={()=>setCurrIncludeStu(-1)}> 全部學生 </div>
                </li>                

              {
                includeStu.map((item,index)=>{
                  return(
                    <li key={index}><div className="dropdown-item d-flex" onClick={()=>setCurrIncludeStu(index)}>{item.GradeClass}-{item.Seat}號：{item.StuNo.trim()}-{item.StuName.trim()}</div></li>
                  )
                })
              }
            </ul>
          </div>
          <div className="d-flex form-group align-items-center" style={{width:'280px'}}>
            <DatePicker
                selected={beginDate}
                onChange={handleBeginDate}
                dateFormat="yyyy-MM-dd"
                isClearable
                placeholderText="開始日期"
                className="form-control custom-datepicker"
            />
            <span className="p-2">到</span>
            <DatePicker
                selected={endDate}
                onChange={handleEndDate}
                dateFormat="yyyy-MM-dd"
                isClearable
                placeholderText="結束日期"
                className="form-control custom-datepicker me-1"
            />
            <span className="me-3"></span>
          </div>
        </div>
        <div className="card p-3">
          <div className="accordion" id="accordionRental">
            <div className="row font-weight-bolder text-second text-nowrap fs-14 m-0" style={{background: '#d9dce6'}}>
              <div className="col p-2">學生登入帳號</div>
              <div className="col p-2">學校</div>
              <div className="col p-2">班級</div>
              <div className="col p-2">學生</div>
              <div className="col p-2">次數</div>
            </div>
            <div className="accordion-item accbg">
              {
                (currIncludeStu===-1)?
                <>
                {
                  includeStu.map((item,index)=>{
                    return(
                      <>
                      <div key={index} className="accordion-header" id={"heading"+index}>
                        <button className="row fs-14 pointer m-0 accordion-button p-0" data-bs-toggle="collapse" data-bs-target={"#collapse"+index}
                          aria-expanded="false" aria-controls={"collapse"+index}>
                          <div className="col p-2">{item.StuNo}</div>
                          <div className="col p-2">{item.SchoolName}</div>
                          <div className="col p-2">{item.GradeClass}</div>
                          <div className="col p-2">{item.StuName}</div>
                          <div className="col p-2 d-flex">{item.LoginCnt}
                            <i className="collapse-close fas fa-angle-down fs-18 ms-auto" aria-hidden="true"></i>
                            <i className="collapse-open fas fa-angle-up fs-18 ms-auto" aria-hidden="true"></i>
                          </div>
                        </button>
                      </div>
                      <div id={"collapse"+index} className="accordion-collapse collapse bg-white border-bottom" aria-labelledby={"heading"+index}
                      data-bs-parent="#accordionRental">
                      <div className="row accordion-body text-sm opacity-8 m-0">
                        <div className="col-12">登入時間</div>
                        {
                          item.LoginRecs.map((item2,index2)=>{
                            return(
                              <div className="col-6 col-md-3">{convertDateTime(item2.LoginTime)}</div>
                            )
                          })
                                                
                        }
                      </div>
                    </div>
                    </>
                    )
                  })
                }
                </>:
                <>
                <div key={currIncludeStu} className="accordion-header" id={"heading"+currIncludeStu}>
                    <button className="row fs-14 pointer m-0 accordion-button p-0" data-bs-toggle="collapse" data-bs-target={"#collapse"+currIncludeStu}
                      aria-expanded="false" aria-controls={"collapse"+currIncludeStu}>
                      <div className="col p-2">{includeStu[currIncludeStu].StuNo}</div>
                      <div className="col p-2">{includeStu[currIncludeStu].SchoolName}</div>
                      <div className="col p-2">{includeStu[currIncludeStu].GradeClass}</div>
                      <div className="col p-2">{includeStu[currIncludeStu].StuName}</div>
                      <div className="col p-2 d-flex">{includeStu[currIncludeStu].LoginCnt}
                        <i className="collapse-close fas fa-angle-down fs-18 ms-auto" aria-hidden="true"></i>
                        <i className="collapse-open fas fa-angle-up fs-18 ms-auto" aria-hidden="true"></i>
                      </div>
                    </button>
                  </div>
                  <div id={"collapse"+currIncludeStu} className="accordion-collapse collapse bg-white border-bottom" aria-labelledby={"heading"+currIncludeStu}
                  data-bs-parent="#accordionRental">
                  <div className="row accordion-body text-sm opacity-8 m-0">
                    <div className="col-12">登入時間</div>
                    {
                      includeStu[currIncludeStu].LoginRecs.map((item2,index2)=>{
                        return(
                          <div className="col-6 col-md-3">{convertDateTime(item2.LoginTime)}</div>
                        )
                      })
                                            
                    }
                  </div>
                </div>
                </>
              }
            </div>
          </div>
        </div>             




      </div>


      <div className="pt-3 mt-4"></div>
    </div> 
    <FooterPage/>
  </>
  )
}

export default TeacherStuLoginStatPage