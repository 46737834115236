import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { IExStudent } from '../../interfaces/ExStudent';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { addExTeaStuBySidTid, deleteExTeaStuBySidsTid, getExTeacherByTNo, getExTeaStuBySid, updatePwdExStudent } from '../../services/ExStuTeService';
import { BSModal } from './bsmodal';

interface ModalProps {
    isOpen: boolean;
    setOpenAssignTeModal: (val:boolean) => void;
  }
  
  export const AssignTeModal: React.FC<ModalProps> = (props) => {
    const [inpTeaNo,setInpTeaNo]=useState("");
    const [addTeacher,setAddTeacher]=useState<IExTeacher>();
    const [loginStu,setLoginStu]=useState<IExStudent>();
    const [pressed,setPressed]=useState(false);
    const [delPressed,setDelPressed]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const [openErrModal,setOpenErrModal]=useState(false);
    const [assignedTe,setAssignedTe]=useState<IExTeacher[]>([])

    const addTeaNo=async ()=>{
        let noError=true;
        let errMsg=""
        
        if(inpTeaNo==="")
        {
            //noError=false;
            //errMsg=errMsg+"教師帳號不可空白,"
            setOpenErrModal(true);
            //errMsg=errMsg.substring();
            setErrMsg("教師帳號不可空白");
        }
        else
        {
            const tmp:IExTeacher=await getExTeacherByTNo(inpTeaNo);
            console.log(tmp);
            if(tmp===undefined)
            {
                noError=false;
                errMsg=errMsg+"教師帳號不存在,"
            }
            else
            {
                if(assignedTe!==undefined)
                {
                    const found=assignedTe.find(data=>{
                        return data.Tid===tmp.Tid;
                    })
                    if (found)
                    {
                        noError=false;
                        errMsg=errMsg+"教師帳號己經指定了不可以新增,"
                    }
                }
         
            }

            if(noError)
            {            

                if(loginStu!==undefined)
                {
                    addExTeaStuBySidTid(loginStu.Sid.toString(),tmp.Tid.toString())
                }
                setInpTeaNo("");
                let tmpTeaNo=(document.getElementById("teacherno") as HTMLInputElement)
                if(tmpTeaNo!==null) tmpTeaNo.value="";
                setPressed(true);
            }
            else
            {
                setOpenErrModal(!noError);
                errMsg=errMsg.substring(0,errMsg.length-1);
                setErrMsg(errMsg);
            }       
        }

    }
    const UnAssignTe=async (tid:number)=>{
        if(loginStu!==undefined)
        {
            await deleteExTeaStuBySidsTid(loginStu.Sid.toString(),tid.toString());
            setPressed(true);

        }
    }
    
    
    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="1") //學生登入
        {
            if(sessionStorage.getItem("LoginStudent")!==null)
            {
                const lStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!)
                setLoginStu(lStu);
                getExTeaStuBySid(lStu.Sid.toString()).then(data=>setAssignedTe(data));
            }
        }     
    },[])

    useEffect(()=>{
        if(props.isOpen)
        {
            if(sessionStorage.getItem("LoginStatus")==="1") //學生登入
            {
                if(sessionStorage.getItem("LoginStudent")!==null)
                {
                    const lStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!)
                    setLoginStu(lStu);
                    getExTeaStuBySid(lStu.Sid.toString()).then(data=>setAssignedTe(data));
                }
        }     
        }
    },[props.isOpen])

    useEffect(()=>{

        if(pressed)
        {
            if(loginStu!==undefined)
            {
                getExTeaStuBySid(loginStu.Sid.toString()).then(data=>setAssignedTe(data));
                setPressed(false);
            }
        }
    },[pressed])


    return  <Modal show={props.isOpen} onHide={()=>props.setOpenAssignTeModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="modal-title">開放學習記錄</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-3'>
                <div>    
                    <div className="alert alert-light" role="alert">
                        可開放學習記錄給多位老師，需先知道老師帳號才可新增。
                    </div>
                    <form role="form text-left">
                        <label>開放學習記錄給</label>
                        <div className="input-group mb-3">
                        <input id="teacherno" type="text" className="form-control" placeholder="輸入老師帳號" onChange={(e)=>setInpTeaNo(e.currentTarget.value)}/>
                        <button className="btn btn-outline-primary mb-0" type="button" onClick={()=>addTeaNo()}>新增</button>
                        </div>
                        <label>已開放學習記錄</label>
                        <>
                        <div id="assignedTe">
                        {
                            assignedTe.map(item=>{
                                return <div className="border-dashed border-1 border-radius-md p-1 d-flex align-items-center mb-2">
                                            <span className="ms-2 me-auto">{item.TeaName}</span>
                                            <button type="button" className="btn btn-sm btn-outline-secondary mb-0 px-3" onClick={()=>UnAssignTe(item.Tid)}>移除</button>
                                        </div>
                            })
                        }
                        </div>
                        </>

                    </form>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={()=>props.setOpenAssignTeModal(false)}>取消</button>
                </Modal.Footer>
                <BSModal
                title={'開放學習記錄'}
                isOpen={openErrModal}
                setOpenModal={setOpenErrModal}                
            >
                {errMsg}
                </BSModal> 
            </Modal>

    };