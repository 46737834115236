import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import IAnsDP from '../interfaces/AnsDP';
import IAnsQDP from '../interfaces/AnsQDP';
import IAnsStateDP from '../interfaces/AnsStateDP';
import { IExDPQA } from '../interfaces/ExDPQA';
import IFormatAnsDP from '../interfaces/FormatAnsDP';
import IQuestionDP from '../interfaces/QuestionDP';
import ITQuestionDP from '../interfaces/TQuestionDP';

export const getDPQAByNo=(no:string)=>{
    const url=config.server.url+`/api/ExDPQA/no/${no}`
    return axios.get(url).then((res)=>{
        const rest:IExDPQA[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPQAMaxPByNo=(no:string)=>{
    const url=config.server.url+`/api/ExDPQA/maxpage/no/${no}`
    return axios.get(url).then((res)=>{
        const rest:number[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPTeacherQAByNoPNo=(no:string,pno:number)=>{
    const url=config.server.url+`/api/ExDPQA/teacher/no/${no}/pno/${pno}`
    return axios.get(url).then((res)=>{
        const rest:ITQuestionDP[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPTeacherQAByNo=async (no:string)=>{
    const url=config.server.url+`/api/ExDPQA/teacher/no/${no}`
    return await axios.get(url).then((res)=>{
        const rest:ITQuestionDP[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPQAByNoPNo=(no:string,pno:number)=>{
    const url=config.server.url+`/api/ExDPQA/no/${no}/pno/${pno}`
    return axios.get(url).then((res)=>{
        const rest:IExDPQA[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getQuestionDPByNoArray=(exdpArray:IExDPQA[]):IQuestionDP[][]=>{
    let result:IQuestionDP[][]=[[]];
    let currPage=0;
    exdpArray.forEach((value)=>{
        const tmpQuestion:IQuestionDP={
                Qno: value.Qno,
                Type: value.Type,
                Question: value.Question,
                QuestionList: [],
                TeacherQuestion: ''
            }
        if(value.Term1!==null) tmpQuestion.QuestionList.push(value.Term1);
        if(value.Term2!==null) tmpQuestion.QuestionList.push(value.Term2);
        if(value.Term3!==null) tmpQuestion.QuestionList.push(value.Term3);
        if(value.Term4!==null) tmpQuestion.QuestionList.push(value.Term4);
        if(value.Term5!==null) tmpQuestion.QuestionList.push(value.Term5);
        if(value.Term6!==null) tmpQuestion.QuestionList.push(value.Term6);
        if(value.Term7!==null) tmpQuestion.QuestionList.push(value.Term7);
        if(value.Term8!==null) tmpQuestion.QuestionList.push(value.Term8);
        if(currPage!==value.PageNo-1)
        {
            currPage=currPage+1
            result[currPage]=[];
        }
        if(value.Type==="老師")
        {
            result[value.PageNo-1].forEach((val)=>{
                if(val.Qno===value.Qno) val.TeacherQuestion=value.Question
            })
        }
        else
        {
            result[currPage].push(tmpQuestion);
        }
    })
    return result;
}

export const getAnsQDPByNoArray=(exdpArray:IExDPQA[]):IAnsQDP[]=>{
    let result:IAnsQDP[]=[];
    //let currPage=0;
    exdpArray.forEach((value)=>{
        const tmpAns:IAnsQDP={
                Qno: value.Qno,
                Type: value.Type,
                Question:value.Question,
                AnsList: [],
                InpAnsList:[]
        }
        switch(value.Type)
        {
            case '簡答':
                tmpAns.AnsList.push(value.Answer);
                break;
            case '單選':
                switch(value.Answer)
                {
                    case '1':
                        tmpAns.AnsList.push(value.Term1);
                        break;
                    case '2':
                        tmpAns.AnsList.push(value.Term2);
                        break;
                    case '3':
                        tmpAns.AnsList.push(value.Term3);
                        break;
                    case '4':
                        tmpAns.AnsList.push(value.Term4);
                        break;
                    case '5':
                        tmpAns.AnsList.push(value.Term5);
                        break;
                    case '6':
                        tmpAns.AnsList.push(value.Term6);
                        break;
                    case '7':
                        tmpAns.AnsList.push(value.Term7);
                        break;
                    case '8':
                        tmpAns.AnsList.push(value.Term8);
                        break; 
                }
                break;
            case '多選':
                let ansTmp=value.Answer.trim();
                for(let i=0;i<ansTmp.length;i++)
                {
                    switch(ansTmp[i])
                    {
                        case '1':
                            tmpAns.AnsList.push(value.Term1);
                            break;
                        case '2':
                            tmpAns.AnsList.push(value.Term2);
                            break;
                        case '3':
                            tmpAns.AnsList.push(value.Term3);
                            break;
                        case '4':
                            tmpAns.AnsList.push(value.Term4);
                            break;
                        case '5':
                            tmpAns.AnsList.push(value.Term5);
                            break;
                        case '6':
                            tmpAns.AnsList.push(value.Term6);
                            break;
                        case '7':
                            tmpAns.AnsList.push(value.Term7);
                            break;
                        case '8':
                            tmpAns.AnsList.push(value.Term8);
                            break; 
                    }
                }
                break; 
        }
        if(value.Type!=="老師")
        {
            result.push(tmpAns);
        }
    })
    return result;   
       
}

export const buildAnsStateDPByNoArray=(exdpArray:IExDPQA[]):IAnsStateDP[][]=>{
    let result:IAnsStateDP[][]=[[]];
    let currPage=0;
    exdpArray.forEach((value)=>{
        const tmpStateAns:IAnsStateDP={
                Qno: value.Qno,
                Type: value.Type,
                InputText:'',
                SelectItems: ''
        }
        if(currPage!==value.PageNo-1)
        {
            currPage=currPage+1
            result[currPage]=[];
        }
        if(value.Type!=="老師")
        {
            result[currPage].push(tmpStateAns);
        }
    })
    return result
}

export const getQuestionDPByNo=(no:string):IQuestionDP[][]=>{
    let result:IQuestionDP[][]=[[]];
    let currPage=0;
    getDPQAByNo(no).then(data=>{
        data.forEach((value)=>{
            const tmpQuestion:IQuestionDP={
                Qno: value.Qno,
                Type: value.Type,
                Question: value.Question,
                QuestionList: [],
                TeacherQuestion: ''
            }
            if(value.Term1!==null) tmpQuestion.QuestionList.push(value.Term1);
            if(value.Term2!==null) tmpQuestion.QuestionList.push(value.Term2);
            if(value.Term3!==null) tmpQuestion.QuestionList.push(value.Term3);
            if(value.Term4!==null) tmpQuestion.QuestionList.push(value.Term4);
            if(value.Term5!==null) tmpQuestion.QuestionList.push(value.Term5);
            if(value.Term6!==null) tmpQuestion.QuestionList.push(value.Term6);
            if(value.Term7!==null) tmpQuestion.QuestionList.push(value.Term7);
            if(value.Term8!==null) tmpQuestion.QuestionList.push(value.Term8);
            if(currPage!==value.PageNo-1)
            {
                currPage=currPage+1
                result[currPage]=[];
            }
            if(value.Type==="老師")
            {
                result[value.PageNo-1].forEach((val)=>{
                    if(val.Qno===value.Qno) val.TeacherQuestion=value.Question
                })
            }
            else
            {
                result[currPage].push(tmpQuestion);
            }
        })
    })
    return result;
}

export const getAnsDPByNo= (no:string):IAnsDP[][]=>{
    let result: IAnsDP[][]=[[]];
    let currPage=0;
    getDPQAByNo(no).then(data=>{
        data.forEach((value)=>{
            let tmpAns:IAnsDP;
            tmpAns={
                Qno:value.Qno,
                Type:value.Type,
                Question:value.Question,
                AnsList:[],
                QuestionList:[],
                TeacherQuestion:"",
                InpAnsList:[]
            }
            switch(value.Type)
            {
                case '簡答':
                    tmpAns.AnsList.push(value.Answer);
                    break;
                case '單選':
                    switch(value.Answer)
                    {
                        case '1':
                            tmpAns.AnsList.push(value.Term1);
                            break;
                        case '2':
                            tmpAns.AnsList.push(value.Term2);
                            break;
                        case '3':
                            tmpAns.AnsList.push(value.Term3);
                            break;
                        case '4':
                            tmpAns.AnsList.push(value.Term4);
                            break;
                        case '5':
                            tmpAns.AnsList.push(value.Term5);
                            break;
                        case '6':
                            tmpAns.AnsList.push(value.Term6);
                            break;
                        case '7':
                            tmpAns.AnsList.push(value.Term7);
                            break;
                        case '8':
                            tmpAns.AnsList.push(value.Term8);
                            break; 
                    }
                    break;
                case '多選':
                    let ansTmp=value.Answer.trim();
                    for(let i=0;i<ansTmp.length;i++)
                    {
                        switch(ansTmp[i])
                        {
                            case '1':
                                tmpAns.AnsList.push(value.Term1);
                                break;
                            case '2':
                                tmpAns.AnsList.push(value.Term2);
                                break;
                            case '3':
                                tmpAns.AnsList.push(value.Term3);
                                break;
                            case '4':
                                tmpAns.AnsList.push(value.Term4);
                                break;
                            case '5':
                                tmpAns.AnsList.push(value.Term5);
                                break;
                            case '6':
                                tmpAns.AnsList.push(value.Term6);
                                break;
                            case '7':
                                tmpAns.AnsList.push(value.Term7);
                                break;
                            case '8':
                                tmpAns.AnsList.push(value.Term8);
                                break; 
                        }
                    }
                    break; 
            }
            if(value.Term1!==null) tmpAns.QuestionList.push(value.Term1);
            if(value.Term2!==null) tmpAns.QuestionList.push(value.Term2);
            if(value.Term3!==null) tmpAns.QuestionList.push(value.Term3);
            if(value.Term4!==null) tmpAns.QuestionList.push(value.Term4);
            if(value.Term5!==null) tmpAns.QuestionList.push(value.Term5);
            if(value.Term6!==null) tmpAns.QuestionList.push(value.Term6);
            if(value.Term7!==null) tmpAns.QuestionList.push(value.Term7);
            if(value.Term8!==null) tmpAns.QuestionList.push(value.Term8);
            if(currPage!==value.PageNo-1)
            {
                currPage=currPage+1
                result[currPage]=[];
            }
            if(value.Type==="老師")
            {
                result[value.PageNo-1].forEach((val)=>{
                    if(val.Qno===value.Qno) val.TeacherQuestion=value.Question
                })
            }
            else
            {
                result[currPage].push(tmpAns);
            }
        })
    });
    return result;
}

export const getForamtAnsDPByNo= (no:string):IFormatAnsDP[][]=>{
    let result: IFormatAnsDP[][]=[[]];
    let currPage=0;
    getDPQAByNo(no).then(data=>{
        data.forEach((value)=>{
            let tmpAns:IFormatAnsDP;
            tmpAns={
                Qno:value.Qno,
                Type:value.Type,
                Question:value.Question,
                AnsList:[],
                QuestionList:[],
                TeacherQuestion:"",
                InpAnsList:[],
                SelectItems:''
            }
            switch(value.Type)
            {
                case '簡答':
                    tmpAns.AnsList.push(value.Answer);
                    break;
                case '單選':
                    switch(value.Answer)
                    {
                        case '1':
                            tmpAns.AnsList.push(value.Term1);
                            break;
                        case '2':
                            tmpAns.AnsList.push(value.Term2);
                            break;
                        case '3':
                            tmpAns.AnsList.push(value.Term3);
                            break;
                        case '4':
                            tmpAns.AnsList.push(value.Term4);
                            break;
                        case '5':
                            tmpAns.AnsList.push(value.Term5);
                            break;
                        case '6':
                            tmpAns.AnsList.push(value.Term6);
                            break;
                        case '7':
                            tmpAns.AnsList.push(value.Term7);
                            break;
                        case '8':
                            tmpAns.AnsList.push(value.Term8);
                            break; 
                    }
                    break;
                case '多選':
                    let ansTmp=value.Answer.trim();
                    for(let i=0;i<ansTmp.length;i++)
                    {
                        switch(ansTmp[i])
                        {
                            case '1':
                                tmpAns.AnsList.push(value.Term1);
                                break;
                            case '2':
                                tmpAns.AnsList.push(value.Term2);
                                break;
                            case '3':
                                tmpAns.AnsList.push(value.Term3);
                                break;
                            case '4':
                                tmpAns.AnsList.push(value.Term4);
                                break;
                            case '5':
                                tmpAns.AnsList.push(value.Term5);
                                break;
                            case '6':
                                tmpAns.AnsList.push(value.Term6);
                                break;
                            case '7':
                                tmpAns.AnsList.push(value.Term7);
                                break;
                            case '8':
                                tmpAns.AnsList.push(value.Term8);
                                break; 
                        }
                    }
                    break; 
            }
            if(value.Term1!==null) tmpAns.QuestionList.push(value.Term1);
            if(value.Term2!==null) tmpAns.QuestionList.push(value.Term2);
            if(value.Term3!==null) tmpAns.QuestionList.push(value.Term3);
            if(value.Term4!==null) tmpAns.QuestionList.push(value.Term4);
            if(value.Term5!==null) tmpAns.QuestionList.push(value.Term5);
            if(value.Term6!==null) tmpAns.QuestionList.push(value.Term6);
            if(value.Term7!==null) tmpAns.QuestionList.push(value.Term7);
            if(value.Term8!==null) tmpAns.QuestionList.push(value.Term8);
            if(currPage!==value.PageNo-1)
            {
                currPage=currPage+1
                result[currPage]=[];
            }
            if(value.Type==="老師")
            {
                result[value.PageNo-1].forEach((val)=>{
                    if(val.Qno===value.Qno) val.TeacherQuestion=value.Question
                })
            }
            else
            {
                result[currPage].push(tmpAns);
            }
        })
    });
    return result;
}


