

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import { alertEx, currentClass, fetchDataSubTopic, getLoginInfo, setSessionStudentDisplayInfo } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';

import { IOpinion, IsubTopic, TeacherGroupInfo, TeacherlInfo } from '../../interfaces/EpModal';
import { getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';
import TeacherFunctionBar from './component/teacherFunctionBar';
import Level34 from './component/level34';
import { getDrGroupByTeaNo, getDrTeacherByNo } from '../../services/EP/EpApplyTeacherService';
import GroupSelect from './component/groupSelect';


export interface StudentDisplay {

  ApplyName: string
  ApplyNo: string
  ApplyTitle: string
  GroupName: string
  GroupNo: string
  SchoolName: string //"國語實小        "
  StuName: string  //"趙小一       "
  StuNo: string //  "FA001-01-1"
  TeaName: string // "李老師       "
  TeaNo: string //""
  TeaTitle: string // 
  Team: {
    TStuName: string,
    TStuNo: string
    checked: boolean
  }[]
  Teachers: {
    TeaNo: string, TeaName: string, TeaTitle: string

  }[]
}




// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpTeachLevel3: React.FunctionComponent<IPageProps> = () => {

  const [currentLoginInfo, setCurrentLoginInfo] = useState<any>()
  const [studentInfo, setStudentInfo] = useState<StudentDisplay>()
  const [isReload, setIsReload] = useState<boolean>(true);
  const [opinion, setOpinion] = useState<IOpinion>();
  const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>();


  // 1
  const [teaccherGroupInfoList, setTeacherGroupInfoList] = useState<TeacherGroupInfo[]>([]);
  const [selectedGroup, setSelectGroupInfo] = useState<TeacherGroupInfo>();
  const [role, setRole] = useState('')


  const [teacherInfo, setTeacherInfo] = useState<TeacherlInfo>()



  const fetchDataTeacher = async () => {
    let currentLoginInfo = getLoginInfo();
    try {
      let teacherRsp = await getDrTeacherByNo(currentLoginInfo.LoginID) as any[]
      setTeacherInfo(teacherRsp[0] as TeacherlInfo)
      // alert("teacherInfo"+JSON.stringify(teacherRsp))
      console.log("...", currentLoginInfo.LoginID.trim());
      setCurrentLoginInfo(currentLoginInfo)


    } catch (ex) {
      alert("取得資料發生錯誤:101 ")
      console.log("ex 101", ex)
    }


  }
  useEffect(() => {
    fetchTeacherGroups();
  }, []);
  const fetchTeacherGroups = async () => {
    let currentLoginInfo = getLoginInfo();
    setRole(currentLoginInfo.LoginStatus)
    console.log('fetchData...', currentLoginInfo.LoginID.trim());
    const rsp = (await getDrGroupByTeaNo(currentClass, currentLoginInfo.LoginID.trim())) as any;
    if (rsp) {
      console.log('rsp---', rsp?.Groups);
      setTeacherGroupInfoList(rsp?.Groups);
      setSelectGroupInfo(rsp?.Groups[0]);
    }
  };



  useEffect(() => {

    FetchDataOpinion();
    // fetchDataSubTopic();

  }, [isReload]);

  useEffect(() => {
    fetchDataSubTopic();
  }, [studentInfo, isReload]);

  const reload = async () => {
    await FetchDataOpinion();
  };


  /** */
  const FetchDataOpinion = async () => {
    let currentLoginInfo = await getLoginInfo();
    setCurrentLoginInfo(currentLoginInfo);
    console.log('rsp..currentLoginInfo.', currentLoginInfo);
    const rsp = (await getOpinionByStuNo(currentLoginInfo.LoginID)) as IOpinion[];

    const opinion = rsp[0];
    if (opinion) {
      setOpinion(opinion);
    }
  };


  /** 取得子題資料*/
  const fetchDataSubTopic = async () => {
    if (studentInfo?.GroupNo) {
      let subTopicbefore = (await getSubTopicByGroupNo(0, studentInfo?.GroupNo)) as IsubTopic[];
      console.log('subTopicbefore 子題(回覆前)', subTopicbefore);
      setSubTopicBeforeFeedbackList(subTopicbefore);
    }
  };

  return (
    <>
      <TopNavPage1 />
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">專題探究競賽</h5>
            <TeacherFunctionBar />
          </div>

          <GroupSelect selectGroupInfo={selectedGroup} teaccherGroupInfoList={teaccherGroupInfoList} setSelectGroupInfo={setSelectGroupInfo} />


          <div id="title-info" style={{ display: "none" }} >
            {selectedGroup?.SchoolName.trim()}-{selectedGroup?.GroupNo.trim()}-{selectedGroup?.GroupName.trim()}
          </div>
          <Level34
            selectGroupInfo={selectedGroup}
            groupNo={selectedGroup?.GroupNo}
            role={role}
            opinion={opinion}
            studentInfo={studentInfo}
            teacherInfo={teacherInfo}
            setStudentInfo={setStudentInfo}
            isFeedback={1} />
        </div>
      </div>
      <FooterPage />
    </>

  )


}

export default EpTeachLevel3;

