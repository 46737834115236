/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react'
import IPageProps from '../interfaces/page'
import { handleLink } from '../modules/utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { IExStudent } from '../interfaces/ExStudent';
import { UpdModal } from '../components/modal/updmodal';
import { PwdModal } from '../components/modal/updpwdmodal';
import { AssignTeModal } from '../components/modal/assigntemodal';
import { BindMailModal } from '../components/modal/updbindmodal';
import { IExTeacher } from '../interfaces/ExTeacher';

const TopNavPage1: React.FunctionComponent<IPageProps> = props => {
  const [loginStu, setLoginStu] = useState<IExStudent>();
  const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();
  const [openUdpModal, setOpenUdpModal] = useState(false);
  const [openPwdModal, setOpenPwdModal] = useState(false);
  const [openBindMailModal, setOpenBindMailModal] = useState(false);
  const [openAssignTeModal, setOpenAssignTeModal] = useState(false);
  const presetDP = () => {
    sessionStorage.setItem("DPPageID", "0");
    handleLink('/dptest')
  }
  const handleLogout = () => {

    sessionStorage.setItem('LoginStatus', '0');
    sessionStorage.setItem('LoginID', JSON.stringify(null));
    sessionStorage.setItem('LoginSchoolNo', JSON.stringify(null));
    sessionStorage.setItem('LoginStudent', JSON.stringify(null));
    sessionStorage.setItem('LearnRec',"");
    sessionStorage.setItem('LoginTeacher', JSON.stringify(null));
    sessionStorage.removeItem('GoogleProfile');   

    handleLink('/')
  }

  useEffect(() => {
    if(sessionStorage.getItem("LoginStatus")==="0") return;

    if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
    {
      if (sessionStorage.getItem("LoginTeacher") !== null) {
        const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
        setLoginTeaacher(lTea);
      }
    }
    else //學生登入
    {
      if (sessionStorage.getItem("LoginStudent") !== null) {
        const lStu = JSON.parse(sessionStorage.getItem("LoginStudent")!)
        setLoginStu(lStu);
      }
    }
  }, [])

  useEffect(() => {
    if(sessionStorage.getItem("LoginStatus")==="0") return;
    if (!openUdpModal) {
      if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
      {
        if (sessionStorage.getItem("LoginTeacher") !== null) {
          const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
          setLoginTeaacher(lTea);
        }
      }
      else
      {
        if (sessionStorage.getItem("LoginStudent") !== null) {
          const lStu = JSON.parse(sessionStorage.getItem("LoginStudent")!)
          setLoginStu(lStu);
        }
      }
    }
  }, [openUdpModal])


  return (
    <>
      <div className="fixed-top">
        <nav className="navbar navbar-expand-lg py-3" style={{ backgroundColor: '#FCF9F3' }} >
          <div className="container">
            <div className="navbar-translate w-100">
              <a className="navbar-brand logo" href="/" rel="tooltip" title="數位讀寫網" data-placement="bottom" >數位讀寫網</a>
            </div>
            <div className="d-flex justify-content-between flex-grow-1">
              {((sessionStorage.getItem("LoginStatus") === "0") || (sessionStorage.getItem("LoginStatus") === null)) &&
                <a href="/login" className="btn btn-outline-secondary btn-round text-nowrap mb-0" type="button">登入</a>}
              {(sessionStorage.getItem("LoginStatus") === "1") &&
                <Dropdown style={{ width: 'fit-content' }}>
                  <Dropdown.Toggle className="d-flex align-items-center mb-0 p-0" style={{ background: 'transparent' }}>
                    {(loginStu?.Gender === "男") ?
                      <img className="avatar" src="./assets/img/boy.svg" alt="" /> : <img className="avatar" src="./assets/img/girl.svg" alt="" />
                    }
                    <div className="mx-1">
                      <h6 className="mb-0 font-weight-bold">{loginStu?.StuName}同學</h6>
                      <p className="text-sm text-secondary mb-0">{loginStu?.Grade}年{loginStu?.Class}班</p>
                    </div>

                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setOpenUdpModal(true)}>修改基本資料</Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenPwdModal(true)}>修改密碼</Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenBindMailModal(true)}>設定第三方平台登入</Dropdown.Item>
                    <hr className="horizontal dark" />
                    <Dropdown.Item onClick={()=>handleLink('/stulearnrec')}>自主學習記錄</Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenAssignTeModal(true)}>開放學習記錄</Dropdown.Item>
                    <hr className="horizontal dark" />
                    <Dropdown.Item onClick={() => handleLogout()}>登出</Dropdown.Item>
                  </Dropdown.Menu>

                </Dropdown>}
                {(sessionStorage.getItem("LoginStatus") === "2") &&
                <Dropdown style={{ width: 'fit-content' }}>
                  <Dropdown.Toggle className="d-flex align-items-center mb-0 p-0" style={{ background: 'transparent' }}>
                    <img className="avatar" src="./assets/img/dp/Teacher.svg" alt="" /> 
                    <div className="mx-1">
                      <h6 className="mb-0 font-weight-bold">{loginTeacher?.TeaName}老師</h6>
                      <p className="text-sm text-secondary mb-0">{loginTeacher?.SchoolName}</p>
                    </div>

                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setOpenUdpModal(true)}>修改基本資料</Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenPwdModal(true)}>修改密碼</Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenBindMailModal(true)}>設定第三方平台登入</Dropdown.Item>
                    <hr className="horizontal dark" />
                    <Dropdown.Item onClick={()=>handleLink('/teacheradmin')}>教師專區</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogout()}>登出</Dropdown.Item>
                  </Dropdown.Menu>

                </Dropdown>}

            </div>
            <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon mt-2">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </button>

          </div>
        </nav>
        <nav className="navbar navbar-expand-lg nav-down nav2" style={{ backgroundColor: '#FCF9F3' }} >
          <div className="container">
            <div className="collapse navbar-collapse" id="navigation">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="/pptest" className="nav-link mt-0 ps-lg-0"><span>PP</span>基礎讀寫</a>
                </li>
                <li className="nav-item">
                  <a type="button" className="nav-link mt-0" onClick={() => presetDP()}><span>DP</span>深度讀寫</a>
                </li>
                <li className="nav-item">
                  <a href="/eptest" className="nav-link mt-0"><span>EP</span>線上探究</a> 
                </li>
                <li className="nav-item">
                  <a href="/fundata" className="nav-link">青春研值</a>
                </li>
                <li className="nav-item">
                  <a href="/tutorial" className="nav-link">教學資源</a>
                </li>
                <li className="nav-item">
                  <a href="/faq" className="nav-link"> 常見問題</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <UpdModal isOpen={openUdpModal} setOpenUpdModal={setOpenUdpModal} />
      <PwdModal isOpen={openPwdModal} setOpenPwdModal={setOpenPwdModal} />
      <BindMailModal isOpen={openBindMailModal} setOpenBindMailModal={setOpenBindMailModal} />
      <AssignTeModal isOpen={openAssignTeModal} setOpenAssignTeModal={setOpenAssignTeModal} />
    </>
  );
}

export default TopNavPage1;