import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { IExStudent } from '../../interfaces/ExStudent';
import { deleteExStudentBySids, deleteExTeaStuBySidsTid, updateDetailExStudent, updateOpenToExStudent } from '../../services/ExStuTeService';
import { BSModal } from './bsmodal';
import { IExTeacher } from '../../interfaces/ExTeacher';

interface ModalProps {
    isOpen: boolean;
    setOpenUpdStuModal: (val:boolean) => void;
    updStu:IExStudent|undefined;
  }
  
  export const UpdStuModal: React.FC<ModalProps> = (props) => {

    const [selGrade,setSelGrade]=useState<string>("");
    const [selClass,setSelClass]=useState<string>("");
    const [selSeat,setSelSeat]=useState<string>("");
    const [selStuName,setSelStuName]=useState<string>("");
    const [selGender,setSelGender]=useState<string>("");
    const [selPWD,setSelPWD]=useState<string>("");
    const [selMail,setSelMail]=useState<string>("");
    const [selRemark,setSelRemark]=useState<string>("");
    const [loginTeacher,setLoginTeacher]=useState<IExTeacher>();

    const [pressed,setPressed]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const [openErrModal,setOpenErrModal]=useState(false);
    const [otherSelClass,setOtherSelClass]=useState("");

    const classString=["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15"];


    const handleSave=async()=>{
        if(props.updStu?.Sid===undefined) return;
        let noError=true;
        let errMsg:string[]=[]
        if(selGrade==="")
        {
            noError=false;
            errMsg.push("年級欄位不可空白")
        }

        if(selClass==="")
        {
            noError=false;
            errMsg.push("班級欄位不可空白")
        }

        if((selClass==="其他") && (otherSelClass===""))
        {
            noError=false;
            errMsg.push("其他班級欄位不可空白")
        }

        if(selStuName==="") 
        {
            noError=false;
            errMsg.push("姓名欄位不可空白")
        }

        if(selGender==="")
        {
            noError=false;
            errMsg.push("性別欄位不可空白")
        }

        if(selPWD==="")
        {
            noError=false;
            errMsg.push("密碼欄位不可空白")
        }
        if(noError)
        {
            let selClassStr=""
            if(selClass==="其他")
            {
                if(otherSelClass.includes("班"))
                {
                    selClassStr=otherSelClass.replace("班","").trim();
                }
                else
                {
                    selClassStr=otherSelClass.trim();
                }
            }
            else
            {
                selClassStr=selClass;
            }
            await updateDetailExStudent(props.updStu.Sid.toString().trim(),selStuName.trim(),selPWD.trim(),selGrade.trim(),selClassStr.trim(),selSeat.trim(),selGender.trim(),selMail.trim(),selRemark.trim());
            props.setOpenUpdStuModal(false);
        }
        else
        {
            setOpenErrModal(!noError);
            const errMsgStr=errMsg.join(',');
            setErrMsg(errMsgStr);
        }
    }

    const handleDelete=async()=>{
        if(props.updStu?.Sid===undefined) return;
        if(loginTeacher!==undefined)
        {
            await deleteExStudentBySids(props.updStu.Sid.toString());
            await deleteExTeaStuBySidsTid(props.updStu.Sid.toString(),loginTeacher.Tid.toString());
            props.setOpenUpdStuModal(false);
        }
    }

    const handleRemove=async ()=>{
        if(props.updStu?.Sid===undefined) return;
        if(loginTeacher!==undefined) //教師登入
        {
            await deleteExTeaStuBySidsTid(props.updStu.Sid.toString(),loginTeacher.Tid.toString());
            const newOpenTo:string[]=props.updStu.OpenTo.split(",");
            let opneToStr="";
            newOpenTo.forEach((data,index)=>{
                if(data!==loginTeacher.TeaName.trim() && data!=="")
                {
                    opneToStr=opneToStr+data+",";
                }
            });
            await updateOpenToExStudent(props.updStu.Sid.toString(),opneToStr);
            props.setOpenUpdStuModal(false);
        }
    }

    const renderPage=()=>{
        return(
            <Modal show={props.isOpen} onHide={()=>props.setOpenUpdStuModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="modal-title">修改學生資料</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-3'>
                    <form role="form text-left">
                        <div className="row">
                            <div className="col-12 col-sm-6 mt-3">
                                <div>★</div>
                                <select className="form-control" value={selGrade} onChange={(e)=>setSelGrade(e.currentTarget.value)}>
                                    <option value="一">一年級</option>
                                    <option value="二">二年級</option>
                                    <option value="三">三年級</option>
                                    <option value="四">四年級</option>
                                    <option value="五">五年級</option>
                                    <option value="六">六年級</option>
                                    <option value="七">七年級</option>
                                    <option value="八">八年級</option>
                                    <option value="九">九年級</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-6 mt-3">
                                <div>★</div>
                                <select className="form-control" value={selClass} onChange={(e)=>setSelClass(e.currentTarget.value)}>
                                    <option value="1" >1班</option>
                                    <option value="2">2班</option>
                                    <option value="3">3班</option>
                                    <option value="4">4班</option>
                                    <option value="5">5班</option>
                                    <option value="6">6班</option>
                                    <option value="7">7班</option>
                                    <option value="8">8班</option>
                                    <option value="9">9班</option>
                                    <option value="10">10班</option>
                                    <option value="11">11班</option>
                                    <option value="12">12班</option>
                                    <option value="13">13班</option>
                                    <option value="14">14班</option>
                                    <option value="15">15班</option>
                                    <option value="其他">其他</option>

                                </select>
                                {
                                    selClass==="其他" && (
                                    <div className="mt-2">
                                        <input type="text" className="form-control mt-2" placeholder="輸入班級" value={otherSelClass} onChange={(e)=>setOtherSelClass(e.currentTarget.value)}/>
                                    </div> )                                   
                                    
                                }
                            </div>

                            <div className="col-12 col-sm-6 mt-3">
                                <label>座號</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入座號" value={selSeat.trim()} onChange={(e)=>setSelSeat(e.currentTarget.value)}/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-3">
                                <label>★姓名</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入姓名" value={selStuName.trim()} onChange={(e)=>setSelStuName(e.currentTarget.value)}/>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-3">
                                <label htmlFor="myclass" className="me-4">★性別</label>
                                <div className="d-flex">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="esex" id="esex1" checked={selGender==='男'} onChange={()=>setSelGender("男")}/>
                                        <label className="custom-control-label me-4" htmlFor="esex1">男</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="esex" id="esex2" checked={selGender==='女'} onChange={()=>setSelGender("女")}/>
                                        <label className="custom-control-label" htmlFor="esex2">女</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-3">
                                <label>★密碼</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入密碼" value={selPWD.trim()} onChange={(e)=>setSelPWD(e.currentTarget.value)}/>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label>第三方平台Email</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入Email" value={selMail.trim()} onChange={(e)=>setSelMail(e.currentTarget.value)}/>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label>備註</label>
                                <div className="input-group">
                                <textarea rows={2} className="form-control" placeholder="輸入備註" value={selRemark.trim()} onChange={(e)=>setSelRemark(e.currentTarget.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="modal-footer justify-content-between mt-4">
                <div>
                    <button type="button" className="btn btn-outline-danger me-2" onClick={()=>handleRemove()}>移除</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={()=>handleDelete()}>刪除</button>
                </div>
                <div>
                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal" onClick={()=>props.setOpenUpdStuModal(false)}>取消</button>
                    <button type="button" className="btn btn-primary" onClick={()=>handleSave()}>儲存</button>
                </div>
                </Modal.Footer>
                <BSModal
                title={'基本資料欄位輸入錯誤'}
                isOpen={openErrModal}
                setOpenModal={setOpenErrModal}                
                >
                {errMsg}
                </BSModal> 
            </Modal>
        )
    }


    useEffect(()=>{
        if(props.updStu===undefined) return;
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
          if (sessionStorage.getItem("LoginTeacher") !== null) {    
            const lTea:IExTeacher = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
            setLoginTeacher(lTea);
          }
        }
        setSelStuName(props.updStu?.StuName===null? "":props.updStu?.StuName);
        setSelPWD(props.updStu?.PWD===null? "":props.updStu?.PWD);
        setSelGrade(props.updStu?.Grade===null? "":props.updStu?.Grade);
        if(props.updStu?.Class===null)
        {
            setSelClass("");
            setOtherSelClass("");
        }
        else
        {
            if (classString.includes(props.updStu?.Class.trim()))
            {
                setSelClass(props.updStu?.Class.trim());
                setOtherSelClass("");
            }
            else
            {
                setSelClass("其他");
                setOtherSelClass(props.updStu?.Class.trim()+"班");
            }
        }
        setSelSeat(props.updStu?.Seat===null? "":props.updStu?.Seat);
        setSelGender(props.updStu?.Gender===null ? "":props.updStu?.Gender);
        setSelMail(props.updStu?.iSchoolMail===null? "":props.updStu?.iSchoolMail);
        setSelRemark(props.updStu?.Remark===null? "":props.updStu?.Remark);
    },[])

    useEffect(()=>{
        if(props.isOpen)
        {
            if(props.updStu===undefined) return;
            if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
            {
              if (sessionStorage.getItem("LoginTeacher") !== null) {    
                const lTea:IExTeacher = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
                setLoginTeacher(lTea);
              }
            }
            setSelStuName(props.updStu?.StuName===null? "":props.updStu?.StuName);
            setSelPWD(props.updStu?.PWD===null? "":props.updStu?.PWD);
            setSelGrade(props.updStu?.Grade===null? "":props.updStu?.Grade);
            if(props.updStu?.Class===null)
            {
                setSelClass("");
                setOtherSelClass("");
            }
            else
            {
                if (classString.includes(props.updStu?.Class.trim()))
                {
                    setSelClass(props.updStu?.Class.trim());
                    setOtherSelClass("");
                }
                else
                {
                    setSelClass("其他");
                    setOtherSelClass(props.updStu?.Class.trim()+"班");
                }
            }           

            setSelSeat(props.updStu?.Seat===null? "":props.updStu?.Seat);
            setSelGender(props.updStu?.Gender===null ? "":props.updStu?.Gender);
            setSelMail(props.updStu?.iSchoolMail===null? "":props.updStu?.iSchoolMail);
            setSelRemark(props.updStu?.Remark===null? "":props.updStu?.Remark);
        }
    },[props.isOpen])


    useEffect(()=>{
        if(pressed)
        {

        }
        else
        {

        }
    },[pressed])

    return <>{renderPage()}</>;
    
    };