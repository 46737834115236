import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IAdFaq } from '../interfaces/AdFaq';

export const getAdFaqByKeyword=(key:string)=>{
    const url=config.server.url+`/api/AdFaq/Category/${key}`
    return axios.get(url).then((res)=>{
        const rest:IAdFaq[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



