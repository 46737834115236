import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo } from '../../services/EP/EpApplyStudentService';
import { currentClass, devSection, fetchdataGroupInfo, getLoginInfo, replaceNewline } from './tool/helper';
import { IGroupForExpertS, IGroupInfo, IGroupStudent, IStudentInfo, dropDownListOption } from '../../interfaces/EpModal';
import { getDrStudentByGroup } from '../../services/EP/EPApplyGroupService';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { getDrGroupByTeaNo } from '../../services/EP/EpApplyTeacherService';
import ExpertFunctionBar from './component/expertFunctionBar';
import { getAllDrGroupByClassExpert } from '../../services/EP/EpExpertService';
import ExportOpinionModal from './modal/exportOpinionModal';
import { updateDrGroupExpOpinions } from '../../services/EP/EpApplyExploreService';
import { isReadable } from 'stream';

// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpExpertOpinion: React.FunctionComponent<IPageProps> = () => {
    const [nowEditItem, setNowEditItem] = useState<IGroupForExpertS>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
  
    /**下拉選單 */
    const [classNameList, setClassNameList] = useState<string[]>([]);
    const [exportList, setExportList] = useState<dropDownListOption[]>([]);
    const [allGroupInfoList, setAllGroupInfoList] = useState<IGroupForExpertS[]>([]);
    const [displayGroupList, setDisplayGroupList] = useState<IGroupForExpertS[]>([]);
    const [selectedClassName, setSelectClassName] = useState<string>();
    const [selectedExpert, setSelectedExpert] = useState<dropDownListOption>();
    const [reload ,setReload] =useState<boolean>(false);

    const [isEditable, setIsEditable] = useState(false);
    const [isMemoSelected,setIsMemoSelected] =useState (false)
    const [selectNum,setSelectNum ]=useState(0)
    const [isCheckAll,setIsCheckAll] =useState(false)
    const [isSelectDisable ,setSelectDisable] =useState(true)



    useEffect(() => {
        if (selectedExpert) setIsEditable(selectedExpert?.expAccount === currentLoginInfo.LoginID);
    }, [selectedExpert]);



    useEffect(() => {
  
        fetchGroupData();
        
      
    }, [reload])

    /** 當專家選項變化時 */
    useEffect(() => {

        if (selectedExpert?.expAccount) {
        
            reloadDisplayList(selectedExpert?.expAccount);
        }
    }, [selectedExpert,selectedClassName]);


    /** */
    const updateMemoToExpOpinion  = async ()=>{
    
      let targetList =displayGroupList.filter(i=>i.isSelected  == true )

      let paramsList : {
        GroupNo:string ;
        ExpOpinion:string ;  
        ExpMemo:string |'';
      }[]
 = []

      targetList.map(item=>{
        paramsList.push( {
            GroupNo:item.Groupno ,
            ExpOpinion:item.ExpMemo ||'' ,  
            ExpMemo:item.ExpMemo||''
          })

      })
     try{
  
      await updateDrGroupExpOpinions (paramsList)
         
      await handleCloseModal() 
      setIsMemoSelected(false)

  
     }catch(ex){
        alert("發生錯誤:"+JSON.stringify(ex))
     }
    }

    const reloadDisplayList = (account: string) => {
        setDisplayGroupList(
            [...allGroupInfoList].filter((item) => {
                return item.exportAccount === account &&item.Class === selectedClassName;
            }) || []
        );
    };

    /** */
    const OpenModal = (item: IGroupForExpertS) => {
        setNowEditItem(item);
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (selectedClassName) {
            reloadDropDownList(selectedClassName);
        }
    }, [selectedClassName,reload]);
    useEffect(() => {
        setFilterData();
    }, [selectedClassName,reload]);

    /** */
    const fetchGroupData = async () => {

        let groupInfoList = await fetchdataGroupInfo(setCurrentLoginInfo, setAllGroupInfoList);
        let classNameListTemp: string[] = [];
        console.log('', groupInfoList);
        groupInfoList?.forEach((item) => {
            if (!classNameListTemp.includes(item.Class)) {
                classNameListTemp.push(item.Class);
            }
        });
        setClassNameList(classNameListTemp);

  
        setSelectClassName( selectedClassName ||  classNameListTemp[0]);
    
        setSelectNum(0)
        setIsCheckAll(false)
        setSelectDisable(false)
    };

    /** reload 下拉選單*/
    const reloadDropDownList = (selectClassName: string) => {
        let temp = allGroupInfoList.filter((item) => {
            return item.Class == selectClassName;
        });
        let dropdownListtemp: dropDownListOption[] = [];
        temp.forEach((item) => {
            if (!dropdownListtemp.find((x) => x.DisplayName == item.GroupSet.trim() + '-' + item.ExpertName.trim())) dropdownListtemp.push(new dropDownListOption('專家', item));
        });
        setExportList(dropdownListtemp);
        setSelectedExpert(dropdownListtemp[0]);
    };

    const setFilterData = () => {
        let result: IGroupForExpertS[] | undefined = [];
    };


    const renderDiv = (target: any ,width?:string) => {
        return <div style={{ width: width||'250'  ,whiteSpace: "pre-wrap"}} className="p-1" dangerouslySetInnerHTML={{ __html: target }}></div>;
    };


    const handleCheckboxChange = (index:number) => {
        // 在這裡更新 checkbox 的狀態
        const updatedGroupList = [...displayGroupList];
        updatedGroupList[index].isSelected = !updatedGroupList[index].isSelected;
        // 將更新後的狀態設置回 state
        setDisplayGroupList(updatedGroupList); 
        let selectNumT=updatedGroupList.filter(x=>x.isSelected).length
        let couldeSelect = updatedGroupList.filter(x=>{return (x.ExpMemo)&&(!x.ExpOpinion)}).length
        setSelectNum(updatedGroupList.filter(x=>x.isSelected).length)
    
        if(selectNumT==couldeSelect )
        {
            setIsMemoSelected(true)

        }
       else {
           setIsMemoSelected(false)
             }   
    };


    /** */
    const handleCloseModal =async()=>{
     await   fetchGroupData();
        if (selectedExpert?.expAccount) {
            reloadDisplayList(selectedExpert?.expAccount);
        }
        setIsModalOpen(false )
    
        setReload(!reload)
    }
    const checkAll=()=> {
   
        let num = 0 
        let isPositive  = false ;
        
        const updatedDisplayGroupList = displayGroupList.map(item => {
     
            if (!!!item.ExpOpinion && item.ExpMemo) {
               
                num++
                if(!isMemoSelected ){ // 只要是有一個被選取
                    isPositive =true ;
                }
                return { ...item, isSelected: !isMemoSelected };
            }
            return item;

        });

    

        setDisplayGroupList(updatedDisplayGroupList);
        if(num>0){
            if(isPositive){

                setSelectNum(num)
                setIsMemoSelected(!isMemoSelected)

            }else{
 
                setSelectNum(0)
                setIsMemoSelected(!isMemoSelected)

            }
        }else{
            setIsMemoSelected(false)
            setSelectNum(0)
            
            alert("無符合條件可勾選項目")
        }
    }

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
            <div className="container px-3 px-md-4">
                <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                    <h5 className="title mb-3 me-3">專題探究競賽</h5>
                    <ExpertFunctionBar />
                </div>
                {/* {devSection(displayGroupList)} */}
                <div className="text-center h5 mt-4 mb-3">回饋意見</div>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="dropdown mx-2 mb-3" style={{ width: 'fit-content' }}>
                        <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                          

                            {!isSelectDisable ?(<>  {selectedClassName}</> ) :(<> </>)}
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {classNameList.map((item) => (
                                <li>
                                   {!isSelectDisable ?( <div className="dropdown-item sel-active" onClick={() => {if(!isSelectDisable)   setSelectClassName(item)}}>
                                        {item}
                                    </div>) :(<> loading ..</>)}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="dropdown mx-2 mb-3" style={{ width: 'fit-content' }}>
                        <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                            {selectedExpert?.DisplayName}
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {exportList.map((item) => (
                                <li>
                                    <div className="dropdown-item" onClick={() => setSelectedExpert(item)}>
                                        {item.DisplayName}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="card p-3">
                    <div className="table-responsive py-3 " style={{ overflow: 'hidden' }} >
                        <table id="opin" className="text-sm table table-striped mb-0">
                            <thead>
                                <tr className="font-weight-bolder text-second align-middle" style={{ background: '#d9dce6' }}>
                                    <th className="p-2 text-nowrap">No.</th>
                                    <th className="p-2 text-nowrap">組別</th>
                                    <th className="p-2 text-nowrap" style={{ minWidth: '100px' }}>
                                        小隊
                                    </th>
                                    <th className="p-2 text-nowrap"   style={{ width: '150px' }}>
                                        題目
                                    </th>
                                    <th className="p-2 text-nowrap" style={{ width: '250px' }}>
                                        線上回饋
                                    </th>
                                    <th className="p-2 text-nowrap" style={{ width: '170px' }}>
                                       {isEditable?(<>
                        <input type="checkbox" className="me-2"   checked={isMemoSelected}  onClick={()=>checkAll()} />
                        Memo <span style={{width:"20px", display:"inline-block"}}>{selectNum? `(${selectNum})`:'   '}</span> 
                        <div className="btn text-second mx-2 mb-0" style={{ background: '#d5e8eb' }}
                        onClick={()=>{updateMemoToExpOpinion()}}>
                         將<span className="material-icons-outlined align-bottom">check_box</span> 內容複製到(空白的)線上回饋欄位
                            </div>
                            </> ):(<>       Memo {selectNum?`(${selectNum})`:''}</>)  }
                                     
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayGroupList.map((i, index) => (
                                    <tr>
                                        <td> {index + 1}</td>
                                        <td>{i.GroupSet}</td>
                                        <td>
                                            <div>{i.Groupno}</div>
                                            <div className="text-nowrap"  style={{ width: '120px' }}>{i.Groupname}</div>
                                        </td>
                                        <td>{renderDiv(i.Topic,'150px')}</td>
                                        <td>{renderDiv(i.ExpOpinion)}</td>
                                        <td  className="text-nowrap"  style={{ width: '170px' }} >
                                        {isEditable ?(<><input type="checkbox" 
                                            checked={i.isSelected} onChange={()=>handleCheckboxChange(index)}  disabled={(!!i.ExpOpinion||!i.ExpMemo)} />
                                            {renderDiv(i.ExpMemo,'330px')}</>):(<>{renderDiv(i.ExpMemo,'330px')}</>)
                                        } 
                                        </td>
                                        <td>
                                         {isEditable?(<span className="material-icons text-dark pointer" data-bs-toggle="modal" data-bs-target="#editModal1-1" title="編輯" 
                                            onClick={() => OpenModal(i)}>
                                                edit
                                            </span>

                                         ):(<></>)}   
                                        </td>
                                    </tr>
                                ))}

                        
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="pt-3 mt-4"></div>
            </div>

            <ExportOpinionModal handleCloseModal ={handleCloseModal}   currentData={nowEditItem} allList={displayGroupList} isOpen={isModalOpen} 
            setCurrentData ={setNowEditItem}     />
</div>
            <FooterPage />
        </>
    );
};

export default EpExpertOpinion;
