import React, { useEffect, useState } from 'react'
import { BSModal } from '../components/modal/bsmodal';
import { IExSchool } from '../interfaces/ExSchool';
import { IExStudent } from '../interfaces/ExStudent';
import IPageProps from '../interfaces/page'
import { handleLink } from '../modules/utils';
import { getExSchoolAreaByCity, getExSchoolAreaByCityArea, getExSchoolCities } from '../services/ExSchoolService';
import { createExStudent, getExStudentByStuNo, getMaxStuID } from '../services/ExStuTeService';
import FooterPage from './footer';

const NewStudentPage: React.FunctionComponent<IPageProps> = props => {
    const [city,setCity]=useState<string[]>([]);
    const [selCity,setSelCity]=useState("");
    const [area,setArea]=useState<string[]>([]);
    const [selArea,setSelArea]=useState("")
    const [schoolName,setSchoolName]=useState<IExSchool[]>([])
    const [selSchool,setSelSchool]=useState("");
    const [selGrade,setSelGrade]=useState("");
    const [selClass,setSelClass]=useState("");
    const [selGender,setSelGender]=useState("");
    const [inpStuName,setInpStuName]=useState("");
    const [inpStuNo,setInpStuNo]=useState("");
    const [inpPwd,setInpPwd]=useState("");
    const [inpPwdAgain,setInpPwdAgain]=useState("");
    const [pressed,setPressed]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const [openModal,setOpenModal]=useState(false);
    const [maxSid,setMaxSid]=useState(0);


    // 取得Session中的Google資料
    const getGoogleInfo = (key: string): string => {
        const googleInfo: {
            uuid: string;
            firstName: string;
            lastName: string;
            language: string;
            mail: string;
        } = (sessionStorage.getItem('GoogleProfile')!==null)? JSON.parse(sessionStorage.getItem('GoogleProfile')!):{
            uuid: '',
            firstName: '',
            lastName: '',
            language: '',
            mail: ''
        };
        let result = '';
        switch (key) {
            case 'firstname':
                result = googleInfo.firstName;
                break;
            case 'lastname':
                result = googleInfo.lastName;
                break;
            case 'mail':
                result = googleInfo.mail;
                break;
        }
        return result;
    };

    const handleSubmit=async ()=>{
        let noError=true;
        let errMsg=""
        if(selCity==="")
        {
            noError=false;
            errMsg=errMsg+"縣市不可空白,"
        }
        if(selArea==="")
        {
            noError=false;
            errMsg=errMsg+"鄉鎮區不可空白,"
        }
        if(selSchool==="")
        {
            noError=false;
            errMsg=errMsg+"學校不可空白,"
        }
        if(selGrade==="")
        {
            noError=false;
            errMsg=errMsg+"年級不可空白,"
        }
        if(selClass==="")
        {
            noError=false;
            errMsg=errMsg+"班級不可空白,"
        }
        if(selGender==="")
        {
            noError=false;
            errMsg=errMsg+"性別不可空白,"
        }
        if(inpStuName==="")
        {
            noError=false;
            errMsg=errMsg+"姓名或暱稱不可空白,"
        }
        else
        {
            if(inpStuName.length>10)
            {
                noError=false;
                errMsg=errMsg+"姓名或暱稱不可超過10碼,"
            }
        }
        
        if(inpStuNo==="")
        {
            noError=false;
            errMsg=errMsg+"帳號不可空白,"
        }
        else
        {
            if((inpStuNo.length>30))
            {
                noError=false;
                errMsg=errMsg+"帳號長度不可大於30碼,"
            }
            else
            {

                const tmp=await getExStudentByStuNo(inpStuNo);
                console.log(tmp);
                if(tmp!==undefined)
                {
                    noError=false;
                    errMsg=errMsg+"帳號已經存在,"
                }   
                
            }


        }
        if(inpPwd==="")
        {
            noError=false;
            errMsg=errMsg+"密碼不可空白,"
        }
        else
        {
            const regChk : RegExp=/^([a-zA-Z0-9]){6,}/; 
            if(!regChk.test(inpPwd))
            {
                noError=false;
                errMsg=errMsg+"密碼設定錯誤(至少6碼並為英數字),"
            }
        }
        if(inpPwdAgain==="")
        {
            noError=false;
            errMsg=errMsg+"確認密碼不可空白,"
        }
        else
        {
            if(inpPwd!==inpPwdAgain)
            {
                noError=false;
                errMsg=errMsg+"密碼與確認密碼輸入不符,"
            }
        }
        
        const maxSid=await getMaxStuID();
        setMaxSid(maxSid.MaxSid);
        

        if(noError)
        {            
            setPressed(true);
            handleLink("/login")
        }
        else
        {
            setOpenModal(!noError);
            errMsg=errMsg.substring(0,errMsg.length-1);
            setErrMsg(errMsg);
        }       

    }

    useEffect(()=>{
        getExSchoolCities().then(data=>setCity(["(縣市)",...data]));
        let area=document.getElementById('area');
        if(area!==null)
        {
            area.innerHTML=`<option value="" disabled selected>(鄉鎮區)</option>`
        }
        let sch=document.getElementById('school');
        if(sch!==null)
        {
            sch.innerHTML=`<option value="" disabled selected>(學校)</option>`
        }

            

    },[])

    useEffect(()=>{
        if(selCity!=="")
        {
            getExSchoolAreaByCity(selCity).then(data=>{
                setArea(data)
                let area=document.getElementById('area');
                if(area!==null)
                {
                    area.innerHTML=`<option value="" disabled selected>(鄉鎮區)</option>`
                    data.map(item=>{
                        if(area!==null)
                        {
                            area.innerHTML=area.innerHTML+` <option value="${item}" >${item}</option>`
                        }
                    })
                }
                let sch=document.getElementById('school');
                if(sch!==null)
                {
                    sch.innerHTML=`<option value="" disabled selected>(學校)</option>`
                }

            }
            )
        }
    },[selCity])

    useEffect(()=>{
        if((selArea!=="") && (selCity!==""))
        {
            getExSchoolAreaByCityArea(selCity,selArea).then(data=>{
                setSchoolName(data);
                let sch=document.getElementById('school');
                if(sch!==null)
                {
                    sch.innerHTML=`<option value="" disabled selected>(學校)</option>`
                    data.map(item=>{
                        if(sch!==null)
                        {
                            sch.innerHTML=sch.innerHTML+`<option value="${item.SchoolNo},${item.SchoolName}" >${item.SchoolName}</option>`
                        }
                    })
                }

            })
        }
    },[selArea])
    useEffect(()=>{
        if(pressed)
        {
            const schDetail=selSchool.split(",");
            const newSid=maxSid+1;
            createExStudent(
                newSid.toString().trim(),inpStuNo,inpStuName,inpPwd,schDetail[0],selCity,selArea,schDetail[1],selGrade,selClass,selGender,
                getGoogleInfo('mail'),'','','','','')
                .then(data=>{
                console.log(data);
            })
        }
    },[pressed])
    return (
        <>
            <div style={{backgroundColor:'#FCF9F3'}}>
                <nav className="navbar navbar-expand-lg py-3" style={{backgroundColor:'#FCF9F3'}}>
                <div className="container align-items-center">
                    <div className="navbar-translate">
                    <a className="navbar-brand logo" href="/" rel="tooltip" title="數位讀寫網" data-placement="bottom">數位讀寫網</a>
                    </div>
                </div>
                </nav>
            </div>
            <div className="py-5">
                <div className="container" style={{maxWidth:'500px'}}>
                    <div className="row" style={{borderRadius: '8px',boxShadow:'0px 0px 10px 5px #d7d7d7'}}>
                        <div className="col-12 bg-primary logreg login px-4">
                            <h4 className="py-3 text-center text-white fw-bolder">學生註冊</h4>
                            {(getGoogleInfo('mail')!=="") &&
                            <>
                                <h5 className="text-center text-white">Google資訊 姓名: {getGoogleInfo('lastname')} {getGoogleInfo('firstname')} mail:{getGoogleInfo('mail')}</h5>
                            </>
                            }
                            <div className="accordion" id="studregps">
                                <div className="accordion-item mb-4">
                                    <div className="accordion-header" id="headingOne">
                                        <button className="accordion-button fw-normal text-white w-auto mx-auto collapsed py-0"
                                        data-bs-toggle="collapse" data-bs-target="#studreg" aria-expanded="false" aria-controls="studreg"
                                        style={{fontSize:'14px!important'}}>
                                        <div className="me-2">學生帳號註冊說明</div>
                                        <i className="collapse-close fa fa-angle-down pt-1 position-absolute end-0 me-2" aria-hidden="true"></i>
                                        <i className="collapse-open fa fa-angle-up pt-1 position-absolute end-0 me-2" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div id="studreg" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#studregps">
                                        <div className="accordion-body border-top text-start text-sm text-justify mt-3 p-3 pb-0"
                                        style={{color:'#ededed'}}>
                                            <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>1. 同學們不一定要申請編號才可以進入讀寫練習(PP&DP)。申請編號的同學可以保留和查詢作答的記錄。</div>
                                            <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>2. 請填寫所有欄位，一般民眾或其他身分者，請於學校的下拉式選單選擇「其他」，亦可記錄您的作答。</div>
                                            <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>3. 編號可混合使用英文字母或數字，避免忘記，建議使用你易記的文字和數字。</div>
                                            <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>4. 申請後，可到【學生主頁】設定第三方平台登入，即可免再輸入帳密登入。</div>
                                            <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>5. 本網站不提供查詢編號或密碼的服務，若忘記了請重新申請。</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <select name="" id="city" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelCity(e.currentTarget.value)} >
                                        <>
                                        {
                                            city.map((data,i)=>{
                                                if(i===0)
                                                {
                                                   return  <option value="" disabled selected>{data}</option>
                                                }
                                                else
                                                {
                                                    return <option value={data} >{data}</option>
                                                }                                                                                                
                                                })                                            
                                        }
                                        </>
                                        </select>
                                        <select name="" id="area" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelArea(e.currentTarget.value)} >
                                        </select>
                                    </div>
                                    <div className="row mt-3">
                                        <select name="" id="school" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelSchool(e.currentTarget.value)} >
                                        </select>
                                        <select name="" id="grade" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelGrade(e.currentTarget.value)} >
                                        <option value="" disabled selected>(年級)</option>
                                        <option value="一">一年級</option>
                                        <option value="二">二年級</option>
                                        <option value="三">三年級</option>
                                        <option value="四">四年級</option>
                                        <option value="五">五年級</option>
                                        <option value="六">六年級</option>
                                        <option value="七">七年級</option>
                                        <option value="八">八年級</option>
                                        <option value="九">九年級</option>
                                        </select>
                                    </div>

                                    <div className="row mt-3">
                                        <select name="" id="class" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelClass(e.currentTarget.value)} >
                                        <option value="" disabled selected>(班級)</option>
                                        <option value="1">1班</option>
                                        <option value="2">2班</option>
                                        <option value="3">3班</option>
                                        <option value="4">4班</option>
                                        <option value="5">5班</option>
                                        <option value="6">6班</option>
                                        <option value="7">7班</option>
                                        <option value="8">8班</option>
                                        <option value="9">9班</option>
                                        <option value="10">10班</option>
                                        <option value="11">11班</option>
                                        <option value="12">12班</option>
                                        <option value="13">13班</option>
                                        <option value="14">14班</option>
                                        <option value="15">15班</option>
                                        <option value="16">16班</option>
                                        <option value="17">17班</option>
                                        <option value="18">18班</option>
                                        <option value="19">19班</option>
                                        <option value="20">20班</option>
                                        </select>
                                        <select name="" id="sex" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelGender(e.currentTarget.value)} >
                                        <option value="" disabled selected>(性別)</option>
                                        <option value="男">男生</option>
                                        <option value="女">女生</option>
                                        </select>
                                    </div>

                                    <div className="row mt-3">
                                        <input type="text" className="col mx-2 form-control" placeholder="姓名或暱稱(最多10碼)" onChange={(e)=>setInpStuName(e.currentTarget.value)} />
                                        <input type="text" className="col mx-2 form-control" placeholder="帳號(最多30碼)" onChange={(e)=>setInpStuNo(e.currentTarget.value)}/>
                                    </div>

                                    <div className="row mt-3">
                                        <input type="password" className="col mx-2 form-control" placeholder="密碼(至少6碼，英數字)" onChange={(e)=>setInpPwd(e.currentTarget.value)}/>
                                        <input type="password" className="col mx-2 form-control" placeholder="確認密碼" onChange={(e)=>setInpPwdAgain(e.currentTarget.value)}/>
                                    </div>

                                    <div className="text-center mt-5">
                                        <button className="btn btn-round btn-white text-primary mb-0" onClick={()=>handleSubmit()}>送出申請</button>
                                    </div>
                                    <div className="text-center my-4">
                                        <a href="/login" className="text-white" style={{borderBottom:'1px solid #fff'}}>返回登入</a>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>    
                </div>        
            </div>
            <BSModal
                title={'學生註冊欄位輸入錯誤'}
                isOpen={openModal}
                setOpenModal={setOpenModal}                
            >
                {errMsg}
            </BSModal> 
            <FooterPage/>            

        </>
    );
}

export default NewStudentPage;