import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { currentClass, devSection, fetchDataOpinion, fetchDataSubTopic, formatDate, formatDateTime, getLoginInfo, getOpinionAttr, replaceNewline, sTitleName } from '../tool/helper';
import {  IGroupForExpertS, IGroupInfo, IGroupStudent, IOpinion, IStudentInfo, IsubTopic } from '../../../interfaces/EpModal';
import { getDrStudentByGroup } from '../../../services/EP/EPApplyGroupService';
import TopNavPage1 from '../../topNav1';
import FooterPage from '../../footer';
import { getDrGroupByTeaNo } from '../../../services/EP/EpApplyTeacherService';
import ExpertFunctionBar from '../component/expertFunctionBar';
import { getAllDrGroup, updateDrGroupExpOpinion } from '../../../services/EP/EpExpertService';
import { getSubTopicByGroupNo } from '../../../services/EP/EpApplyExploreService';
import { Modal } from 'react-bootstrap';

interface IModalComponentProps {
    groupNo :string  
}

// https://attendance-67917bb.web.app/digit/epStudHome.html
const OpinionSection: React.FunctionComponent<IModalComponentProps> = ({groupNo} ) => {
    const [opinion, setOpinion] = useState<IOpinion>();
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [groupForExpertList, setGroupForExpertList] = useState<IGroupForExpertS[]>();
    const [groupForExpertDisplayList, setGroupForExpertDisplayList] = useState<IGroupForExpertS[]>();
    const [selectedClassName, setSelectClassName] = useState<string>();
    const [selectedExpert, setSelectedExpert] = useState<string>();

    /** 子題 */
    const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>([]);
    const [isModalOpen ,setIsModalOpen] =useState <boolean>();
    /**下拉選單 */
    const [classNameList, setClassNameList] = useState<string[]>([]);
    const [groupList, setGroupList] = useState<string[]>([]);
    const [schoolNameList, setSchoolNameList] = useState<string[]>([]);
    const [selectSectionList, setSelectSectionList] = useState<IGroupForExpertS[]>();
    const [formData, setFormData] = useState<{data:string|any }>({data:""})
    const [isloading, setIsoading] =useState<boolean>(false)

    useEffect(() => {
        fetchdataGroupInfo();
    }, []);

    // useEffect(() => {
    //     setFilterData();
    // }, [selectedClassName]);

    useEffect(() => {
        setIsoading(true)
        fetchDataOpinion(groupNo, setOpinion);

    }, [groupNo]);

    useEffect(()=>{
        setIsoading(false)
    },[opinion])

 

    const getGroupNoList = () => {
        let result: IGroupForExpertS[] =
            groupForExpertList?.filter((item) => {
                return item.Class == selectedClassName;
            }) || [];

        setSelectSectionList(result);
      
    };

    /** 以專家身分取得  */
    const fetchdataGroupInfo = async () => {
        /** 取得登入資料 */

        let currentLoginInfo = getLoginInfo();
        console.log('目前登入資訊', currentLoginInfo);
        setCurrentLoginInfo(currentLoginInfo);

        try {
            let classNameList: string[] = [];
            let groupNameList: string[] = [];
            groupNameList.push('全部組別');
            let schoolNameList: string[] = [];
            schoolNameList.push('無');

            // let rsp = (await getAllDrGroupByClassExpert(currentClass, 'liue')) as IGroupForExpert[];
            let rsp = (await getAllDrGroup()) as IGroupForExpertS[];
            console.log('rsp', rsp);
            // 處理下拉式選單
            rsp.forEach((item) => {
                if (!classNameList.includes(item.Class)) {
                    classNameList.push(item.Class);
                }
                if (!groupNameList.includes(item.Groupname)) {
                    groupNameList.push(item.Groupname);
                }
                if (!schoolNameList.includes(item.Schoolname)) schoolNameList.push(item.Schoolname);
            });
            setClassNameList(classNameList);
            setGroupList(groupNameList);
            setSchoolNameList(schoolNameList);
            setSelectClassName(classNameList[0]);

            setGroupForExpertList(rsp);
            console.log('取得專家的隊伍', rsp);
        } catch (error) {}
        // const groupInfo
    };


    useEffect(() => {
      if(groupNo){
        fetchDataSubTopic(groupNo, setSubTopicBeforeFeedbackList);
      }
  }, []);




    const renderOpiniondiv = (displaytitle: string, name: sTitleName) => {
        let attr = getOpinionAttr(displaytitle, name);
        // alert(JSON.stringify(attr))
        if (opinion && attr) {
            if (name.includes('-修改時間')) {
                return <>{formatDateTime(opinion[attr?.attr])}</>;
            } else {
                return <div className="p-3" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion[attr?.attr]) }}></div>;
            }
        } else {
            return <div>未填寫</div>;
        }
    };


    const [uniqueGroupS,setUniqueGroupS] =useState<any[]>([]); 


    /** */

    return (
            isloading? (  <div className="container d-flex justify-content-center  px-3 px-md-4" style={{ minHeight: '100vh' }}>
            <div>loading ....</div>
        </div> ):

        <>
            <div className="container px-3 px-md-4">
                <div className="accordion" id="accordionRental">
    
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level1" aria-expanded="true" aria-controls="level1">
                                <h4 className="my-0 me-3">階段1、決定探究題目</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="collapse show" id="level1">
                            <div className="card p-4 pt-3">
                                <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2 text-nowrap">成員</th>
                                                <th className="p-2 text-nowrap">我想探究的題目</th>
                                                <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {opinion?.Pre11.map((item) => (
                                                <tr>
                                                    <td className="text-nowrap">{item.StuName}</td>
                                                    <td>{item.DataA}</td>
                                                    <td>{item.DataB}</td>
                                                    <td>{formatDate(item.UpdateDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">
                                        我們決定探究的題目：
                                        {renderOpiniondiv('1-2 決定探究題目', '前-決定探究題目')}
                                    </div>
                                    <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>
                                    {renderOpiniondiv('1-2 決定探究題目', '前-決定探究題目原因')}
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{renderOpiniondiv('1-2 決定探究題目', 'Post12-修改時間')}</span>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">我們預測可能的結果是：</div>
                                    <div>
                                        一、經由小組分工每人負責工作說明如( )說明。
                                        <br />
                                        二、大家決議上網搜集相關資料，每人至少閱讀三篇參考文獻，並做整理，寫下相關資料和自己的收穫。
                                        <br />
                                        三、預測結果如下:
                                        <br />
                                        <div>{renderOpiniondiv('1-3 預測可能的結果', '前-探究題目預測結果原因')}</div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{renderOpiniondiv('1-3 預測可能的結果', 'Pre13-修改時間')}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段1」遇到的困難：</div>
                                        {renderOpiniondiv('1-4 困難、解決與新學習', '前-遭遇困難')}
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        {renderOpiniondiv('1-4 困難、解決與新學習', '前-解決方式')}
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        {renderOpiniondiv('1-4 困難、解決與新學習', '前-學到的新思維')}
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span> {renderOpiniondiv('1-4 困難、解決與新學習', 'Pre14-修改時間')}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                    <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    {renderOpiniondiv('階段1，指導老師留言', '前-指導老師留言')}
                                    {/* <div>學校裡的新住民子女學生人數大約在70人上下，其中包含許多不同的國家，題目中有些想討論『新住民媽媽』，有些想討論『越南新住民媽媽』，為什麼最後題目會將對象定為『越南新住民媽媽』？ */}
                                </div>
                                <div className="pt-3 pb-2">
                                    <span className="fw-bolder text-second">日期：</span>
                                    <span>{renderOpiniondiv('階段1，指導老師留言', 'Pre19-修改時間')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level2" aria-expanded="true" aria-controls="level2">
                                <h4 className="my-0 me-2">階段2、規劃探究架構</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="collapse show" id="level2">
                            <div className="card p-4 pt-3">
                                <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                <div className="d-flex flex-wrap align-items-end">
                                    <div className="fw-bolder text-second pt-3 pb-2">
                                        本隊探究題目：
                                        {/* <span className="text-primary">越南新住民媽媽如何融入臺灣的社會生活？</span> */}
                                        {renderOpiniondiv('2-1 擬定探究子題', 'Pre14-修改時間')}
                                    </div>
                                </div>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2">#</th>
                                                <th className="p-2 text-nowrap">子題名稱</th>
                                                <th className="p-2 text-nowrap">負責隊員</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                          {subTopicBeforeFeedbackList.map((item,index)=>(
                                              <tr>
                                              <td>{index+1}</td>
                                              <td>{item.SubName}</td>
                                              <td className="text-nowrap">{item.Description}</td>
                                              <td>{formatDate(item.UpdateDate)}</td>
                                               </tr>

                                          ))}
                                            {/* <tr>
                                                <td>1</td>
                                                <td>越南跟臺灣的飲食文化有哪些相異處？</td>
                                                <td className="text-nowrap">趙小一</td>
                                                <td>02/13</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>越南新住民媽媽如何面對家中經濟重擔？</td>
                                                <td className="text-nowrap">葉巧宜</td>
                                                <td>02/13</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>越南新住民媽媽如何適應臺灣交通問題？</td>
                                                <td className="text-nowrap">蔡沛軒</td>
                                                <td>02/13</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>越南新住民媽媽思念故鄉時有哪些方式能做為心靈寄託嗎？</td>
                                                <td>羅湘琳、趙小一、蔡沛軒、葉巧宜</td>
                                                <td>02/13</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">2-2 繪製探究架構</h5>
                                <div className="rounded-3 mx-auto p-3 imgborder text-center">
                                    <div className="d-flex justify-content-center align-items-start">
                                        <img src="./assets/img/ep/file_upload.png" alt="" />
                                    </div>
                                </div>
                                <hr className="horizontal dark" />

                                <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">我們在「階段2」遇到的困難：</div>

                                    {renderOpiniondiv('2-3 困難、解決與新學習', '前-遭遇困難')}
                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>

                                    {renderOpiniondiv('2-3 困難、解決與新學習', '前-解決方式')}
                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>

                                    {renderOpiniondiv('2-3 困難、解決與新學習', '前-學到的新思維')}
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{renderOpiniondiv('2-3 困難、解決與新學習', 'Pre24-修改時間')} </span>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                {renderOpiniondiv('2-3 困難、解決與新學習', '前-指導老師留言')}
                                <div className="pt-3 pb-2">
                                    <span className="fw-bolder text-second">日期：</span>
                                    <span>2023/02/20 下午 03:39:00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="pt-3 mt-4"></div>
            </div>

    
        </>
    );
};




export default OpinionSection;