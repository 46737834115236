import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import config from '../../config/config';
import { IExStudent } from '../../interfaces/ExStudent';
import { handleLink } from '../../modules/utils';
import { updateGmailExStudent, updateGmailExTeacher, updateiSchoolExStudent, updateiSchoolExTeacher} from '../../services/ExStuTeService';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { BSModal } from './bsmodal';

// 設定Modal的props
interface ModalProps {
    isOpen: boolean;
    setOpenBindMailModal: (val:boolean) => void;
}

export const BindMailModal: React.FC<ModalProps> = (props) => {
    const [loginStu,setLoginStu]=useState<IExStudent>();
    const [loginTeacher,setLoginTeacher]=useState<IExTeacher>();
    const [openModal,setOpenModal]=useState(false);
    const [errMsg,setErrMsg]=useState("");

    // 跳轉到Google登入頁面
    const BindGoogle=()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            handleLink(config.server.url+config.localAuth.signgooglein+"/2:"+loginTeacher?.Tid.toString());

        }
        else
        {
            handleLink(config.server.url+config.localAuth.signgooglein+"/1:"+loginStu?.Sid.toString());
        }
        console.log("BindGoogle");
    }

    const BindiSchool=()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            handleLink(config.server.url+config.localAuth.signntpcin+"/2:"+loginTeacher?.Tid.toString());

        }
        else
        {
            handleLink(config.server.url+config.localAuth.signntpcin+"/1:"+loginStu?.Sid.toString());
        }
        console.log("BindNTPC");
    }

    const unBindiSchool=async ()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(loginTeacher!==undefined)
            {
                loginTeacher.Gmail="";
                setLoginTeacher(loginTeacher);
                sessionStorage.setItem("LoginTeacher",JSON.stringify(loginTeacher));
                await updateiSchoolExTeacher(loginTeacher.Tid.toString(),"");
            }
        }
        else //學生登入
        {
            if(loginStu!==undefined)
            {
                loginStu.Gmail="";
                setLoginStu(loginStu);
                sessionStorage.setItem("LoginStudent",JSON.stringify(loginStu));
                await updateiSchoolExStudent(loginStu.Sid.toString(),"");
            }
        }
        handleLink("/");
    }  



    // 解除綁定Google
    const unBindGoogle=async ()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(loginTeacher!==undefined)
            {
                loginTeacher.Gmail="";
                setLoginTeacher(loginTeacher);
                sessionStorage.setItem("LoginTeacher",JSON.stringify(loginTeacher));
                await updateGmailExTeacher(loginTeacher.Tid.toString(),"");
            }
        }
        else //學生登入
        {
            if(loginStu!==undefined)
            {
                loginStu.Gmail="";
                setLoginStu(loginStu);
                sessionStorage.setItem("LoginStudent",JSON.stringify(loginStu));
                await updateGmailExStudent(loginStu.Sid.toString(),"");
            }
        }
        handleLink("/");
    }    

    // 載入登入資料 (學生或教師)
    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="0") return;
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(sessionStorage.getItem("LoginTeacher")!==null)
            {
                const lTea:IExTeacher=JSON.parse(sessionStorage.getItem("LoginTeacher")!);
                setLoginTeacher(lTea);
            }
        }
        else //學生登入
        {
            if(sessionStorage.getItem("LoginStudent")!==null)
            {
                const lStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!)
                setLoginStu(lStu);
            }    
        }
    },[])

    // 載入登入資料 (學生或教師)
    useEffect(()=>{
        if(props.isOpen)
        {
            if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
            {
                if(sessionStorage.getItem("LoginTeacher")!==null)
                {
                    const lTea:IExTeacher=JSON.parse(sessionStorage.getItem("LoginTeacher")!);
                    setLoginTeacher(lTea);
                }
            }
            else //學生登入
            {
                if(sessionStorage.getItem("LoginStudent")!==null)
                {
                    const lStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!)
                    setLoginStu(lStu);
                }
            }
            if(sessionStorage.getItem("ErrorBindGoogle")!==null)
            {
                const err:string=sessionStorage.getItem("ErrorBindGoogle")!;
                if(err.trim()!=="")
                {
                    setOpenModal(true);
                    setErrMsg(err);
                }
            }
            if(sessionStorage.getItem("ErrorBindiSchool")!==null)
            {
                const err:string=sessionStorage.getItem("ErrorBindiSchool")!;
                if(err.trim()!=="")
                {
                    setOpenModal(true);
                    setErrMsg(err);
                }
            }

        }
    },[props.isOpen])

    return  <Modal show={props.isOpen} onHide={()=>props.setOpenBindMailModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="modal-title">設定第三方平台登入</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-3 text-center'>
                    {
                        (sessionStorage.getItem("LoginStatus")==="2") ?
                        <>
                        {
                            ((loginTeacher?.Gmail===undefined) || (loginTeacher?.Gmail==="") ||(loginTeacher?.Gmail===null)) ?
                            <>
                                <h6 className="mb-0">尚未綁定 Google 帳號</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>BindGoogle()}>
                                    開始綁定「Google」帳號
                                </button>
                            </> :
                            <>
                                <h6 className="mb-0">綁定 Google 帳號:{loginTeacher?.Gmail}</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>unBindGoogle()}>
                                    解除綁定「Google」帳號
                                </button>
                            </>
                        }
                        <hr className="horizontal dark" />
                        {
                            ((loginTeacher?.iSchoolMail===undefined) || (loginTeacher?.iSchoolMail==="") || (loginTeacher?.iSchoolMail===null)) ?
                            <>
                                <h6 className="mb-0">尚未綁定親師生平台帳號</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>BindiSchool()}>
                                    開始綁定「親師生平台帳號」帳號
                                </button>
                            </> :
                            <>
                                <h6 className="mb-0">綁定親師生平台帳號:{loginTeacher?.iSchoolMail}</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>unBindiSchool()}>
                                    解除綁定「親師生平台帳號」帳號
                                </button>
                            </>

                        }
                        </>
                        :
                        <>
                            {((loginStu?.Gmail===undefined) || (loginStu?.Gmail==="") || (loginStu?.Gmail===null)) ?
                            <>
                                <h6 className="mb-0">尚未綁定 Google 帳號</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>BindGoogle()}>
                                    開始綁定「Google」帳號
                                </button>
                            </> :
                            <>
                                <h6 className="mb-0">綁定 Google 帳號:{loginStu?.Gmail}</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>unBindGoogle()}>
                                    解除綁定「Google」帳號
                                </button>
                            </>
                            }
                            <hr className="horizontal dark" />
                            {((loginStu?.iSchoolMail===undefined) || (loginStu?.iSchoolMail==="") || (loginStu?.iSchoolMail===null)) ?
                            <>
                                <h6 className="mb-0">尚未綁定親師生平台帳號</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>BindiSchool()}>
                                    開始綁定「親師生平台帳號」帳號
                                </button>
                            </> :
                            <>
                                <h6 className="mb-0">綁定親師生平台帳號:{loginStu?.iSchoolMail}</h6>
                                <button type="button" className="btn btn-round btn-outline-primary btn-sm w-100 mt-2 mb-0" onClick={()=>unBindiSchool()}>
                                    解除綁定「親師生平台帳號」帳號
                                </button>
                            </>
                            }

                        </>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={()=>props.setOpenBindMailModal(false)}>關閉</button>
                </Modal.Footer>
                <BSModal
                title={'提醒您'}
                isOpen={openModal}
                setOpenModal={setOpenModal}                
            >
                {errMsg}
                </BSModal>
            </Modal>

};