import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import IAdNews from '../../interfaces/AdNews';

interface ModalProps {
    title: string;
    isOpen: boolean;
    setOpenModal: (val:boolean) => void;
    currentNews:IAdNews;
  }
  
  export const NewsModal: React.FC<ModalProps> = (props) => {
      
    useEffect(()=>{
    let modalBody=document.getElementById('modalBody');
    if(modalBody!==null) modalBody.innerHTML=props.currentNews.Context!;
    console.log('in modal'+props.currentNews.Context)
    
    },[])

    useEffect(()=>{
        let modalBody=document.getElementById('modalBody');
        if(modalBody!==null) modalBody.innerHTML=props.currentNews.Context!;
        console.log('in modal'+props.currentNews.Context)
        
    },[props.isOpen])


  
    return <Modal show={props.isOpen} onHide={()=>props.setOpenModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="mx-auto">{props.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto' >
                    <small className="text-muted">{props.currentNews.SendDate}</small>
                    <div id="modalBody"></div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary m-0" onClick={()=>props.setOpenModal(false)}>關閉</button>
                </Modal.Footer>
            </Modal>

  };