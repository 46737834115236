import React, { useEffect, useState } from 'react'
import IAnsPP1 from '../../interfaces/AnsPP1';
import IAnsStatePP1 from '../../interfaces/AnsStatePP1';
import { IExWord } from '../../interfaces/ExWords';
import IPageProps from '../../interfaces/page'
import {  getLearnRecCurrVal, getLearnRecCurrValStr, getLearnRecVal, setLearnRecCurrVal, setLearnRecVal } from '../../modules/SessionTestCnt';
import { calcPercentage, genFerrisImageURL, handleLink } from '../../modules/utils';
import { AddExStuPP1, AddExStuRec } from '../../services/ExStuRecService';
import { getAnExWordByLevel, getAnExWord } from '../../services/ExWordsService';
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2'
import { useSysInfoContext } from '../../components/sysContext';


const PPTestPage1: React.FunctionComponent<IPageProps> = props => {
    const {sharedState} = useSysInfoContext();

    const redirToPPTest=()=>{
        AddExStuRec(
            getLearnRecCurrVal("userID"),
            getLearnRecCurrValStr("TType"),
            getLearnRecCurrValStr("TQno"),
            getLearnRecCurrVal("sCorrect"),
            getLearnRecCurrVal("sWrong"),
            getLearnRecCurrVal("sSecond"),)
            sessionStorage.setItem("LearnRecCurr","");
        handleLink('/pptest');
    }
    
    
    
    const pp1LearnRec={
        countPP1:getLearnRecVal("sCountPP1"),
        correctPP1:getLearnRecVal("sCorrectPP1"),
        wrongPP1:getLearnRecVal("sWrongPP1")        
    }

    const [AnsStatePP1,setAnsStatePP1]=useState<IAnsStatePP1>({
        correctPP1:0,
        wrongPP1:0,
        AnsListPP1:[]
    })

    const [AnsPP1,setAnsPP1]=useState<IAnsPP1>({
        Word:'',
        InpWord:''
    })

    const [activePP1,setActivePP1]=useState(false);
    const [hardLevel,setHardLevel]=useState('全部');
    const [reHardLevel,setReHardLevel]=useState('全部');
    const [ppExWord,setPPExWord]=useState<IExWord>({Words:''});
    const [ppInpAnswer1,setPPInpAnswer1]=useState('');
    const [totalSecs,setTotalSecs]=useState(0);
    const [entPP1,setEntPP1]=useState(true);

    const countUpString=():string=>{
        const secStr=pad2(totalSecs%60,false);
        const minStr=pad2(totalSecs/60,true);
        return minStr+":"+secStr;
        
    }

    const pad2=(val:number,dec:boolean):string=>{
        let valStr=val.toString();
        if(dec)
        {
            if(valStr.includes("."))
            {
                valStr=valStr.substring(0,valStr.indexOf(".")); 
            }
        }   
        if(valStr.length<2)
        {
            return "0"+valStr;
        }
        else
        {
            return valStr;
        }
    }

    const handlePP1=()=>{

        setActivePP1(true);
        if(activePP1){
            setTotalSecs(0);
        }
        getExWord();
        setReHardLevel(hardLevel);
    }

    const getExWord=async ()=>{
        let lvl=-1;
        switch(hardLevel)
        {
            case '三年級':
                lvl=3;
                break;
            case '四年級':
                lvl=4;
                break;
            case '五年級':
                lvl=5;
                break;
            case '六年級':
                lvl=6;
                break;
            case '全部':
                lvl=-1
                break;    
        }
        if (lvl===-1)
        {
            //setPPExWord(await getAnExWord());
            setAnsPP1({                
                Word:(await getAnExWord()).Words,
                InpWord:''
            })

        } else {
            setAnsPP1({
                Word:(await getAnExWordByLevel(lvl)).Words,
                InpWord:''
            })
        }
    }

    const handleReHardLevel=()=>{
        setHardLevel(reHardLevel);
        getExWord();
    }

    const handlePPInpAnswer1=(e:React.FormEvent<HTMLInputElement>)=>{        
        setAnsPP1({
            ...AnsPP1,
            InpWord:e.currentTarget.value
        })

    }

    const handleKeyDown=(e: React.KeyboardEvent<HTMLElement>)=>{        
        if(e.key==="Enter") handlePPAnswer1()
    }


    const handlePPAnswer1=()=>{
        if(AnsPP1.InpWord==="") return;
        AnsStatePP1.AnsListPP1.push(AnsPP1)
        setLearnRecCurrVal("sSceond",totalSecs);
        if(AnsPP1.Word.trim()===AnsPP1.InpWord)
        {
            setLearnRecCurrVal("sCorrect",AnsStatePP1.correctPP1+1);            
            setAnsStatePP1({                
                correctPP1:AnsStatePP1.correctPP1+1,
                wrongPP1:AnsStatePP1.wrongPP1,
                AnsListPP1: AnsStatePP1.AnsListPP1
            })
            setLearnRecVal("sCorrectPP1",pp1LearnRec.correctPP1+1);
            AddExStuPP1(Number(sessionStorage.getItem("LoginID")),AnsPP1.Word,AnsPP1.InpWord,1);
        }
        else
        {
            setLearnRecCurrVal("sWrong",AnsStatePP1.wrongPP1+1);            
            setAnsStatePP1({
                correctPP1:AnsStatePP1.correctPP1,
                wrongPP1:AnsStatePP1.wrongPP1+1,
                AnsListPP1: AnsStatePP1.AnsListPP1
            })
            setLearnRecVal("sWrongPP1",pp1LearnRec.wrongPP1+1)
            AddExStuPP1(Number(sessionStorage.getItem("LoginID")),AnsPP1.Word,AnsPP1.InpWord,0);            
        }
        if(entPP1)
        {
            setLearnRecVal("sCountPP1",pp1LearnRec.countPP1+1)
            setEntPP1(false);
        }

        // Get a new word 
        getExWord();
        setReHardLevel(hardLevel);

    }

    useEffect(()=>{
        if(activePP1)
        {
            setTimeout(()=>{
            setTotalSecs(totalSecs+1)
            },1000)
        }
        console.log("in PPtest1:"+sharedState)

    }
    ,[totalSecs,activePP1]);
    
 
    return (
    <>  
        <div style={{backgroundColor:'#FCF9F3'}}>
            <div><TopNavPage2/></div>
            <div className="container d-flex flex-column flex-md-row align-items-center mb-2">
                <div className="d-flex align-items-start align-items-sm-center py-2 order-2 order-md-1">
                    <img src="./assets/img/PP-01.svg" className="me-2" style={{width:'80px'}}/>
                    <div>
                        <p className="mb-1">依照隨機出現的題目，在格子裡輸入相同的字詞。</p>
                        {
                            (sessionStorage.getItem("LoginStatus")==="0") || (sessionStorage.getItem("LoginStatus")===null) || (sessionStorage.getItem("LoginStatus")==="2") ?
                            <div>
                                <span className="badge badge-danger">您未登入，故不會儲存本次練習結果。</span>
                            </div> :
                       
                            <div>
                                <span className="badge badge-secondary">學習次數：<>{pp1LearnRec.countPP1}</></span>
                                <span className="badge badge-secondary">答對字詞數：<>{pp1LearnRec.correctPP1}</></span>
                                <span className="badge badge-secondary">答錯字詞數：<>{pp1LearnRec.wrongPP1}</></span>
                            </div>
                        }
                    </div>
                </div>
                <div className="ms-auto order-1 order-md-2 my-3">
                    <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>redirToPPTest()}>
                        <i className="fas fa-chevron-left"></i>
                        返回 PP 基礎讀寫
                    </button>
                </div>
            </div>
        </div>
        
        <div className="section pt-0">
            <div className="container">
                {!activePP1 &&
                <div id="info">
                    <div  className="text-center d-flex justify-content-center align-items-center" style={{minHeight:'300px'}}>
                    <div className="card w-lg-50">
                        <div className="card-header">
                            <h5>請先選擇字詞的「年級」</h5>
                        </div>
                        <div className="card-body">
                            <div className="nav-wrapper position-relative end-0 mb-5">
                                <ul className="nav nav-pills nav-fill p-1 flex-column flex-lg-row" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="ee" aria-selected="true" onClick={()=>setHardLevel('全部')}>
                                            全部
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#" role="tab" aria-controls="aa" aria-selected="false" onClick={()=>setHardLevel('三年級')}>
                                            三年級
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="bb" aria-selected="false" onClick={()=>setHardLevel('四年級')}>
                                            四年級
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="cc" aria-selected="false" onClick={()=>setHardLevel('五年級')}>
                                            五年級
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="dd" aria-selected="false" onClick={()=>setHardLevel('六年級')}>
                                            六年級
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <button type="button" className="btn btn-round btn-primary btn-lg" onClick={()=>handlePP1()}>開始測驗</button>
                        </div>
                    </div>
                    </div>
                </div>
                }              
                {activePP1 &&
                <div id="start" >
                    <div className="row">
                        <div className="col-12 col-lg-9">
                            <div className="card">
                                <div className="card-body">
                                    <h1 className="test-word mt-0 text-center"><>{AnsPP1.Word}</></h1>
                                    <input type="text" value={AnsPP1.InpWord} onChange={handlePPInpAnswer1} className="form-control form-control-lg text-center mt-3"
                                      onPaste={(e)=>{
                                        e.preventDefault()
                                        return false;
                                      }}
                                      onCopy={(e)=>{
                                        e.preventDefault()
                                        return false;
                                      }}
                                      onKeyDown={handleKeyDown}/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center flex-column">
                                <button type="button" className="btn btn-round btn-primary btn-lg my-3 px-5 w-fc" onClick={()=>handlePPAnswer1()}>確定</button>
                                <button type="button" className="btn btn-link" onClick={()=>handleLink('/pptest')}>結束練習</button>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="card bg-light">
                                <div className="card-body">
                                <button className="btn btn-secondary w-100 mb-0" data-bs-toggle="modal" data-bs-target="#lvModal">{hardLevel}<br/>（重選難易度）</button>
                                    <div className="d-flex text-center">
                                        <div className="flex-fill mx-1">
                                            <h5 className="font-weight-bolder mb-0 mt-3"><>{AnsStatePP1.correctPP1}</></h5>
                                            <span className="text-sm">答對</span>
                                        </div>
                                        <div className="flex-fill">
                                            <h5 className="font-weight-bolder mb-0 mt-3"><>{AnsStatePP1.wrongPP1}</></h5>
                                            <span className="text-sm">答錯</span>
                                        </div>
                                        <div className="flex-fill">
                                            <h5 className="font-weight-bolder mb-0 mt-3"><>{calcPercentage(AnsStatePP1.correctPP1,AnsStatePP1.wrongPP1,1)}</><sub className="ps-1">%</sub></h5>
                                            <span className="text-sm">答對率</span>
                                        </div>
                                    </div>
                                    <h5 className="font-weight-bolder text-center mt-4"><span className="material-icons-outlined align-top me-2">alarm</span>{countUpString()}</h5>
                                    <hr className="horizontal dark"/>
                                    <div className="row">
                                        <div className="col-12 accordion" id="accordionRental">
                                            <div className="accordion-item mb-3">
                                                <h5 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button text-dark collapsed px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{width:'auto'}}>
                                                        <h5 className="my-0 me-2 p-2">已練習的字詞</h5>
                                                        <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                        <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                    </button>
                                                </h5>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionRental" style={{}}>
                                                    <div className="row">
                                                        {
                                                            AnsStatePP1.AnsListPP1.slice(0).reverse().map((item,i)=>{
                                                                if(item.Word.trim()===item.InpWord)
                                                                {
                                                                    return(
                                                                        <div className="col-12 col-md-4 col-lg-12 my-1">
                                                                        <div className="badge badge-circle badge-success">
                                                                            <i className="ni ni-check-bold"></i>
                                                                        </div>
                                                                        {item.Word}</div> 
                                                                    )
                                                                }
                                                                else
                                                                {
                                                                    return(
                                                                        <div className="col-12 col-md-4 col-lg-12 my-1">
                                                                        <div className="badge badge-circle badge-danger">
                                                                            <i className="fas fa-times" aria-hidden="true"></i>
                                                                        </div>
                                                                        {item.InpWord}<span className="text-danger">（{item.Word}）</span>
                                                                    </div>
                                                                    )
                                                                }



                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(sessionStorage.getItem("LoginStatus")==="1") &&
                                        <>
                                        <hr className="horizontal dark mt-3"/>
                                        <div className="col-12 d-flex flex-column flex-sm-row flex-lg-column align-items-center">
                                            <img src={genFerrisImageURL(AnsStatePP1.correctPP1,0,30)} className="w-100 w-sm-50 w-lg-100"/>
                                            答對30題，晉一級並點亮一個車廂，最高為六級。
                                        </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>            
        </div>
        <FooterPage />
        {/*<!-- 難易度 -->*/}
        <div className="modal fade" id="lvModal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title">重選年級</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body pb-3">
                <ul className="nav nav-pills nav-fill p-1 flex-column flex-lg-row" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-controls="ee" aria-selected="true" onClick={()=>setReHardLevel('全部')}>
                            全部
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#" role="tab" aria-controls="aa" aria-selected="false" onClick={()=>setReHardLevel('三年級')}>
                            三年級
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="bb" aria-selected="false" onClick={()=>setReHardLevel('四年級')}>
                            四年級
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="cc" aria-selected="false" onClick={()=>setReHardLevel('五年級')}>
                            五年級
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" role="tab" aria-controls="dd" aria-selected="false" onClick={()=>setReHardLevel('六年級')}>
                            六年級
                        </a>
                    </li>
                </ul>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>handleReHardLevel()}>確定</button>
                </div>
            </div>
            </div>
        </div>
    </>
    );
}

export default PPTestPage1;