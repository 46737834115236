import React from 'react'
import IAnsQDP from '../../../interfaces/AnsQDP';
import { genFerrisImageURL } from '../../../modules/utils';

interface IDispDPAnsProps {
    ansQDP:IAnsQDP[]
}

const DispDPAns: React.FunctionComponent<IDispDPAnsProps> = props => {
    return (
                <>
                <div className="card bg-light mt-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <div className="accordion" id="accordionRental">
                                <div className="accordion-item mb-3">
                                    <div className="d-flex justify-content-between align-items-center accordion-header" id="headingOne">
                                    <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{width:'auto'}}>
                                        <h5 className="my-0 me-2 p-3">參考答案</h5>
                                        <i className="collapse-close fas fa-angle-down pt-2 position-absolute end-0 fs-22"
                                        aria-hidden="true"></i>
                                        <i className="collapse-open fas fa-angle-up pt-2 position-absolute end-0 fs-22"
                                        aria-hidden="true"></i>
                                        </button>
                                        {/*<a href="#" className="btn btn-outline-secondary btn-round text-nowrap mb-0" type="button">
                                        下載全部答案
                                        </a> */}
                                    </div>
                                    
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionRental">
                                    <>
                                        {
                                        props.ansQDP.map((data,i)=>{
                                
                                                return <>
                                                <div className="bg-gray-300 border-radius-lg p-3 my-4" >
                                                    <div className="text-sm font-weight-bold pt-2">{data.Qno}.{data.Question}</div>
                                                    <hr className="horizontal dark"/>
                                                    <div className="text-secondary text-sm font-weight-bold mt-3">您寫的答案</div>
                                                    {
                                                        data.InpAnsList.map(inpans=>{
                                                            return <div>{inpans}</div>
                                                        })
                                                    }
                                                    <div className="text-secondary text-sm font-weight-bold mt-3">參考答案</div>
                                                    {
                                                        data.AnsList.map((ans,idx)=>{
                                                            return (
                                                                ((data.Type!=="單選") && (data.Type!=="多選"))) ?
                                                                <div>{ans}</div> :
                                                                (data.InpAnsList.includes(ans)) ?
                                                                <div>{ans}</div> :
                                                                <div className="text-danger">{ans}</div>
                                                        })
                                                    }
                                                </div>
                                                </>                                                                              
                                                                                    
                                        })                                    
                                        }                                
                                    </>                            
                                    </div>

                                </div>
                            </div>
                        </div>
                        {(sessionStorage.getItem("LoginStatus")==="1") &&
                        <div className="col-12 col-lg-4">
                            <hr className="horizontal dark mt-3 d-lg-none"/>
                            <div className="col-12 d-flex flex-column flex-sm-row flex-lg-column align-items-center">
                            <img src={genFerrisImageURL(Number(sessionStorage.getItem("DPCnt")),4,3)} className="w-100 w-sm-50 w-lg-100"/>
                            完成3篇進階任務，晉一級並點亮一個車廂，最高為六級。
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            </>
    );
}

export default DispDPAns;