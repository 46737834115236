import React, { useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page'
import TopNavPage2 from '../topNav2';
import FooterPage from '../footer';
import { IExTeacher } from '../../interfaces/ExTeacher';



const TeacherAdminPage: React.FunctionComponent<IPageProps> = props => {
  const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();
    useEffect(() => {
      if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
      {
        if (sessionStorage.getItem("LoginTeacher") !== null) {
          const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
          setLoginTeaacher(lTea);
        }
        sessionStorage.setItem("LoginStudent","");
      }
    }, []);
    const resetStu=()=>{
      sessionStorage.setItem("LoginStudent","");
    }
    return (
    <>
      <TopNavPage2/>
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">教師專區</h5>
            <div className="mx-auto ms-auto me-md-0 text-center">
              <a href="" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active" type="button">
                操作說明</a>
              <a href="/teacherstumgn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                type="button" >
                學生管理</a>
              <a href="/teacherstulearn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                type="button" >
                學習記錄</a>
              <a href="/teacherstustat" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                type="button">
                學習統計</a>
              <a href="/teacherstulogin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                type="button">
                登入記錄</a>
            </div>
          </div>
        
          <div className=" bg-primary border-radius-md p-4 mx-auto mt-4" style={{maxWidth: '750px', boxShadow: '0px 0px 10px 5px #d7d7d7'}}>
            <h4 className="text-center text-white fw-bolder">操作說明</h4>
            <hr className="horizontal white"/>
            <div className="text-white">
              <div className="text-start my-4">
                <ol className="mx-auto my-4">
                  <li>您的「教師代碼」(<span id="LabelTid2">{loginTeacher?.Tid}</span>)由系統產生，不能更改。</li>
                  <li>教師可以同時產生多個「學生登入號」。</li>
                  <li>您也可以使用學生自行申請後開放給您的「學生登入號」。</li>
                  <li>學生學習依路徑可分為：
                    <ol type="i">
                      <li>不登入的學習：學習記錄不會呈現。</li>
                      <li>學生自主學習：可查看學習記錄。</li>
                    </ol>
                  </li>
                  <li>縣市單一簽入平台：
                    <ol type="i">
                      <li>首次由「縣市單一簽入平台」登入，會帶入基本資料，並由系統產生「教師代碼」。</li>
                      <li>非學生的角色皆設定為「教師」。</li>
                      <li>可由教師產生學生登入號後，再輸入「縣市單一簽入平台」的學生Email綁定。</li>
                      <li>可由學生從「縣市單一簽入平台」自動產生「學生登入號」後，再開放給老師。</li>
                      <li>帳號綁定後，即可直接從親師生平台登入數位讀寫網。</li>

                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <FooterPage/>
    </>
  )
}

export default TeacherAdminPage