import React, { useEffect, useState } from 'react'
import IPageProps from '../interfaces/page'
import FooterPage from './footer';
import TopNavPage1 from './topNav1';
import DPDeepPage from './dptest/dpdeep';
import DPTextPage from './dptest/dptext';
import DPSDGsPage from './dptest/dpsdgs';
import DPInterPage from './dptest/dpinter';
import { handleLink } from '../modules/utils';
import { getExStuTestCntBySid } from '../services/ExStuRecService';
import { getUpdExStuLearnRec } from '../services/ExStuTeService';

const DPTestPage: React.FunctionComponent<IPageProps> = props => {
    const [activePage,setActivePage]=useState([true,false,false,false]);
    const [activePageID,setActivePageID]=useState(0);

    const handleActivePage=(pnum:number)=>{
        for(let i=0;i<activePage.length;i++) activePage[i]=false
        activePage[pnum]=true;
        setActivePage(activePage);
        setRefresh(true)
        sessionStorage.setItem("DPPageID",pnum.toString());
        setActivePageID(pnum); 
 
    }
    const [refresh,setRefresh]=React.useState(false);

    useEffect(()=>{
        if(refresh) setRefresh(false);
    },[refresh])

    useEffect(()=>{
        setActivePageID(Number(sessionStorage.getItem("DPPageID")))
        for(let i=0;i<activePage.length;i++) activePage[i]=false
        activePage[Number(sessionStorage.getItem("DPPageID"))]=true;
        if(sessionStorage.getItem("LoginStatus")==='1')
        {
            getUpdExStuLearnRec(sessionStorage.getItem("LoginID")!).then(data=>{
            sessionStorage.setItem("LearnRec",JSON.stringify(data));
            })
        }

    },[])

    return (
        <>
            <TopNavPage1/>
            <div className="section pt-3">
                <div className="container">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <h5 className="title mb-3 me-3">深度讀寫<small className="ms-2">DP,Deep Process</small></h5>
                        <div className="mx-auto ms-sm-auto me-sm-0">
                            <button className="btn btn-outline-secondary btn-round text-nowrap me-3" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                DP 說明
                            </button>
                            <a className="btn btn-outline-secondary btn-round text-nowrap" type="button" onClick={()=>handleLink('/dpref')}>
                                參考資料
                            </a>
                        </div>
                    </div>
                    <div className="collapse" id="collapseExample">
                        <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                        <img src="./assets/img/DP-00.svg" className="me-2" style={{width: '200px'}}/>
                        <h5 className="description text-start mb-5">
                            <p>深度閱讀(DP)的目的，一是擴展讀者的閱讀廣度，一是透過題目幫助讀者閱讀兩篇或是以上的文章後，找出其間的關聯。</p>
                            <p>如兩篇文章的觀點是互補還是互斥、各自支持觀點的理由是否合理，最後形成自己的觀點。</p>
                            <p>所回答的問題，要言之有理，沒有絕對的標準答案，各題的參考答案為的是幫助你思考，你可以不同意。</p>
                            <p>【課程主題延伸】題組係由「IEA國際閱讀素養調查2021研究（PIRLS
                            2021）委辦計畫張郁雯教授團隊」所建置，原架設於數位閱讀學習平台，因配合資安規範、網站減量及資源整合等政策，自2022年7月1日起轉移至本網站。</p>
                            <p>【課程主題延伸】共31題組，以學科領域閱讀為主，輔以社會情緒表達、人際關係、生命教育等議題納入命題元素，以促進自主學習為目標。此外尚有ePIRLS中文樣題「原住民文化」，提供師生練習。</p>
                            <p>【SDGs】2015年，聯合國宣布了「2030永續發展目標」（Sustainable Development Goals,
                            SDGs），SDGs包含17項核心目標，其中又涵蓋了169項細項目標、230項指標，指引全球共同努力、邁向永續。</p>
                            <div className="text-center">
                                <img src="./assets/img/dp/SdgsAll.png" alt="" className="w-100" style={{maxWidth: '600px'}}/>
                            </div>
                        </h5>
                        </div>
                    </div>

                    <div className="nav-wrapper position-relative z-index-2">

                        {/*<div className='text-center' >
                            <div className='row g-2'>
                                <div className='col'>
                                    <button type="button" className={["btn w-100", activePage[0]?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleActivePage(0)} ><i className="ni ni-app me-2"></i>多文本題組</button>                                   
                                </div>
                                <div className='col'>
                                    <button type="button" className={["btn w-100", activePage[1]?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleActivePage(1)} ><i className="ni ni-album-2 me-2"></i>課文主題延伸</button>
                                </div>
                                <div className='col'>
                                    <button type="button" className={["btn w-100", activePage[2]?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleActivePage(2)} ><i className="ni ni-atom me-2"></i>SDGs</button>
                                </div>
                                <div className='col'>
                                    <button type="button" className={["btn w-100", activePage[3]?'btn-primary':'btn-outline-secondary'].join(' ')} onClick={()=>handleActivePage(3)} ><i className="ni ni-world-2 me-2"></i>國際閱讀評量</button>
                                </div>

                            </div>
                        </div> */}



                        {/*<Nav variant="tabs" fill >
                            <Nav.Item>
                                <Nav.Link className='mb-0' active={activePage[0]} onClick={()=>handleActivePage(0)}><i className="ni ni-app me-2"></i>多文本題組</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link className='mb-0' active={activePage[1]} onClick={()=>handleActivePage(1)}><i className="ni ni-album-2 me-2"></i>課文主題延伸</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link className='mb-0'active={activePage[2]} onClick={()=>handleActivePage(2)}><i className="ni ni-atom me-2"></i>SDGs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link className='mb-0'active={activePage[3]} onClick={()=>handleActivePage(3)}><i className="ni ni-world-2 me-2"></i>國際閱讀評量</Nav.Link>
                            </Nav.Item>
                        </Nav> */}

                        <ul className="nav nav-pills nav-fill flex-wrap flex-md-nowrap" id="dp" role="tablist">
                            <li className="nav-item">
                            <a className={(activePage[0])? "nav-link mb-0 active":"nav-link mb-0"} id="deep-tab" data-bs-toggle="tab" href="#deep" role="tab"
                            aria-controls="deep" aria-selected={activePage[0]} onClick={()=>handleActivePage(0)}>
                            <i className="ni ni-app me-2"></i>多文本題組
                            </a>
                            </li>
                            <li className="nav-item">
                            <a className={(activePage[1])? "nav-link mb-0 active":"nav-link mb-0"} id="text-tab" data-bs-toggle="tab" href="#text" role="tab" aria-controls="text"
                            aria-selected={activePage[1]} onClick={()=>handleActivePage(1)}>
                            <i className="ni ni-album-2 me-2"></i>課文主題延伸
                            </a>
                            </li>
                            <li className="nav-item">
                            <a className={(activePage[2])? "nav-link mb-0 active":"nav-link mb-0"} id="sdgs-tab" data-bs-toggle="tab" href="#sdgs" role="tab" aria-controls="sdgs"
                            aria-selected={activePage[2]} onClick={()=>handleActivePage(2)}>
                            <i className="ni ni-atom me-2"></i>SDGs
                            </a>
                            </li>
                            <li className="nav-item">
                            <a className={(activePage[3])? "nav-link mb-0 active":"nav-link mb-0"} id="inter-tab" data-bs-toggle="tab" href="#inter" role="tab" aria-controls="inter"
                            aria-selected={activePage[3]} onClick={()=>handleActivePage(3)}>
                            <i className="ni ni-world-2 me-2"></i>國際閱讀評量
                            </a>
                            </li>
                        </ul> 
                        <div>
                            {activePage[0]&&<DPDeepPage/>}
                            {activePage[1]&&<DPTextPage/>}
                            {activePage[2]&&<DPSDGsPage/>}
                            {activePage[3]&&<DPInterPage/>}
                        </div>
                    </div>
                </div>    
            </div>
            <FooterPage/>

        </>
    );
}

export default DPTestPage;

