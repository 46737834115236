import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'

interface ModalProps {
    title: string;
    isOpen: boolean;
    setOpenModal: (val:boolean) => void;
    objectURL:string;
  }
  
  export const ObjectModal: React.FC<ModalProps> = (props) => {
      
 
    return <Modal show={props.isOpen} onHide={()=>props.setOpenModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="mx-auto">{props.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center pb-3 px-0' style={{ height: '90vh', maxHeight: '700px', overflowY: 'auto'}} >
                <iframe 
                    src={props.objectURL} 
                    width="100%" 
                    height="800px" 
                    title="pdf-viewer"
                ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary m-0" onClick={()=>props.setOpenModal(false)}>關閉</button>
                </Modal.Footer>
            </Modal>

  };