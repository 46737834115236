/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import IFunDataPart from '../../interfaces/FunDataPart';
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils';
import { getFunDataPartByYr } from '../../services/FunDSService';
import TopNavPage1 from '../topNav1';
import e from 'express';
import { set } from 'date-fns';

const EpFunPage: React.FunctionComponent<IPageProps> = props => {
    const [fundata,setFundata]= useState<IFunDataPart[]>([])
    const [funDataYr,setFunDataYr]=useState<string>('*');

    const getStus=(item:IFunDataPart):string=>{
        let result=''
        if(item.StuName1!==null) result=result+item.StuName1
        if(item.StuName2!==null) result=result+item.StuName2
        if(item.StuName3!==null) result=result+item.StuName3
        if(item.StuName4!==null) result=result+item.StuName4
        return result
    }

    const getFunDataPart=(yr:string)=>{
        getFunDataPartByYr(yr).then(data=>setFundata(data))
        setFunDataYr(yr);
    }

   /* const equalCardHeight=()=>{
        const cards=document.getElementsByClassName("card");
        let maxHeight=0;
        for(let i=0;i<cards.length;i++)
        {
            if(cards[i].clientHeight>maxHeight) maxHeight=cards[i].clientHeight;
        }
        for(let i=0;i<cards.length;i++)
        {
            cards[i].setAttribute("style","height:"+maxHeight+"px");
        }
    }*/


    const handleFunText=(gno:string)=>{
        sessionStorage.setItem("GroupNo",gno);
        handleLink("/funtext");
    }
   // equalCardHeight();

    useEffect(()=>{
        getFunDataPartByYr('*').then(data=>setFundata(data))

    },[])


    return (
        <>
        <TopNavPage1/>
        <div className="section pt-3 pt-lg-5">
            <div className="container px-3 px-md-4">
                <div className="d-flex align-items-center flex-column flex-md-row">
                    <h5 className="title mb-3 me-3">青春研值</h5>
                    <div className="mx-auto ms-auto me-md-0">
                        <button className="btn btn-outline-secondary btn-round text-nowrap me-3" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            青春研值 說明</button>
                    </div>
                </div>    
                <div className="collapse" id="collapseExample">
                    <div className="d-flex flex-column flex-lg-row align-items-center">
                        <img src="./assets/img/EP-00.svg" alt="" className="me-2" style={{width:'200px'}}/>
                        <h5 className="description text-start text-justify mb-0" style={{maxWidth:'800px'}}>
                            <p>本專欄的構想由柯華葳教授所倡議，邀請歷屆參加數位閱讀專題探究競賽(EP)獲獎的隊伍參加，將原本以學術報告格式書寫的作品，
                            改寫成平易近人的科普文章，讓中小學生探究的作品被看見、被討論，改變社會的觀點，鼓勵更多學生線上探索和研究。</p>
                            <p className="mb-0">專欄命名為「青春研值」，以呼應柯教授給中小學生的勉勵：「叫人不小看你年輕！」</p>
                        </h5>
                    </div>
                </div>
                <div className="text-center"><img src="./assets/img/fun/Funbanner3.png" alt="" className="w-100" style={{maxWidth:'600px'}}/></div>
                <div className="dropdown my-4">
                    <button type="button" id="year" className="btn text-second bg-second dropdown-toggle mb-0" style={{width:'160px'}}
                    data-bs-toggle="dropdown" aria-expanded="false">{(funDataYr==="*") ? <>全部學年度</>:funDataYr}
                    </button>
                    <ul className="dropdown-menu px-2 py-3" style={{left:'auto!important'}} aria-labelledby="year">
                    <li><a className="dropdown-item d-flex" onClick={()=>getFunDataPart('*')}>全部年度</a></li>
                        <li><a className="dropdown-item d-flex"  onClick={()=>getFunDataPart('112年度')}>112年度</a></li>
                        <li><a className="dropdown-item d-flex"  onClick={()=>getFunDataPart('111年度')}>111年度</a></li>
                        <li><a className="dropdown-item d-flex"  onClick={()=>getFunDataPart('110年度')}>110年度</a></li>
                        <li><a className="dropdown-item d-flex"  onClick={()=>getFunDataPart('109年度')}>109年度</a></li>
                        <li><a className="dropdown-item d-flex"  onClick={()=>getFunDataPart('108年度')}>108年度</a></li>
                    </ul>
                </div>
                <div className="row mt-4">
                    {(fundata.length>0) ?
                        fundata.map(item=>{
                            return <>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="card mb-3" style={{height:'420px'}} onClick={()=>handleFunText(item.Groupno)}>
                                        <img className="card-img-top" alt="" src={"./assets/img/fun/"+item.Groupno+".jpg"}/>
                                        <div className="card-body text-justify">
                                            <h5 className="text-clamp2 text-dblue fw-bolder">{item.Title}</h5>
                                            <p className="text-second mb-2 text-sm">{item.School}</p>
                                            <p className="text-clamp3 lh-sm mb-2">
                                                {item.Abstract}
                                            </p>
                                            <div className="text-sm text-second text-end" style={{wordBreak: 'keep-all'}}>
                                                <span className="me-2">―</span>{getStus(item)}
                                            </div>
                                            <div className="text-end mt-2">
                                                {/*<a href={"/fundata/"+item.Groupno} className="text-primary icon-move-right">繼續閱讀 * */}
                                                <a onClick={()=>handleFunText(item.Groupno)} className="text-primary icon-move-right">繼續閱讀
                                                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }): <>尚無資料!!</>

                    }
                </div>
            </div>            

        </div>
        </>
    );
}

export default EpFunPage;