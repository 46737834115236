import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExDPWeb } from '../interfaces/ExDPWeb';
import { IExDPWebTab } from '../interfaces/ExDPWebTab';



export const getExDPWebTabs=(ArticleNo:string)=>{
    const url=config.server.url+`/api/ExDPWeb/tabs/no/${ArticleNo}`
    return axios.get(url).then((res)=>{
        const rest:IExDPWebTab[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExDPWeb=(ArticleNo:string,PageNo:number)=>{
    const url=config.server.url+`/api/ExDPWeb/no/${ArticleNo}/pno/${PageNo}`
    return axios.get(url).then((res)=>{
        const rest:IExDPWeb[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExDPWebs=(ArticleNo:string)=>{
    const url=config.server.url+`/api/ExDPWeb/no/${ArticleNo}`
    return axios.get(url).then((res)=>{
        const rest:IExDPWeb[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



