import React, {useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import {  getLoginInfo, } from './tool/helper';
import { IGroupForExpertS, IGroupInfo,  } from '../../interfaces/EpModal';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import ExpertFunctionBar from './component/expertFunctionBar';
import { getAllDrGroup } from '../../services/EP/EpExpertService';
import { PageIndexX } from '../../components/pageIndexX';

// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpExpertGroup: React.FunctionComponent<IPageProps> = () => {
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();

    const [groupForExpertList, setGroupForExpertList] = useState<IGroupForExpertS[]>();
    /** 下拉式選單 */
    const [selectSectionList, setSelectSectionList] = useState<IGroupForExpertS[]>();
    const [groupForExpertDisplayList, setGroupForExpertDisplayList] = useState<IGroupForExpertS[]>([]);
    const [selectedClassName, setSelectClassName] = useState<string>();
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [selectedSchoolName, setSelectedSchoolName] = useState<string>('(全部學校)');
    const [displayAmount, setDisplayAmount] = useState<number>(20);
    /**下拉選單 【班級】*/
    const [classNameList, setClassNameList] = useState<string[]>([]);
    /** 下拉選單 【小組】*/
    const [groupList, setGroupList] = useState<string[]>([]);
    /** 下拉選單 【學校】 */
    const [schoolNameList, setSchoolNameList] = useState<string[]>([]);

    const [lCurrentPage1, setLCurrentPage1] = useState(1);
    useEffect(() => {
        fetchdataGroupInfo();
    }, []);

    // 選擇的變動時開始塞選
    useEffect(() => {
        setFilterData();
    }, [selectedClassName, selectedGroup, selectedSchoolName]);

    const setFilterData = (target?: string) => {
        let result: IGroupForExpertS[] = groupForExpertList || [];
        // 處理年度
        result =
            groupForExpertList?.filter((item) => {
                return selectedClassName?.trim() === item.Class.trim();
            }) || [];
        if (selectedGroup !== '全部組別') {
            result = result.filter((item) => {
                return selectedGroup?.trim() === item.Groupname.trim();
            });
        }

        if (selectedSchoolName !== '(全部學校)' && selectedSchoolName && selectedSchoolName.length > 0) {
            result = result.filter((item) => {
                return selectedSchoolName?.trim() === item.Schoolname.trim();
            });
        }

        // 處理

        setGroupForExpertDisplayList(result);
    };

    const fetchdataGroupInfo = async () => {
        /** 取得登入資料 */
        let currentLoginInfo = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);

        try {
            let classNameList: string[] = [];
            let groupNameList: string[] = [];
            // groupNameList.push('全部組別');
            let schoolNameList: string[] = [];
            let rsp = (await getAllDrGroup()) as IGroupForExpertS[];
            console.log('rsp', rsp);
            // 處理下拉式選單
            rsp.forEach((item) => {
                if (!classNameList.includes(item.Class)) {
                    // 設定年度
                    classNameList.push(item.Class);
                }
                if (!groupNameList.includes(item.Groupname)) {
                    groupNameList.push(item.Groupname);
                }
                if (!schoolNameList.includes(item.Schoolname)) schoolNameList.push(item.Schoolname);
            });
            setClassNameList(classNameList);
            setSelectClassName(classNameList[0]);
            setGroupList(groupNameList);
            setSelectedGroup(groupNameList[0]);
            setSchoolNameList(schoolNameList);
            setSelectedGroup('全部組別');

            setGroupForExpertList(rsp);
            console.log('取得專家的隊伍', rsp);
        } catch (error) {}
        // const groupInfo
    };

    useEffect(() => {
        getGroupNoList();
    }, [selectedClassName]);


    const getGroupNoList = () => {
        let result: IGroupForExpertS[] =
            groupForExpertList?.filter((item) => {
                return item.Class == selectedClassName;
            }) || [];

        setSelectSectionList(result);
        setSelectedGroup('全部組別');
        setSelectedSchoolName('(全部學校)');
    };

    const renderSchoolOptions = () => {
        const uniqueSchools = [...new Set(selectSectionList?.map((item) => item.Schoolname))];

        return uniqueSchools.map((school) => (
            <li>
                <div className="dropdown-item sel-active" onClick={() => setSelectedSchoolName(school)}>
                    {school}
                </div>
            </li>
        ));
    };
    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>


                        <ExpertFunctionBar />
                    </div>
                    <div className="text-center h5 mt-4 mb-3">小隊基本資料查詢</div>

                    {/* {JSON.stringify(groupForExpertDisplayList)} */}
                    <div className="d-flex flex-wrap justify-content-center align-items-center mb-4">
                        <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                            <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                                {selectedClassName}
                            </button>
                            <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                {classNameList.map((item) => (
                                    <li>
                                        <div className="dropdown-item sel-active" onClick={() => setSelectClassName(item)}>
                                            {item}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                            <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                                {selectedGroup}
                            </button>
                            <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <div className="dropdown-item sel-active">
                                        <b>全部組別</b>
                                    </div>
                                </li>
                                {selectSectionList?.map((item) => (
                                    <li>
                                        <div className="dropdown-item sel-active" onClick={() => setSelectedGroup(item.Groupname)}>
                                            {item.Groupname}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                            <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                                {selectedSchoolName}
                            </button>
                            <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <div className="dropdown-item sel-active" onClick={() => setSelectedSchoolName('(全部學校)')}>
                                        <b>(全部學校)</b>
                                    </div>
                                </li>

                                {renderSchoolOptions()}
                            </ul>
                        </div>
                    </div>

                    <div className="card p-4 pt-3">
                        共 {groupForExpertDisplayList?.length} 筆
                        <div className="d-flex align-items-end ms-auto">
                            <div className="text-second">顯示筆數</div>
                            <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                                <button className="btn dropdown-toggle fs-16 mb-0 py-0 px-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {displayAmount} 筆
                                </button>
                                <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                    {[
                                        { display: '10筆', number: 10 },
                                        { display: '20筆', number: 20 },
                                        { display: '50筆', number: 50 },
                                        { display: '100筆', number: 100 },
                                        { display: '全部', number: null }
                                    ].map((item) => (
                                        <li>
                                            <div className="dropdown-item sel-active" onClick={() => setDisplayAmount(item.number || 1000)}>
                                                {item.display}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="table-responsive py-3">
                            <table className="text-sm table table-striped mb-0">
                                <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                        <th className="p-2 text-nowrap">No.</th>
                                        <th className="p-2 text-nowrap">回饋組別</th>
                                        <th className="p-2 text-nowrap">小隊編號</th>
                                        <th className="p-2 text-nowrap">小隊名稱</th>
                                        <th className="p-2 text-nowrap">學校</th>
                                        <th className="p-2 text-nowrap">縣市</th>
                                        <th className="p-2 text-nowrap">鄉鎮區</th>
                                        <th className="p-2 text-nowrap">題目</th>
                                        <th className="p-2 text-nowrap">通過</th>
                                        <th className="p-2 text-nowrap">備註</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupForExpertDisplayList.slice((lCurrentPage1 - 1) * displayAmount, lCurrentPage1 * displayAmount).map((item, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.GroupSet}</td>
                                            <td>{item.Groupno}</td>
                                            <td>{item.Groupname}</td>
                                            <td>{item.Schoolname} </td>
                                            <td>{item.City}</td>
                                            <td>{item.Area} </td>
                                            <td>
                                                <div dangerouslySetInnerHTML={{ __html: item.Topic }}></div>
                                                {/* <div>動物排泄物與腸道的關係</div>
                                                  <div>動物排泄物與腸道的關係與因素</div> */}
                                            </td>
                                            <td> {item.FlagOK ? '√' : '--'} </td>
                                            <td></td>
                                        </tr>
                                    ))}

                                    {/* <tr>
                                        <td>2</td>
                                        <td>A</td>
                                        <td>EB003-03</td>
                                        <td>B.E.L.K好奇寶寶團 </td>
                                        <td>臺南大學附小 </td>
                                        <td>臺南市</td>
                                        <td>中西區 </td>
                                        <td>
                                            <div>動物也會鳩佔鵲巢？-外來種的分析與探討</div>
                                            <div>動物也會鳩佔鵲巢-外來種的分析與探討</div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <PageIndexX frameSize={displayAmount} pageSize={displayAmount} totalRec={groupForExpertDisplayList?.length} currentPage={lCurrentPage1} setCurrentPage={setLCurrentPage1} />
                    </div>

                    <div className="pt-3 mt-4"></div>
                </div>
            </div>

            <FooterPage />
        </>
    );
};

export default EpExpertGroup;
