

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import { Action, Export2Word, IOpinionForModal, IsubTopicForModal, OpinCode, TutorialModal, alertEx, currentClass, geIndexBytOpinionCode, getClassNameForShow, getLoginInfo, getModalConfigs, replaceNewline, setSessionStudentDisplayInfo } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import Schedule from './component/schedule';
import OpinionPost11 from './component/OpinionPost11';
import OpinionStageAfter22 from './component/OpinionStageAfter22';
import EditModal from './component/EditModal';
import EditModal2 from './component/EditModal2';
import { getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateOpinionCopyToSureOpinion, updateOpinionForClear } from '../../services/EP/OpinionService';
import { IOpinion, IsubTopic, Param11OrPost11, TeacherGroupInfo } from '../../interfaces/EpModal';
import AfterFeedback from './component/Afterfeedback';
import { getDrGroupByTeaNo } from '../../services/EP/EpApplyTeacherService';
import GroupSelect from './component/groupSelect';
import TeacherFunctionBar from './component/teacherFunctionBar';


export interface StudentDisplay {

  ApplyName: string
  ApplyNo: string
  ApplyTitle: string
  GroupName: string
  GroupNo: string
  SchoolName: string //"國語實小        "
  StuName: string  //"趙小一       "
  StuNo: string //  "FA001-01-1"
  TeaName: string // "李老師       "
  TeaNo: string //""
  TeaTitle: string // 
  Team: {
    TStuName: string,
    TStuNo: string
    checked: boolean
  }[]
  Teachers: {
    TeaNo: string, TeaName: string, TeaTitle: string

  }[]
}




// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpTeachfeedback: React.FunctionComponent<IPageProps> = () => {
  const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState(false)
  const [currentShowModalConfig, setCurrentShowModalConfig] = useState<IOpinionForModal | IsubTopicForModal>();
  const [currentLoginInfo, setCurrentLoginInfo] = useState<any>()
//   const [studentInfo, setStudentInfo] = useState<StudentDisplay>()
  const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
  const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });

  const [teaccherGroupInfoList, setTeacherGroupInfoList] = useState<TeacherGroupInfo[]>([]);
  const [selectGroupInfo, setSelectGroupInfo] = useState<TeacherGroupInfo|undefined >({}as TeacherGroupInfo );
  const [role ,setRole] =useState('')
  
  //     <GroupSelect  selectGroupInfo={selectGroupInfo} teaccherGroupInfoList={teaccherGroupInfoList} setSelectGroupInfo ={setSelectGroupInfo}/>
  useEffect(() => {
    fetchTeacherGroups() ;
   
  },
    [])


const fetchTeacherGroups = async () => {
    let currentLoginInfo = getLoginInfo();
    setRole(currentLoginInfo.LoginStatus)
    console.log('fetchData...', currentLoginInfo.LoginID.trim());
    setCurrentLoginInfo(currentLoginInfo);
    const rsp = (await getDrGroupByTeaNo(currentClass, currentLoginInfo.LoginID.trim())) as any;
    if (rsp) {
        console.log('rsp---', rsp?.Groups);
        alertEx(rsp?.Groups)
        setTeacherGroupInfoList(rsp?.Groups);
        setSelectGroupInfo(rsp?.Groups[0]);
    }
};
  


  return (
    <>

      <TopNavPage1 />
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">專題探究競賽</h5>
            <TeacherFunctionBar />
          </div>
          <GroupSelect  selectGroupInfo={selectGroupInfo} teaccherGroupInfoList={teaccherGroupInfoList} setSelectGroupInfo ={setSelectGroupInfo}/>
          <AfterFeedback  selectGroupInfo={selectGroupInfo} role={role} groupNo={selectGroupInfo?.GroupNo||''} />
          {/*  */}

          <div className="pt-3 mt-4"></div>
          <TutorialModal 
        id="teach1" 
        title="階段1 教學" 
        src="//www.slideshare.net/slideshow/embed_code/key/6tppHRHcTHtMut" 
        href="//www.slideshare.net/LoveBook2/1202301pptx" 
      />
      <TutorialModal 
        id="teach2" 
        title="階段2 教學" 
        src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd" 
        href="//www.slideshare.net/LoveBook2/2202301pptx" 
      />  
        </div>
      </div>
      <FooterPage />
    </>

  )


}

export default EpTeachfeedback;