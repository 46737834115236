import React, {useRef, useState} from 'react'
import IPageProps from '../interfaces/page'
import { handleLink } from '../modules/utils';
import { useSysInfoContext } from '../components/sysContext';
import {Link, useHistory} from 'react-router-dom';
import { set } from 'date-fns';



const MainFuncPage: React.FunctionComponent<IPageProps> = props => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {sharedState,setSharedState} = useSysInfoContext();
    let history = useHistory();

  const navigate = () => {
    history.push('/pptest');
  };
    const handleContext = ()=>{
        setSharedState("ContextTest");
        console.log("sharedState",sharedState);    
    }
    return (
        <>
            <header className="section-gray">
                <div className="container d-flex flex-column flex-sm-row px-0 px-md-4">
                    <div className="pp pointer" >
                        <img src="../assets/img/dp.svg" onClick={()=> handleLink('/pptest')}/> 
                    </div>
                    <div className="dp pointer">
                        <img src="../assets/img/dp.svg" onClick={()=> handleLink('/dptest')}/> 
                    </div>
                    <div className="ep pointer" >
                         <img src="../assets/img/ep.svg" onClick={()=> handleLink('/eptest')}/> 
                         {/* <img src="../assets/img/ep.svg"  alt="施工" title="施工中" /> */}
                         {/*<img src="../assets/img/ep.svg"  onClick={()=> handleLink('/eplogin')} /> */}
                    </div>
                </div>
            </header>   
        </>
    );
}

export default MainFuncPage;