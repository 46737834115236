import React, { useEffect, useState, useRef } from 'react'
import IPageProps from '../../interfaces/page'
import TopNavPage2 from '../topNav2';
import FooterPage from '../footer';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { addExTeaStuBySidTid, addExTeaStusBySidTid, createExStudents, deleteExStudentBySids, deleteExTeaStuBySidsTid, getExStudentByTeacherID, getExStudentByTidKeyword, getExTeacherBySchoolNo, getLastStuNo, getMaxStuID, getNewStuNo, updateExStuGradeClassBySids, updateOpenToExStudent } from '../../services/ExStuTeService';
import { IExStudent } from '../../interfaces/ExStudent';
import { AddStuModal } from '../../components/modal/addstumodal';
import { UpdStuModal } from '../../components/modal/updstumodal';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import { NotifyModal } from '../../components/modal/notifymodal';


const TeacherStuMgnPage: React.FunctionComponent<IPageProps> = props => {
  const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();
  const [schTeachers,setSchTeachers]=useState<IExTeacher[]>([]); //同校老師
  const [openStudents,setOpenStudents]=useState<IExStudent[]>([]); //開放學生
  const [currUpdStu,setCurrUpdStu]=useState<IExStudent>(); //目前編輯學生
  const [currOpenTeacher,setCurrOpenTeacher]=useState(-1); //目前開放老師
  const [currGrade,setCurrGrade]=useState(""); //目前年級
  const [currClass,setCurrClass]=useState(""); //目前班級
  const [openAddStuModal,setOpenAddStuModal]=useState(false);
  const [openUpdStuModal,setOpenUpdStuModal]=useState(false);
  const [refresh,setRefresh]=useState(false);
  const [entPressed,setEntPressed]=useState(false);
  const [inpKeyword,setInpKeyword]=useState("")
  const [chkAllStu,setChkAllStu]=useState(false);
  const [openNotifyModal,setOpenNotifyModal]=useState(false);
  const [notification,setNotification]=useState("");
  const [reply,setReply]=useState(false);
  const [replayMode,setReplyMode]=useState(0); //0:刪除 1:移除

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyDown=(e: React.KeyboardEvent<HTMLElement>)=>{
    if(e.key==="Enter") setEntPressed(true);    
  }

  const handleInpKeyword=(e:React.FormEvent<HTMLInputElement>)=>{
    setInpKeyword(e.currentTarget.value.replaceAll("/","%2F"));
  }


  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const chkSelectedFile = e.target.files && e.target.files[0];

    if ((chkSelectedFile) && (e.target.files!==null)) {
      if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
      {
        if (sessionStorage.getItem("LoginTeacher") !== null) {
  
          const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
          setLoginTeaacher(lTea);
          const arrayBuffer=await readFileAsync(e.target.files[0]);
          const wb = new Excel.Workbook();
          await wb.xlsx.load(arrayBuffer);
          const ws = wb.getWorksheet(1);
          const dataRows:IExStudent[] = [];
          interface teastu {
            Tid: string;
            Sid: string;
          }
          const TeaStus:teastu[] = [];
          const maxSid=await getMaxStuID();          
          const lastStuNo=await getLastStuNo(lTea.Tid.toString());

          ws.eachRow((row, rowNumber) => 
          {
            
            if (rowNumber > 1)
            {
              if(maxSid!==undefined)
              {
                const newSid=maxSid.MaxSid+rowNumber+1;
                let newStuNo=(lastStuNo+rowNumber+1).toString().padStart(3,"0");

                  const rowData:IExStudent=
                  {
                    Sid:newSid,
                    StuNo:lTea.Tid.toString().trim()+"-"+newStuNo,
                    StuName:(row.getCell(4).value ===null)? "":row.getCell(4).value!.toString().trim() , //學生姓名                  
                    SchoolNo:lTea.SchoolNo.trim(),
                    SchoolName:lTea.SchoolName.trim(),
                    City:lTea.City.trim(),
                    Area:lTea.Area.trim(),
                    Grade:(row.getCell(1).value===null)? "":row.getCell(1).value!.toString().trim(), //年級
                    Class:(row.getCell(2).value===null)? "":row.getCell(2).value!.toString().trim(), //班級
                    Gender:(row.getCell(6).value===null)? "":row.getCell(6).value!.toString().trim(), //性別
                    Gmail:"", 
                    iSchoolMail:(row.getCell(7).value===null)? "":row.getCell(7).text!.toString().trim(), //第三方平台Email
                    OpenTo:lTea.TeaName.trim()+",", //開放給誰
                    TeaCreate:true,
                    PWD:(row.getCell(5).value===null)? "":row.getCell(5).value!.toString().trim(), //密碼
                    Seat:(row.getCell(3).value===null)? "":row.getCell(3).value!.toString().trim(), //座號
                    PP1Correct:0,
                    PP1Wrong:0,
                    PP4Correct:0,
                    PP4Wrong:0,
                    PP5Count:0,
                    PP6Count:0,
                    DPCount:0,
                    MaTen1:0,
                    MaTen2:0,
                    MaTen3:0,
                    MaTen4:0,
                    MaTen5:0,
                    Remark:(row.getCell(8).value===null)? "":row.getCell(8).value!.toString().trim(), //備註
                    Selected:false  
                  };
                  const newTeaStu:teastu={
                    Tid:lTea.Tid.toString().trim(),
                    Sid:newSid.toString()
                  }
                  TeaStus.push(newTeaStu);
                  dataRows.push(rowData);
                }
            }
          });
          if(dataRows.length>0)
          {
            const asyncTask=[
              ()=>createExStudents(dataRows),
              ()=>addExTeaStusBySidTid(TeaStus)
            ]
            await Promise.all(asyncTask.map((task)=>task()));
            setRefresh(true);            
          }
        }
      }     
    }
  };

  const readFileAsync = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target?.result;
        if (result) {
          resolve(result as ArrayBuffer);
        } else {
          reject(new Error('Failed to read file.'));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };


  function handleUpdStu(student: IExStudent): void {
    setCurrUpdStu(student);
    setOpenUpdStuModal(true);
  }

  function handleNewStu(): void {
    setOpenAddStuModal(true);
  }

  function handleSelect(index: number): void {
    const newOpenStudents = [...openStudents];
    newOpenStudents[index].Selected = !newOpenStudents[index].Selected;
    setOpenStudents(newOpenStudents);
  }

  function handleGrade(grade: string): void {
    setCurrGrade(grade);
  }

  function handleClass(cls: string): void {
    setCurrClass(cls);
  }

  const handleNotifyClose=()=>{
    setOpenNotifyModal(false);
  }


  function handleShare(): void {
    if(loginTeacher===undefined) return;
    if(currOpenTeacher===-1) return;

    let sids:string[]=[];

    openStudents.map(async (student,index)=>{
      if(student.Selected)
      {
        sids.push(student.Sid.toString().trim());
        let newOpenTo:string[]=student.OpenTo.split(",");
        let openToStr="";
        if(newOpenTo.indexOf(schTeachers[currOpenTeacher].TeaName.trim())===-1)
        {
          openToStr=student.OpenTo+schTeachers[currOpenTeacher].TeaName.trim()+",";
          await updateOpenToExStudent(student.Sid.toString(),openToStr);
          await addExTeaStuBySidTid(student.Sid.toString(),schTeachers[currOpenTeacher].Tid.toString());
        }
      }
    });
    setCurrOpenTeacher(-1);
    setRefresh(true);
  }
  
  const handleStuExport=async ()=>{
    if(loginTeacher===undefined) return;
    const wb=new Excel.Workbook();
    const ws=wb.addWorksheet('學生資料-'+loginTeacher.TeaName.trim());
    ws.columns=[
        {key:'SchoolName', header:'學校',width:15},
        {key:'Grade', header:'年級',width:10},
        {key:'Class', header:'班級',width:10},
        {key:'Seat', header:'座號',width:10},
        {key:'StuName', header:'姓名',width:15},
        {key:'PWD', header:'密碼',width:15},
        {key:'Gender', header:'性別',width:10},
        {key:'iSchoolMail', header:'第三方平台Email',width:20},
        {key:'TeaCreate', header:'新增者',width:10},
        {key:'OpenTo', header:'開放給',width:20},
        {key:'Remark', header:'備註',width:20}];
        openStudents.forEach((item)=>{
          let rowItem={
              SchoolName:item.SchoolName,
              Grade:item.Grade,
              Class:item.Class,
              Seat:item.Seat,
              StuName:item.StuName,
              StuNo:item.StuNo,
              PWD:item.PWD,
              Gender:item.Gender,
              iSchoolMail:item.iSchoolMail,
              TeaCreate:(item.TeaCreate)?"老師":"其他",
              OpenTo:item.OpenTo,
              Remark:item.Remark
            }
          ws.addRow(rowItem);
      });

      await wb.xlsx.writeBuffer().then(buffer=> FileSaver.saveAs(new Blob([buffer]), `${loginTeacher.TeaName.trim()}老師-匯出學生資料-${getDateTimestring(Date.now())}.xlsx`))
  };

  const handleImportSample=()=>{
    const wb=new Excel.Workbook();
    const ws=wb.addWorksheet('匯入學生範例資料');
    ws.columns=[
        {key:'Grade', header:'年級',width:10},
        {key:'Class', header:'班級',width:10},
        {key:'Seat', header:'座號',width:10},
        {key:'StuName', header:'姓名',width:15},
        {key:'PWD', header:'密碼',width:15},
        {key:'Gender', header:'性別',width:10},
        {key:'iSchoolMail', header:'電子郵件',width:20},
        {key:'Remark', header:'備註',width:20}];
       
    let rowItem={
        Grade:'三',
        Class:'甲',
        Seat:'12',
        StuName:'方琳琳',
        PWD:'123456',
        Gender:'女',
        iSchoolMail:'abc@ischool.com.tw',
        Remark:''
      }
      ws.addRow(rowItem);

      rowItem={
        Grade:'四',
        Class:'2',
        Seat:'2',
        StuName:'王大明',
        PWD:'11111',
        Gender:'男',
        iSchoolMail:'xyz@gmail.com',
        Remark:''
      }
      ws.addRow(rowItem);
      wb.xlsx.writeBuffer().then(buffer=> FileSaver.saveAs(new Blob([buffer]), `匯入學生資料範例-${getDateTimestring(Date.now())}.xlsx`))  
  }
  
  const getDateTimestring=(timestamp:number):string=>{
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }


  const waitForConfirm=async(mode:number)=>{
  
    if(mode===1) //刪除
    {
      setNotification("請確認是否刪除資料，刪除後學生學習紀錄與帳號將無法還原");
      setReplyMode(0);
      setOpenNotifyModal(true);
    }
    else //移除
    {
      setNotification("請確認是否移除學生，移除後將無法檢視該學生學習紀錄學生學習紀錄");
      setReplyMode(1);
      setOpenNotifyModal(true);
    }
  }

  useEffect(()=>{
    if(reply)
    {
      if(replayMode===0)
      {
        handleBatchDelete();
      }
      else
      {
        handleBatchRemove();
      }
    }
  },[reply])

  async function handleBatchDelete(): Promise<void> {
    let sids:string[]=[];
    openStudents.map((student,index)=>{
      if(student.Selected)
      {
        sids.push(student.Sid.toString().trim());
      }
    });
    if((sids.length>0) && (loginTeacher!==undefined))
    {
      const sidStrs=sids.join(",");
      await deleteExStudentBySids(sidStrs);
      await deleteExTeaStuBySidsTid(sidStrs,loginTeacher.Tid.toString());
      setRefresh(true);
      setReply(false);
    }
  }

  async function handleBatchRemove(): Promise<void> {
    if(loginTeacher===undefined) return;
    let sids:string[]=[];
    openStudents.map(async (student,index)=>{
      if(student.Selected)
      {
        sids.push(student.Sid.toString().trim());
        const newOpenTo:string[]=student.OpenTo.split(",");
        let opneToStr="";
        newOpenTo.forEach((data,index)=>{
            if(data!==loginTeacher.TeaName.trim() && data!=="")
            {
                opneToStr=opneToStr+data+",";
            }
        });
        await updateOpenToExStudent(student.Sid.toString(),opneToStr);
      }
    });
    if(sids.length>0)
    {
      const sidStrs=sids.join(",");
      await deleteExTeaStuBySidsTid(sidStrs,loginTeacher.Tid.toString());

      setRefresh(true);
    }
    setReply(false);
  }

  function handleChgGradeClass(): void {
    let sids:string[]=[];
    openStudents.map((student,index)=>{
      if(student.Selected)
      {
        sids.push(student.Sid.toString().trim());
      }
    });
    if((sids.length>0) && (currGrade!=="") && (currClass!==""))
    {
      const sidStrs=sids.join(",");
      updateExStuGradeClassBySids(sidStrs,currGrade,currClass);
      setCurrClass("");
      setCurrGrade("");
      setRefresh(true);
    }
  }
  const handleChkAllStu=()=>{
    if(!chkAllStu)
    {
      setChkAllStu(true);
      openStudents.map((student,index)=>{
        student.Selected=true;
      });
    }
    else
    {
      setChkAllStu(false);
      openStudents.map((student,index)=>{
        student.Selected=false;
      });
    }
  }

  const fetchData=async(keyword:string,mode:string)=>{
    if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
    {
      if (sessionStorage.getItem("LoginTeacher") !== null) {

        const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
        setLoginTeaacher(lTea);
        //取得同校老師
        let schTeachers=await getExTeacherBySchoolNo(lTea.SchoolNo);
        schTeachers=schTeachers.map((teacher,index)=>{
          teacher.Selected=false;
          return teacher;
        })

        setSchTeachers(schTeachers);

        //取得開放學生
        let openStudents=await getExStudentByTidKeyword(lTea.Tid,keyword,mode);
        openStudents=openStudents.map((student,index)=>{
          student.Selected=false;
          return student;
        })
        setOpenStudents(openStudents);
      }
    }
  }  

  useEffect(() => {
    fetchData('*','0');
    setInpKeyword("");
  }, []);

  useEffect(() => {
    if(refresh)
    {
      fetchData('*','0');
      setInpKeyword("");
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if(!openUpdStuModal)
    {
      fetchData('*','0');
      setInpKeyword("");
    }
  }, [openUpdStuModal]);

  useEffect(() => {
    if(!openAddStuModal)
    {
      fetchData('*','0');
      setInpKeyword("");
    }
  }, [openAddStuModal]);

  useEffect(()=>{
    if(entPressed)
    {
      if(inpKeyword==="")
      {
        fetchData('*','0');
      }
      else
      {
        fetchData(inpKeyword.trim(),'1');
      }
      setEntPressed(false);
    }
  },[entPressed])

  return (
  <>
    <TopNavPage2/>
    <div className="section pt-3">
      <div className="container px-3 px-md-4">
      <div className="d-flex align-items-center flex-column flex-lg-row">
          <h5 className="title mb-3 me-3">教師專區</h5>
          <div className="mx-auto ms-auto me-md-0 text-center">
            <a href="/teacheradmin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active" type="button">
              操作說明</a>
            <a href="/teacherstumgn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active" 
              type="button">
              學生管理</a>
            <a href="/teacherstulearn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
              type="button">
              學習記錄</a>
            <a href="/teacherstustat" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
              type="button">
              學習統計</a>
            <a href="/teacherstulogin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
              type="button">
              登入記錄</a>
          </div>
        </div>
      
        <div className="text-center h5 mb-3">學生管理</div>

        <div className="input-group mb-3 mx-auto" style={{maxWidth: '450px'}}>
          <span className="input-group-text"><span className="material-icons-outlined text-muted">search</span></span>
          <input className="form-control ps-3" placeholder="關鍵字篩選" type="text" checked={chkAllStu} onChange={handleInpKeyword} onKeyDown={handleKeyDown}/>
        </div>

        <div className="d-flex flex-wrap align-items-center justify-content-center mb-0" >
          <div className="">
            <div className="btn text-second bg-second mb-3" onClick={()=>waitForConfirm(1)}>刪除打 √ 資料</div>
            <div className="btn text-second bg-second mb-3 mx-3" onClick={()=>waitForConfirm(2)}>移除打 √ 學生</div>
          </div>

          <div className="dropdown d-flex mb-3 ms-2">
            <button className="btn text-second bg-second dropdown-toggle mb-0 me-2" type="button" id="dropdownMenuButton"
            data-bs-toggle="dropdown" aria-expanded="false">
                { (currOpenTeacher===-1) ? <>開放打 √ 資料</> : <>{schTeachers[currOpenTeacher].TeaName}</> }
            </button>
            <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
              <li className="dropdown-item">(限同校老師)</li>
              {
                schTeachers.map((teacher,index)=>{
                  return (
                    (teacher.TeaName!==loginTeacher?.TeaName) ? <li className="dropdown-item" key={index} onClick={()=>setCurrOpenTeacher(index)}>{teacher.TeaName}</li>:null
                  )
                })
              }
            </ul>
            <button type="button" className="btn btn btn-outline-primary mb-0" onClick={()=>handleShare()}>送出</button>
          </div>

          <div className="d-flex mx-auto ms-xl-auto me-xl-0 mb-3 ps-4">
            <div className="dropdown">
              <button className="btn text-second bg-second dropdown-toggle mb-0 me-2" type="button" id="greade"
                data-bs-toggle="dropdown" aria-expanded="false">{(currGrade==="") ? "年級": currGrade+"年級"}</button>
              <ul className="dropdown-menu px-2 py-3" aria-labelledby="greade" style={{minWidth:'8rem'}}>
                <li className="dropdown-item" onClick={()=>handleGrade("一")}>一年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("二")}>二年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("三")}>三年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("四")}>四年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("五")}>五年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("六")}>六年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("七")}>七年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("八")}>八年級</li>
                <li className="dropdown-item" onClick={()=>handleGrade("九")}>九年級</li>
              </ul>
            </div>

            <div className="dropdown">
              <button className="btn text-second bg-second dropdown-toggle mb-0 me-2" type="button" id="classice"
                data-bs-toggle="dropdown" aria-expanded="false">{(currClass==="") ? "班級": currClass+"班"}</button>
              <ul className="dropdown-menu px-2 py-3" aria-labelledby="classice" style={{minWidth:'8rem'}}>
                <li className="dropdown-item" onClick={()=>handleClass("1")}>1班</li>
                <li className="dropdown-item" onClick={()=>handleClass("2")}>2班</li>
                <li className="dropdown-item" onClick={()=>handleClass("3")}>3班</li>
                <li className="dropdown-item" onClick={()=>handleClass("4")}>4班</li>
                <li className="dropdown-item" onClick={()=>handleClass("5")}>5班</li>
                <li className="dropdown-item" onClick={()=>handleClass("6")}>6班</li>
                <li className="dropdown-item" onClick={()=>handleClass("7")}>7班</li>
                <li className="dropdown-item" onClick={()=>handleClass("8")}>8班</li>
                <li className="dropdown-item" onClick={()=>handleClass("9")}>9班</li>
                <li className="dropdown-item" onClick={()=>handleClass("10")}>10班</li>
                <li className="dropdown-item" onClick={()=>handleClass("11")}>11班</li>
                <li className="dropdown-item" onClick={()=>handleClass("12")}>12班</li>
                <li className="dropdown-item" onClick={()=>handleClass("13")}>13班</li>
                <li className="dropdown-item" onClick={()=>handleClass("14")}>14班</li>
                <li className="dropdown-item" onClick={()=>handleClass("15")}>15班</li>
                <li className="dropdown-item" onClick={()=>handleClass("其他")}>其他</li>
              </ul>
            </div>
            <button type="button" className="btn btn-outline-primary mb-0" onClick={()=>handleChgGradeClass()}>更改打 √ 年班</button>
          </div>

        </div>

        <div className="mx-auto ms-xl-auto text-end" >
          <div className="btn text-second bg-second mb-3 mx-2" onClick={()=>handleStuExport()}>匯出學生資料</div>
          <div className="btn text-second bg-second mb-3 mx-2" onClick={()=>handleFileSelect()} >
            Excel 匯入學生資料
          </div>
          <div className="btn text-second bg-second mb-3 mx-2" onClick={()=>handleImportSample()}>
            下載匯入學生資料範例
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <div className="btn text-second bg-second mb-3" data-bs-toggle="modal" data-bs-target="#addStd" onClick={()=>handleNewStu()}>
            新增學生
          </div>

        </div>
        <div className="card p-3 table-responsive">
          <div className="text-muted">第三方平台Email可輸入「縣市單一簽入平台」的學生Email來綁定，學生即可直接從親師生平台登入數位讀寫網。</div>
          <table className="text-sm table table-striped mb-0">
            <thead>
              <tr className="font-weight-bolder text-second" style={{background: '#d9dce6'}}>
                <th className="p-2 text-nowrap"><input type="checkbox" className="me-2" onClick={()=>handleChkAllStu()}/>No.</th>
                <th className="p-2 text-nowrap order-by">學校</th>
                <th className="p-2 text-nowrap order-by">年班</th>
                <th className="p-2 text-nowrap order-by">座號</th>
                <th className="p-2 text-nowrap order-by">姓名</th>
                <th className="p-2 text-nowrap order-by">性別</th>
                <th className="p-2 text-nowrap order-by">登入號</th>
                <th className="p-2 text-nowrap">密碼</th>
                <th className="p-2 text-nowrap order-by">第三方平台Email</th>
                <th className="p-2 text-nowrap order-by">新增者</th>
                <th className="p-2 text-nowrap order-by">開放給</th>
                <th className="p-2 text-nowrap order-by">備註</th>
                <th className="p-2 text-nowrap">功能</th>
              </tr>
              {
                openStudents.map((student,index)=>{                  

                  return (
                      <tr className="align-middle">
                        <td className="z-index-3"><input type="checkbox" className="me-2" checked={student.Selected} onChange={()=>handleSelect(index)}/>{(index+1).toString()}</td>
                        <td className="">{student.SchoolName}</td>
                        <td className="">{student.Grade+"年"+student.Class+"班"}</td>
                        <td className="">{student.Seat}</td>
                        <td className="">{student.StuName}</td>
                        <td>{student.Gender}</td>
                        <td className="">{student.StuNo}</td>
                        <td>{student.PWD}</td>
                        <td>{student.iSchoolMail}</td>
                        <td>{(student.TeaCreate)? "老師":"其他"}</td>
                        <td>{student.OpenTo}</td>
                        <td>{student.Remark}</td>
                        <td>
                          <div className="btn btn-outline-primary py-2 mb-0" data-bs-toggle="modal" data-bs-target="#editStd01" onClick={()=>handleUpdStu(student)}>編輯
                          </div>
                        </td>
                      </tr>

                  )
                })
              }
            </thead>
            <tbody>
            </tbody>
          </table>              
        </div>    

      </div>
      <div className="pt-3 mt-4"></div>
      <AddStuModal isOpen={openAddStuModal} setOpenAddStuModal={setOpenAddStuModal}/>
      <UpdStuModal isOpen={openUpdStuModal} setOpenUpdStuModal={setOpenUpdStuModal} updStu={currUpdStu}/>
      <NotifyModal title={"提醒您"} isOpen={openNotifyModal} onClose={handleNotifyClose} notification={notification} setReply={setReply}/>
    </div> 
    <FooterPage/>
  </>
  )
}

export default TeacherStuMgnPage
