import React, { useEffect, useState } from 'react'
import { handleLink } from '../../../modules/utils';
import TopNavPage2 from '../../topNav2';
import {RouteComponentProps} from 'react-router-dom'
import FooterPage from '../../footer';
import { getExDPWebs } from '../../../services/ExDPWebService';
import { IExDPWeb } from '../../../interfaces/ExDPWeb';
import DPQAPage from './dpqa';
import IAnsDP from '../../../interfaces/AnsDP';
import IAnsPageDP from '../../../interfaces/AnsPageDP';

import { buildAnsStateDPByNoArray, getAnsDPByNo, getAnsQDPByNoArray, getDPQAByNo, getQuestionDPByNoArray } from '../../../services/ExDPQAService';
import IAnsStateDP from '../../../interfaces/AnsStateDP';
import IQuestionDP from '../../../interfaces/QuestionDP';
import IAnsQDP from '../../../interfaces/AnsQDP';
import { getDPLearnTaskByNo } from '../../../services/ExDPService';


//type IDPPracticeProp=RouteComponentProps< {
   // group:string;
   // no: string
//}>;

interface IDPPracticeProp {

}


const DPPracticePageWeb: React.FunctionComponent<IDPPracticeProp> = props => {
    //const match=props.match;
    //console.log("group:"+match.params.group+",no:"+match.params.no);
    //const [dpTab,setDPTab]=useState<IExDPWebTab[]>([]);

    const [currTab,setCurrTab]=useState(0);
    const [currTabQ,setCurrTabQ]=useState(0);
    const [ExDPWebs,setExDPWebs]=useState<IExDPWeb[]>([]);
    const [ansStateDP,setAnsStateDP]=useState<IAnsStateDP[][]>([[]]);
    const [questionDP,setQuestionDP]=useState<IQuestionDP[][]>([[]]);
    const [ansQDP,setAnsQDP]=useState<IAnsQDP[]>([]);
    const [learnTask,setLearnTask]=useState("");
    const [unfinishAns,setUnFinishAns]=useState("1");
    const [finishFlag,setFinishFlag]=useState(false)


    console.log('--------AsnState--------- Beginning')
    console.log(ansStateDP)

    const handleTab=(idx:number)=>{
        if(idx!==currTab)
        {
            setCurrTab(idx); 
        }
    }

    const InitData=()=>{
        getDPQAByNo(""+sessionStorage.getItem("DPNo")).then(data=>{
            setQuestionDP(getQuestionDPByNoArray(data));
            setAnsQDP(getAnsQDPByNoArray(data));
            setAnsStateDP(buildAnsStateDPByNoArray(data))
        })
                                                      
    }

    const handelTextInp=(inpStr:string,idx:number)=>{
        ansStateDP[currTab][idx].InputText=inpStr
        setAnsStateDP(ansStateDP);
        setUnFinishAns(chkAnsState());

    }
    /*const handelRadioInp=(inpStr:string,idx:number)=>{
        ansStateDP[currTab][idx].SelectItems=inpStr;
        setAnsStateDP(ansStateDP);
    }*/

    const handelRadioInp=(idx:number,selIdx:number)=>{
        ansStateDP[currTab][idx].SelectItems=selIdx.toString();
        setAnsStateDP(ansStateDP);
        setUnFinishAns(chkAnsState());
    }

    const handleCheckInp=(idx:number,selIdx:number)=>{
        let result=ansStateDP[currTab][idx].SelectItems
        if(result.includes(selIdx.toString()))
        {
            result.replace(selIdx.toString(),'')
        }
        else
        {
            result=result+selIdx.toString();
            result.split('').sort().join('')
        }
        ansStateDP[currTab][idx].SelectItems=result
        setAnsStateDP(ansStateDP);
        setUnFinishAns(chkAnsState());
    }

    const handleReset=()=>{
     
        (document.getElementById('question-form') as HTMLFormElement).reset();
        setInitial();
    }

    const setInitial=()=>{
        ansStateDP[currTab].forEach((val,idx)=>{
            if(val.Type==='簡答')
            {
                let tmp=(document.getElementById("ans"+currTab.toString()+"-"+val.Qno.toString()) as HTMLInputElement)
                if(tmp!==null) tmp.value=ansStateDP[currTab][idx].InputText
            }
            if(val.Type==='單選')
            {
                if(ansStateDP[currTab][idx].SelectItems!=="") 
                {
                    let itemIdx=(Number(ansStateDP[currTab][idx].SelectItems)).toString()
                    let tmp=(document.getElementById("ans"+currTab.toString()+"-"+val.Qno.toString()+"-"+itemIdx) as HTMLInputElement)
                    if(tmp!==null) tmp.checked=!tmp.checked
                }
                
            }
            if(val.Type==='多選')
            {
                if(ansStateDP[currTab][idx].SelectItems!=="") 
                {
                    let selItems=ansStateDP[currTab][idx].SelectItems

                    for(let i=0;i<selItems.length;i++)
                    {
                        let itemIdx=(Number(selItems[i])).toString()
                        let tmp=(document.getElementById("ans"+currTab.toString()+"-"+val.Qno.toString()+"-"+itemIdx) as HTMLInputElement)
                        if(tmp!==null) tmp.checked=true
                    }
                }
                
            }
        })
    }
    const chkAnsState=():string=>{
        let result=''

        for(let i=0;i<ansStateDP.length;i++)
        {
            // eslint-disable-next-line no-loop-func
            ansStateDP[i].forEach(data=>{
                if(data.Type==='簡答' && data.InputText==="")
                {
                    result=result+data.Qno.toString()+","
                }
                if(data.Type==='單選' && data.SelectItems==="" )
                {
                    result=result+data.Qno.toString()+","
                }
                if(data.Type==='多選' && data.SelectItems==="" )
                {
                    result=result+data.Qno.toString()+","
                }                             
                    
            })
        }        
        result=result.slice(0,result.length-1)
        return result
    }
    const handleCompleteAns=()=>{
        let aState=chkAnsState()
        setUnFinishAns(aState);
        if(aState==="")
        {
            let tmp=(document.getElementById("submit-ans-01") as HTMLButtonElement)
            if(tmp!==null) tmp.disabled=true
            setFinishFlag(true);
            genAnsQDP();
            console.log('------- finished-------')
            console.log(ansQDP);
        }
        
    }
    const genAnsQDP=()=>{
        for(let i=0;i<ansStateDP.length;i++)
        {
            ansStateDP[i].forEach((data,idx)=>{
                if (data.Type==="簡答")
                {
                    let item=ansQDP.find(val=>val.Qno===data.Qno)
                    if(item!==undefined) item.InpAnsList[0]=data.InputText
                }
                if (data.Type==="單選")
                {
                    let item=ansQDP.find(val=>val.Qno===data.Qno)
                    if(item!==undefined) item.InpAnsList[0]=questionDP[i][idx].QuestionList[Number(data.SelectItems)]
                }
                if (data.Type==="多選")                
                {
                    let item=ansQDP.find(val=>val.Qno===data.Qno)
                    if(item!==undefined)
                    {
                        for(let j=0;j<data.SelectItems.length;j++)
                        {
                            item.InpAnsList.push(questionDP[i][idx].QuestionList[Number(data.SelectItems[j])])
                        }

                    }
                }
            })
        }
    }

    useEffect(()=>{
        getExDPWebs(""+sessionStorage.getItem("DPNo")).then(data=>setExDPWebs(data));
        getDPLearnTaskByNo(""+sessionStorage.getItem("DPNo")).then(data=>setLearnTask(data));
        InitData();
    },[])

    useEffect(()=>{
        if(ExDPWebs.length!==0)
        {
            let leftContent=document.getElementById('left-content');
            if(leftContent!==null) leftContent.innerHTML=""+ExDPWebs[currTab].HTML;
            let anTag=document.getElementsByClassName("anotherTag");
            if(anTag.length!==0) 
            {
                for(let i=0;i<anTag.length;i++) anTag[i].className=""+"pointer text-blue"
            }
        }
    },[ExDPWebs,currTab])

    return (
        <>
            <div style={{backgroundColor:'#FCF9F3'}}>
                <TopNavPage2 />
                <div id="test" className="container d-flex flex-column flex-xl-row align-items-center mb-2">
                    <div className="d-flex align-items-start align-items-sm-center py-2 order-2 order-xl-1">
                        <img src={'./assets/img/dp/'+sessionStorage.getItem("DPImage")} className="me-2" style={{width:'80px'}}/>
                        <div>
                            <p className="mb-1">右欄有學習任務，請閱讀後回答問題。</p>
                            {/*<!-- <div>
                                        <span className="badge badge-danger">您未登入，故不會儲存本次練習結果。</span>
                            </div> --> */}
                            <div>
                                <span className="badge badge-secondary me-2">已學習DP文章數：3</span>
                                <span className="badge badge-secondary">本篇文章學習次數 : 0</span>
                            </div>
                        </div>
                    </div>
                    <div className="ms-0 ms-xl-auto order-1 order-xl-2 my-3">
                        <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/dptest')}>
                        <i className="fas fa-chevron-left"></i>
                        返回 多文本題組
                        </button>
                    </div>
                </div>
            </div>
            <div className="section pt-0 mt-54">
                <div className="container">
                <div className="text-center">
                    <span style={{fontSize:'30px', paddingRight:'4px', color:'#D13521'}}>{sessionStorage.getItem("DPNo")}</span>
                    <span style={{fontSize:'18px', color:'#000'}}>{sessionStorage.getItem("DPTopic")}</span>
                </div>

                <div id="start">
                    <div className="card">
                    <div className="card-body" style={{textAlign: 'left'}}>


                        <div className="tab-content" id="dpContent">

                        <div className="tab-pane fade show active" id="deep" role="tabpanel" aria-labelledby="deep-tab">

                            <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth:'420px'}}>
                            <ul className="nav nav-pills nav-fill flex-nowrap"
                                style={{background:'#9ad6d742'}} id="dp50" role="tablist">
                                <>
                                {
                                    ExDPWebs.map((item,i)=>{
                                        if(i===currTab)
                                        {
                                            return <li className="nav-item">
                                                <a className="nav-link mb-0 font-weight-bold text-sm active" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                    href="" role="tab" aria-controls="" aria-selected="true" onClick={()=>handleTab(i)}>
                                                    <span className="text-second">{item.Tab}</span></a>
                                                </li>
                                        }
                                        else
                                        {
                                            return <li className="nav-item">
                                                <a className="nav-link mb-0 font-weight-bold text-sm" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                    href="" role="tab" aria-controls="" aria-selected="false" onClick={()=>handleTab(i)}>
                                                    <span className="text-second">{item.Tab}</span></a>
                                                </li>
                                        }
                                    })

                                }
                                </>
                            </ul>
                            </div>

                            <div className="tab-content my-4 me-2" id="dpContent">

                            <div >
                                <div className="row align-items-start text-justify">
                                    <div className="col-12 col-lg-8 pe-4" id="left-content">

                                    </div>
                                    {/*----------------------------------------------------------
                                    {((ExDPWebs.length!==0))? <DPQAPage ArticleNo={ExDPWebs[currTab].ArticleNo} PageNo={ExDPWebs[currTab].PageNo} AnsDP={ansDP} SetAnsDP={setAnsDP}/> :null} */}
                                    <div className="col-12 col-lg-4 rounded-3 p-0 font-weight-bold bg-light mt-4 mt-lg-0" style={{position:'sticky',top:'24px',overflowY:'auto',maxHeight:'calc(95vh - 24px)'}}>
                                    <div className="px-3 py-2 w-100 text-center bg-learn text-white border-top-3">學習任務</div>
                                    {
                                        (learnTask!=="")?
                                        <div className="p-3">
                                        <img src="./assets/img/dp/Teacher.svg" alt="" className="float-start me-2" style={{width:'60px'}}/>            
                                        <p className="text-primary font-weight-bold mb-0"> {learnTask} </p>
                                        </div>
                                        : null
                                    }

                                    <hr className="hr1 my-0"/>
                                    <div className="p-3">
                                    <form id="question-form" onLoad={()=>handleReset()}>
                                    {
                                        <>
                                            {
                                                questionDP[currTab].map((item,idx)=>{
                                                    switch(item.Type)
                                                    {
                                                        case '簡答':
                                                        {
                                                        return <div className="mt-3">
                                                                    <div className="font-weight-bold mb-2"><span >{item.Qno.toString()}.</span>{item.Question}</div>
                                                                    <div className="ps-4">
                                                                        <div className="form-group">
                                                                            <textarea className="form-control" id={"ans"+currTab.toString()+"-"+item.Qno.toString()} rows={3} onChange={(e)=>handelTextInp(e.target.value.toString(),idx)}>
                                                                                {/*{ansStateDP[currTab][idx].InputText}*/}
                                                                            </textarea>    
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (item.TeacherQuestion!=="") ?
                                                                            <>
                                                                                <hr className="hr1 my-0"/>
                                                                                <div className="d-flex align-items-center p-3">
                                                                                    <img src="./assets/img/dp/Teacher.svg" alt="" className="me-2" style={{width:'60px'}}/>
                                                                                    <div className="text-primary font-weight-bold">{item.TeacherQuestion}</div>
                                                                                </div>                                                    
                                                                            </> : null
                                                                    }
                                                                </div>                                                               

                                                        }
                                                        case '單選':
                                                        {
                                                        return <div className="mt-3">
                                                                    <div className="font-weight-bold mb-2"><span >{item.Qno.toString()}.</span>{item.Question}</div>
                                                                    <div className="ps-4">
                                                                        {
                                                                            item.QuestionList.map((sitem,i)=>{
                                                                                {/*let chk=false;
                                                                            if(ansStateDP[currTab][idx].SelectItems===(i+1).toString()) chk=true*/}
                                                                                return  <div className="form-check">
                                                                                            {/*<input className="form-check-input" type="radio" name={"ans"+currTab.toString()+"-"+item.Qno.toString()} id={"ans"+currTab.toString()+"-"+item.Qno.toString()+"-"+i.toString()} onClick={()=>handelRadioInp(idx,i)} value={(i+1).toString()} defaultChecked={chk}/> */}
                                                                                            <input className="form-check-input" type="radio" name={"ans"+currTab.toString()+"-"+item.Qno.toString()} id={"ans"+currTab.toString()+"-"+item.Qno.toString()+"-"+i.toString()} onClick={()=>handelRadioInp(idx,i)} value={(i+1).toString()} />
                                                                                            <label className="custom-control-label" htmlFor={"ans"+currTab.toString()+"-"+item.Qno.toString()+"-"+i.toString()}>{sitem}</label>
                                                                                        </div>
                                                                                

                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        (item.TeacherQuestion!=="") ?
                                                                            <>
                                                                                <hr className="hr1 my-0"/>
                                                                                <div className="d-flex align-items-center p-3">
                                                                                    <img src="./assets/img/dp/Teacher.svg" alt="" className="me-2" style={{width:'60px'}}/>
                                                                                    <div className="text-primary font-weight-bold">{item.TeacherQuestion}</div>
                                                                                </div>                                                    
                                                                            </> : null
                                                                        
                                                                    }
                                                                </div>    
                                                        }
                                                        case '多選':
                                                        {
                                                        return  <div className="mt-3">
                                                                    <div className="font-weight-bold mb-2"><span >{item.Qno.toString()}.</span>{item.Question}</div>
                                                                    <div className="ps-4">
                                                                        {
                                                                            item.QuestionList.map((sitem,i)=>{
                                                                                return <div className="form-check">
                                                                                           {/* <input className="form-check-input" type="checkbox" value={(i+1).toString()} id={"ans"+currTab.toString()+"-"+item.Qno.toString()+"-"+i.toString()} onChange={(e)=>handleCheckInp(e.target.value,idx)} defaultChecked={chk}/> */}
                                                                                            <input className="form-check-input" type="checkbox" value={(i+1).toString()} id={"ans"+currTab.toString()+"-"+item.Qno.toString()+"-"+i.toString()} onClick={()=>handleCheckInp(idx,i)}/>
                                                                                            <label className="custom-control-label" htmlFor={"ans"+currTab.toString()+"-"+item.Qno.toString()+"-"+i.toString()}>{sitem}</label>
                                                                                        </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        (item.TeacherQuestion!=="") ?
                                                                            <>
                                                                                <div className="d-flex align-items-center p-3">
                                                                                    <img src="./assets/img/dp/Teacher.svg" alt="" className="me-2" style={{width:'60px'}}/>
                                                                                    <div className="text-primary font-weight-bold">{item.TeacherQuestion}</div>
                                                                                </div>                                                    
                                                                            </> : null
                                                                    }

                                                                </div>   
                                                        }


                                                    }
                                                })
                                            }
                                            {
                                                (questionDP.length===(currTab+1)) ? <><hr className="hr1 my-0"/>
                                                        <div className="d-flex align-items-center justify-content-center p-3">
                                                            <img src="./assets/img/dp/student.svg" alt="學生" title="學生" className="me-2" style={{width:'60px'}}/>
                                                            {
                                                                (unfinishAns==="") ?
                                                                <button type="button" id="submit-ans-01" className="btn btn-round btn-primary btn-lg mb-0" onClick={()=>handleCompleteAns()} >作答完畢</button>:
                                                                <button type="button" id="submit-ans-01" className="btn btn-round btn-primary btn-lg mb-0" data-bs-toggle="modal" data-bs-target="#ans" onClick={()=>handleCompleteAns()} >作答完畢</button>
                                                            }
                                                        </div></> :null


                                            }           
                                        </>
                                    }
                                    </form>
                                    </div>
                                    </div>        
                                </div>
                            </div>
                            </div>

                            <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth:'420px'}}>
                                <ul className="nav nav-pills nav-fill flex-nowrap"
                                    style={{background:'#9ad6d742'}} id="dp50" role="tablist">
                                <>
                                {
                                    ExDPWebs.map((item,i)=>{
                                        if(i===currTab)
                                        {
                                            return <li className="nav-item">
                                                <a className="nav-link mb-0 font-weight-bold text-sm active" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                    href="" role="tab" aria-controls="" aria-selected="true" onClick={()=>handleTab(i)}>
                                                    <span className="text-second">{item.Tab}</span></a>
                                                </li>
                                        }
                                        else
                                        {
                                            return <li className="nav-item">
                                                <a className="nav-link mb-0 font-weight-bold text-sm" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                    href="" role="tab" aria-controls="" aria-selected="false" onClick={()=>handleTab(i)}>
                                                    <span className="text-second">{item.Tab}</span></a>
                                                </li>
                                        }
                                    })

                                }
                                </>

                                </ul>
                            </div>

                        </div>
                        </div>
                    </div>
                    </div>                  
      
                    <div className="card bg-light mt-4">
                    <div className="card-body">
                        <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="accordion" id="accordionRental">
                            <div className="accordion-item mb-3">
                                <div className="d-flex justify-content-between align-items-center accordion-header" id="headingOne">
                                <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{width:'auto'}}>
                                    <h5 className="my-0 me-2">參考答案</h5>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22"
                                    aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22"
                                    aria-hidden="true"></i>
                                    </button>
                                    <a href="#" className="btn btn-outline-secondary btn-round text-nowrap mb-0" type="button">
                                    下載全部答案
                                    </a>
                                </div>
                                
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionRental">
                                <>
                                {finishFlag &&
                                    ansQDP.map((data,i)=>{
                               
                                            return <>
                                            <div className="bg-gray-300 border-radius-lg p-3 my-4" >
                                                <div className="text-sm font-weight-bold pt-2">{data.Qno}.{data.Question}</div>
                                                <hr className="horizontal dark"/>
                                                <div className="text-secondary text-sm font-weight-bold mt-3">您寫的答案</div>
                                                {
                                                    data.InpAnsList.map(inpans=>{
                                                        return <div>{inpans}</div>
                                                    })
                                                }
                                                <div className="text-secondary text-sm font-weight-bold mt-3">參考答案</div>
                                                {
                                                    data.AnsList.map(ans=>{
                                                        return <div>{ans}</div>
                                                    })
                                                }
                                            </div>
                                            </>                                                                              
                                                                                
                                    })                                    
                                }                                
                                </>                            
                                </div>

                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <hr className="horizontal dark mt-3 d-lg-none"/>
                            <div className="col-12 d-flex flex-column flex-sm-row flex-lg-column align-items-center">
                            <img src="./assets/img/MatenDP_0.png" className="w-100 w-sm-50 w-lg-100"/>
                            完成3篇進階任務，晉一級並點亮一個車廂，最高為六級。
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            
            <div className="modal fade" id="ans" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content mx-auto">
                    <div className="modal-header">
                        <h5 className="modal-title mx-auto">提醒您</h5>
                        {/*<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                            </button> */}
                    </div>
                    <div className="modal-body mx-auto">
                        <p>第 {unfinishAns} 題尚未作答喔</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary m-0" data-bs-dismiss="modal">關閉</button>
                    </div>
                    </div>
                </div>
            </div>    



            <FooterPage />
        </>

    );
}

export default DPPracticePageWeb;