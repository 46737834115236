import React, { useEffect, useState } from 'react'
import { IExDPSource } from '../../interfaces/ExDPSource';
import { handleLink, handleLinkWithBlank } from '../../modules/utils';
import { getExDPSourceByGroup } from '../../services/ExDPSourceService';
import TopNavPage1 from '../topNav1';


const DPRefPage: React.FunctionComponent = props => {
    const [activeTab,setActiveTab]=useState([true,false,false]);
    const [activeDPScr,setActiveDPScr]=useState<IExDPSource[]>([])
    const presetDP=()=>{
        sessionStorage.setItem("DPPageID","0");
        handleLink('/dptest')
    }

    const handleActiveTab=(aTab:string)=>{
        switch(aTab)
        {
            case 'DP':
                getExDPSourceByGroup("DP").then(data=>setActiveDPScr(data))
                setActiveTab([true,false,false])
                break;
            case 'Pirls':
                getExDPSourceByGroup("Pirls").then(data=>setActiveDPScr(data))
                setActiveTab([false,true,false])
                break;
            case 'SDGs':
                getExDPSourceByGroup("SDGs").then(data=>setActiveDPScr(data))
                setActiveTab([false,false,true])
                break;
        }
    }
    useEffect(()=>{
        getExDPSourceByGroup("DP").then(data=>setActiveDPScr(data))
    },[]) 

    return (
        <>
            <TopNavPage1/>
            <div className="section pt-3 pt-lg-5">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h5 className="title mb-3 me-3">深度讀寫<small className="ms-2">DP,Deep Process</small></h5>
                        <h5 className="order-2 order-md-1 mb-3">參考資料</h5>
                        <div className="order-1 order-md-2 mb-3">
                            <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>presetDP()}>
                                <i className="fas fa-chevron-left"></i>
                                返回 DP 深度讀寫練習
                            </button>
                        </div>
                    </div>
                    <div className="nav-wrapper position-relative z-index-2">
                        <ul className="nav nav-pills nav-fill flex-wrap flex-md-nowrap" id="dp" role="tablist">
                            <li className="nav-item">
                                <a className={(activeTab[0])? "nav-link mb-0 active":"nav-link mb-0"} id="deep-tab" data-bs-toggle="tab" href="#deep" role="tab"
                                aria-controls="deep" aria-selected={activeTab[0]} onClick={()=>handleActiveTab("DP")}>
                                <i className="ni ni-app me-2"></i>深度讀寫
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={(activeTab[1])? "nav-link mb-0 active":"nav-link mb-0"} id="text-tab" data-bs-toggle="tab" href="#text" role="tab" aria-controls="text"
                                aria-selected={activeTab[1]} onClick={()=>handleActiveTab("Pirls")}>
                                <i className="ni ni-album-2 me-2"></i>課文主題延伸
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={(activeTab[2])? "nav-link mb-0 active":"nav-link mb-0"} id="sdgs-tab" data-bs-toggle="tab" href="#sdgs" role="tab" aria-controls="sdgs"
                                aria-selected={activeTab[2]} onClick={()=>handleActiveTab("SDGs")}>
                                <i className="ni ni-atom me-2"></i>SDGs
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content mt-5" id="dpContent">
                        <div className="tab-pane fade show active" id="deep" role="tabpanel" aria-labelledby="deep-tab">
                            <div className="card p-2">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover align-items-center mb-0">
                                        <thead>
                                            <tr style={{color:'#5c71a2'}}>
                                                <th
                                                className="text-sm font-weight-bolder text-nowrap px-2 px-md-3 text-center">
                                                篇號</th>
                                                <th className="text-sm font-weight-bolder text-nowrap px-2 px-md-3"
                                                style={{minWidth:'70px'}}>篇名</th>
                                                <th className="text-sm font-weight-bolder text-nowrap px-2 px-md-3">資料來源</th>
                                                <th className="p-0"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                            {
                                                activeDPScr.map((item,i)=>{
                                                    return  <tr onClick={()=>handleLinkWithBlank(item.url)} style={{cursor:'pointer'}}>
                                                                <td>
                                                                <p className="text-sm font-weight-bold text-center my-2">{item.ArticleNo}</p>
                                                                </td>
                                                                <td className="align-middle text-sm">
                                                                <p className="text-wrap text-sm my-2">{item.DPTopic}</p>
                                                                </td>
                                                                <td>
                                                                <div className="d-flex">
                                                                    <p className="text-wrap text-sm text-secondary my-2">{item.Source}</p>

                                                                </div>

                                                                </td>
                                                                <td><a href={item.url}
                                                                    rel="noreferrer noopener" target="_blank"><span className="material-icons-outlined align-middle">link</span></a>
                                                                </td>
                                                            </tr>

                                                })
                                            }
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>    
            </div>
        </>    
    );
}

export default DPRefPage;