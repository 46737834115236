import axios, { AxiosError } from 'axios'
import  config from '../../config/config';
import { IExStudent } from '../../interfaces/ExStudent';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { IExGradeClass } from '../../interfaces/ExGradeClass';
import { IExStuLearnRec } from '../../interfaces/ExStuLearnRec';
import { IupdateApply } from '../../interfaces/EpModal';

// updateDrApplyByNo
export const UpdateApplyBaseInfo=async (param:IupdateApply ,applyNo :string)=>{
    const url=config.server.url+`/api/DrApply/updano/${applyNo}`
    console.log("url",url);
    
    return new Promise((resolve, reject) => {
        axios.put(url,param)
          .then(response => {
            console.log("getLoginInfoByAccountAndPass ",response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


   

}



// 以城市名稱及區域取得學校資料
// Get School Data from ExSchool By City Name and Area Name
// Get /api/ExSchool/all
// const getExSchoolByCityArea= asyncHandler(async(req: Request, res: Response) => {
  export const getAllSchool = async  ()=>{
    const url=config.server.url+`/api/ExSchool/all`
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  }





  // 取得學校資料內所有城市
// Get City from ExSchool 
// Get /api/ExSchool/city
// const getExSchoolCity= asyncHandler(async(req: Request, res: Response) => {
  export const getExSchoolCity = async  ()=>{
    const url=config.server.url+`/api/ExSchool/city`
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  }
// 以城市名稱取得學校區域
// Get Area from ExSchool By City Name
// Get /api/ExSchool/area/city/:city
// const getExSchoolAreaByCity= asyncHandler(async(req: Request, res: Response) => {
  export const getExSchoolAreaByCity = async  (city:string )=>{
    const url=config.server.url+`/api/ExSchool/area/city/${city}`
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  }
// 以城市名稱及區域取得學校資料
// Get School Data from ExSchool By City Name and Area Name
// Get /api/ExSchool/city/:city/area/:area
// const getExSchoolByCityArea= asyncHandler(async(req: Request, res: Response) => {
//  
export const getExSchoolByCityArea = async  (city:string ,area:string )=>{
  const url=config.server.url+`/api/ExSchool/city/${city}/area/${area}`
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });
}
