import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExSentence } from '../interfaces/ExSentence';

export const getAnExSentenceByLevel=(level:number)=>{
    const url=config.server.url+`/api/ExSentence/level/${level}`
    return axios.get(url).then((res)=>{
        const rest:IExSentence[]=res.data;
        const retval:IExSentence={
            Sentence:rest[0].Sentence,
            Answer:rest[0].Answer,
            RandomWords:[]
        }
        return retval;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getAnExSentence=()=>{
    const url=config.server.url+`/api/ExSentence/fixnum/1`
    return axios.get(url).then((res)=>{
        const rest:IExSentence[]=res.data;
        const retval:IExSentence={
            Sentence:rest[0].Sentence,
            Answer:rest[0].Answer,
            RandomWords:[]
        }
        return retval;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

