import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IAdLoginStu } from '../interfaces/AdLoginStuCount';
import { IAdLoginRecord } from '../interfaces/AdLoginRecord';


export const getAdLoginsCntByTid=(tid:string,loginfrom:string,bdate:string,edate:string)=>{

    const url=config.server.url+`/api/AdLogin/getreccntbytid`
    const params={
        tid:tid,
        loginfrom:loginfrom,
        begindate:bdate,
        enddate:edate
    }
    console.log("params:"+bdate+","+edate);
    return axios.post(url,params).then((res)=>{
        const rest:IAdLoginStu[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getAdLoginsByTid=(tid:string,loginfrom:string,bdate:string,edate:string)=>{

    const url=config.server.url+`/api/AdLogin/getrecbytid`
    const params={
        tid:tid,
        loginfrom:loginfrom,
        begindate:bdate,
        enddate:edate
    }

    return axios.post(url,params).then((res)=>{
        const rest:IAdLoginRecord[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getAdLoginsCnt=(accs:string[],loginfrom:string,bdate:string,edate:string)=>{

    const url=config.server.url+`/api/AdLogin/getrecords`
    const params={
        accs:accs,
        loginfrom:loginfrom,
        begindate:bdate,
        enddate:edate
    }

    return axios.post(url,params).then((res)=>{
        const rest:IAdLoginStu[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
export const getAdLogin=(account:string,loginfrom:string,bdate:string,edate:string)=>{
    const url=config.server.url+`/api/AdLogin/account/${account}/loginfrom/${loginfrom}/begindate/${bdate}/enddate/${edate}`

    return axios.get(url).then((res)=>{
        const rest= res.data[0];
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const addAdLogin=(
    account:string,
    loginfrom:string,    
    ip:string
    )=>{
    const url=config.server.url+`/api/AdLogin/addrecord`
    return axios.post(url,{
        Account:account,
        LoginTime:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-"),
        LoginIP:ip,
        LoginFrom:loginfrom
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



