import React, { createContext, useEffect, useState } from 'react'
import IPageProps from '../interfaces/page'
import NewsCards from './news/newscards';
import TopNavPage1 from './topNav1';
import MainFuncPage from './mainFunc';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PPTestPage from './pptest';
import PPTestPage1 from './pptest/pptest1';
import FooterPage from './footer';
import PPTestPage2 from './pptest/pptest2';
import PPTestPage3 from './pptest/pptest3';
import PPTestPage4 from './pptest/pptest4';
import DPTestPage from './dptest';

import DPPracticePageWeb from './dptest/dppractice/dppractice-web';
import DPPracticePageMix from './dptest/dppractice/dppractice-mix';
import DPPracticePage from './dptest/dppractice/dppractice';
import DPRefPage from './dptest/dpref';
import LoginPage from './login/login';
import NewStudentPage from './newstudent';
import NewTeacherPage from './newteacher';
import MemoryKoPage from './static/memoryko';
import CooperatePage from './static/cooperate';
import AllNews from './news/allnews';
import LoginGooglePage from './login/login-google';
import EpFunPage from './epfun/epfun';
import EpFunTextPage from './epfun/epfuntext';
import TutSitePage from './static/tutsite';
import EpMainPage from './ep/epMain';
import EpResultPage from './ep/epResult';
import TutorialPage from './tutorial';
import StuLearnRecPage from './students/StuLearnRec';
import AboutPage from './static/about';
import IntoPage from './static/introduction';
import TeacherAdminPage from './teachers/TeacherAdmin';
import TeacherStuMgnPage from './teachers/TeacherStuAdmin';
import TeacherStuLearnPage from './teachers/TeacherStuLearn';
import TeacherStuStatisticPage from './teachers/TeacherStuStatistic';
import TeacherStuLoginStatPage from './teachers/TeacherStuLoginStat';
import FaqPage from './faq';
import ReLoginGooglePage from './login/relogin-google';
import LoginNTPCPage from './login/login-ntpc';
import ReLoginNTPCPage from './login/relogin-ntpc';
import EpLoginPage from './ep/epLoginPage';
import EpNewApply from './ep/epNewApply';
import EpApplyHome from './ep/epApplyHome';
import EpApplyTeacher from './ep/epApplyTeacher';
import EpApplyGroup from './ep/epApplyGroup';
import EpApplyExplore from './ep/epApplyExplore';
import EpStudentHome from './ep/epStudentHome';
import EpStudLevel1 from './ep/epStudLevel1';
import EpTeachrHome from './ep/epTeachHome';
import EpTeachLevel1 from './ep/epTeachLevel1';
import EpExpertHome from './ep/epExpertHome';
import EpExpertGroup from './ep/epExpertGroup';
import EpExpertFeedback from './ep/epExpertFeedback';
import EpExpertOpinion from './ep/epExpertOpinion';
import EpExpertComment from './ep/epExpertComment';
import EPFAQPage from './faq-ep';
import EPAboutPage from './static/epabout';

import { getcontrol } from './ep/tool/helper';
import { IApplyFormData, crtlInfo } from '../interfaces/EpModal';
import ApplySysContext from '../components/sysContext';
import EpStudExpert from './ep/epStudExpert';
import EpStudfeedback from './ep/epStudfeedback';
import EpStudLevel3 from './ep/epStudLevel3';
import EpTeachExpert from './ep/epTeachExpert';
import EpTeachfeedback from './ep/epTeachfeedback';
import EpTeachLevel3 from './ep/epTeachLevel3';
import EpStudResult from './ep/epStudResult';
import EpTeachResult from './ep/epTeachResult';


export type MyContextType = {
    crtlInfoList:crtlInfo[] // 開放時間控制項
    setSharedState: React.Dispatch<React.SetStateAction<string>>;
    setApplyInfoShare : React.Dispatch<React.SetStateAction<IApplyFormData>>;
    applyInfoShare:IApplyFormData
  };
  
  
const MyContext = createContext<MyContextType | undefined>(undefined);
const HomePage: React.FunctionComponent<IPageProps> = props => {


    
    const HomePack = () => {
        
        return <>
                <TopNavPage1 />
                <MainFuncPage />
                <NewsCards />
                <FooterPage />
                </>
    }

    return (
        <>
        {/*<MyContext.Provider value={{ setSharedState,crtlInfoList,setApplyInfoShare: setApplyInfo,applyInfoShare: applyInfo }} */}

            <ApplySysContext>
            <BrowserRouter>
                <Switch>
                    <Route path="/pptest" component={PPTestPage} />
                    <Route path="/pptest1" component={PPTestPage1} />
                    <Route path="/pptest2" component={PPTestPage2} />
                    <Route path="/pptest3" component={PPTestPage3} />
                    <Route path="/pptest4" component={PPTestPage4} />
                    <Route path="/dptest" component={DPTestPage} />
                    {/*<Route path="/dppractice/:group/:no" component={DPPracticePage} />*/}
                    <Route path="/dppractice-web" component={DPPracticePageWeb} />
                    <Route path="/dppractice-mix" component={DPPracticePageMix} />
                    <Route path="/dppractice" component={DPPracticePage} />
                    <Route path="/dpref" component={DPRefPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/login-google" component={LoginGooglePage} />
                    <Route path="/relogin-google" component={ReLoginGooglePage} />
                    <Route path="/login-ntpc" component={LoginNTPCPage} />
                    <Route path="/relogin-ntpc" component={ReLoginNTPCPage} />
                    <Route path="/newstudent" component={NewStudentPage} />
                    <Route path="/newteacher" component={NewTeacherPage} />
                    <Route path="/memoryko" component={MemoryKoPage} />
                    <Route path="/cooperate" component={CooperatePage} />
                    <Route path="/tutorial" component={TutorialPage} />
                    <Route path="/tutsite" component={TutSitePage} />
                    <Route path="/allnews" component={AllNews} />
                    <Route exact path="/fundata" component={EpFunPage} /> 
                    {/*<Route exact path="/fundata/:gno" component={EpFunTextXPage} /> */}
                    <Route exact path="/funtext" component={EpFunTextPage} />
                    <Route exact path="/eptest" component={EpMainPage} />
                    {/*<Route exact path="/epresult" component={EpResultPage} /> */}
                    <Route exact path="/faq" component={FaqPage} />
                    <Route exact path="/stulearnrec" component={StuLearnRecPage} />
                    <Route exact path="/about" component={AboutPage} />
                    <Route exact path="/introduction" component={IntoPage} />
                    <Route exact path="/teacheradmin" component={TeacherAdminPage} />
                    <Route exact path="/teacherstumgn" component={TeacherStuMgnPage} />
                    <Route exact path="/teacherstulearn" component={TeacherStuLearnPage} />
                    <Route exact path="/teacherstustat" component={TeacherStuStatisticPage} />
                    <Route exact path="/teacherstulogin" component={TeacherStuLoginStatPage} />
                    {/*<Route exact path="/stulearnrecx" component={testPageIdx} /> */}

                    <Route path="/eplogin" component={EpLoginPage} />
                    <Route path="/epNewPage" component={EpNewApply} />
                    <Route path='/epresult' component={EpResultPage} />
                    <Route path="/epApplyHome" component={EpApplyHome} />
                    <Route path="/epApplyTeacher" component={EpApplyTeacher} />
                    <Route path="/epApplyGroup" component={EpApplyGroup} />
                    <Route path="/epApplyExplore" component={EpApplyExplore} />
                    <Route path="/epStudentHome" component={EpStudentHome} />
                    <Route path="/epTeachHome" component={EpTeachrHome} />
                    <Route path="/epStudLevel1" component={EpStudLevel1} />
                    <Route path="/epTeachLevel1" component={EpTeachLevel1} />
                    <Route path="/epFAQ" component={EPFAQPage} />
                    <Route path="/epabout" component={EPAboutPage} />
                    {/* 專家 */}
                    <Route path="/epExpertHome" component={EpExpertHome} /> 
                    <Route path="/epExpertGroup" component={EpExpertGroup} /> 
                    <Route path="/epExpertFeedback" component={EpExpertFeedback} /> 
                    <Route path="/epExpertOpinion" component={EpExpertOpinion} /> 
                    <Route path="/epExpertComment" component={EpExpertComment} /> 

                    
                    <Route path="/epStudExpert" component={EpStudExpert} /> 
                    <Route path="/epStudfeedback" component={EpStudfeedback} /> 
                    <Route path="/epStudLevel3" component={EpStudLevel3} /> 
                    <Route path="/EpteachExpert" component={EpTeachExpert} /> 
                    <Route path="/epTeachfeedback" component={EpTeachfeedback} /> 
                    <Route path="/epTeachLevel3" component={EpTeachLevel3} /> 
                    <Route path="/epStudResult" component={EpStudResult} /> 
                    <Route path="/epTeachResult" component={EpTeachResult} /> 
                    
                    {/* <Route path="/ep" component={EpHome} />  */}


                    {/* epStudLevel1 */}
                    <Route path="/" component={HomePack} />
                </Switch>
            </BrowserRouter>
            </ApplySysContext>
        {/*</MyContext.Provider> */}

        </>
    );
}

export default HomePage;
export { MyContext }
