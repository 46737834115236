

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo } from '../../services/EP/EpApplyStudentService';
import { getLoginInfo } from './tool/helper';
import { IGroupInfo, IGroupStudent, ILoginUserInfo, IStudentInfo } from '../../interfaces/EpModal';
import { getDrStudentByGroup } from '../../services/EP/EPApplyGroupService';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { getDrGroupByTeaNo } from '../../services/EP/EpApplyTeacherService';
import ExpertFunctionBar from './component/expertFunctionBar';







// https://attendance-67917bb.web.app/digit/epStudHome.html
const EPExpertHome: React.FunctionComponent<IPageProps> =() => {

  const [ currentLoginInfo , setCurrentLoginInfo] = useState<ILoginUserInfo> ()
  const [ groupInfoList  , setGroupInfo ] = useState<IGroupInfo[]>()

 useEffect(()=>{
  fetchdataExpertInfo();


 },
 [])
 
 /** */
const fetchdataExpertInfo =async ()=>{
  /** 取得登入資料 */

  let currentLoginInfo = getLoginInfo();
  console.log('目前登入資訊', currentLoginInfo);
  setCurrentLoginInfo(currentLoginInfo);

  // const groupInfo


}
    
return (
    <>

    <TopNavPage1 />
    <div className="section pt-3">
    <div className="container px-3 px-md-4">
      <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
        <h5 className="title mb-3 me-3">專題探究競賽</h5>

    <ExpertFunctionBar/>
      </div>
      <div className="text-center h5 mt-4">專家學者首頁</div>
      <div className="text-muted text-center">歡迎 <span>{currentLoginInfo?.LoginName}</span> 老師</div>
      <div className="text-muted fs-18 fw-bold">說明與提醒</div>
      <div className="bd-callout px-4 py-3">
        <ol className="mb-0">
          <li>111全國聯賽學者回饋 3/24 ~ 4/13 ， 共 21天。</li>
          <li>在「探究(回饋前)」和「回饋意見」都可編輯自己分配到小隊的回饋意見，並可參考其他人的建議和評語。</li>
          <li>可先將意見寫在 Memo, 確定後再 Copy到"線上回饋"欄位。</li>
          <li>111全國聯賽"線上回饋"欄位，目前學生的網頁還不能看到建議資料。等大家的資料齊全後再一起呈現。</li>
          <li>若無資料，請註明 (沒有第1,2階段的資料)</li>
          <li>若略懂Html語法，可用span,ol,li 等標籤編排字體和條目。</li>
        </ol>
      </div>
      <hr className="horizontal dark" />
      <div className="text-muted fs-18 fw-bold pt-3">專家學者回饋參考事項</div>
      <div className="card p-4 pt-3">
        <div className="fw-bolder text-second pt-3">1-1 提出探究題目</div>
        <ol>
          <li>可勉勵每位隊員都提出題目，或多元性的題材可供討論選擇。</li>
          <li>有些題目是指導老師設定的範圍，亦可給予適當的鼓勵。</li>
        </ol>
        <div className="fw-bolder text-second pt-3">1-2 決定探究題目</div>
        <ol>
          <li>若題目太大，可建議再聚焦。(例如題目為「台灣的教育制度」，在子題中若有提到日本的升學壓力，可建議改為「台灣和日本的考試制度有何異同?」)</li>
          <li>題目是否符合數位閱讀探究的精神。(例如「如何清除班上垃圾?」、「井字遊戲的必勝攻略?」、「投籃時何種角度是最容易進球的?」)</li>
          <li>題目避免流於只是事實陳述的資料蒐集與整理。(例如「球鞋的構造與種類?」、「鹽水蜂炮的由來?」)</li>
          <li>若題目較抽象，可建議加上副標題，讓別人一目了然。(例如「甜蜜的負荷」的主題，加上「手搖飲料對人的影響?」的副標題)</li>
          <li>題目本身建議改為較不帶觀點的客觀敘述。(例如「為什麼賞鳥活動有助於推動環境保育?」改為「賞鳥活動與環境保育的關係?」)</li>
          <li>探究的題目建議在資料蒐集整理時要以科學性的網站為依據，有可靠的統計數據更佳。(例如「世界上有沒有鬼?」、「什麼星座的人容易得皮膚病?」、「地球暖化與北極熊的關係?」)</li>
          <li>某些題目可建議以問句的敘述較恰當。</li>
        </ol>
        <div className="fw-bolder text-second pt-3">1-3 預測可能的結果</div>
        <ol>
          <li>若題目為是非問句，可建議學生在探究前先預測對題目，本身是偏向肯定或否定的答案?(例如「世界上有沒有鬼?」)</li>
          <li>若題目為What/How/Why問句，可建議學生寫出心中預測的答案，在網路搜尋資料時有方向，細心求證。</li>
        </ol>
        <div className="fw-bolder text-second pt-3">2-1 擬定探究子題</div>
        <ol>
          <li>子題是否涵蓋了探究題目，若有偏離或不足，可建議探究方向或可搜尋的題材。</li>
          <li>子題的順序可建議調整，並有邏輯性，如先定義問題。</li>
          <li>子題可建議正反面的議題並列和並重，使探究結果更全面和客觀。(例如題目為「是否應該延後上學時間?」,子題卻一面倒偏向肯定的論述)</li>
          <li>子題可建議改為較中立的敘述。(例如子題為「長輩為何比較不接受ACGN?」,改為「長輩對ACGN的看法」)</li>
          <li>建議隊員有適當的分工。</li>
        </ol>
        <div className="fw-bolder text-second pt-3">2-2 繪製探究架構</div>
        <ol>
          <li>架構圖可提醒字體清晰，大小適中，橫置轉正。</li>
          <li>架構圖最好3~4層，是由題目和子題發展出的關聯架構。</li>
          <li>若使用照片，要提醒注意版權問題，可在旁邊註明出處。</li>
        </ol>
        <div className="fw-bolder text-second pt-3">1-4, 2-3 困難、解決與新學習</div>
        <ol>
          <li>給予適當的鼓勵。</li>
          <li>「指導老師留言」中若有很好的老師意見，可提醒學生注意。</li>
          <li>「新學習」是本次競賽新增的項目，希望同學提出在這個階段的心得與收穫。</li>
        </ol>
      </div>

      <div className="pt-3 mt-4"></div>
    </div>
  </div>

      <FooterPage />
    </>
   
    )


    } 

export default EPExpertHome;