import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { IExSchool } from '../../interfaces/ExSchool';
import { IExStudent } from '../../interfaces/ExStudent';
import { addExTeaStuBySidTid, createExStudent, getMaxStuID, getNewStuNo } from '../../services/ExStuTeService';
import { BSModal } from './bsmodal';
import { IExTeacher } from '../../interfaces/ExTeacher';

interface ModalProps {
    isOpen: boolean;
    setOpenAddStuModal: (val:boolean) => void;
  }
  
  export const AddStuModal: React.FC<ModalProps> = (props) => {

    const [selGrade,setSelGrade]=useState<string>("");
    const [selClass,setSelClass]=useState<string>("");
    const [selSeat,setSelSeat]=useState<string>("");
    const [selStuName,setSelStuName]=useState<string>("");
    const [selGender,setSelGender]=useState<string>("");
    const [selPWD,setSelPWD]=useState<string>("");
    const [selMail,setSelMail]=useState<string>("");
    const [selRemark,setSelRemark]=useState<string>("");
    const [newStuNo,setNewStuNo]=useState<string>("");
    const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();

    const [errMsg,setErrMsg]=useState("");
    const [openErrModal,setOpenErrModal]=useState(false);

    const [otherSelClass,setOtherSelClass]=useState("");
    const classString=["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15"];

    const handleSubmit=async ()=>{
        let noError=true;
        let errMsg:string[]=[]
        if(selGrade==="")
        {
            noError=false;
            errMsg.push("年級欄位不可空白")
        }

        if(selClass==="")
        {
            noError=false;
            errMsg.push("班級欄位不可空白")
        }

        if((selClass==="其他") && (otherSelClass===""))
        {
            noError=false;
            errMsg.push("其他班級欄位不可空白")
        }

        if(selStuName==="") 
        {
            noError=false;
            errMsg.push("姓名欄位不可空白")
        }

        if(selGender==="")
        {
            noError=false;
            errMsg.push("性別欄位不可空白")
        }

        if(selPWD==="")
        {
            noError=false;
            errMsg.push("密碼欄位不可空白")
        }

        if(noError)
        {            
            if(loginTeacher!==undefined)
            {
                const maxSid=await getMaxStuID();
                if(maxSid!==undefined)
                {
                    const newSid=maxSid.MaxSid+1;
                    let selClassStr=""
                    if(selClass==="其他")
                    {
                        if(otherSelClass.includes("班"))
                        {
                            selClassStr=otherSelClass.replace("班","").trim();
                        }
                        else
                        {
                            selClassStr=otherSelClass.trim();
                        }
                    }
                    else
                    {
                        selClassStr=selClass;
                    }
                    await createExStudent(
                        newSid.toString().trim(),newStuNo,selStuName.trim(),selPWD,
                        loginTeacher.SchoolNo.trim(),loginTeacher.City.trim(),loginTeacher.Area.trim(),loginTeacher.SchoolName.trim(),
                        selGrade.trim(),selClassStr.trim(),selGender.trim(),'',selMail.trim(),'1',loginTeacher.TeaName.trim()+",",selSeat,selRemark);
                    await addExTeaStuBySidTid(newSid.toString().trim(),loginTeacher.Tid.toString().trim());              
                }
                props.setOpenAddStuModal(false);
            }
        }
        else
        {
            setOpenErrModal(!noError);
            const errMsgStr=errMsg.join(',');
            setErrMsg(errMsgStr);
        }      

    }
    
    
    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
          if (sessionStorage.getItem("LoginTeacher") !== null) {
    
            const lTea:IExTeacher = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
            const fetchData=async()=>{
                const newStuNo=await getNewStuNo(lTea.Tid.toString());                
                setNewStuNo(lTea.Tid.toString().trim()+"-"+newStuNo);
                setLoginTeaacher(lTea);
            }
            fetchData();
            setSelStuName("");
            setSelPWD("");
            setSelMail("");
            setSelRemark("");
            setSelGender("");
            setSelGrade("");
            setSelClass("");
            setSelSeat("");    
          }
        }

    },[])

    useEffect(()=>{
        if(props.isOpen)
        {
            if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
            {
              if (sessionStorage.getItem("LoginTeacher") !== null) {
        
                const lTea:IExTeacher = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
                const fetchData=async()=>{
                    const newStuNo=await getNewStuNo(lTea.Tid.toString());                
                    setNewStuNo(lTea.Tid.toString().trim()+"-"+newStuNo);
                    setLoginTeaacher(lTea);
                }
                fetchData();
                setSelStuName("");
                setSelPWD("");
                setSelMail("");
                setSelRemark("");
                setSelGender("");
                setSelGrade("");
                setSelClass("");
                setSelSeat("");              

              }
            }
        }
    },[props.isOpen])


    return  <Modal show={props.isOpen} onHide={()=>props.setOpenAddStuModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="modal-title">新增學生資料</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-3'>
                    <div className="col-12 col-sm-12 mt-3">
                        <label>登入號</label>
                        <div className="input-group">
                        <input type="text" className="form-control" placeholder="" value={newStuNo} disabled/>
                        </div>
                    </div>
                    <form role="form text-left">
                        <div className="row">
                            <div className="col-12 col-sm-6 mt-3">
                                <div>★</div>
                                <select className="form-control" onChange={(e)=>setSelGrade(e.currentTarget.value)}>
                                <option value="grade1" selected disabled>(年級)</option>
                                <option value="一">一年級</option>
                                <option value="二">二年級</option>
                                <option value="三">三年級</option>
                                <option value="四">四年級</option>
                                <option value="五">五年級</option>
                                <option value="六">六年級</option>
                                <option value="七">七年級</option>
                                <option value="八">八年級</option>
                                <option value="九">九年級</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-6 mt-3">
                                <div>★</div>
                                <select className="form-control" onChange={(e)=>setSelClass(e.currentTarget.value)}>
                                <option value="class1" selected disabled>(班級)</option>
                                <option value="1" >1班</option>
                                <option value="2">2班</option>
                                <option value="3">3班</option>
                                <option value="4">4班</option>
                                <option value="5">5班</option>
                                <option value="6">6班</option>
                                <option value="7">7班</option>
                                <option value="8">8班</option>
                                <option value="9">9班</option>
                                <option value="10">10班</option>
                                <option value="11">11班</option>
                                <option value="12">12班</option>
                                <option value="13">13班</option>
                                <option value="14">14班</option>
                                <option value="15">15班</option>
                                <option value="其他">其他</option>
                                </select>
                                {
                                    selClass==="其他" && (
                                    <div className="mt-2">
                                        <input type="text" className="form-control mt-2" placeholder="輸入班級" value={otherSelClass} onChange={(e)=>setOtherSelClass(e.currentTarget.value)}/>
                                    </div> )                                   
                                    
                                }
                            </div>

                            <div className="col-12 col-sm-6 mt-3">
                                <label>座號</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入座號" value={selSeat} onChange={(e)=>setSelSeat(e.currentTarget.value)}/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-3">
                                <label>★姓名</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入姓名" value={selStuName} onChange={(e)=>setSelStuName(e.currentTarget.value)}/>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-3">
                                <label htmlFor="myclass" className="me-4">★性別</label>
                                <div className="d-flex">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="sex" id="sex1" onChange={()=>setSelGender("男")}/>
                                    <label className="custom-control-label me-4" htmlFor="sex1">男</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="sex" id="sex2" onChange={()=>setSelGender("女")}/>
                                    <label className="custom-control-label" htmlFor="sex2">女</label>
                                </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-3">
                                <label>★密碼</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入密碼" value={selPWD} onChange={(e)=>setSelPWD(e.currentTarget.value)} />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label>第三方平台Email</label>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="輸入Email" value={selMail} onChange={(e)=>setSelMail(e.currentTarget.value)}/>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label>備註</label>
                                <div className="input-group">
                                    <textarea rows={2} className="form-control" placeholder="輸入備註" value={selRemark} onChange={(e)=>setSelRemark(e.currentTarget.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <button type="button" className="btn btn-secondary" onClick={()=>props.setOpenAddStuModal(false)}>取消</button>
                    <button type="button" className="btn btn-primary" onClick={()=>handleSubmit()}>儲存</button>
                </Modal.Footer>
                <BSModal
                title={'基本資料欄位輸入錯誤'}
                isOpen={openErrModal}
                setOpenModal={setOpenErrModal}                
                >
                {errMsg}
                </BSModal> 
            </Modal>

    };