import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { IGroupForExpertS, IInsertTeacher, ITearcherInfo, IUpdateTeacher } from '../../../interfaces/EpModal';
import { IExTeacher } from '../../../interfaces/ExTeacher';
import { insertDrTeacherByApplyNo, updateDrTeacherByNo } from '../../../services/EP/EpApplyTeacherService';
import { Modal } from 'react-bootstrap';
import OpinionSection from '../component/opinionSection';
import { updateDrGroupExpOpinion } from '../../../services/EP/EpExpertService';
import { debug } from 'console';
import { alertEx } from '../tool/helper';

interface IModalComponentProps {
    
    allList :IGroupForExpertS[]

    currentData:IGroupForExpertS |undefined
    /** 儲存完後目前改變currentData */
    setCurrentData: (val: IGroupForExpertS) => void;
    /** 此視窗是否開啟 */
    
    isOpen: boolean;
    /** 關閉此視窗 */
    // setIsModalOpen: (val: boolean) => void;
   
    handleCloseModal :() => void;
   
}
interface IformData{
  expertOpinion :string  |any
  expertMemo :string | any

}




const ExportOpinionModal: React.FunctionComponent<IModalComponentProps> = ({allList,setCurrentData ,handleCloseModal, isOpen,currentData }) => {

    const [formData, setFormData] = useState<IformData>({ expertOpinion :"",expertMemo :""});
    const [title, setTitle] = useState('');

    useEffect(()=>{
      setFormData({expertOpinion :currentData?.ExpOpinion ,expertMemo:currentData?.ExpMemo ||''})
    },[currentData])

    /** 儲存後下一筆 */
  const updateDataAndNext =async ()=>{
  await updateData()
  if(currentData?.Groupno){
   const tempItem  = allList.find(i => i.Groupno === currentData.Groupno)
   if(tempItem){
     const index  = allList.indexOf(tempItem);
     setCurrentData(allList[index+1])
   }



  }
  
}
const updateDataAndClose =async ()=>{
        await updateData();
        handleCloseModal()
}
    /** 儲存 */
const updateData  =async ()=>{
      let param ={
        ExpOpinion: formData?.expertOpinion ||'',
        ExpMemo: formData?.expertMemo||''
      } 
      if(currentData?.Groupno){
        try{
          await  updateDrGroupExpOpinion(param ,currentData?.Groupno ) ;
          alert(currentData?.Groupname.trim() +"小隊,儲存成功!")
  
          
        }catch(ex){
            alert( "發生錯誤 :" +JSON.stringify(ex))
        }
      }
 }

  
    return (
        <>
            <Modal size='xl'    dialogClassName="modal-90w"  show={isOpen} onHide={() =>handleCloseModal()} style={{ width: '1200px !important' }} >
                <Modal.Header  > 
                    <Modal.Title>
                        <h5 className="modal-title">回饋意見</h5>
                        {/* {JSON.stringify(formData)} */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-3"  style={{ maxWidth: '2000px' }}>
                <div className="d-flex py-3">
                                <div className="overflow-auto px-3" style={{ width: '800px', maxHeight: '515px' }}>
                                {currentData?.Groupno ?<OpinionSection  groupNo={currentData?.Groupno}/>:<></>}
                                </div>
                                <form role="form text-left" className="ms-3" style={{ width: '520px' }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <label>小隊：</label>
                                            <span>{currentData?.Groupno }{currentData?.Groupname}</span>
                                        </div>
                                        <div className="col-12">
                                            <label>題目 :</label>
                                            <span>{currentData?.Topic}</span>
                                        </div>
                                        <div className="col-12">
                                            <label className="mt-3">線上回饋</label>
                                            <div className="input-group">
                                                <textarea
                                                value={formData?.expertOpinion||""} 
                                                onChange={(e)=>setFormData({ ...formData, expertOpinion: e.target.value }as IformData  )}
                                                rows={7} className="form-control" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label className="mt-3">Memo</label>
                                            <div className="input-group">
                                                <textarea rows={7} 
                                                    value={formData?.expertMemo||''} 
                                              
                                                    onChange={(e)=>setFormData({ ...formData, expertMemo: e.target.value }as IformData  )}
                                                className="form-control" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                </Modal.Body>
                <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>handleCloseModal()}  >
                                取消
                            </button>
                            <button type="button" className="btn btn-primary mx-3" onClick={()=>updateDataAndClose()}>
                                儲存
                            </button>
                            <button type="button" className="btn btn-blue" onClick={()=>updateDataAndNext()} >
                                儲存並繼續編輯下一隊
                            </button>
                </Modal.Footer>
            </Modal>

            {/*  */}

    
            {/*  */}
        </>
    );
};

export default ExportOpinionModal;
