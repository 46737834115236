import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TeacherFunctionBar from './component/teacherFunctionBar';
import FooterPage from '../footer';
import TopNavPage1 from '../topNav1';
import { getDrGroupByTeaNo, getDrTeacherByNo, getOpinionByGroupOpIdx } from '../../services/EP/EpApplyTeacherService';
import { alertEx, currentClass, formatDate, getLoginInfo, getSessionStudentDisplayInfo, setLoginInfoOnSession, studentloginInfo } from './tool/helper';
import Schedule from './component/schedule';
import { IOpinion, TeacherGroupInfo, TeacherlInfo } from '../../interfaces/EpModal';
import StudentFunctionBar from './component/studentFunctionBar';
import { getOpinionByGroupNo } from '../../services/EP/EpApplyExploreService';
import GroupSelect from './component/groupSelect';

interface IGroupInfo {
    FlagOK: string;
    GroupName: string;
    GroupNo: string;
    IsEnterFinal: string;
    PptDate: string;
    Team: string;
    Topic: string;
    UploadDate: string;
}

// interface ITeacherInfoDisplay {
//     FlagOK: string;
//     GroupName: string;
//     GroupNo: string;
//     IsEnterFinal: string;
//     PptDate: string;
//     Team: { StuNo: string; StuName: string }[];
//     TeamString?: string;
//     /** 探究題目 */
//     data12: string;
//     UploadDate: string;
//     Remark: string;
//     Topic: string;
// }

const EpTeachExpert: React.FunctionComponent<IPageProps> = () => {

    const [selectGroupInfo, setSelectGroupInfo] = useState<TeacherGroupInfo|undefined >({}as TeacherGroupInfo);
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [teacherInfo, setTeacherInfo] = useState<TeacherlInfo>();
    const [teaccherGroupInfoList, setTeacherGroupInfoList] = useState<TeacherGroupInfo[]>([]);

    const [classYear, setClassYear] = useState<string>('112年度');

    const [studentInfo, setStudentInfo] = useState<studentloginInfo>();
    const [opinion, setOpinion] = useState<IOpinion>();
    useEffect(() => {
    
        fetchGroupInfo();
     
        // setStudentInfo(studentInfo);
    }, []);

    useEffect(() => {
        alertEx("selectGroupInfo")
        if(selectGroupInfo?.GroupNo){
            fetchOpinion(selectGroupInfo?.GroupNo) ;
        }
        // setStudentInfo(studentInfo);
    }, [selectGroupInfo]);


    /** 取得1-1探究題目 */
    const fetchDataindex11 = async (isfeedback: 0 | 1, index: string, groupNo: string) => {
        try {
            let data = (await getOpinionByGroupOpIdx(isfeedback, index, groupNo)) as any;
            return data[0];
        } catch (ex) {
            alert('發生錯誤 :' + JSON.stringify(ex));
        }
    };

    /** */
    const arrayToString = (arr: any[], attrube: string) => {
        let itemArr: string[] = [];
        arr.forEach((item) => {
            itemArr.push(item[attrube]);
        });
        return itemArr.join('/');
    };

    const fetchGroupInfo = async () => {
        let currentLoginInfo = getLoginInfo();
        console.log('epExport...Logininfo', currentLoginInfo.LoginID.trim());
        setCurrentLoginInfo(currentLoginInfo);
        const rsp = (await getDrGroupByTeaNo(currentClass, currentLoginInfo.LoginID.trim())) as any;
        if (rsp) {
            alertEx(rsp)
            console.log('rsp---', rsp?.Groups);
            setTeacherGroupInfoList(rsp?.Groups);
            setSelectGroupInfo(rsp?.Groups[0]);
        }
    };

    const fetchOpinion = async (groupNo: string) => {
        let opinions = (await getOpinionByGroupNo(groupNo)) as IOpinion[];
        setOpinion(opinions[0]);
        console.log('epstudent opinion  solutoin', opinions);
    };

    return (
        <>
            {/* {JSON.stringify(teacherGroupInfoList)} */}
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <TeacherFunctionBar />
                    </div>
                    <div className="text-center h5 my-4">專家學者線上回饋</div>
                    <div className="text-center">
                        <div>(112年4月17日12:00前提供回饋意見)</div>
                        <div>專家學者將依據階段1和階段2的內容，提供各隊回饋建議。</div>
                    </div>
                    <hr className="horizontal dark" />
                    <div className='justify-content-center'>
 
                    </div>
                    <div className="text-center fw-bolder text-muted fs-18 my-4">
                    <GroupSelect  selectGroupInfo={selectGroupInfo} teaccherGroupInfoList={teaccherGroupInfoList} setSelectGroupInfo ={setSelectGroupInfo}/>
                        {/* {studentInfo?.SchoolName} {studentInfo?.GroupName} ({studentInfo?.GroupNo}) */}
                    </div>
                    <div className="fs-18">
                        <div className="card mx-auto p-4" style={{ maxWidth: '800px' }}>
                            {opinion?.ExpOpinion ? opinion.ExpOpinion : '(專家學者尚未回饋) '}
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <img src="./assets/img/cheer_up1.png" alt="" className="" />
                </div>

                <div className="pt-3 mt-4"></div>
            </div>
            <FooterPage />
        </>
    );
};

export default EpTeachExpert;
