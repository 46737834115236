import React from 'react';
import ReactDOM from 'react-dom/client';
import config from './config/config';
import express from 'express';
import HomePage from './pages/home';
import ApplySysContext from './components/sysContext';

//localStorage.setItem("countPP1",JSON.stringify(0));
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/** oAuth */
/*
const router = express();
router.use('/auth/signin', (req, res) => {
  // OAuth 2.0 單一簽入流程 - 步驟 1
  const signInUrl = [
      'https://auth.ischool.com.tw/oauth/authorize.php',
      `?client_id=${config.oAuth.clientID}`,
      `&redirect_uri=${config.oAuth.redirectURL}`,
      '&response_type=code',
      '&scope=User.Mail,User.BasicInfo',
      '&state=我是一個自訂的變數',
  ].join('');
  
  console.log('signInUrl => ', signInUrl);
  return res.redirect(signInUrl);
});

router.use('/auth/callback', async (req, res) => {
    if (req.query.code) {
        // OAuth 2.0 單一簽入流程 - 步驟 2
        const tokenUrl = [
      'https://auth.ischool.com.tw/oauth/token.php',
      `?client_id=${config.oAuth.clientID}`,
      `&client_secret=${config.oAuth.clientSecret}`,
      `&redirect_uri=${config.oAuth.redirectURL}`,
      '&grant_type=authorization_code',
      `&code=${req.query.code}`,
    ].join('');
    console.log('code'+req.query.code);

    const token:any = await (await fetch(tokenUrl)).json();
    console.log('token => ', token);
    
    if (!token.error) {
        // OAuth 2.0 單一簽入流程 - 步驟 3
        const profileUrl = [
            'https://auth.ischool.com.tw/services/me.php',
            `?access_token=${token.access_token}`,
          ].join('');
          
          const profile:any = await (await fetch(profileUrl)).json();
          console.log('profile => ', profile);
          
          if (!profile.error) {
              // OAuth 2.0 單一簽入流程 - 步驟 4
              return res.send({
                  status: 'Success',
                  state: req.query.state,
                  profile,
              });
          }

          // OAuth 2.0 單一簽入流程 - 步驟 5
          return res.send({ status: 'Error', state: req.query.state, profile });
      }

      // OAuth 2.0 單一簽入流程 - 步驟 5
      return res.send({ status: 'Error', state: req.query.state, token });
  }

  // OAuth 2.0 單一簽入流程 - 步驟 5
  return res.send({
    status: 'Error',
    state: req.query.state,
    error: 'Missing code variable',
  });
});

*/

root.render(
  <React.StrictMode>
    <HomePage />
  </React.StrictMode>
);



