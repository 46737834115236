import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { IExSchool } from '../../interfaces/ExSchool';
import { IExStudent } from '../../interfaces/ExStudent';
import { getExSchoolAreaByCity, getExSchoolAreaByCityArea, getExSchoolCities } from '../../services/ExSchoolService';
import { updateExStudent, updateExTeacher } from '../../services/ExStuTeService';
import { BSModal } from './bsmodal';
import { IExTeacher } from '../../interfaces/ExTeacher';

interface ModalProps {
    isOpen: boolean;
    setOpenUpdModal: (val:boolean) => void;
  }
  
  export const UpdModal: React.FC<ModalProps> = (props) => {
    const [city,setCity]=useState<string[]>([]);
    const [selCity,setSelCity]=useState("");
    const [area,setArea]=useState<string[]>([]);
    const [selArea,setSelArea]=useState("")
    const [schoolName,setSchoolName]=useState<IExSchool[]>([])
    const [selSchool,setSelSchool]=useState("");
    const [selGrade,setSelGrade]=useState("");
    const [selClass,setSelClass]=useState("");
    const [otherSelClass,setOtherSelClass]=useState("");  
    const [loginStu,setLoginStu]=useState<IExStudent>();
    const [loginTeacher,setLoginTeacher]=useState<IExTeacher>();
    const [pressed,setPressed]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const [openErrModal,setOpenErrModal]=useState(false);
    const classString=["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15"];

    const handleSubmit=async ()=>{
        let noError=true;
        let errMsg=""
        if(selCity==="")
        {
            noError=false;
            errMsg=errMsg+"縣市不可空白,"
        }
        if(selArea==="")
        {
            noError=false;
            errMsg=errMsg+"鄉鎮區不可空白,"
        }
        if(selSchool==="")
        {
            noError=false;
            errMsg=errMsg+"學校不可空白,"
        }

        if(noError)
        {            
            setPressed(true);
            props.setOpenUpdModal(false);
        }
        else
        {
            setOpenErrModal(!noError);
            errMsg=errMsg.substring(0,errMsg.length-1);
            setErrMsg(errMsg);
        }      

    }
    
    const updSchool=async ()=>{
        if((selArea!=="") && (selCity!==""))
        {
            await getExSchoolAreaByCityArea(selCity,selArea).then(data=>{
                setSchoolName(data);          
                let sch=document.getElementById('school')!;
                if(sch!==null) sch.innerHTML=`<option value="" disabled selected>(選擇學校)</option>`
                data.map(item=>{
                            if(sch!==null) sch.innerHTML=sch.innerHTML+`<option value="${item.SchoolNo},${item.SchoolName}" >${item.SchoolName}</option>`
                })
            
            })
        }
    }
    const presetAll=async ()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(loginTeacher!==undefined)
            {
                await getExSchoolCities().then(cityArray=>
                {
                    setCity(cityArray)
                    let cityElem=document.getElementById('city')!;
                    cityElem.innerHTML=`<option value="" disabled selected>(選擇縣市)</option>`
                    cityArray.map(cityItem=>{
                        if(loginTeacher.City !==null)
                        {
                            if(loginTeacher.City.trim()===cityItem.trim())
                            {
                                setSelCity(loginTeacher.City);
                                cityElem.innerHTML=cityElem.innerHTML+` <option value="${cityItem}" selected>${cityItem}</option>`
                                getExSchoolAreaByCity(loginTeacher.City).then(areaArray=>{
                                    setArea(areaArray);
                                    let areaElem=document.getElementById('area')!;
                                        areaElem.innerHTML=`<option value="" disabled selected>(選擇鄉鎮區)</option>`
                                        areaArray.map(areaItem=>{
                                            if(loginTeacher.Area.trim()===areaItem.trim())
                                            {
                                                setSelArea(loginTeacher.Area);
                                                areaElem.innerHTML=areaElem.innerHTML+` <option value="${areaItem}" selected>${areaItem}</option>`
                                                getExSchoolAreaByCityArea(loginTeacher.City,loginTeacher.Area).then(schArray=>{
                                                    setSchoolName(schArray);
                                                    let schElem=document.getElementById('school')!;
                                                    schElem.innerHTML=`<option value="" disabled selected>(選擇學校)</option>`
                                                    schArray.map(schItem=>{
                                                        if(schItem.SchoolName.trim()===loginTeacher.SchoolName.trim())
                                                        {
                                                            setSelSchool(loginTeacher.SchoolNo+","+loginTeacher.SchoolName);
                                                            schElem.innerHTML=schElem.innerHTML+`<option value="${schItem.SchoolNo},${schItem.SchoolName}" selected>${schItem.SchoolName}</option>`
                                                        }
                                                        else
                                                        {
                                                            schElem.innerHTML=schElem.innerHTML+`<option value="${schItem.SchoolNo},${schItem.SchoolName}" >${schItem.SchoolName}</option>`
                                                        }                                   
                    
                                                    })
                                                })
                                            }
                                            else
                                            {
                                                areaElem.innerHTML=areaElem.innerHTML+` <option value="${areaItem}" >${areaItem}</option>`
                                            }
                                        })
                                    
                                })
                            }
                            else
                            {
                                cityElem.innerHTML=cityElem.innerHTML+` <option value="${cityItem}" >${cityItem}</option>`
                            }

                        }
                        else
                        {
                            cityElem.innerHTML=cityElem.innerHTML+` <option value="${cityItem}" >${cityItem}</option>`
                        }
                    })
    
                });
     
            }
        }
        else //學生登入
        {
            if(loginStu!==undefined)
            {
                await getExSchoolCities().then(cityArray=>
                {
                    setCity(cityArray)
                    let cityElem=document.getElementById('city')!;
                    cityElem.innerHTML=`<option value="" disabled selected>(選擇縣市)</option>`
                    cityArray.map(cityItem=>{
                        if(loginStu.City !==null)
                        {
                            if(loginStu.City.trim()===cityItem.trim())
                            {
                                setSelCity(loginStu.City);
                                cityElem.innerHTML=cityElem.innerHTML+` <option value="${cityItem}" selected>${cityItem}</option>`
                                getExSchoolAreaByCity(loginStu.City).then(areaArray=>{
                                    setArea(areaArray);
                                    let areaElem=document.getElementById('area')!;
                                        areaElem.innerHTML=`<option value="" disabled selected>(選擇鄉鎮區)</option>`
                                        areaArray.map(areaItem=>{
                                            if(loginStu.Area.trim()===areaItem.trim())
                                            {
                                                setSelArea(loginStu.Area);
                                                areaElem.innerHTML=areaElem.innerHTML+` <option value="${areaItem}" selected>${areaItem}</option>`
                                                getExSchoolAreaByCityArea(loginStu.City,loginStu.Area).then(schArray=>{
                                                    setSchoolName(schArray);
                                                    let schElem=document.getElementById('school')!;
                                                    schElem.innerHTML=`<option value="" disabled selected>(選擇學校)</option>`
                                                    schArray.map(schItem=>{
                                                        if(schItem.SchoolName.trim()===loginStu.SchoolName.trim())
                                                        {
                                                            setSelSchool(loginStu.SchoolNo+","+loginStu.SchoolName);
                                                            schElem.innerHTML=schElem.innerHTML+`<option value="${schItem.SchoolNo},${schItem.SchoolName}" selected>${schItem.SchoolName}</option>`
                                                        }
                                                        else
                                                        {
                                                            schElem.innerHTML=schElem.innerHTML+`<option value="${schItem.SchoolNo},${schItem.SchoolName}" >${schItem.SchoolName}</option>`
                                                        }                                   
                    
                                                    })
                                                })
                                            }
                                            else
                                            {
                                                areaElem.innerHTML=areaElem.innerHTML+` <option value="${areaItem}" >${areaItem}</option>`
                                            }
                                        })
                                    
                                })
                            }
                            else
                            {
                                cityElem.innerHTML=cityElem.innerHTML+` <option value="${cityItem}" >${cityItem}</option>`
                            }

                        }
                        else
                        {
                            cityElem.innerHTML=cityElem.innerHTML+` <option value="${cityItem}" >${cityItem}</option>`
                        }
                    })
    
                });
                let tmpGrade=(document.getElementById("grade") as HTMLSelectElement)
                tmpGrade.value=loginStu.Grade;
                let tmpClass=(document.getElementById("class") as HTMLSelectElement)
                if(classString.includes(loginStu.Class.trim()))
                {
                    tmpClass.value=loginStu.Class.trim();
                }
                else
                {
                    tmpClass.value="其他";         
                }
            }

        }
    }

    const updArea=async ()=>{
        if(selCity!=="")
        {
            await getExSchoolAreaByCity(selCity).then(data=>{
                setArea(data);       
                let area=document.getElementById('area');
                if(area!==null)
                {
                    area.innerHTML=`<option value="" disabled selected>(選擇鄉鎮區)</option>`
                    data.map(item=>{
                        if(area!=null) area.innerHTML=area.innerHTML+` <option value="${item}" >${item}</option>`
                    })
                }    
                let sch=document.getElementById('school')!;
                if(sch!==null) sch.innerHTML=`<option value="" disabled selected>(選擇學校)</option>`
            })
        }
    }
    
    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="0") return;
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(sessionStorage.getItem("LoginTeacher")!==null)
            {
                const lTea=JSON.parse(sessionStorage.getItem("LoginTeacher")!)
                setLoginTeacher(lTea);
            }
        }
        else //學生登入
        {
            if(sessionStorage.getItem("LoginStudent")!==null)
            {
                const lStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!)
                setLoginStu(lStu);
            }   

        }
    },[])

    useEffect(()=>{
        if(props.isOpen)
        {
            if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
            {
                if(sessionStorage.getItem("LoginTeacher")!==null)
                {
                    const lTea=JSON.parse(sessionStorage.getItem("LoginTeacher")!)
                    setLoginTeacher(lTea);
                }
            }
            else //學生登入
            {
                if(sessionStorage.getItem("LoginStudent")!==null)
                {
                    const lStu:IExStudent=JSON.parse(sessionStorage.getItem("LoginStudent")!)
                    setLoginStu(lStu);
                }
            }
            presetAll();
            
            if(loginStu!==undefined)
            {
             setSelGrade(loginStu.Grade);
             if (classString.includes(loginStu.Class.trim()))
             {
                 setSelClass(loginStu.Class.trim());
                 setOtherSelClass("");
             }
             else
             {
                 setSelClass("其他");
                 setOtherSelClass(loginStu.Class.trim()+"班");
             }

            }
        }
    },[props.isOpen])

    useEffect(()=>{
        
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(loginTeacher!==undefined)
            {
                if(selCity!==loginTeacher.City)
                {
                    updArea();
                    setSelArea("");
                    setSelSchool("");
                }
            }
        }
        else //學生登入
        {
            
            if(loginStu!==undefined)
            {
                if(selCity!==loginStu.City)
                {
                    updArea();
                    setSelArea("");
                    setSelSchool("");
                }
            }
        }
    },[selCity])

    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
        {
            if(loginTeacher!==undefined)
            {
                if(selArea!==loginTeacher.Area)
                {
                    updSchool();
                    setSelSchool("");
                }
            }
        }
        else //學生登入
        {
            if(loginStu!==undefined)
            {
                if(selArea!==loginStu.Area)
                {
                    updSchool();
                    setSelSchool("");
                }
            }
        }
    },[selArea])

    useEffect(()=>{
        if(pressed)
        {
            if(sessionStorage.getItem("LoginStatus")==="1") //學生登入
            {
                if(loginStu!==undefined)
                {
                    const schDetail=selSchool.split(",");
                    let modified=false;
                    if((loginStu.City===null) || (loginStu.Area===null) || (loginStu.SchoolName ===null) || (loginStu.SchoolNo===null))
                    {
                      loginStu.City=selCity;
                      loginStu.City=selCity;
                      loginStu.Area=selArea;
                      loginStu.SchoolNo=schDetail[0];
                      loginStu.SchoolName=schDetail[1];
                      modified=true;
                    }
                    else
                    {
                        if((loginStu.City.trim()!==selCity.trim()) || (loginStu.Area.trim()!==selArea.trim()) || (loginStu.SchoolNo.trim()!==schDetail[0].trim()) || (loginStu.SchoolName.trim()!==schDetail[1].trim()))
                        {
                            loginStu.City=selCity;
                            loginStu.Area=selArea;
                            loginStu.SchoolNo=schDetail[0];
                            loginStu.SchoolName=schDetail[1];
                            modified=true;
                        }
                    }
                    if((loginStu.Grade.trim()!==selGrade.trim()) || (loginStu.Class.trim()!==selClass.trim()))
                    {                 
                        loginStu.Grade=selGrade;
                        let selClassStr="";
                        if(selClass==="其他")
                        {
                            if(otherSelClass.includes("班"))
                            {
                                selClassStr=otherSelClass.replace("班","").trim();
                            }
                            else
                            {
                                selClassStr=otherSelClass.trim();
                            }
                        }
                        else
                        {
                            selClassStr=selClass.trim();
                        }
                        loginStu.Class=selClassStr;
                        modified=true;
                    }
                    if(modified)
                    {
                        setLoginStu(loginStu);
                        sessionStorage.setItem("LoginStudent",JSON.stringify(loginStu));
                        updateExStudent(
                            loginStu.Sid.toString(),
                            schDetail[0],
                            selCity,
                            selArea,
                            schDetail[1],
                            selGrade,
                            loginStu.Class
                        )

                    }                       
                    
                }
            }
            else
            {
                if(loginTeacher!==undefined)
                {
                    const schDetail=selSchool.split(",");
                    let modified=false;
                    if((loginTeacher.City===null) || (loginTeacher.Area===null) || (loginTeacher.SchoolName ===null) || (loginTeacher.SchoolNo===null))
                    {
                      loginTeacher.City=selCity;
                      loginTeacher.City=selCity;
                      loginTeacher.Area=selArea;
                      loginTeacher.SchoolNo=schDetail[0];
                      loginTeacher.SchoolName=schDetail[1];
                      modified=true;
                    }
                    else
                    {
                        if((loginTeacher.City.trim()!==selCity.trim()) || (loginTeacher.Area.trim()!==selArea.trim()) || (loginTeacher.SchoolNo.trim()!==schDetail[0].trim()) || (loginTeacher.SchoolName.trim()!==schDetail[1].trim()))
                        {
                            loginTeacher.City=selCity;
                            loginTeacher.Area=selArea;
                            loginTeacher.SchoolNo=schDetail[0];
                            loginTeacher.SchoolName=schDetail[1];
                            modified=true;
                        }
                    }
                    if(modified)
                    {                 
                        updateExTeacher(
                            loginTeacher.Tid.toString(),
                            schDetail[0],
                            selCity,
                            selArea,
                            schDetail[1]
                        )
                        loginTeacher.City=selCity;
                        loginTeacher.Area=selArea;
                        loginTeacher.SchoolNo=schDetail[0];
                        loginTeacher.SchoolName=schDetail[1];
                        setLoginTeacher(loginTeacher);
                        sessionStorage.setItem("LoginSchoolNo",schDetail[0]);
                        sessionStorage.setItem("LoginSchoolName",schDetail[1]);
                        sessionStorage.setItem("LoginTeacher",JSON.stringify(loginTeacher));
                    }
                }
            }
            setPressed(false);
        }
    },[pressed])

    return  <Modal show={props.isOpen} onHide={()=>props.setOpenUpdModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="modal-title">修改基本資料</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-3'>
                    <form role="form text-left">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <label className="mt-4">縣市</label>
                                <select id="city" className="form-control js-choice" placeholder="選擇縣市" onChange={(e)=>setSelCity(e.currentTarget.value)}>

                                </select>
                            </div>
                            <div className="col-12 col-sm-6">
                                <label className="mt-4">鄉鎮區</label>
                                <select id="area" className="form-control js-choice" placeholder="選擇鄉鎮區" onChange={(e)=>setSelArea(e.currentTarget.value)}>

                                </select>
                            </div>
                        </div>
                        <label className="mt-4">學校</label>
                        <select id="school" className="form-control js-choice" placeholder="選擇學校" onChange={(e)=>setSelSchool(e.currentTarget.value)}>

                        </select>
                        {(sessionStorage.getItem("LoginStatus")==="1") &&
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <label className="mt-4">年級</label>
                                    <select className="form-control js-choice" placeholder="選擇年級" id="grade" onChange={(e)=>setSelGrade(e.currentTarget.value)}>
                                        <></>
                                        <option value="一">一</option>
                                        <option value="二">二</option>
                                        <option value="三">三</option>
                                        <option value="四">四</option>
                                        <option value="五">五</option>
                                        <option value="六">六</option>
                                        <option value="七">七</option>
                                        <option value="八">八</option>
                                        <option value="九">九</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="myclass" className="mt-4">班級</label>
                                    <select className="form-control js-choice" placeholder="選擇班級" id="class" onChange={(e)=>setSelClass(e.currentTarget.value)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="其他">其他</option>
                                    </select>
                                    {
                                    selClass==="其他" && (
                                    <div className="mt-2">
                                        <input type="text" className="form-control mt-2" placeholder="輸入班級" value={otherSelClass} onChange={(e)=>setOtherSelClass(e.currentTarget.value)}/>
                                    </div> )                                   
                                    
                                    }
                                </div>
                            </div>
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={()=>props.setOpenUpdModal(false)}>取消</button>
                    <button type="button" className="btn btn-primary" onClick={()=>handleSubmit()}>儲存</button>
                </Modal.Footer>
                <BSModal
                title={'基本資料欄位輸入錯誤'}
                isOpen={openErrModal}
                setOpenModal={setOpenErrModal}                
                >
                {errMsg}
                </BSModal> 
            </Modal>

    };