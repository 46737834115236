
import React, { useEffect, useState } from 'react'

import IAnsQDP from '../../../interfaces/AnsQDP';
import IAnsStateDP from '../../../interfaces/AnsStateDP';
import IQuestionDP from '../../../interfaces/QuestionDP';
import { getLearnRecVal, setLearnRecVal } from '../../../modules/SessionTestCnt';
import { AddExStuDP, AddExStuRec } from '../../../services/ExStuRecService';
import { UpdExStuLearnRec } from '../../../services/ExStuTeService';



interface IExDPQAProp {
    ArticleNo:string;
    PageNo:number;
    questionDP:IQuestionDP[][];
    ansStateDP:IAnsStateDP[][];
    ansQDP:IAnsQDP[];
    LearnTask:string;
    finishFlag:boolean;
    currTab:number;
    QMode:string;
    OPMode:string;
    SetAnsStateDP:(val:IAnsStateDP[][])=>void;
    SetAnsQDP:(val:IAnsQDP[])=>void;
    SetFinishFlag:(val:boolean)=>void;
    SetCurrTab:(val:number)=>void;
}
const DPQAPage: React.FunctionComponent<IExDPQAProp> = props => {
    const [currTabQ,setCurrTabQ]=useState(0);
    const [unfinishAns,setUnFinishAns]=useState("1");

    // Handel onclick event for input type of textarea 
    const handelTextInp=(inpStr:string,idx:number)=>{
        props.ansStateDP[currTabQ][idx].InputText=inpStr
        props.SetAnsStateDP(props.ansStateDP);
        setUnFinishAns(chkAnsState());

    }

    // Handel onclick event for input type of radio 
    const handelRadioInp=(idx:number,selIdx:number)=>{
        props.ansStateDP[currTabQ][idx].SelectItems=selIdx.toString();
        props.SetAnsStateDP(props.ansStateDP);
        setUnFinishAns(chkAnsState());
    }

    // Handel onclick event for input type of checkbox 
    const handleCheckInp=(idx:number,selIdx:number)=>{
        let result=props.ansStateDP[currTabQ][idx].SelectItems
        const cidx=result.indexOf(selIdx.toString())
        //if(result.includes(selIdx.toString()))
        if(cidx!==-1)
        {
            result=result.slice(0,cidx)+result.slice(cidx+1)
        }
        else
        {
            result=result+selIdx.toString();
            let tmpresult=result
            result=""
            for(let j=0;j<props.questionDP[currTabQ][idx].QuestionList.length;j++)
            {
                if(tmpresult.includes(j.toString())) result=result+j.toString()

            }
        }
        props.ansStateDP[currTabQ][idx].SelectItems=result
        props.SetAnsStateDP(props.ansStateDP);
        setUnFinishAns(chkAnsState());
    }

    // Handle form loading event to reset all input fields and fill with previous answers 
    const handleReset=()=>{
     
        (document.getElementById('question-form') as HTMLFormElement).reset();
        setInitial();
    }

    // Fill previous answer
    const setInitial=()=>{
        props.ansStateDP[currTabQ].forEach((val,idx)=>{
            if(val.Type==='簡答')
            {
                let tmp=(document.getElementById("ans"+currTabQ.toString()+"-"+val.Qno.toString()) as HTMLInputElement)
                if(tmp!==null) tmp.value=props.ansStateDP[currTabQ][idx].InputText
            }
            if(val.Type==='單選')
            {
                if(props.ansStateDP[currTabQ][idx].SelectItems!=="") 
                {
                    let itemIdx=(Number(props.ansStateDP[currTabQ][idx].SelectItems)).toString()
                    let tmp=(document.getElementById("ans"+currTabQ.toString()+"-"+val.Qno.toString()+"-"+itemIdx) as HTMLInputElement)
                    if(tmp!==null) tmp.checked=true
                }
                
            }
            if(val.Type==='多選')
            {
                if(props.ansStateDP[currTabQ][idx].SelectItems!=="") 
                {
                    let selItems=props.ansStateDP[currTabQ][idx].SelectItems

                    for(let i=0;i<selItems.length;i++)
                    {
                        let itemIdx=(Number(selItems[i])).toString()
                        let tmp=(document.getElementById("ans"+currTabQ.toString()+"-"+val.Qno.toString()+"-"+itemIdx) as HTMLInputElement)
                        if(tmp!==null) tmp.checked=true
                    }
                }
                
            }
        })
    }

    // Check the answer state of all quesitons, return question no with comma
    const chkAnsState=():string=>{
        let result=''

        for(let i=0;i<props.ansStateDP.length;i++)
        {
            // eslint-disable-next-line no-loop-func
            props.ansStateDP[i].forEach(data=>{
                if(data.Type==='簡答' && data.InputText==="")
                {
                    result=result+data.Qno.toString()+","
                }
                if(data.Type==='單選' && data.SelectItems==="" )
                {
                    result=result+data.Qno.toString()+","
                }
                if(data.Type==='多選' && data.SelectItems==="" )
                {
                    result=result+data.Qno.toString()+","
                }                             
                    
            })
        }        
        result=result.slice(0,result.length-1)
        return result
    }

    // On click handle function when user complete answers all questions
    const handleCompleteAns=()=>{
        let aState=chkAnsState()
        setUnFinishAns(aState);
        if(aState==="")
        {
            let tmp=(document.getElementById("submit-ans-01") as HTMLButtonElement)
            if(tmp!==null) tmp.disabled=true
            props.SetFinishFlag(true);
            genAnsQDP();
            setLearnRecVal("sCountDP",getLearnRecVal("sCountDP")+1);
            sessionStorage.setItem("DPCnt",(Number(sessionStorage.getItem("DPCnt"))+1).toString());
            AddExStuDP(Number(sessionStorage.getItem("LoginID")),sessionStorage.getItem("DPNo")!,genAnsQDPStr());
            AddExStuRec(
                Number(sessionStorage.getItem("LoginID")),
                "DP",
                sessionStorage.getItem("DPNo")!,0,0,0)
            UpdExStuLearnRec(sessionStorage.getItem("LoginID")!,"DP",1,0);
        }
        
    }

    // Generate formatted answers and input answers for displaying 
    const genAnsQDP=()=>{
        
        for(let i=0;i<props.ansStateDP.length;i++)
        {
            props.ansStateDP[i].forEach((data,idx)=>{
                if (data.Type==="簡答")
                {
                    let item=props.ansQDP.find(val=>val.Qno===data.Qno)
                    if(item!==undefined) 
                    {
                        item.InpAnsList[0]=data.InputText
                    }
                }
                if (data.Type==="單選")
                {
                    let item=props.ansQDP.find(val=>val.Qno===data.Qno)
                    if(item!==undefined) item.InpAnsList[0]=props.questionDP[i][idx].QuestionList[Number(data.SelectItems)]
                }
                if (data.Type==="多選")                
                {
                    let item=props.ansQDP.find(val=>val.Qno===data.Qno)
                    if(item!==undefined)
                    {
                        for(let j=0;j<data.SelectItems.length;j++)
                        {
                            item.InpAnsList.push(props.questionDP[i][idx].QuestionList[Number(data.SelectItems[j])])
                        }

                    }
                }
            })
        }
    }

        // Generate formatted input answers 
    const genAnsQDPStr=():string=>{
        let ansStr=""
        for(let i=0;i<props.ansQDP.length;i++)
        {
            if ((props.ansQDP[i].Type==="簡答") || (props.ansQDP[i].Type==="單選"))
            {
                ansStr=ansStr+props.ansQDP[i].InpAnsList[0]+"//"
            }
            if (props.ansQDP[i].Type==="多選")                
            {
                for(let j=0;j<props.ansQDP[i].InpAnsList.length;j++)
                    {
                        ansStr=ansStr+props.ansQDP[i].InpAnsList[j]+"<br/>"
                    }

                ansStr=ansStr+"//"
            }
        }
        return ansStr;
    }


    
    // Handle onclick event for previous page 
    const handlePrevPage=()=>{
        if(currTabQ===0) return
        setCurrTabQ(currTabQ-1)
        props.SetCurrTab(currTabQ-1)

    }

    // Handle onclick event for next page 
    const handleNextPage=()=>{
        if(currTabQ===props.questionDP.length-1) return
        setCurrTabQ(currTabQ+1)
        props.SetCurrTab(currTabQ+1)
    

    }

    useEffect(()=>{
        //getDPLearnTaskByNo(props.ArticleNo).then(data=>setLearnTask(data));
        if(props.OPMode==="TOri") setCurrTabQ(props.currTab)
    },[])

    useEffect(()=>{
        //getDPLearnTaskByNo(props.ArticleNo).then(data=>setLearnTask(data));
        if(props.OPMode==="TOri")
        {
         setCurrTabQ(props.currTab)
         //setInitial()
        }
    },[props.currTab])

    useEffect(()=>{
        (document.getElementById('question-form') as HTMLFormElement).reset();
        //getDPLearnTaskByNo(props.ArticleNo).then(data=>setLearnTask(data));
        setInitial()
    },[currTabQ])  



    return (
        <>
        <div className="col-12 col-lg-4 rounded-3 p-0 font-weight-bold bg-light mt-4 mt-lg-0" style={{position:'sticky',top:'24px',overflowY:'auto',maxHeight:'calc(95vh - 24px)'}}>
            <div className="px-3 py-2 w-100 text-center bg-learn text-white border-top-3">學習任務</div>
            {
                (props.LearnTask!==null)?
                <div className="p-3">
                <img src="./assets/img/dp/Teacher.svg" alt="" className="float-start me-2" style={{width:'60px'}}/>            
                <p className="text-primary font-weight-bold mb-0"> {props.LearnTask} </p>
                <hr className="hr1 my-0"/>
                </div>
                : null
            }

            <div className="p-3">
                <form id="question-form" onLoad={()=>handleReset()}>
                {
                    <>
                        {
                            props.questionDP[currTabQ].map((item,idx)=>{
                                switch(item.Type)
                                {
                                    case '簡答':
                                    {
                                    return <div className="mt-3">
                                                <div className="font-weight-bold mb-2"><span >{item.Qno.toString()}.</span>{item.Question}</div>
                                                <div className="ps-4">
                                                    <div className="form-group">
                                                        <textarea className="form-control" id={"ans"+currTabQ.toString()+"-"+item.Qno.toString()} rows={3} onChange={(e)=>handelTextInp(e.target.value.toString(),idx)}>

                                                        </textarea>    
                                                        
                                                    </div>
                                                </div>
                                                {
                                                    (item.TeacherQuestion!=="") ?
                                                        <>
                                                            <hr className="hr1 my-0"/>
                                                            <div className="d-flex align-items-center p-3">
                                                                <img src="./assets/img/dp/Teacher.svg" alt="" className="me-2" style={{width:'60px'}}/>
                                                                <div className="text-primary font-weight-bold">{item.TeacherQuestion}</div>
                                                            </div>                                                    
                                                        </> : null
                                                }
                                            </div>                                                               

                                    }
                                    case '單選':
                                    {
                                    return <div className="mt-3">
                                                <div className="font-weight-bold mb-2"><span >{item.Qno.toString()}.</span>{item.Question}</div>
                                                <div className="ps-4">
                                                    {
                                                        item.QuestionList.map((sitem,i)=>{
                                                            return  <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name={"ans"+currTabQ.toString()+"-"+item.Qno.toString()} id={"ans"+currTabQ.toString()+"-"+item.Qno.toString()+"-"+i.toString()} onClick={()=>handelRadioInp(idx,i)} value={(i+1).toString()} />
                                                                        <label className="custom-control-label" htmlFor={"ans"+currTabQ.toString()+"-"+item.Qno.toString()+"-"+i.toString()}>{sitem}</label>
                                                                    </div>
                                                        })
                                                    }
                                                </div>
                                                {
                                                    (item.TeacherQuestion!=="") ?
                                                        <>
                                                            <hr className="hr1 my-0"/>
                                                            <div className="d-flex align-items-center p-3">
                                                                <img src="./assets/img/dp/Teacher.svg" alt="" className="me-2" style={{width:'60px'}}/>
                                                                <div className="text-primary font-weight-bold">{item.TeacherQuestion}</div>
                                                            </div>                                                    
                                                        </> : null
                                                    
                                                }
                                            </div>    
                                    }
                                    case '多選':
                                    {
                                    return  <div className="mt-3">
                                                <div className="font-weight-bold mb-2"><span >{item.Qno.toString()}.</span>{item.Question}</div>
                                                <div className="ps-4">
                                                    {
                                                        item.QuestionList.map((sitem,i)=>{
                                                            return <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value={(i).toString()} id={"ans"+currTabQ.toString()+"-"+item.Qno.toString()+"-"+i.toString()} onClick={()=>handleCheckInp(idx,i)}/>
                                                                        <label className="custom-control-label" htmlFor={"ans"+currTabQ.toString()+"-"+item.Qno.toString()+"-"+i.toString()}>{sitem}</label>
                                                                    </div>
                                                        })
                                                    }
                                                </div>
                                                {
                                                    (item.TeacherQuestion!=="") ?
                                                        <>
                                                            <div className="d-flex align-items-center p-3">
                                                                <img src="./assets/img/dp/Teacher.svg" alt="" className="me-2" style={{width:'60px'}}/>
                                                                <div className="text-primary font-weight-bold">{item.TeacherQuestion}</div>
                                                            </div>                                                    
                                                        </> : null
                                                }

                                            </div>   
                                    }


                                }
                            })
                        }
                    </>
                }
                </form>
                {
                    (props.OPMode==="TOri") ?
                            (props.questionDP.length===(currTabQ+1)) ? <><hr className="hr1 my-0"/>
                                <div className="d-flex align-items-center justify-content-center p-3">
                                    <img src="./assets/img/dp/student.svg" alt="學生" title="學生" className="me-2" style={{width:'60px'}}/>
                                    {
                                        (unfinishAns==="") ?
                                        <button type="button" id="submit-ans-01" className="btn btn-round btn-primary btn-lg mb-0" onClick={()=>handleCompleteAns()} >作答完畢</button>:
                                        <button type="button" id="submit-ans-01" className="btn btn-round btn-primary btn-lg mb-0" data-bs-toggle="modal" data-bs-target="#ans" onClick={()=>handleCompleteAns()} >作答完畢</button>
                                    }
                                </div></> :null
                        :
                        <><hr className="hr1 my-0"/>                                     
                            <div className="d-flex align-items-center justify-content-center">
                            <button className="btn btn-round btn-primary my-2 text-nowrap" onClick={()=>handlePrevPage()}>上一步</button>
                            {
                                        (unfinishAns==="") ?
                                        <button type="button" id="submit-ans-01" className="btn btn-round btn-primary btn-lg m-2 mx-sm-3 px-4 text-nowrap" onClick={()=>handleCompleteAns()} >作答完畢</button>:
                                        <button type="button" id="submit-ans-01" className="btn btn-round btn-primary btn-lg m-2 mx-sm-3 px-4 text-nowrap" data-bs-toggle="modal" data-bs-target="#ans" onClick={()=>handleCompleteAns()} >作答完畢</button>
                            }
                            <button className="btn btn-round btn-primary my-2 text-nowrap" onClick={()=>handleNextPage()}>下一步</button>
                            </div>
                        </>    


                }           
            </div>
        </div>
        <div className="modal fade" id="ans" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content mx-auto">
                    <div className="modal-header">
                        <h5 className="modal-title mx-auto">提醒您</h5>
                    </div>
                    <div className="modal-body mx-auto">
                        <p>第 {unfinishAns} 題尚未作答喔</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary m-0" data-bs-dismiss="modal">關閉</button>
                    </div>
                </div>
            </div>
        </div>
        </> 

    );
}

export default DPQAPage;