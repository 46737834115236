import React, { useEffect, useState } from 'react'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { IExDP } from '../../interfaces/ExDP';
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils';
import { getDPByGrpLvl, getDPByGrpLvlSid } from '../../services/ExDPService';
import { getExStuWordsCntByWordTestSid } from '../../services/ExStuRecService';


const DPDeepPage: React.FunctionComponent<IPageProps> = props => {
    const [hardLevel,setHardLevel]=useState(0);
    const [DPTopics,setDPTopics]=useState<IExDP[]>([])

    const acqDPTopic=async (lvl:number)=>{
        setHardLevel(lvl)
        //setDPTopics(await getDPByGrpLvl('DP',lvl));
        if(sessionStorage.getItem("LoginStatus")==="1")
            getDPByGrpLvlSid('DP',lvl,Number(sessionStorage.getItem("LoginID"))).then(data=>setDPTopics(data))
        else
            getDPByGrpLvl('DP',lvl).then(data=>setDPTopics(data))    
    }
    const handleDPPractice=async (_exdp:IExDP)=>{
        sessionStorage.setItem("DPGroup","DP");
        sessionStorage.setItem("DPNo",_exdp.ArticleNo);
        sessionStorage.setItem("DPTopic",_exdp.DPTopic);
        sessionStorage.setItem("DPImage",_exdp.ArticleImg);
        sessionStorage.setItem("DPCnt",(_exdp.DPCnt)?_exdp.DPCnt.toString():"0");

        handleLink('/dppractice')
    }
    const getDiffStr=(lvl:number):string=>{
        let ret=""
        switch (lvl){
            case 1: 
                ret="★☆☆";
                break;
            case 2: 
                ret="★★☆";
                break;
            case 3: 
                ret="★★★";
                break;
        }
        return ret
    }

    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="1")
            getDPByGrpLvlSid('DP',0,Number(sessionStorage.getItem("LoginID"))).then(data=>setDPTopics(data))
        else
            getDPByGrpLvl('DP',0).then(data=>setDPTopics(data))
    },[])
    
    return (
        <>
            <div className="d-flex justify-content-center mb-4" style={{marginTop:'20px'}}>
                <div className="me-4" style={{color:'#5c71a2'}}>難易度</div>
                <div className="form-check me-4">
                    <input className="form-check-input" type="radio" name="degree50" id="degree50-0" checked={hardLevel===0?true:false} onClick={()=>acqDPTopic(0)}/>
                    <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-0">全部</label>
                </div>
                <div className="form-check me-4">
                    <input className="form-check-input" type="radio" name="degree50" id="degree50-1" checked={hardLevel===1?true:false} onClick={()=>acqDPTopic(1)}/>
                    <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-1">★☆☆</label>
                </div>
                <div className="form-check me-4">
                    <input className="form-check-input" type="radio" name="degree50" id="degree50-2" checked={hardLevel===2?true:false} onClick={()=>acqDPTopic(2)}/>
                    <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-2">★★☆</label>
                </div>
                <div className="form-check me-4">
                    <input className="form-check-input" type="radio" name="degree50" id="degree50-3" checked={hardLevel===3?true:false} onClick={()=>acqDPTopic(3)}/>
                    <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-3">★★★</label>
                </div>
            </div>

            <div className="row align-items-start">
                <>
                {
                    DPTopics.map((item,i)=>{
                        return  <div className="col-12 col-md-6 col-xl-4 mb-4">
                                    <div className="card pointer h-100" onClick={()=> handleDPPractice(item)}>
                                        <div className="card-body d-flex p-0" >
                                            <div className="w-50 me-2">
                                                <img src={'./assets/img/dp/'+item.ArticleImg} className="rounded-2 w-100 h-100" onClick={()=> handleDPPractice(item)}/>
                                            </div>
                                            <div className="d-flex flex-column w-50 py-3 pe-2">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="badge badge-secondary py-1 px-1 px-sm-2">{item.ArticleNo}</div>
                                                    <div className="ms-1 ms-sm-2 ms-md-1 ms-lg-2 text-second"><span className="text-xs me-1">難易度</span><span
                                                        className="text-md">{getDiffStr(item.Difficult)}</span></div>
                                                </div>
                                                <p className="news-title mt-2 mb-0">{item.DPTopic}</p>
                                                <div className="d-flex mt-auto">
                                                    {
                                                        (sessionStorage.getItem("LoginStatus")==="1")?
                                                            <div className="text-second text-xs">自主學習次數：{(item.DPCnt) ? item.DPCnt:0}</div> :
                                                            null
                                                    }
                                                    <a type="button" className="btn btn-link mb-0 ms-auto p-0" onClick={()=> handleDPPractice(item)}>
                                                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                                                    </a>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                    })
                }
                </>
            </div>
        </>
    );
}

export default DPDeepPage;



