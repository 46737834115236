

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import { OpinCode, alertEx, currentClass, fetchDataSubTopic, getLoginInfo, getModalConfigs, setSessionStudentDisplayInfo, Action, geIndexBytOpinionCode, IOpinionForModal, IsubTopicForModal, replaceNewline, formatDateTime, getStart, SummaryFileName, Export2Word, TutorialModal, ReacherArPictureName_before, downloadImage, getFileNameString } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import Schedule from './component/schedule';
import { IOpinion, ISearchDataSummary, IsubTopic, Param11OrPost11, Summary, subTopicParam } from '../../interfaces/EpModal';
import { getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';
import AllOpinion from './component/allOpinion';
import { createOpinion, deleteSubSummaryByUid, getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateOpinionByGroupOpIdxUID, updateSubSummaryByGroupSubSumNo } from '../../services/EP/OpinionService';
import SubTopicTable from './component/subTopicTable';
import SummaryModal from './component/SummaryModal';
import EditModal from './component/EditModal';
import { FileUploader } from '../../components/fileUploader';
import { checkFileExists } from '../../services/DrFileService';
import FileUploaderNew from '../../components/fileUploaderNew';



export interface StudentDisplay {

  ApplyName: string
  ApplyNo: string
  ApplyTitle: string
  GroupName: string
  GroupNo: string
  SchoolName: string //"國語實小        "
  StuName: string  //"趙小一       "
  StuNo: string //  "FA001-01-1"
  TeaName: string // "李老師       "
  TeaNo: string //""
  TeaTitle: string // 
  Team: {
    TStuName: string,
    TStuNo: string
    checked: boolean
  }[]
  Teachers: {
    TeaNo: string, TeaName: string, TeaTitle: string

  }[]
}




// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpStudResult: React.FunctionComponent<IPageProps> = () => {

  const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(true);
  const [ishModalOpen, setIsModalOpen] = useState(false)
  // 根据 isVisible 变量的值决定要应用的 CSS 类名
  const containerClassName = isVisible ? 'visible' : 'hidden';
  const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
  const [currentLoginInfo, setCurrentLoginInfo] = useState<any>()
  const [studentInfo, setStudentInfo] = useState<StudentDisplay>()
  const [isReload, setIsReload] = useState<boolean>(true);
  const [opinion, setOpinion] = useState<IOpinion>();
  const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>();
  const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
  const [currentSubTopic, setCurrentSubTopic] = useState<string>('')
  const [currentSummary, setCurrentSummary] = useState<Summary>()
  const [currentMode, setCurrentMode] = useState<'新增' | '編輯' | ''>('')
  const [searchDataList, setSearchDataList] = useState<ISearchDataSummary[]>();
  const [isFilePathValid, setIsFilePathValid] = useState<boolean>(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [afterTopic, setAfterTopic] = useState<string>("");
  const [reflection, setReflection] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false)
  const [isFileExist, setIsFileExist] = useState({
    summary_ppt: {
      isExist: false,
    },
    photo1: {
      isExist: false,
    },
    photo2: {
      isExist: false,
    },
    photo3: {
      isExist: false,
    },
  });

  useEffect(() => {

    if (studentInfo) {
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'report.pptx', 'summary_ppt')
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'photo1.jpg', 'photo1')
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'photo2.jpg', 'photo2')
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'photo3.jpg', 'photo3')

    }
  }, [studentInfo, isReload])


  useEffect(() => {

    if (studentInfo) {
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'report.pptx', 'summary_ppt')
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'photo1.jpg', 'photo1')
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'photo2.jpg', 'photo2')
      getIsFileExist(`/uploads/upload${currentClass}/${studentInfo?.GroupNo}`, 'photo3.jpg', 'photo3')
      setForceUpdate(forceUpdate + 1)
    }
  }, [isReload])





  const getIsFileExist = async (path: string, fileName: string, pathKey: string) => {

    const rsp = await checkFileExists(path, fileName, "")
    if (rsp === '檔案已存在.') {
      setIsFileExist(prevStates => ({
        ...prevStates,
        [pathKey]: {
          isExist: rsp === '檔案已存在.'
        }
      }));
    }
  }
  useEffect(() => {
    fetchdataStudentInfo();
    FetchDataOpinion();


  }, []);
  useEffect(() => {
    fetchdataStudentInfo();
    FetchDataOpinion();
    fetchDataSubSummarySubCollectByGroupNo()
    checkFilePath()


  }, [isReload]);
  useEffect(() => {

    setAfterTopic(opinion?.Post12_DataA || "")
    setReflection(opinion?.Post54_DataA || "")
    setIsReload(false)
  }, [opinion])


  useEffect(() => {
    fetchDataSubTopic();
    fetchDataSubSummarySubCollectByGroupNo()
  }, [studentInfo, isReload]);

  const reload = async () => {
    await fetchdataStudentInfo();
    await FetchDataOpinion();
  };

  /** 取得summery  */
  const fetchDataSubSummarySubCollectByGroupNo = async () => {
    // alert ('sss')
    if (studentInfo?.GroupNo) {

      let rsp = (await getSubSummarySubCollectByGroupNo(1, studentInfo?.GroupNo)) as ISearchDataSummary[];
      setSearchDataList(rsp || []);
      console.log("setSearchDataList 4 ", rsp);
    }


  };

  /** 取得登入資料 */
  const fetchdataStudentInfo = async () => {
    /** 取得登入資料 */
    try {
      let currentLoginInfo = getLoginInfo();
      setCurrentLoginInfo(currentLoginInfo);
      const studentInfo = (await getDrGroupByStuNoClass(currentClass, currentLoginInfo.LoginID)) as StudentDisplay; //as StudentInfo
      console.log('currentLoginInfo', currentLoginInfo);
      setStudentInfo(studentInfo);
    } catch (ex) {
      console.log('取得學生資料發生錯誤! ', ex);
    }
  };
  /** */
  const FetchDataOpinion = async () => {
    let currentLoginInfo = await getLoginInfo();
    setCurrentLoginInfo(currentLoginInfo);
    const rsp = (await getOpinionByStuNo(currentLoginInfo.LoginID)) as IOpinion[];
    const opinion1 = rsp[0];
    if (opinion1) {
      setOpinion(opinion1);
    }
  };


  /** 取得子題資料*/
  const fetchDataSubTopic = async () => {
    if (studentInfo?.GroupNo) {
      let subTopicbefore = (await getSubTopicByGroupNo(0, studentInfo?.GroupNo)) as IsubTopic[];
      setSubTopicBeforeFeedbackList(subTopicbefore);
    }
  };

  /**  */
  const Delete = async (summaryID: string) => {
    if (window.confirm('確定要刪除嗎?')) {
      alertEx(summaryID)
      try {
        await deleteSubSummaryByUid(summaryID)
        setIsReload(!isReload)
      } catch (ex) {
        alert('刪除發生錯誤 :' + JSON.stringify(ex))

      }

    }
  }
  const checkFilePath = async (): Promise<boolean> => {
    try {
      const response = await fetch(`/uploads/subTopicSummary${currentClass}/${studentInfo?.GroupNo}/${"subTopic_summary_pdf.pdf"}`);
      if (response.ok) {
        // 如果路径存在且请求成功，则设置状态为 true
        return true
      } else {
        // 如果路径不存在或请求失败，则设置状态为 false
        return false
      }
    } catch (error) {
      // 捕获异常，并设置状态为 false
      return false
    }
  };

  const openEditModal = async (opinCode: OpinCode, action: Action, param11?: Param11OrPost11, subTopic?: IsubTopic, enable?: boolean) => {
    if (enable) {
      return
    }
    let modalConfig = getModalConfigs(opinCode, action, 1);
    debugger
    let params = {
      UID: undefined,
      ParamType: modalConfig?.sourceType,
      GroupNo: studentInfo?.GroupNo,
      MemberNo: studentInfo?.StuNo,
      DataA: '',
      DataB: '',
      DataC: ''
    } as paramsCreateOpinionSubTopic;

    setFormData(params);
    /**  彈出視窗設定檔*/
    if (studentInfo) {
      if (action === '新增') {
        params = {
          action: '新增',
          UID: undefined,
          ParamType: modalConfig?.sourceType,
          GroupNo: studentInfo?.GroupNo,
          MemberNo: studentInfo?.StuNo,
          DataA: '',
          DataB: '',
          DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        //需要有設定檔 跟 修改對象 ID
      } else if (action === '修改') {
        // fectchData todo
        if (modalConfig) {
          //如果沒有 傳進來的物件
          if (param11 && !subTopic) {
            // params
            params = {
              action: '修改',
              UID: param11.OpinionID,
              ParamType: modalConfig.sourceType,
              GroupNo: studentInfo?.GroupNo,
              MemberNo: studentInfo?.StuNo,
              DataA: param11.DataA,
              DataB: param11.DataB,
              DataC: '',
              termsStringList: param11.DataB.split('、')
            } as paramsCreateOpinionSubTopic;
            setFormData(params);
          } else if (!param11 && subTopic) {
            // 沒有物件
            try {
              params = {
                action: '修改',
                UID: subTopic.uid,
                ParamType: modalConfig.sourceType,
                GroupNo: studentInfo?.GroupNo,
                MemberNo: studentInfo?.StuNo,
                SubNo: subTopic.SubNo + '',
                SubName: subTopic.SubName,
                Description: subTopic.Description
              } as paramsCreateOpinionSubTopic;

              setFormData(params);
            } catch (ex) {
              alertEx('ex:' + JSON.stringify(ex));
            }
          }
        }
      } else if (action === '新增或修改') {

        if (modalConfig) {
          // 有moaal 設定檔
          if (!param11 && !subTopic) {
            // topic
            try {
              let optionCode = geIndexBytOpinionCode(opinCode);
              let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, studentInfo?.GroupNo)) as any[];
              if (rsp.length > 0) {
                // 原本有資料 設定修改
                params = {
                  action: '修改',
                  UID: rsp[0].OpinionID || rsp[0].uid,
                  ParamType: modalConfig.sourceType,
                  GroupNo: studentInfo?.GroupNo,
                  MemberNo: studentInfo?.StuNo,
                  DataA: rsp[0].DataA || null,
                  DataB: rsp[0].DataB || null,
                  DataC: rsp[0].DataC || null
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
              } else {
                // 沒有資料就設定  新增
                params = {
                  action: '新增',
                  UID: undefined,
                  ParamType: modalConfig?.sourceType,
                  GroupNo: studentInfo?.GroupNo,
                  MemberNo: studentInfo?.StuNo,
                  DataA: '',
                  DataB: '',
                  DataC: ''
                } as paramsCreateOpinionSubTopic;
                setFormData(params);
              }
            } catch (ex) {
              alertEx('ex:' + JSON.stringify(ex));
            }
          } else if (!param11 && subTopic) {
          } else if (param11 && !subTopic) {
            //opinion 一對一
          }
        }
      } else if (action === '刪除') {
      }
    }

    if (getModalConfigs(opinCode, action, 1)) {
      setCurrentShowModal(getModalConfigs(opinCode, action, 1));
    }
    setIsAddRsearchModalOpen(true)
  };


  const setModalConfigAndOpen = (mode: any, currentSummary: Summary | any, subNo: string) => {
    setCurrentMode(mode)
    setIsModalOpen(true)
    setCurrentSummary(currentSummary)
    setCurrentSubTopic(subNo)


  }
  /** */
  const updateSave = async (opinCode: string, isAfterFeedback: 0 | 1, groupNo: string | undefined, textValue: string) => {

    if (!groupNo) {
      return
    }

    let params = {} as paramsCreateOpinionSubTopic;
    let index = geIndexBytOpinionCode(opinCode);
    try {
      let optionCode = geIndexBytOpinionCode(opinCode);
      let rsp = (await getOpinionByGroupOpIdx(1, optionCode, groupNo)) as any[];
      if (rsp.length > 0) {
        // 原本有資料 設定修改
        params = {
          action: '修改',
          UID: rsp[0].OpinionID || rsp[0].uid,
          ParamType: "",
          GroupNo: studentInfo?.GroupNo,
          MemberNo: studentInfo?.StuNo,
          DataA: textValue,
          DataB: rsp[0].DataB || null,
          DataC: rsp[0].DataC || null
        } as paramsCreateOpinionSubTopic;

        try {
          if (params.UID) {
            await updateOpinionByGroupOpIdxUID(params, 1, index, groupNo, params.UID);
          }
          alert('儲存成功!');
          setIsAddRsearchModalOpen(false);
          setIsReload(true)


        } catch (ex) {
          alert("更新發生錯誤!")
        }



        setFormData(params);
      } else {
        // 沒有資料就設定  新增
        params = {
          action: '新增',
          UID: undefined,
          ParamType: "",
          GroupNo: studentInfo?.GroupNo,
          MemberNo: studentInfo?.StuNo,
          DataA: textValue,
          DataB: '',
          DataC: ''
        } as paramsCreateOpinionSubTopic;


        try {
          await createOpinion(params, isAfterFeedback, index);
          alert('儲存成功!');
          setIsAddRsearchModalOpen(false);
          setIsReload(true)

        } catch (ex) {
          alert('err: ' + JSON.stringify(ex));
        }
      }
    } catch (ex) {
      alertEx('ex:' + JSON.stringify(ex));
    }

  }




  const handleError = (error: any) => {
    // 您可以根據需要顯示錯誤訊息或預設圖片
    // 例如：
    alert('PDF 檔案載入失敗，請稍後再試');
    // 或
    <div>
      <p>PDF 檔案載入失敗</p>
      <img src="https://placehold.co/300x200" alt="預設圖片" />
    </div>
  };




  return (
    <>
      <TopNavPage1 />
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">專題探究競賽</h5>

            <StudentFunctionBar />
          </div>
          <div className="text-center h5 mt-4">成果報告</div>
          <div id="title-info" hidden>
            {studentInfo?.SchoolName.trim()} {studentInfo?.GroupName.trim()}({studentInfo?.GroupNo})

          </div>

          <div>
            {studentInfo?.SchoolName} {studentInfo?.GroupName}({studentInfo?.GroupNo})-{studentInfo?.StuName}({studentInfo?.StuNo})
          </div>
          <div className="accordion">
            <div className="accordion-item">
              <div className="card bd-callout bd-callout-danger">
                <button className="accordion-button text-dark p-0" type="button" aria-expanded="true" style={{ cursor: "auto" }}>
                  <h4 className="my-0 me-2">階段5、完成專題探究報告</h4>
                  <div className="text-second font-weight-bold"> (專題探究報告請於<span>112/06/07</span> 前完成)</div>

                </button>
              </div>
              <div className="collapse show" id="result">

                <div
                  className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3 mt-0 mb-3">
                  <div>
                    <div>恭喜大家完成各階段的報告，辛苦了。</div>
                    <div>各隊可以從下面看到你們階段1到階段4的報告，撰寫成果時更方便。</div>
                    <div>請從下面下載「專題探究成果報告_參考格式」檔案，開始撰寫報告。</div>
                    <div>檔案裡會引導大家怎麼下筆喔！</div>
                  </div>
                </div>
                <div className="card p-4 pt-3">
                  <h5 className="fw-bolder pt-3">探究題目是否微調？</h5>
                  <ul>
                    <li>經過探究歷程後，如果想微調題目，請在下方編輯輸入後儲存。如果題目不需微調，可直接略過。
                    </li>
                  </ul>
                  <div className="pt-3">
                    <span className="fw-bolder text-second">原探究題目：</span>
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataA || '--') }}></span>
                  </div>
                  <div className="fw-bolder text-second pt-3">修改後的探究題目為：</div>
                  <div className="d-flex flex-wrap align-items-center pb-3">
                    <input type="text" className="form-control me-3" style={{ maxWidth: "500px" }}
                      value={afterTopic}
                      onChange={(e) => setAfterTopic(e.target.value)}
                      placeholder="最多30個字元" />

                    <div className="btn text-second bg-second mb-0" onClick={() => updateSave('1_2決定探究題目', 1, studentInfo?.GroupNo, afterTopic)}>更新</div>
                  </div>

                  <hr className="horizontal dark" />
                  <h5 className="fw-bolder pt-3">省思</h5>
                  <ul>
                    <li>請寫出每個隊員參與這次任務的省思，每個隊員都要寫，不論收穫、困難等都可以。</li>
                    <li>可以透過各階段記下的困難與解決，幫助大家回憶一路走來的甘苦。</li>
                    <li>每位隊員寫完後，請在最後用括弧加上姓名，例如(陳小明)。</li>
                  </ul>
                  <label className="fw-bolder text-second">省思</label>
                  <div className="d-flex flex-wrap align-items-end pb-3">
                    <textarea rows={5} className="form-control me-3"
                      value={reflection}
                      onChange={(e) => setReflection(e.target.value)}
                      style={{ maxWidth: "700px" }} placeholder="">在完成階段一到四時，我們小組遇到了許多困難，但隨著網際網路的發達，不會的問題也可以詢問 Google，當然還有跟老師討論後，老師給予的建議，我們自己再去 try。
                    </textarea>
                    <div className="btn text-second bg-second mb-0"
                      onClick={() => updateSave('5_4參加本活動的省思', 1, studentInfo?.GroupNo, reflection)}
                    >
                      更新
                    </div>
                  </div>
                  <hr className="horizontal dark" />

                  <h5 className="fw-bolder pt-3">指導老師心得</h5>
                  <div className="fw-bolder text-second pt-3 pb-2">老師心得：</div>
                  <div className="pb-3">  <span className="text-primary" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post55_DataA || '--') }}></span></div>
                  <hr className="horizontal dark" />

                  <h5 className="fw-bolder pt-3">撰寫成果報告</h5>
                  <ul>
                    <li>可匯出各階段資料做為參考，再整合到成果報告檔案。</li>
                    <li>請於6/7(二)23:59前上傳成果報告。</li>
                  </ul>
                  <h5 className="fw-bolder pt-3">成果報告</h5>
                  <div className="mb-3">
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-1-post', '階段1', getFileNameString(undefined, studentInfo))}>
                      階段1
                    </div>
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-2-post', '階段2', getFileNameString(undefined, studentInfo))}>
                      階段2
                    </div>
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-3', '階段3', getFileNameString(undefined, studentInfo))}>
                      階段3
                    </div>
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-4', '階段4', getFileNameString(undefined, studentInfo))}>
                      階段4
                    </div>
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-5', '階段5', getFileNameString(undefined, studentInfo))}>
                      階段5(省思與心得)
                    </div>
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-all', '階段1~5', getFileNameString(undefined, studentInfo))}>
                      階段1~5
                    </div>
                    <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('refer_source', '參考資料', getFileNameString(undefined, studentInfo))}>
                      參考資料
                    </div>
                  </div>
                  {/* <a href="/assets/doc/專題探究成果報告_參考格式_2022年版.docx" target="_blank" className="text-green fw-bold"> */}
                  <span>請下載「專題探究成果報告_參考格式_2022年版」WORD檔，開始撰寫。</span>
                  {/* </a> */}
                  <div className="btn text-second bg-second me-auto mt-3 ms-2">
                    <a href="/assets/doc/專題探究成果報告_參考格式_2022年版.docx" target="_blank" className="text-green fw-bold">
                      成果報告
                    </a>
                  </div>

                  <hr className="horizontal dark" />

                  <h5 className="fw-bolder pt-3">初審通過的小隊上傳決賽簡報與歷程照片</h5>
                  <ul>
                    <li>初審通過入選的小隊學生製作簡報並於決賽當日發表，每隊發表7分鐘，並接受5分鐘之提問與回答。</li>
                    <li>請依據「專題探究成果報告」內容，製作 Powerpoint 簡報，pptx檔案不超過10MB。</li>
                    <li>請上傳3張教師指導或學生探究照片，每張照片800萬畫素以上的jpg或jpeg檔，大於2MB。</li>
                    <li>請於<span className="text-green">7/1~7/14(四)23:59</span>前上傳簡報與歷程照片。</li>
                  </ul>
                  <div className="mb-3">
                    {/* <div className="btn text-second bg-second mx-2">上傳簡報檔案(pptx)</div> */}



                    <FileUploaderNew
                      fileType=".pptx"
                      uploaderDir={`/upload${currentClass}/${studentInfo?.GroupNo}`}
                      buttonStr="上傳簡報檔案(pptx)"
                      setIsReload={setIsReload} isReload={isReload}
                      setIsUploading={setIsUploading} isUploading={isUploading}
                      NewFileName={"report.pptx"}
                    />


                    {isFileExist.summary_ppt.isExist ?
                      (<>
                        <a className="btn text-second bg-second" href={`/uploads/upload${currentClass}/${opinion?.GroupNo}/report.pptx`} download="報告簡報">
                          下載已上傳的報告簡報檔案(若無法開啟，請重整網頁)
                        </a>
                      </>
                      )
                      : (<></>)}

                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4 text-center">
                      <div className="rounded-3 p-3 imgborder mw-100 w-100 me-3">

                        {isFileExist.photo1.isExist ? (
                          <a href={`/uploads/upload${currentClass}/${opinion?.GroupNo}/photo1.jpg`} download="相片一">
                            <img
                              className=""
                              src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/photo1.jpg?v=${new Date().getSeconds()}`}
                              alt=""
                              style={{
                                width: '100%', // 最大寬度為 500px
                                height: 'auto',    // 高度自動調整，以保持比例
                                // 添加指標效果，使其看起來像可點擊
                              }}
                            />
                          </a>
                        ) : (<>   <img className="imgs" src="/assets/img/ep/file_upload.png" alt="" /></>)}
                      </div>
                      <FileUploader
                        fileType="image/jpeg,image/jpg"
                        uploaderDir={`/upload${currentClass}/${opinion?.GroupNo}`}
                        buttonStr="上傳相片一"
                        setIsReload={setIsReload} isReload={isReload}
                        setIsUploading={setIsUploading} isUploading={isUploading}
                        NewFileName={"photo1.jpg"}
                      />

                    </div>
                    <div className="col-6 col-md-4 text-center">
                      <div className="rounded-3 p-3 imgborder mw-100 w-100 me-3">
                        {isFileExist.photo2.isExist ? (
                          <a href={`/uploads/upload${currentClass}/${opinion?.GroupNo}/photo2.jpg`} download="相片二">
                            <img
                              src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/photo2.jpg?v=${new Date().getSeconds()}`}
                              alt=""
                              style={{
                                width: '100%', // 最大寬度為 500px
                                height: 'auto',    // 高度自動調整，以保持比例
                                cursor: 'pointer'  // 添加指標效果，使其看起來像可點擊
                              }}
                            /></a>
                        ) : (<>
                          <img src="/assets/img/ep/file_upload.png" alt="" />
                        </>)}
                      </div>
                      <FileUploader
                        fileType="image/jpeg,image/jpg"
                        uploaderDir={`/upload${currentClass}/${opinion?.GroupNo}`}
                        buttonStr="上傳相片二"
                        setIsReload={setIsReload} isReload={isReload}
                        setIsUploading={setIsUploading} isUploading={isUploading}
                        NewFileName={"photo2.jpg"}
                      />

                    </div>
                    <div className="col-6 col-md-4 text-center">
                      <div className="rounded-3 p-3 imgborder mw-100 w-100 me-3">
                        {isFileExist.photo3.isExist ? (
                          <a href={`/uploads/upload${currentClass}/${opinion?.GroupNo}/photo3.jpg`} download="相片三">
                            <img
                              src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/photo3.jpg?v=${new Date().getSeconds()}`}
                              alt=""

                              style={{
                                width: '100%', // 最大寬度為 500px
                                height: 'auto',    // 高度自動調整，以保持比例
                                cursor: 'pointer'  // 添加指標效果，使其看起來像可點擊
                              }}
                            />
                          </a>
                        ) : (<> <img src="/assets/img/ep/file_upload.png" alt="" /></>)}
                      </div>
                      <FileUploader
                        fileType="image/jpeg,image/jpg"
                        uploaderDir={`/upload${currentClass}/${opinion?.GroupNo}`}
                        buttonStr="上傳相片三"
                        setIsReload={setIsReload} isReload={isReload}
                        setIsUploading={setIsUploading} isUploading={isUploading}
                        NewFileName={"photo3.jpg"}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <AllOpinion propClassName={currentClass}
            propGroupNo={studentInfo?.GroupNo}
            opinion={opinion}
            isHidden={true}
            forceUpdate={forceUpdate}
          />

          <div className="pt-3 mt-4"></div>

        </div>
      </div >

      <FooterPage />
    </>

  )


}

export default EpStudResult;

