
import React, { useContext, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page'
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { handleLink } from '../../modules/utils';
import ApplyFunctionBar from './component/applyFunctionBar';
import { alertEx, getLoginInfo } from './tool/helper';
import { delDrTeacherByNo, getDrTeacherByApplyNo } from '../../services/EP/EpApplyTeacherService';
import { IApplyFormData, ILoginUserInfo, ITearcherInfo } from '../../interfaces/EpModal';
import ApplyNewTeacherModal from './modal/applyNewTeacherModal';
import { MyContext } from '../home';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';
import Schedule from './component/schedule';
import StepGuide from './component/StepGuide';




/**epApplyTeacher.html 【6-4-4	指導老師】*/
const EpApplyTeacher: React.FunctionComponent<IPageProps> = props => {

  const context = useContext(MyContext);
  const [applyInfo, setApplyInfo] = useState<IApplyFormData>();
  const[isreload ,setIsReload ] =useState(false);
  /** 目前登入者資訊 */
  const [currentLoginInfo , setCurrentLoginInfo ] =useState<ILoginUserInfo>();
  /** 老師資訊列表 */
  const [teacherDataList , setTeacherDataList]=useState<ITearcherInfo[]>([])
  const [selectedTeacher , setSelectedTeacher] =useState<ITearcherInfo>(
    {
      GroupNo:"", 
      Password:"", 
      Remark: "",  
      TeaCell :"", 
      TeaEmail : "",  
      TeaName :"",   
      TeaNo :"",  
      TeaTitle :"",  
      uid : "",  
  } as ITearcherInfo


  )


  // const [data, setData] = useState<ITearcherInfo>({}as ITearcherInfo);
  const [mode ,setMode] = useState<'新增'|'編輯'|''>('');
  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal =  (mode :'新增'|'編輯'|'',teacher?:ITearcherInfo) => {

    if(teacher&& mode ==='編輯'){
      teacher.TeaTitle =teacher.TeaTitle.trim();
      setSelectedTeacher(teacher)
    }else if(mode ==='新增'){

      setSelectedTeacher(  {
        GroupNo:"", 
        Password:"", 
        Remark: "",  
        TeaCell :"", 
        TeaEmail : "",  
        TeaName :"",   
        TeaNo :"",  
        TeaTitle :"",  
        uid : "",  
    } as ITearcherInfo
  )

    }
    setMode(mode);
    setIsModalOpen(true);
   
  };

  const closeModal = () => {
    setIsReload(!isreload)
    setIsModalOpen(false);
  };

  const handleDataChange = (newData:any) => {
   
    closeModal();
    reload();
  };

  /** 初始化 */
  useEffect(() => {
    console.log("contextEpApplyTeacher",context)
    fetchData();
  }, [isreload]);


  const getEPApplyByLoginID = async (loginID: string) => {
    let rsp = await getEPApplyByNo(loginID);
    console.log('rsp', rsp[0]);
    setApplyInfo(rsp[0] as IApplyFormData);
};

   
  /**  取資料 */
  const  fetchData = async() =>{
    // alert("feach Data")
    let currentLoginInfo = getLoginInfo() as ILoginUserInfo;  
    setCurrentLoginInfo(currentLoginInfo)
    await getEPApplyByLoginID(currentLoginInfo.LoginID)
    let  teacherDataList = await  getDrTeacherByApplyNo(currentLoginInfo.LoginID) ;
    setTeacherDataList(teacherDataList as ITearcherInfo[])
    console.log(" feachData teacherDataList",teacherDataList);
  }

  /** */
  const reload = async ()=>{
    await fetchData() ;
    setIsModalOpen(true) 
  }

  /** 刪除教師資料 */
  const deleteData = async (targetTeacher :ITearcherInfo )=>{

  const isdeleted =   window.confirm('您確定刪除嗎？');
  if(isdeleted){
    try{
      await  delDrTeacherByNo(targetTeacher.TeaNo) ;
      alert("刪除成功!")
      setIsReload(!isreload)
    } catch(ex){
     alert("刪除教師發生錯誤: "+JSON.stringify(ex))
    }
  }
  }


  
    return (
        <>
    <TopNavPage1/>
            <div className="section pt-3">
    <div className="container px-3 px-md-4">
      <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
        <h5 className="title mb-3 me-3">專題探究競賽 {mode}</h5>
        <ApplyFunctionBar crtlInfoList={context?.crtlInfoList} />
      </div>
      {/* <Schedule /> */}
      <StepGuide/>
      <div className="text-center h5 mt-4">指導老師資料</div>
      <div className="d-flex flex-wrap align-items-center my-3">
        <div className="text-second fw-bold">{applyInfo?.SchoolName} {currentLoginInfo?.LoginName}{`(${currentLoginInfo?.LoginID})`}</div>
        <div onClick={()=>{openModal('新增')}} className="btn text-second bg-second pointer mb-0 ms-auto" data-bs-toggle="modal" data-bs-target="#editModal">
          <span className="material-icons-outlined align-middle">add</span>
          <span>新增指導老師</span>
        </div>
      </div>
      <div className="card p-4 pt-3">
        <div className="table-responsive pt-3">
          <table className="text-sm table table-striped mb-0">
            <thead>
              <tr className="font-weight-bolder text-second" style= {{background: '#d9dce6'}}>
                <th className="p-2 text-nowrap">老師編號</th>
                <th className="p-2 text-nowrap">姓名</th>
                <th className="p-2 text-nowrap">職稱</th>
                <th className="p-2 text-nowrap">連絡電話(手機或學校電話)</th>
                <th className="p-2 text-nowrap">Email</th>
                <th className="p-2 text-nowrap">密碼</th>
                <th className="p-2 text-nowrap">備註</th>
                <th className="p-2 text-nowrap" style={{width: '100px'}}>功能</th>
              </tr>
            </thead>
            <tbody>

            
              {teacherDataList.map((teacher) => (
                
         <tr>  
                <td>{teacher.TeaNo}</td>
                <td>{teacher.TeaName}</td>
                <td>{teacher.TeaTitle}</td>
                <td>{teacher.TeaCell}</td>
                <td>{teacher.TeaEmail}</td>
                <td>{teacher.Password}</td>
                <td>{teacher.Remark}</td>
                <td className="text-nowrap">
                  <span className="material-icons text-dark pointer me-3" onClick={()=>deleteData(teacher) }  title="刪除">delete_outline</span>
                  <span   onClick={()=>{openModal('編輯',teacher)}} className="material-icons text-dark pointer" data-bs-toggle="modal" data-bs-target="#editModal"
                    title="編輯">edit</span>
                </td>
              </tr>
      
      ))}
             
            </tbody>
          </table>
        </div>
      </div>


    </div>
    {/* 編輯視窗 */}
    {
      
    <ApplyNewTeacherModal 
       applyNo ={currentLoginInfo?.LoginID}
       mode = {mode}
       teacherInfo={selectedTeacher}
       onClose={closeModal}
       onDataChange={handleDataChange}
       isOpen={isModalOpen}
       setIsModalOpen={setIsModalOpen}
    />}
  </div>
  <FooterPage/>
        </>

    )

}
export default EpApplyTeacher;