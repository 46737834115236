/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';



interface PageIdxProps {
    frameSize:number;
    pageSize:number;
    totalRec:number;
    currentPage:number;
    setCurrentPage: (val:number) => void;
  }
  
  export const PageIndexX: React.FC<PageIdxProps> = (props) => {
    const [enPrev,setEnPrev]=useState(false);
    const [enNext,setEnNext]=useState(false);

    let baseIdx=0;
    let totalPage=0;
    if(props.currentPage%props.frameSize===0)
    {
        baseIdx=Math.floor(props.currentPage/props.frameSize)-1;

    }
    else
    {
        baseIdx=Math.floor(props.currentPage/props.frameSize);
    }

    if(props.totalRec%props.pageSize===0)
    {
        totalPage=props.totalRec/props.pageSize;
    }
    else
    {
        totalPage=Math.ceil(props.totalRec/props.pageSize);
    }


    const chkItemStatus=(idx:number):string=>{
        if(idx===props.currentPage) return "page-item active";
        else 
        {
            if(((props.pageSize*idx)-props.totalRec)>=props.pageSize) return "page-item disabled";
            else return "page-item";
        }
    }

    const liElements=()=>{
        let liArr=[];
        for(let i=0;i<props.frameSize;i++)
        {
            liArr.push(<li className={chkItemStatus(baseIdx*props.frameSize+i+1)}><a className="page-link" type="button" onClick={()=>props.setCurrentPage(baseIdx*props.frameSize+i+1)}>{baseIdx*props.frameSize+i+1}</a></li>);
        }
        return liArr;
    }


    const movePre=()=>{
        if(baseIdx>0)
        {
            props.setCurrentPage((baseIdx-1)*props.frameSize+1);
        }
    }

    const moveNext=()=>{
        //const totalPage=Math.ceil(props.totalRec/props.pageSize);
        if(totalPage%props.frameSize===0)
        {
            if(baseIdx<Math.floor(totalPage/props.frameSize)-1)
            {
                props.setCurrentPage((baseIdx+1)*props.frameSize+1);
            }
        }
        else
        {
            if(baseIdx<Math.floor(totalPage/props.frameSize))
            {
                props.setCurrentPage((baseIdx+1)*props.frameSize+1);
            }      

        }
    }

    useEffect(()=>{
        if(baseIdx>0) setEnPrev(true);
        else setEnPrev(false);

        if(totalPage%props.frameSize===0)
        {
            if(baseIdx<Math.floor(totalPage/props.frameSize)-1)
            {
               setEnNext(true);
            }
            else
            {
                setEnNext(false);
            }
        }
        else
        {
            if(baseIdx<Math.floor(totalPage/props.frameSize))
            {
                setEnNext(true);
            }
            else
            {
                setEnNext(false);
            }      

        }
    },[props.currentPage])


    return (props.totalRec>0) ?  
    <ul className="pagination pagination-secondary mx-auto mt-3 mb-0">
                <li className={(enPrev)?"page-item":"page-item disabled"} title="前10頁">
                    <a className="page-link" tabIndex={-1} type="button" onClick={()=>movePre()} title="前10頁">
                        <i className="fa fa-angle-double-left"></i>
                    </a>
                </li>
                {liElements()}                
                <li className={(enNext)?"page-item":"page-item disabled"} title="後10頁">
                    <a className="page-link" type="button" onClick={()=>moveNext()} title="後10頁">
                        <i className="fa fa-angle-double-right"></i>
                    </a>
                </li>
            </ul> : null
};