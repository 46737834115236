
import React, { useEffect, useState } from 'react';


const StepGuide: React.FC = () => {
    const styles: { [key: string]: React.CSSProperties } = {
      stepGuide: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      },
      step: {
        textAlign: 'center' as 'center',
        position: 'relative' as 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      circle: {
        width: '30px', // Smaller circle
        height: '30px',
        backgroundColor: '#006400', // Dark green
        color: 'white',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '14px', // Smaller font size for smaller circle
        marginBottom: '5px',
      },
      activeCircle: {
        color: 'red',
        fontWeight: 'bold',
      },
      label: {
        fontSize: '12px',
        color: '#006400',
      },
      date: {
        fontSize: '12px',
        color: '#666',
      },
      line: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px', // Set a fixed width for the line
        height: '2px',
        backgroundColor: '#006400',
        color: '#006400',
        fontSize: '16px', // Size of the arrow
        margin: '0 10px',
      },
    };
  
    return (
      <div style={styles.stepGuide}>
        <div style={styles.step}>
          <div style={{ ...styles.circle }}>1</div>
          <div style={styles.label}>承辦人專頁</div>
        </div>
        <div style={styles.line}></div>
        <div style={styles.step}>
          <div style={styles.circle}>2</div>
          <div style={styles.label}>指導老師資料</div>
        </div>
        <div style={styles.line}></div>
        <div style={styles.step}>
          <div style={styles.circle}>3</div>
          <div style={styles.label}>小隊資料</div>
        </div>
      </div>
    );
  };
  
  export default StepGuide;