import React, { useEffect, useState } from 'react'
import { IExStuPP1 } from '../../interfaces/ExStuPP1'
import { IExStuRecordStat } from '../../interfaces/ExStuRecordStat'
import IPageProps from '../../interfaces/page'
import { IExDPStu } from '../../interfaces/ExDPStu'
import { GetExStuDPByAGSid } from '../../services/ExDPStuService'
interface IProps {
    pirlsRefresh: boolean;
    setPirlsRefresh: (val:boolean) => void;
}
const StuPirlsPage: React.FunctionComponent<IProps> = (props) => {
    const [stuDPRec,setStuDPRec]=useState<IExDPStu[]>([])
    const [isFetched,setIsFetched]=useState(false);
    const convertDateTime=(date_str:string):string=>{
        const date = new Date(date_str);
        const formattedDate = date.toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")

          return formattedDate;
        }
    const fetchData=async(sid:number)=>{
        const response=await GetExStuDPByAGSid('Pirls',sid); 
        setStuDPRec(response);
        setIsFetched(true);
    }
    const markAnsColor=(stuAns:string,ans:string,ansType:string):string=>{
        switch (ansType) {
            case "單選":
                if(stuAns===ans)
                {
                    return "<div>"+stuAns+"</div>";
                }
                else
                {
                    return  "<div class='text-danger'>"+stuAns+"</div>";;
                }                
            case "多選":
                const ansArr=ans.split("<br/>");
                const stuAnsArr=stuAns.split("<br/>").slice(0,-1);
                let ansStr="<ul style='list-style-type: none; padding:0 ; margin:0;'>";
                stuAnsArr.map((item)=>{
                    if(ansArr.includes(item))
                    {
                        ansStr+="<li>"+item+"</li>";
                    }
                    else
                    {
                        ansStr+="<li class='text-danger'>"+item+"</li>";
                    }
                })
                ansStr+="</ul>";
                return ansStr;               
            default:
                return "<div>"+stuAns+"</div>";
        }

    }



    useEffect(() => {
        if(sessionStorage.getItem("LoginStatus") === "1") 
        {
            if(sessionStorage.getItem("LoginID") !== null) 
            {
                fetchData(Number(sessionStorage.getItem("LoginID")));
            }
        }
        else
        {
            const stuStr=sessionStorage.getItem("LoginStudent");
            if(stuStr!==null)
            {
                const stu=JSON.parse(stuStr);
                if(stu.Sid!==undefined)
                {
                    fetchData(stu.Sid);
                }
            }
        }
    }, [])

    useEffect(() => {
        if(sessionStorage.getItem("LoginStatus") === "1") 
        {
            if(sessionStorage.getItem("LoginID") !== null) 
            {
                fetchData(Number(sessionStorage.getItem("LoginID")));
            }
        }
        else
        {
            const stuStr=sessionStorage.getItem("LoginStudent");
            if(stuStr!==null)
            {
                const stu=JSON.parse(stuStr);
                if(stu.Sid!==undefined)
                {
                    fetchData(stu.Sid);
                }
            }
        }
        if(props.pirlsRefresh)
        {
            props.setPirlsRefresh(false);
        }
    }, [props.pirlsRefresh])



    return ((isFetched)? 
        <>
            {/*<div className="tab-pane fade mt-5" id="deep" role="tabpanel" aria-labelledby="deep-tab">*/}
            <div className="mt-5">
                <div className="card p-4 pt-3">
                    <div className="text-muted py-3">因題數調整和增加題號的對應，僅提供2022/8/1以後的作答資料，敬請見諒!!</div>
                    <div className="table-responsive">
                        <table className="text-sm table table-bordered">
                            <thead>
                                <tr className="font-weight-bolder text-second" style={{background:'#d9dce6'}}>
                                    <th className="p-2 text-nowrap">No.</th>
                                    <th className="p-2 text-nowrap">日期</th>
                                    <th className="p-2 text-nowrap">文章</th>
                                    <th className="p-2 text-nowrap">題號</th>
                                    <th className="p-2 text-nowrap">學生的回答</th>
                                    <th className="p-2 text-nowrap">參考答案</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(stuDPRec.length>0) ?
                                stuDPRec.map((item,index)=>(
                                    <>
                                        <tr>
                                            <>
                                            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} rowSpan={item.StuAnswer.length}>{index + 1}</td>
                                            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} rowSpan={item.StuAnswer.length}>{convertDateTime(item.AddTime)}</td>
                                            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} rowSpan={item.StuAnswer.length}>{item.ArticleNo}<br />{item.DPTopic}</td>                                            
                                            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>1</td>
                                            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: markAnsColor(item.StuAnswer[0],item.Answer[0],item.AnswerType[0]) }}></td>
                                            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: item.Answer[0] }}></td>
                                            </>
                                        </tr>
                                            {item.StuAnswer.slice(1).map((sans,idx)=>(
                                                <>
                                                <tr>
                                                <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{idx+2}</td>
                                                <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: markAnsColor(sans,item.Answer[idx+1],item.AnswerType[idx+1]) }}></td>
                                                <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: item.Answer[idx+1]}}></td>
                                                </tr>
                                                </>
                                            ))}
                                                                          
                                    
                                    </>
                                )):<>尚無資料</>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>  
        </> :<div>Loading.........</div>
    );
}

export default StuPirlsPage;