import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import IFunData from '../interfaces/FunData';
import IFunDataPart from '../interfaces/FunDataPart';
import IFunSource from '../interfaces/FunSource';


export const getFunDataPartByYr=(yr:string)=>{
    let url=''
    if(yr==='*')
        url=config.server.url+`/api/FunData/part/yr/*`
    else
        url=config.server.url+`/api/FunData/part/yr/${yr}`

    return axios.get(url).then((res)=>{
        const rest:IFunDataPart[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getFunDataByGroupno=(gno:string)=>{
    const url=config.server.url+`/api/FunData/gno/${gno}`

    return axios.get(url).then((res)=>{
        const rest:IFunData[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
export const getFunSourceByGroupno=(gno:string)=>{
    const url=config.server.url+`/api/FunSource/gno/${gno}`

    return axios.get(url).then((res)=>{
        const rest:IFunSource[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



