
import axios, { AxiosError } from 'axios'
import config from '../../config/config';
import { sourceType } from '../../pages/ep/tool/helper';



// Get Opinion or OpinionSure by groupno and memberno and opidx
// Get /api/OpinionTable/isfeedback/:isfeedback/opidx/:opidx/groupno/:groupno/memberno/:memberno 
/**以小隊編號、成員編號及意見編碼取得該小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1*/
export const getOpinionByGroupMemOpIdx = async (isfeedback: 0 | 1, opidx: string, groupno: string, memberno: string) => {
  const url = config.server.url + `/api/OpinionTable/isfeedback/${isfeedback}/opidx/${opidx}/groupno/${groupno}/memberno/${memberno} `
  console.log('rul', url)
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });


}




// 以新增小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1
// Create Opinion or OpinionSure 
// Post /api/OpinionTable/newopinion/isfeedback/:isfeedback/opidx/:opidx
// Request Body JSON Sample:
/*
{
  "GroupNo":"",
  "MemberNo":"",    
  "DataA":"",
  "DataB":"",
  "DataC":""
}
*/
// const createOpinion = asyncHandler(async(req: Request, res: Response) => {
//   try{
//     //let pool = await sql.connect(config.mssql);
//     await pool.connect();

//     const opinion=(req.params.isfeedback==='0') ? 'Opinion' : 'OpinionSure';

//     const opinionType=getOpinionCode(req.params.opidx);

//     const dataA=(req.body.DataA==="") ? "null" : req.body.DataA;
//     const dataB=(req.body.DataB==="") ? "null" : req.body.DataB;
//     const dataC=(req.body.DataC==="") ? "null" : req.body.DataC;
//     const memberno=(req.body.MemberNo==="") ? "null" : req.body.MemberNo;

//     const qryStr=`INSERT ${opinion} VALUES ('${req.body.GroupNo}','${memberno}','${opinionType}','${dataA}','${dataB}','${dataC}',GETDATE(),NULL)`;

//     const rs= await pool.request().query(qryStr);
//     res.status(200).json(rs.recordset);
//   }
//   catch(error){
//     console.log(error);
//   }  
// });
// uid":9182,"GroupNo":"FA001-01","SubNo":1,"SubName":"我是子題一AAAAA","Description":"趙小一，趙小二","Keyword":null,"DeleteDate":null}

export interface paramsCreateOpinionSubTopic {
  action?: "新增" | "修改" | "刪除"
  UID: number | undefined
  ParamType: sourceType | ""
  GroupNo: string,
  MemberNo?: string,
  DataA?: string,
  DataB?: string,
  DataC?: string
  SubNo?: string | any
  SubName?: string | any
  Description?: string | any
  Keyword?: string | any
  [key: string]: any;
  termsStringList?: string[]

}

/** */
export interface paramsUpdateSubTopic {
  uid: string
  GroupNo: string,
  SubName: string,
  Description: string,
  Keyword: null,
}
export const createOpinion = async (param: paramsCreateOpinionSubTopic, isfeedback: 0 | 1, opidx: string) => {

  const url = config.server.url + `/api/OpinionTable/newopinion/isfeedback/${isfeedback}/opidx/${opidx}`
  console.log('createOpinion', url)
  return new Promise((resolve, reject) => {
    axios.post(url, param)
      .then(response => {
        console.log("insertDrApplyByPrefix ", response.data);
        resolve(response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}




// 以小隊編號及意見編碼修改小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1
// Get Opinion or OpinionSure by groupno and opidx
// Put /api/OpinionTable/updopinion/isfeedback/:isfeedback/opidx/:opidx/groupno/:groupno/uid/:uid
// Request Body JSON Sample:
/*
{
  "DataA":"",
  "DataB":"",
  "DataC":""
}
*/
// const updateOpinionByGroupOpIdxUID = asyncHandler(async(req: Request, res: Response) => {
export const updateOpinionByGroupOpIdxUID = async (param: any, isfeedback: 0 | 1, opidx: string, groupno: string, uid: number) => {
  //Here
  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/OpinionTable/updopinion/isfeedback/${isfeedback}/opidx/${opidx}/groupno/${groupno}/uid/${uid}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.put(url, param)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });




}



// 以小隊編號及意見編碼取得該小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1
// Get Opinion or OpinionSure by groupno and opidx
// Get /api/OpinionTable/isfeedback/:isfeedback/opidx/:opidx/groupno/:groupno 
// const getOpinionByGroupOpIdx = asyncHandler(async(req: Request, res: Response) => {
/** 取得opinion */
export const getOpinionByGroupOpIdx = async (isfeedback: 0 | 1, opidx: string, groupno: string) => {
  //Here

  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/OpinionTable/isfeedback/${isfeedback}/opidx/${opidx}/groupno/${groupno}`
  console.log('rul', url)
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });



}


// Update SubTopic and SubTopicSure by Groupno and SubNo
// Put /api/SubTable/updsubtopic/isfeedback/:isfeedback/uid/:uid 回饋前 isfeedback:0 回饋後 isfeedback:1
// const updateSubTopicByUid = asyncHandler(async(req: Request, res: Response) => {
export const updateSubTopicByUid = async (param: any, isfeedback: 0 | 1, uid: number) => {
  //Here
  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/SubTable/updsubtopic/isfeedback/${isfeedback}/uid/${uid}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.put(url, param)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });




}

// 以小隊編號及探究子題編號刪除探究子題(包含回饋前及回饋後) 僅設定DeleteDate 不實際刪除
// Delete SubTopic and SubTopicSure by Groupno and SubNo 
// put /api/SubTable/delSubTopic/isfeedback/:isfeedback/groupno/:groupno/subno/:subno 回饋前 isfeedback:0 回饋後 isfeedback:1
// const deleteSubTopicByGroupNoSubNo = asyncHandler(async(req: Request, res: Response) => {
export const deleteSubTopicByGroupNoSubNo = async (isfeedback: 0 | 1, groupno: string, subno: number) => {
  //Here
  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/SubTable/delSubTopic/isfeedback/${isfeedback}/groupno/${groupno}/subno/${subno}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });




}


// 以小隊編號及意見編碼刪除小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1 (僅設定DeleteDate)
// Delete Opinion or OpinionSure by groupno and opidx
// Put /api/OpinionTable/delopinion/isfeedback/:isfeedback/uid/:uid/

// const deleteOpinionByUID = asyncHandler(async(req: Request, res: Response) => {
export const deleteOpinionByUID = async (isfeedback: 0 | 1, uid: number) => {
  //Here
  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/OpinionTable/delopinion/isfeedback/${isfeedback}/uid/${uid}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });




}

export interface subTopicParam {
  GroupNo: string
  SubName: string
  Description: string

}

// 新增探究子題依據子題最後編號+1(包含回饋前及回饋後)
// Create SubTopic and SubTopicSure
// Post /api/SubTable/newsubtopic/isfeedback/:isfeedback 回饋前 isfeedback:0 回饋後 isfeedback:1
// const createSubTopic = asyncHandler(async(req: Request, res: Response) => {}

export const createSubTopic = async (param: subTopicParam, isfeedback: 0 | 1) => {

  const url = config.server.url + `/api/SubTable/newsubtopic/isfeedback/${isfeedback}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.post(url, param)
      .then(response => {
        console.log("createSubTopic ", response.data);
        resolve(response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}

// Put /api/OpinionTable/updopinionsforclear/groupno/:groupno
export const updateOpinionForClear = async (groupno: string) => {
  const url = config.server.url + `/api/OpinionTable/updopinionsforclear/groupno/${groupno}`

  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("updateOpinionForClear", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}



// Put /api/OpinionTable/updopinionscopy/groupno/:groupno
// const updateOpinionCopyToSureOpinion = asyncHandler(async(req: Request, res: Response) => {
export const updateOpinionCopyToSureOpinion = async (groupno: string) => {
  const url = config.server.url + `/api/OpinionTable/updopinionscopy/groupno/${groupno}`
  console.log('url ...updateOpinionCopyToSureOpinion', url)
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("updateOpinionForClear", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}

// 以小隊編號、探究子題編號及重點整理編號新增資料來源
// Create SubCollect by Groupno and SubNo and SummaryNo
// Post /api/SubTable/newcollect/groupno/:groupno/subno/:subno/sumno/:sumno
export const createSubCollectByGroupSubSumNo = async (param: any, groupno: string, subno: string, sumno: string) => {

  const url = config.server.url + `/api/SubTable/newcollect/groupno/${groupno}/subno/${subno}/sumno/${sumno}`

  return new Promise((resolve, reject) => {
    axios.post(url, param)
      .then(response => {
        console.log("createSubTopic ", response.data);
        resolve(response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });


}



// 以小隊編號及探究子題編號新增重點整理(包含回饋前及回饋後)
// Create SubSummary by Groupno and SubNo
// Post /api/SubTable/newsum/groupno/:groupno/subno/:subno 
// const createSubSummaryByGroupNoSubNo = asyncHandler(async(req: Request, res: Response) => {
export const createSubSummaryByGroupNoSubNo = async (param: any, groupno: string, subno: string) => {
  const url = config.server.url + `/api/SubTable/newsum/groupno/${groupno}/subno/${subno}`

  return new Promise((resolve, reject) => {
    axios.post(url, param)
      .then(response => {
        console.log("createSubSummaryByGroupNoSubNo ", response.data);
        resolve(response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}
// 以小隊編號、探究子題編號及重點整理編號更新重點整理
// Update SubSummary by Groupno and SubNo
// Put /api/SubTable/updsum/groupno/:groupno/subno/:subno/sumno/:sumno

export const updateSubSummaryByGroupSubSumNo = async (groupno: string, subno: string, sumno: string) => {
  const url = config.server.url + `/api/SubTable/updsum/groupno/${groupno}/subno/${subno}/sumno/${sumno}`
  console.log('url ...updateSubSummaryByGroupSubSumNo')
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("updateOpinionForClear", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}



// 以重點整理Uid刪除重點整理及資料來源 (僅設定DeleteDate 不實際刪除)
// Delete SubSummary and SubCollect by uid
// Put /api/SubTable/delsum/sumuid/:sumuid
// const deleteSubSummaryByUid = asyncHandler(async(req: Request, res: Response) => {

export const deleteSubSummaryByUid = async (sumuid: string) => {
  //Here
  const url = config.server.url + `/api/SubTable/delsum/sumuid/${sumuid}`
  console.log("deleteSubSummaryByUid" + url);
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("deleteSubSummaryByUid", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });




}



// Delete SubCollect by Groupno and SubNo and SummaryNo and CollectNo
// Put /api/SubTable/delcollect/groupno/:groupno/subno/:subno/sumno/:sumno/colno/:colno
// const deleteSubCollectByGroupSubSumColNo = asyncHandler(async(req: Request, res: Response) => {


export const deleteSubCollectByGroupSubSumColNo = async (groupno: string, subno: string, sumno: string, colno: string) => {
  //Here
  console.log("deleteSubCollectByGroupSubSumColNo");
  const url = config.server.url + `/api/SubTable/delcollect/groupno/${groupno}/subno/${subno}/sumno/${sumno}/colno/${colno}`
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });




}

//   // 以小隊編號、探究子題編號、重點整理編號及資料來源編號刪除資料來源 (僅設定DeleteDate 不實際刪除)
// // Delete SubCollect by Groupno and SubNo and SummaryNo and CollectNo
// // Put /api/SubTable/delcollect/groupno/:groupno/subno/:subno/sumno/:sumno/colno/:colno
// // const deleteSubCollectByGroupSubSumColNo = asyncHandler(async(req: Request, res: Response) => {
//   export const deleteSubCollectByGroupSubSumColNo = async (isfeedback :0|1  ,groupno:string  , subno:number )=>{
//     //Here
//     console.log("UpdateApplyBaseInfo",);
//     const url=config.server.url+`/api/SubTable/delSubTopic/isfeedback/${isfeedback}/groupno/${groupno}/subno/${subno}`
//     console.log('url',url)
//     return new Promise((resolve, reject) => {
//         axios.put(url)
//           .then(response => {
//             console.log("updateOpinionByGroupOpIdxUID ",response.data);
//             resolve(response.data);
//           })
//           .catch(error => {
//             console.error('Error fetching data:', error);
//             reject(error); //
//           });
//       });




// }

// 以資料來源UID刪除資料來源 (僅設定DeleteDate 不實際刪除)
// Delete SubCollect by uid
// Put /api/SubTable/delcollect/coluid/:coluid
export const deleteSubCollectByUid = async (coluid: string) => {
  const url = config.server.url + `/api/SubTable/delcollect/coluid/${coluid}`
  return new Promise((resolve, reject) => {
    axios.put(url)
      .then(response => {
        console.log("deleteSubCollectByUid ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });

}

// 以重點整理uid更新重點整理
// Update SubSummary by uid
// Put /api/SubTable/updsum/sumuid/:sumuid 
export const updateSubSummaryByUid = async (param: any,sumuid:string) => {
  //Here
  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/SubTable/updsum/sumuid/${sumuid}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.put(url, param)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });
}

// Update SubCollect by uid
// Put /api/SubTable/updcollect/coluid/:coluid
export const updateSubCollectByUid = async (param :any ,coluid: string) => {
  //Here
  console.log("UpdateApplyBaseInfo",);
  const url = config.server.url + `/api/SubTable/updcollect/coluid/${coluid}`
  console.log('url', url)
  return new Promise((resolve, reject) => {
    axios.put(url, param)
      .then(response => {
        console.log("updateOpinionByGroupOpIdxUID ", response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reject(error); //
      });
  });
}