import React, { useEffect, useState } from 'react'
import { handleLink } from '../../../modules/utils';
import TopNavPage2 from '../../topNav2';
import {RouteComponentProps} from 'react-router-dom'
import FooterPage from '../../footer';
import { getExDPWebs } from '../../../services/ExDPWebService';
import { IExDPWeb } from '../../../interfaces/ExDPWeb';

import { buildAnsStateDPByNoArray, getAnsDPByNo, getAnsQDPByNoArray, getDPQAByNo, getQuestionDPByNoArray } from '../../../services/ExDPQAService';
import IAnsStateDP from '../../../interfaces/AnsStateDP';
import IQuestionDP from '../../../interfaces/QuestionDP';
import IAnsQDP from '../../../interfaces/AnsQDP';
import { getDPLearnTaskByNo } from '../../../services/ExDPService';
import DispDPAns from './dispdpans';
import DPQAPage from './dpqa';
import { createNoSubstitutionTemplateLiteral } from 'typescript';


//type IDPPracticeProp=RouteComponentProps< {
   // group:string;
   // no: string
//}>;

interface IDPPracticeProp {

}


const DPPracticePageMix: React.FunctionComponent<IDPPracticeProp> = props => {

    const [currTab,setCurrTab]=useState(0);
    const [ExDPWebs,setExDPWebs]=useState<IExDPWeb[]>([]);
    const [ansStateDP,setAnsStateDP]=useState<IAnsStateDP[][]>([[]]);
    const [questionDP,setQuestionDP]=useState<IQuestionDP[][]>([[]]);
    const [ansQDP,setAnsQDP]=useState<IAnsQDP[]>([]);
    const [finishFlag,setFinishFlag]=useState(false);
    const [learnTask,setLearnTask]=useState("");

    const handleTab=(idx:number)=>{
        if(idx!==currTab)
        {
            setCurrTab(idx); 
        }
    }    

    const InitData=()=>{
        getDPQAByNo(""+sessionStorage.getItem("DPNo")).then(data=>{
            setQuestionDP(getQuestionDPByNoArray(data));
            setAnsQDP(getAnsQDPByNoArray(data));
            setAnsStateDP(buildAnsStateDPByNoArray(data))
        })
                                                      
    }
    const getOpMode=():string=>{
        if(sessionStorage.getItem("DPGroup")==="Pirls")
            return "QOri" // Quesiton button to switch pages
        else
            return "TOri" // Tab button to switch pages
    }


    useEffect(()=>{
        getExDPWebs(""+sessionStorage.getItem("DPNo")).then(data=>setExDPWebs(data));
        getDPLearnTaskByNo(""+sessionStorage.getItem("DPNo")).then(data=>setLearnTask(data));
        InitData();        
    },[])

    useEffect(()=>{
        if(ExDPWebs.length!==0)
        {
            let leftContent=document.getElementById('left-content');
            if(leftContent!==null)
            {
                if(ExDPWebs[currTab].Model==="W")
                {
                    if(ExDPWebs[currTab].Banner!==null)
                    {
                        leftContent.innerHTML=`<div className="mx-auto">
                                               <img src="./assets/img/PirlsImage/${ExDPWebs[currTab].Banner.trim()}" style="max-width:100%; border-radius:16px "/>                
                                               </div>`+ExDPWebs[currTab].HTML;
                    }
                    else
                    {
                        leftContent.innerHTML=ExDPWebs[currTab].HTML;
                    }
                 
                    let anTag=document.getElementsByClassName("anotherTag");
                    if(anTag.length!==0) 
                    {
                        for(let i=0;i<anTag.length;i++) anTag[i].className=""+"pointer text-blue"
                    }
                } 
                else
                {
                    leftContent.innerHTML=`<div className="mx-auto">
                                           <img src="./assets/img/PirlsImage/${ExDPWebs[currTab].Banner.trim()}" alt="" style="max-width:100%; border-radius:16px "/>                  
                                           </div> `
                }
            }
        }    
    },[ExDPWebs,currTab])

    return (
        <>
            <div style={{backgroundColor:'#FCF9F3'}}>
                <TopNavPage2 />
                <div id="test" className="container d-flex flex-column flex-xl-row align-items-center mb-2">
                    <div className="d-flex align-items-start align-items-sm-center py-2 order-2 order-xl-1">
                        {
                            (getOpMode()==="QOri") ?
                                <img src='./assets/img/dp/Sdgs-Love.png' className="me-2" style={{width:'80px'}}/> :
                                <img src={'./assets/img/dp/'+sessionStorage.getItem("DPImage")} className="me-2" style={{width:'80px'}}/>    
                        }
                        <div>
                            <p className="mb-1">右欄有學習任務，請閱讀後回答問題。</p>
                            {/*<!-- <div>
                                        <span className="badge badge-danger">您未登入，故不會儲存本次練習結果。</span>
                            </div> --> */}
                            <div>
                                <span className="badge badge-secondary me-2">已學習DP文章數：3</span>
                                <span className="badge badge-secondary">本篇文章學習次數 : 0</span>
                            </div>
                        </div>
                    </div>
                    <div className="ms-0 ms-xl-auto order-1 order-xl-2 my-3">
                        <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/dptest')}>
                        <i className="fas fa-chevron-left"></i>
                        返回 多文本題組
                        </button>
                    </div>
                </div>
            </div>
            <div className="section pt-0 mt-54">
                <div className="container">
                    <div className="text-center">
                        <span style={{fontSize:'30px', paddingRight:'4px', color:'#D13521'}}>{sessionStorage.getItem("DPNo")}</span>
                        <span style={{fontSize:'18px', color:'#000'}}>{sessionStorage.getItem("DPTopic")}</span>
                    </div>
                    <div id="start">
                        <div className="card">
                            <div className="card-body" style={{textAlign: 'left'}}>
                                <div className="tab-content" id="dpContent">
                                    <div className="tab-pane fade show active" id="deep" role="tabpanel" aria-labelledby="deep-tab">
                                        <>
                                        {
                                        (ExDPWebs.length>0 && ExDPWebs[currTab].Model==="W") ?
                                        <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth:'420px'}}>
                                            <ul className="nav nav-pills nav-fill flex-nowrap"
                                                style={{background:'#9ad6d742'}} id="dp50" role="tablist">
                                                <>
                                                {
                                                    ExDPWebs.filter(data=>data.Model==="W").map((item)=>{
                                                        if(item.PageNo-1===currTab)
                                                        {
                                                            return <li className="nav-item">
                                                                <a className="nav-link mb-0 font-weight-bold text-sm active" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                    href="" role="tab" aria-controls="" aria-selected="true" onClick={()=>handleTab(item.PageNo-1)}>
                                                                    <span className="text-second">{item.Tab}</span></a>
                                                                </li>
                                                        }
                                                        else
                                                        {
                                                            return <li className="nav-item">
                                                                <a className="nav-link mb-0 font-weight-bold text-sm" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                    href="" role="tab" aria-controls="" aria-selected="false" onClick={()=>handleTab(item.PageNo-1)}>
                                                                    <span className="text-second">{item.Tab}</span></a>
                                                                </li>
                                                        }
                                                    })

                                                }
                                                </>
                                            </ul>
                                        </div> :null
                                        }
                                        </>
                                        <div className="tab-content my-4 me-2" id="dpContent">

                                            <div className="row align-items-start text-justify">
                                                <div className="col-12 col-lg-8 pe-4" id="left-content">
                                                </div>                                                       
                                                <DPQAPage 
                                                    ArticleNo={""+sessionStorage.getItem("DPNo")}
                                                    PageNo={currTab}
                                                    questionDP={questionDP}
                                                    ansStateDP={ansStateDP}
                                                    ansQDP={ansQDP}
                                                    LearnTask={learnTask}
                                                    finishFlag={false}
                                                    currTab={currTab}
                                                    QMode={(ExDPWebs.length>0)?ExDPWebs[currTab].Model:""}
                                                    OPMode={getOpMode()}
                                                    SetAnsStateDP={setAnsStateDP}
                                                    SetAnsQDP={setAnsQDP}
                                                    SetFinishFlag={setFinishFlag}
                                                    SetCurrTab={setCurrTab}
                                                />
                                        </div>
                                    </div>
                                    <>
                                    {
                                    (ExDPWebs.length>0 && ExDPWebs[currTab].Model==="W") ?
                                    <div className="nav-wrapper position-relative z-index-2 mx-auto" style={{maxWidth:'420px'}}>
                                        <ul className="nav nav-pills nav-fill flex-nowrap"
                                            style={{background:'#9ad6d742'}} id="dp50" role="tablist">
                                            <>
                                            {
                                                ExDPWebs.filter(data=>data.Model==="W").map((item)=>{
                                                    if(item.PageNo-1===currTab)
                                                    {
                                                        return <li className="nav-item">
                                                            <a className="nav-link mb-0 font-weight-bold text-sm active" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                href="" role="tab" aria-controls="" aria-selected="true" onClick={()=>handleTab(item.PageNo-1)}>
                                                                <span className="text-second">{item.Tab}</span></a>
                                                            </li>
                                                    }
                                                    else
                                                    {
                                                        return <li className="nav-item">
                                                            <a className="nav-link mb-0 font-weight-bold text-sm" id={item.ArticleNo+item.PageNo.toString()} data-bs-toggle="tab"
                                                                href="" role="tab" aria-controls="" aria-selected="false" onClick={()=>handleTab(item.PageNo-1)}>
                                                                <span className="text-second">{item.Tab}</span></a>
                                                            </li>
                                                    }
                                                })

                                            }
                                            </>

                                        </ul>
                                    </div>:null
                                    }
                                    </>
                                    </div>
                                </div>
                            </div>
                        </div>                  
      
                        {finishFlag && <DispDPAns ansQDP={ansQDP} />}
                    
                    </div>
                </div>
            </div>
            
            <FooterPage />
        </>

    );
}

export default DPPracticePageMix;