import axios, { AxiosError } from 'axios'
import  config from '../../config/config';
import { IExStudent } from '../../interfaces/ExStudent';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { IExGradeClass } from '../../interfaces/ExGradeClass';
import { IExStuLearnRec } from '../../interfaces/ExStuLearnRec';
import { IInsertTeacher, IUpdateTeacher, IupdateApply } from '../../interfaces/EpModal';


// 以學生申請編號取得參賽學生資料
// Get DrStudent by StuNo 
// Get /api/DrStudent/stuno/:stuno
// const getDrStudentByNo = asyncHandler(async(req: Request, res: Response) => {
    export const getDrStudentByNo = async  (studentNo:string)=>{
        const url=config.server.url+`/api/DrStudent/stuno/${studentNo}`
            return new Promise((resolve, reject) => {
              axios.get(url)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                  reject(error); //
                });
            });
      
      
      }




      // 以學生編號取得該小隊各階段的意見內容
// Get /api/OpinionTable/allstage/stuno/:stuno
/* Response JSON Sample: 
 {
    "GroupID":"1",
    "GroupNo":"1".
    "GroupName":"小隊一",
    "Pre11":[
      {
        "StuID":"1",
        "StuNo":"2",
        "StuName":"小明",
        "DataA":"探究題目"
        "DataB""探究題目理由",
        UpdateDate:"2021-01-01 12:00:00"
      }
    ],
    "Post11":[
      {
        "StuID":"1",
        "StuNo":"2",
        "StuName":"小明",
        "DataA":"探究題目"
        "DataB""探究題目理由",
        UpdateDate:"2021-01-01 12:00:00"
      }
    ],
    "Pre12_DataA":"前-決定探究題目",
    "Pre12_DataB":"前-決定探究題目原因",
    "Pre12_UpdateDate":"2021-01-01 12:00:00"  
    "Pre13_DataB":"前-探究題目預測結果原因",
    "Pre13_UpdateDate":"2021-01-01 12:00:00"
    "Pre14_DataA":"前-遭遇困難",
    "Pre14_DataB":"前-解決方式",
    "Pre14_DataC":"前-學到的新思維",
    "Pre14_UpdateDate":"2021-01-01 12:00:00"
    "Pre19_DataA":"前-指導老師留言",
    "Pre19_UpdateDate":"2021-01-01 12:00:00",
    "Pre24_DataA":"前-遭遇困難",
    "Pre24_DataB":"前-解決方式",
    "Pre24_DataC":"前-學到的新思維",
    "Pre24_UpdateDate":"2021-01-01 12:00:00"
    "Pre29_DataA":"前-指導老師留言",
    "Pre29_UpdateDate":"2021-01-01 12:00:00"
    "ExpoOpinion":"專家回饋",
    "Post12_DataA":"後-決定探究題目",
    "Post12_DataB":"後-決定探究題目原因",
    "Post12_UpdateDate":"2021-01-01 12:00:00"  
    "Post13_DataB":"後-探究題目預測結果原因",
    "Post13_UpdateDate":"2021-01-01 12:00:00"
    "Post14_DataA":"後-遭遇困難",
    "Post14_DataB":"後-解決方式",
    "Post14_DataC":"後-學到的新思維",
    "Post14_UpdateDate":"2021-01-01 12:00:00"
    "Post19_DataA":"後-指導老師留言",
    "Post19_UpdateDate":"2021-01-01 12:00:00",
    "Post24_DataA":"後-遭遇困難",
    "Post24_DataB":"後-解決方式",
    "Post24_DataC":"後-學到的新思維",
    "Post24_UpdateDate":"2021-01-01 12:00:00"
    "Post29_DataA":"後-指導老師留言",
    "Post29_UpdateDate":"2021-01-01 12:00:00"
    "Post30_DataA":"後-回饋意見後修改",
    "Post30_UpdateDate":"2021-01-01 12:00:00"
    "Post33_DataA":"後-遭遇困難",
    "Post33_DataB":"後-解決方式",
    "Post33_DataC":"後-學到的新思維",
    "Post33_UpdateDate":"2021-01-01 12:00:00"
    "Post39_DataA":"後-指導老師留言",
    "Post39_UpdateDate":"2021-01-01 12:00:00"
    "Post42_DataA":"後-提出結論與發現",
    "Post42_UpdateDate":"2021-01-01 12:00:00"
    "Post43_DataA":"後-比較探究結論",
    "Post43_UpdateDate":"2021-01-01 12:00:00"
    "Post44_DataA":"後-遭遇困難",
    "Post44_DataB":"後-解決方式",
    "Post44_DataC":"後-學到的新思維",
    "Post44_UpdateDate":"2021-01-01 12:00:00"
    "Post49_DataA":"後-指導老師留言",
    "Post49_UpdateDate":"2021-01-01 12:00:00"
    "Post51_DataA":"後-更改前探究題目",
    "Post51_UpdateDate":"2021-01-01 12:00:00"
    "Post54_DataA":"後-參加本活動的省思",
    "Post54_UpdateDate":"2021-01-01 12:00:00"
    "Post55_DataA":"後-指導老師心得",
    "Post55_UpdateDate":"2021-01-01 12:00:00"
  }
 
*/
// const getOpinionByStuNo = asyncHandler(async(req: Request, res: Response) => {

/** 取得學生資料 */
export const getOpinionByStuNo = async  (studentNo:string)=>{
  const url=config.server.url+`/api/OpinionTable/allstage/stuno/${studentNo}`
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


}




// ------



// 以學生編號取得參賽小組資料
// Get DrGroup by stuno
// Get /api/DrGroup/stuno/:stuno
// Response body
/*
{
  "StuNo": "FD001-01-1",
  "StuName": "王小明",
  "GroupNo": "FD001-01",
  "GroupName": "綠色能量",
  "SchoolName": "台北市立大安國民中學",
  "ApplyNo": "FD001",
  "ApplyName": "王小明",
  "ApplyTitle": "教師",
  "TeaNo": "T001",
  "TeaName": "陳大明",
  "TeaTitle": "教師",
  "Team":[
    {
      TStuNo: "FD001-01-1",
      TStuName: "王小明",
    },
    {
      TStuNo: "FD001-01-2",
      TStuName: "陳小明",
    }
  ]
}
*/
// const getDrGroupByStuNo = asyncHandler(async(req: Request, res: Response) => 


export const getDrGroupByStuNo = async  (studentNo:string)=>{
  const url=config.server.url+`/api/DrGroup/stuno/${studentNo}`
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


}




// 以學生編號及年度取得參賽小組資料
// Get DrGroup by stuno
// Get /api/DrGroup/class/:class/stuno/:stuno
// Response body
/*
{
  "StuNo": "FD001-01-1",
  "StuName": "王小明",
  "GroupNo": "FD001-01",
  "GroupName": "綠色能量",
  "SchoolName": "台北市立大安國民中學",
  "ApplyNo": "FD001",
  "ApplyName": "王小明",
  "ApplyTitle": "教師",
  Teachers:[
    {
      "TeaNo": "T001",
      "TeaName": "陳大明",
      "TeaTitle": "教師",
    }  
  ]
  "Team":[
    {
      TStuNo: "FD001-01-1",
      TStuName: "王小明",
    },
    {
      TStuNo: "FD001-01-2",
      TStuName: "陳小明",
    }
  ]
}
*/
// const getDrGroupByStuNoClass = asyncHandler(async(req: Request, res: Response) => 


export const getDrGroupByStuNoClass = async  (className :string ,studentNo:string)=>{
  const url=config.server.url+`/api/DrGroup/class/${className}/stuno/${studentNo}`
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


}