import React, { useEffect, useState } from 'react'
import { IExStuPP1 } from '../../interfaces/ExStuPP1'
import { IExStuRecordStat } from '../../interfaces/ExStuRecordStat'
import IPageProps from '../../interfaces/page'
import { getExStuPP1BySid, getExStuPP4BySid, getExStuPP5BySid, getExStuPP6BySid,getStuRecStatBySidTest } from '../../services/ExStuRecService'
import { PageIndexX } from '../../components/pageIndexX'
import { IExStuPP4 } from '../../interfaces/ExStuPP4'
import { IExStuPP5 } from '../../interfaces/ExStuPP5'
import { IExStuPP6 } from '../../interfaces/ExStuPP6'

interface IProps {
    ppRefresh: boolean;
    setPPRefresh: (val:boolean) => void;
    StuID:number;
  }

const StuPPXPage: React.FunctionComponent<IProps> = (props) => {
    const [stuRecStat1,setStuRecStat1]=useState<IExStuRecordStat[]>([])
    const [stuRecStat2,setStuRecStat2]=useState<IExStuRecordStat[]>([])
    const [stuRecStat3,setStuRecStat3]=useState<IExStuRecordStat[]>([])
    const [stuRecStat4,setStuRecStat4]=useState<IExStuRecordStat[]>([])
    const [stuPP1,setStuPP1]=useState<IExStuPP1[]>([])
    const [stuPP4,setStuPP4]=useState<IExStuPP4[]>([])
    const [stuPP5,setStuPP5]=useState<IExStuPP5[]>([])
    const [stuPP6,setStuPP6]=useState<IExStuPP6[]>([])
    const [isFetchedL1,setIsFetchedL1]=useState(false);
    const [isFetchedR1,setIsFetchedR1]=useState(false);
    const [rCurrentPage1,setRCurrentPage1]=useState(1);
    const [lCurrentPage1,setLCurrentPage1]=useState(1);

    const [isFetchedL2,setIsFetchedL2]=useState(false);
    const [isFetchedR2,setIsFetchedR2]=useState(false);
    const [rCurrentPage2,setRCurrentPage2]=useState(1);
    const [lCurrentPage2,setLCurrentPage2]=useState(1);

    const [isFetchedL3,setIsFetchedL3]=useState(false);
    const [isFetchedR3,setIsFetchedR3]=useState(false);
    const [rCurrentPage3,setRCurrentPage3]=useState(1);
    const [lCurrentPage3,setLCurrentPage3]=useState(1);

    const [isFetchedL4,setIsFetchedL4]=useState(false);
    const [isFetchedR4,setIsFetchedR4]=useState(false);
    const [rCurrentPage4,setRCurrentPage4]=useState(1);
    const [lCurrentPage4,setLCurrentPage4]=useState(1);


    const convertDateTime=(date_str:string):string=>{
        const date = new Date(date_str);
        const formattedDate = date.toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")

          return formattedDate;
        }
    const fetchData=async(sid:number)=>{
        if(sessionStorage.getItem("LoginID") !== null) 
        {
            const response1=await getStuRecStatBySidTest(sid, 'PP1');
            setStuRecStat1(response1);
            setIsFetchedL1(true);

            const response2=await getExStuPP1BySid(sid);
            setStuPP1(response2);
            setIsFetchedR1(true);

            const response3=await getStuRecStatBySidTest(sid, 'PP4');
            setStuRecStat2(response3);
            setIsFetchedL2(true);

            const response4=await getExStuPP4BySid(sid);
            setStuPP4(response4);
            setIsFetchedR2(true);

            const response5=await getStuRecStatBySidTest(sid, 'PP5');
            setStuRecStat3(response5);
            setIsFetchedL3(true);

            const response6=await getExStuPP5BySid(sid);
            setStuPP5(response6);
            setIsFetchedR3(true);

            const response7=await getStuRecStatBySidTest(sid, 'PP6');
            setStuRecStat4(response7);
            setIsFetchedL4(true);

            const response8=await getExStuPP6BySid(sid);
            setStuPP6(response8);
            setIsFetchedR4(true);
        }
    }
    
    useEffect(() => {        
        if(sessionStorage.getItem("LoginStatus") === "1") 
        {
            if(sessionStorage.getItem("LoginID") !== null) 
            {
                fetchData(Number(sessionStorage.getItem("LoginID")));
            } 
        }
        else
        {
          /*  const stuStr=sessionStorage.getItem("LoginStudent");
            if(stuStr!==null)
            {
                const stu=JSON.parse(stuStr);
                if(stu.Sid !== null) 
                {
                    fetchData(stu.Sid);
                }   
            }*/
            fetchData(props.StuID);
        }
    }, [])

    useEffect(() => {        
        if(sessionStorage.getItem("LoginStatus") === "1") 
        {
            if(sessionStorage.getItem("LoginID") !== null) 
            {
                fetchData(Number(sessionStorage.getItem("LoginID")));
            } 
        }
        else
        {
            const stuStr=sessionStorage.getItem("LoginStudent");
            if(stuStr!==null)
            {
                const stu=JSON.parse(stuStr);
                if(stu.Sid !== null) 
                {
                    fetchData(stu.Sid);
                }   
            }
        }
        if(props.ppRefresh) props.setPPRefresh(false);
    }, [props.ppRefresh])


    return (
        <>
            {isFetchedL1 && isFetchedR1 &&
            <div className="mt-5">
                <div className="accordion" id="accordionRental">
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-primary py-3">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#learn1" aria-expanded="true" aria-controls="learn1">
                                <h5 className="my-0 me-2">快打高手</h5>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>                    

                        <div className="collapse show" id="learn1" data-bs-parent="#accordionRental">
                            <div className="d-flex flex-column flex-lg-row align-items-baseline">
                                <div className="col-12 col-lg-5" style={{marginRight:'5px'}}>
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second text-nowrap" style={{background: '#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className="p-2 text-nowrap">題數</th>
                                                    <th className="p-2 text-nowrap">答對</th>
                                                    <th className="p-2 text-nowrap">答錯</th>
                                                    <th className="p-2">秒</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {stuRecStat1.slice((lCurrentPage1-1)*10,lCurrentPage1*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                            <td>{item.TestCnt}</td>
                                                            <td>{item.CorrectCnt}</td>
                                                            <td>{item.WrongCnt}</td>
                                                            <td>{item.Second}</td>
                                                        </tr>
                                                    })}

                                            </tbody>
                                        </table>
                                        <PageIndexX frameSize={10} pageSize={10} totalRec={stuRecStat1.length} currentPage={lCurrentPage1} setCurrentPage={setLCurrentPage1}/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{background:'#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">問題</th>
                                                    <th className="p-2 text-nowrap">你的回答</th>
                                                    <th className="p-2 text-nowrap">正確</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stuPP1.slice((rCurrentPage1-1)*10,rCurrentPage1*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.Question}</td>
                                                            <td>{item.StuAnswer}</td>
                                                            <td>{(item.Correct===1)?'V':''}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                        </tr>
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                        <PageIndexX frameSize={10} pageSize={10} totalRec={stuPP1.length} currentPage={rCurrentPage1} setCurrentPage={setRCurrentPage1}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                                        
            }
            {isFetchedL2 && isFetchedR2 &&
            <div className="mt-5">
                <div className="accordion" id="accordionRental">
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-primary py-3">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#learn2" aria-expanded="true" aria-controls="learn2">
                                <h5 className="my-0 me-2">句句實言</h5>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>                    

                        <div className="collapse show" id="learn2" data-bs-parent="#accordionRenta2">
                            <div className="d-flex flex-column flex-lg-row align-items-baseline">
                                <div className="col-12 col-lg-5" style={{marginRight:'5px'}}>
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second text-nowrap" style={{background: '#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className="p-2 text-nowrap">題數</th>
                                                    <th className="p-2 text-nowrap">答對</th>
                                                    <th className="p-2 text-nowrap">答錯</th>
                                                    <th className="p-2">秒</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {stuRecStat2.slice((lCurrentPage2-1)*10,lCurrentPage2*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                            <td>{item.TestCnt}</td>
                                                            <td>{item.CorrectCnt}</td>
                                                            <td>{item.WrongCnt}</td>
                                                            <td>{item.Second}</td>
                                                        </tr>
                                                    })}

                                            </tbody>
                                        </table>
                                        <PageIndexX frameSize={10} pageSize={10} totalRec={stuRecStat2.length} currentPage={lCurrentPage2} setCurrentPage={setLCurrentPage2}/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{background:'#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">問題</th>
                                                    <th className="p-2 text-nowrap">你的回答</th>
                                                    <th className="p-2 text-nowrap">正確</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stuPP4.slice((rCurrentPage2-1)*10,rCurrentPage2*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.Question}</td>
                                                            <td>{item.StuAnswer}</td>
                                                            <td>{(item.Correct===1)?'V':''}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                        </tr>
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                        <PageIndexX frameSize={10} pageSize={10} totalRec={stuPP4.length} currentPage={rCurrentPage2} setCurrentPage={setRCurrentPage2}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                                        
            }
            {isFetchedL3 && isFetchedR3 &&
            <div className="mt-5">
                <div className="accordion" id="accordionRental">
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-primary py-3">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#learn3" aria-expanded="true" aria-controls="learn3">
                                <h5 className="my-0 me-2">長話短說</h5>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>                 

                        <div className="collapse show" id="learn3" data-bs-parent="#accordionRental">
                            <div className="d-flex flex-column flex-lg-row align-items-baseline">
                                <div className="col-12 col-lg-4" style={{marginRight:'5px'}}>
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{background:'#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className="p-2 text-nowrap">題數</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {stuRecStat3.slice((lCurrentPage3-1)*10,lCurrentPage3*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                            <td>{item.TestCnt}</td>
                                                        </tr>
                                                    })}

                                            </tbody>      
                                        </table>
                                        <PageIndexX frameSize={8} pageSize={10} totalRec={stuRecStat3.length} currentPage={lCurrentPage3} setCurrentPage={setLCurrentPage3}/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{background: '#d9dce6'}}>
                                                <th className="p-2 text-nowrap">No.</th>
                                                <th className="p-2 text-nowrap">題目</th>
                                                <th className="p-2 text-nowrap">標題</th>
                                                <th className="p-2 text-nowrap">你的摘要回答</th>
                                                <th className="p-2 text-nowrap">你的關鍵字回答</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {stuPP5.slice((rCurrentPage3-1)*10,rCurrentPage3*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.AbsNo}</td>
                                                            <td>{item.Topic}</td>
                                                            <td>{item.StuAbs}</td>
                                                            <td>{item.StuAnswer}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                        </tr>
                                                    })}
                                            </tbody>  
                                        </table>
                                        <PageIndexX frameSize={10} pageSize={10} totalRec={stuPP5.length} currentPage={rCurrentPage3} setCurrentPage={setRCurrentPage3}/>
                                    </div>
                                </div>    
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            }
            {isFetchedL4 && isFetchedR4 &&
            <div className="mt-5">
                <div className="accordion" id="accordionRental">
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-primary py-3">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#learn4" aria-expanded="true" aria-controls="learn4">
                                <h5 className="my-0 me-2">讀出滋味</h5>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="collapse show" id="learn4" data-bs-parent="#accordionRental">
                            <div className="d-flex flex-column flex-lg-row align-items-baseline">
                                <div className="col-12 col-lg-4" style={{marginRight:'5px'}}>
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{background: '#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className="p-2 text-nowrap">題數</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {stuRecStat4.slice((lCurrentPage4-1)*10,lCurrentPage4*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                            <td>{item.TestCnt}</td>
                                                        </tr>
                                                    })}

                                            </tbody>   
                                        </table>
                                        <PageIndexX frameSize={8} pageSize={10} totalRec={stuRecStat4.length} currentPage={lCurrentPage4} setCurrentPage={setLCurrentPage4}/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="card p-3 table-responsive">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{background: '#d9dce6'}}>
                                                    <th className="p-2 text-nowrap">No.</th>
                                                    <th className="p-2 text-nowrap">題目</th>
                                                    <th className="p-2 text-nowrap">標題</th>
                                                    <th className="p-2 text-nowrap">你的關鍵字回答</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {stuPP6.slice((rCurrentPage4-1)*10,rCurrentPage4*10).map((item, index) => {
                                                        return <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.AbsNo}</td>
                                                            <td>{item.Topic}</td>
                                                            <td>{item.StuAnswer}</td>
                                                            <td>{convertDateTime(item.AddTime)}</td>
                                                        </tr>
                                                    })}
                                            </tbody>  
                                        </table>
                                        <PageIndexX frameSize={10} pageSize={10} totalRec={stuPP6.length} currentPage={rCurrentPage4} setCurrentPage={setRCurrentPage4}/>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default StuPPXPage;