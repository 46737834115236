import React, { useEffect, useState, useRef } from 'react'
import IPageProps from '../../interfaces/page'
import TopNavPage2 from '../topNav2';
import FooterPage from '../footer';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { addExTeaStuBySidTid, addExTeaStusBySidTid, createExStudent, createExStudents, deleteExStudentBySids, deleteExTeaStuBySidsTid, getDistGradeClassExStuByTid, getExStudentByTeacherID, getExStudentByTidGradeClass, getExStudentByTidKeyword, getExTeacherBySchoolNo, getLastStuNo, getMaxStuID, getNewStuNo, updateExStuGradeClassBySids, updateOpenToExStudent } from '../../services/ExStuTeService';
import TopNavPage1 from '../topNav1';
import { IExStudent } from '../../interfaces/ExStudent';
import Excel from 'exceljs';
import FileSaver from 'file-saver';

import { Dropdown } from 'react-bootstrap';
import { IExGradeClass } from '../../interfaces/ExGradeClass';
import StuPPPage from '../students/StuPP';
import StuDPPage from '../students/StuDP';
import StuPirlsPage from '../students/StuPirls';
import StuSDGSPage from '../students/StuSDGs';
import { set } from 'date-fns';
import { getExStuPPAllBySid } from '../../services/ExStuRecService';
import { GetExStuDPBySid } from '../../services/ExDPStuService';
import StuPPXPage from '../students/StuPPX';

const TeacherStuLearnPage: React.FunctionComponent<IPageProps> = props => {
  const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();
  const [refresh,setRefresh]=useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [ppRefresh,setPPRefresh]=useState(false);
  const [dpRefresh,setDPRefresh]=useState(false);
  const [pirlsRefresh,setPirlsRefresh]=useState(false);
  const [sdgsRefresh,setSDGsRefresh]=useState(false);

  const [curGrdCls,setCurGrdCls]=useState<IExGradeClass>({
    GradeClass:"",
    Grade:"",
    Class:""
  }); //目前年級班級
  const [curStu,setCurStu]=useState<IExStudent>(); //目前學生
  const [openToGrdCls,setOpenToGrdCls]=useState<IExGradeClass[]>([]); //開放班級
  const [openToStu,setOpenToStu]=useState<IExStudent[]>([]); //開放班級學生

  const convertDateTime=(date_str:string):string=>{
    const date = new Date(date_str);
    const formattedDate = date.toLocaleString('zh-Hans',{
        hour12:false,
    }).replaceAll("/","-")
      
        return formattedDate;
  }

  const handleCurGrdCls=async (curgc:IExGradeClass)=>{
    if(loginTeacher===undefined) return;
    setCurGrdCls(curgc);    
    await getExStudentByTidGradeClass(loginTeacher?.Tid.toString().trim(),curgc.Grade.trim(),curgc.Class.trim()).then((iStus)=>{
      setOpenToStu(iStus);
      setCurStu(iStus[0]);
      sessionStorage.setItem("LoginStudent",JSON.stringify(iStus[0]));
    //  handlePage();
    });
    setRefresh(true);

  }

  const handleCurStu=async (curstu:IExStudent)=>{
    setCurStu(curstu);
    sessionStorage.setItem("LoginStudent",JSON.stringify(curstu));
    setCurrentPage(currentPage);
    handlePage(currentPage);
    setRefresh(true);
  }   

  const fetchData=async()=>{
    if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
    {
      if (sessionStorage.getItem("LoginTeacher") !== null) {

        const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
        setLoginTeaacher(lTea);

        //取得開放班級及學生
        await getDistGradeClassExStuByTid(lTea.Tid.toString().trim()).then((iGrdCls)=>{
          setOpenToGrdCls(iGrdCls);
          setCurGrdCls(iGrdCls[0]);
          getExStudentByTidGradeClass(lTea.Tid.toString().trim(),iGrdCls[0].Grade.trim(),iGrdCls[0].Class.trim()).then((iStus)=>{
            setOpenToStu(iStus);
            setCurStu(iStus[0]);
            sessionStorage.setItem("LoginStudent",JSON.stringify(iStus[0]));
            setRefresh(true);
          });;

        });

      }
    }
  }
  const handlePage=(cPage:number)=>{
    if(curStu===undefined) return null;
    if(cPage===0) return <StuPPXPage setPPRefresh={setPPRefresh} ppRefresh={ppRefresh} StuID={curStu.Sid}/>
    else if(cPage===1) return <StuDPPage setDPRefresh={setDPRefresh} dpRefresh={dpRefresh}/>
    else if(cPage===2) return <StuPirlsPage setPirlsRefresh={setPirlsRefresh} pirlsRefresh={pirlsRefresh}/>
    else if(cPage===3) return <StuSDGSPage setSDGsRefresh={setSDGsRefresh} sdgsRefresh={sdgsRefresh}/>
    else
    return null
  }

  const handleExportExcel=async()=>{
    if(loginTeacher===undefined) return;
    const wb=new Excel.Workbook();
    let ws=wb.addWorksheet('學生資料');
    let workingCell=ws.getCell('A1');
    workingCell.value="教師代碼:"+loginTeacher.Tid.toString().trim();

    workingCell=ws.getCell('A2');
    workingCell.value="學校:"+loginTeacher.SchoolName.trim();

    workingCell=ws.getCell('A3');
    workingCell.value="教師:"+loginTeacher.TeaName.trim();

    workingCell=ws.getCell('A5');
    workingCell.value="登入號";

    workingCell=ws.getCell('B5');
    workingCell.value="姓名";

    workingCell=ws.getCell('C5');
    workingCell.value="年級";

    workingCell=ws.getCell('D5');
    workingCell.value="班級";

    workingCell=ws.getCell('E5');
    workingCell.value="座號";

    workingCell=ws.getCell('F5');
    workingCell.value="性別";

    workingCell=ws.getCell('A6');
    workingCell.value=curStu?.StuNo;

    workingCell=ws.getCell('B6');
    workingCell.value=curStu?.StuName;

    workingCell=ws.getCell('C6');
    workingCell.value=curStu?.Grade;

    workingCell=ws.getCell('D6');
    workingCell.value=curStu?.Class;

    workingCell=ws.getCell('E6');
    workingCell.value=curStu?.Seat;

    workingCell=ws.getCell('F6');
    workingCell.value=curStu?.Gender;    

    if(curStu!==undefined)
    { 
      const stuRecDetail=await getExStuPPAllBySid(curStu.Sid);
      
      ws=wb.addWorksheet('快打高手');
      let rowItem=['日期','問題','回答','正確'];
      ws.addRow(rowItem);
      let stuPPDetail=stuRecDetail.filter((item)=>item.Test==='PP1');   
      if(stuPPDetail.length>0) // 快打高手
      {
        stuPPDetail.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.Question);
          rowItem.push(item.StuAnswer);
          rowItem.push((item.Correct===1)? "V":"");
          ws.addRow(rowItem);
        });
      }
      ws=wb.addWorksheet('句句實言');
      rowItem=['日期','問題','回答','正確'];
      ws.addRow(rowItem);
      stuPPDetail=stuRecDetail.filter((item)=>item.Test==='PP4');
      if(stuPPDetail.length>0) // 句句實言
      {
        stuPPDetail.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.Question);
          rowItem.push(item.StuAnswer);
          rowItem.push((item.Correct===1)? "V":"");
          ws.addRow(rowItem);
        });
      }
      ws=wb.addWorksheet('長話短說');
      rowItem=['日期','題號','題目','學生摘要回答','學生關鍵字回答'];
      ws.addRow(rowItem);
      stuPPDetail=stuRecDetail.filter((item)=>item.Test==='PP5');
      if(stuPPDetail.length>0) // 長話短說
      {
        stuPPDetail.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.AbsNo);
          rowItem.push(item.Question);
          rowItem.push(item.StuAbs);
          rowItem.push(item.StuAnswer);
          ws.addRow(rowItem);
        });
      }
      ws=wb.addWorksheet('讀出滋味');
      rowItem=['日期','題號','題目','學生關鍵字回答'];
      ws.addRow(rowItem);
      stuPPDetail=stuRecDetail.filter((item)=>item.Test==='PP6');
      if(stuPPDetail.length>0) // 讀出滋味
      {
        stuPPDetail.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.AbsNo);
          rowItem.push(item.Question);
          rowItem.push(item.StuAnswer);
          ws.addRow(rowItem);
        });
      }

      const stuDPDetail=await GetExStuDPBySid(curStu.Sid);
      
      ws=wb.addWorksheet('多文本題組');
      rowItem=['日期','文章編號','文章名稱','題號','學生的回答'];
      ws.addRow(rowItem);
      let stuDPRec=stuDPDetail.filter((item)=>item.ArticleGroup.trim()==='DP');
      if(stuDPRec.length>0) //
      {
        stuDPRec.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.ArticleNo);
          rowItem.push(item.DPTopic);
          rowItem.push("1");
          rowItem.push(item.StuAnswer[0]);
          ws.addRow(rowItem);
          item.StuAnswer.slice(1).map((sans,idx)=>
          {
              rowItem=[];
              rowItem.push("");
              rowItem.push("");
              rowItem.push("");
              rowItem.push((idx+2).toString());
              rowItem.push(sans);
              ws.addRow(rowItem);
          });          
        });
      }

      ws=wb.addWorksheet('課文主題延伸');
      rowItem=['日期','文章編號','文章名稱','題號','學生的回答'];
      ws.addRow(rowItem);
      stuDPRec=stuDPDetail.filter((item)=>item.ArticleGroup.trim()==='Pirls');
      if(stuDPRec.length>0) // Pirls
      {
        stuDPRec.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.ArticleNo);
          rowItem.push(item.DPTopic);
          rowItem.push("1");
          rowItem.push(item.StuAnswer[0]);
          ws.addRow(rowItem);
          item.StuAnswer.slice(1).map((sans,idx)=>
          {
              rowItem=[];
              rowItem.push("");
              rowItem.push("");
              rowItem.push("");
              rowItem.push((idx+2).toString());
              rowItem.push(sans);
              ws.addRow(rowItem);
          });          
        });
      }

      ws=wb.addWorksheet('SDGs');
      rowItem=['日期','文章編號','文章名稱','題號','學生的回答'];
      ws.addRow(rowItem);
      stuDPRec=stuDPDetail.filter((item)=>item.ArticleGroup.trim()==='SDGs');
      if(stuDPRec.length>0) // SDGs
      {
        stuDPRec.map((item,index)=>{
          rowItem=[];
          rowItem.push(convertDateTime(item.AddTime));
          rowItem.push(item.ArticleNo);
          rowItem.push(item.DPTopic);
          rowItem.push("1");
          rowItem.push(item.StuAnswer[0]);
          ws.addRow(rowItem);
          item.StuAnswer.slice(1).map((sans,idx)=>
          {
              rowItem=[];
              rowItem.push("");
              rowItem.push("");
              rowItem.push("");
              rowItem.push((idx+2).toString());
              rowItem.push(sans);
              ws.addRow(rowItem);
          });          
        });
      }
    }
    const dateNow=new Date().toLocaleString('zh-Hans').replaceAll("/","-");
    await wb.xlsx.writeBuffer().then(buffer=> FileSaver.saveAs(new Blob([buffer]), `${curStu?.StuNo.trim()}-${curStu?.StuName.trim()}-學習紀錄-${dateNow}.xlsx`))
  }


 

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(refresh)
    {
      if(currentPage===0) setPPRefresh(true);
      if(currentPage===1) setDPRefresh(true);
      if(currentPage===2) setPirlsRefresh(true);
      if(currentPage===3) setSDGsRefresh(true);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    handlePage(currentPage);
  },[currentPage]);


  return (
  <>
    <TopNavPage2/>
    <div className="section pt-3">
      <div className="container px-3 px-md-4">
      <div className="d-flex align-items-center flex-column flex-lg-row">
          <h5 className="title mb-3 me-3">教師專區</h5>
          <div className="mx-auto ms-auto me-md-0 text-center">
            <a href="/teacheradmin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
              操作說明</a>
            <a href="/teacherstumgn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 " type="button">
              學生管理</a>
            <a href="/teacherstulearn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active" type="button">
              學習記錄</a>
            <a href="/teacherstustat" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
              學習統計</a>
            <a href="/teacherstulogin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
              登入記錄</a>
          </div>
        </div>
      
        <div className="text-center h5 mb-3">學生自主學習記錄</div>
        <div className="d-flex flex-wrap justify-content-center align-items-center">
          <div className="mb-3">已開放的學生學習記錄</div>
          <div className="d-flex">
            <div className="dropdown ms-2" style={{width:'fit-content'}}>
              <button className="btn text-second bg-second dropdown-toggle mb-3" type="button" id="grades"
                data-bs-toggle="dropdown" aria-expanded="false">{curGrdCls.GradeClass}
              </button>
              <ul className="dropdown-menu px-2 py-3" aria-labelledby="grades" style={{minWidth: '8rem'}}>
                {
                  openToGrdCls.map((item,index)=>{
                    return <li key={index}><button className="dropdown-item d-flex" onClick={()=>handleCurGrdCls(item)}>{item.GradeClass}</button></li>
                  })
                }
              </ul>
            </div>
            <div className="dropdown ms-2 me-4" style={{width:'fit-content'}}>
              <button className="btn text-second bg-second dropdown-toggle mb-3" type="button" id="students"
                data-bs-toggle="dropdown" aria-expanded="false">{curStu?.StuNo+"-"+curStu?.StuName}
              </button>
              <ul className="dropdown-menu px-2 py-3" aria-labelledby="students">
                {
                  openToStu.map((item,index)=>{
                    return <li key={index}><a className="dropdown-item d-flex" onClick={()=>handleCurStu(item)}>{item.StuNo+"-"+item.StuName}</a></li>
                  })
                }
              </ul>
            </div>
          </div>
          <div className="btn text-second bg-second mb-3" onClick={()=>handleExportExcel()}>匯出學習記錄</div>
        </div>
        <div className="container px-3 px-md-4">
            <div className="nav-wrapper position-relative z-index-2 mt-4">
                <ul className="nav nav-pills nav-fill flex-wrap flex-md-nowrap" id="dp" role="tablist">
                <li className="nav-item">
                    <a className="nav-link mb-0 active" id="pp-tab" data-bs-toggle="tab" role="tab" aria-controls="pp" type="button"
                    aria-selected="true" onClick={()=>setCurrentPage(0)}>
                    <i className="ni ni-single-copy-04 me-2"></i>基礎讀寫(PP)
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link mb-0" id="deep-tab" data-bs-toggle="tab" role="tab" aria-controls="deep" type="button"
                    aria-selected="false" onClick={()=>setCurrentPage(1)}>
                    <i className="ni ni-app me-2" ></i>多文本題組
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link mb-0" id="text-tab" data-bs-toggle="tab" role="tab" aria-controls="text" type="button"
                    aria-selected="false" onClick={()=>setCurrentPage(2)}>
                    <i className="ni ni-album-2 me-2"></i>課文主題延伸
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link mb-0" id="sdgs-tab" data-bs-toggle="tab" role="tab" aria-controls="sdgs" type="button"
                    aria-selected="false" onClick={()=>setCurrentPage(3)}>
                    <i className="ni ni-atom me-2"></i>SDGs
                    </a>
                </li>
                </ul>
            </div>
            {handlePage(currentPage)}
          </div>
      </div>
      <div className="pt-3 mt-4"></div>
    </div> 
    <FooterPage/>
  </>
  )
}

export default TeacherStuLearnPage