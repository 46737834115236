import React, { useEffect, useState } from 'react'
import { BSModal } from '../components/modal/bsmodal';
import { IExSchool } from '../interfaces/ExSchool';
import IPageProps from '../interfaces/page'
import { handleLink } from '../modules/utils';
import { getExSchoolAreaByCity, getExSchoolAreaByCityArea, getExSchoolCities } from '../services/ExSchoolService';
import { createExTeacher, getExTeacherByTNo, getMaxTID } from '../services/ExStuTeService';
import FooterPage from './footer';

const NewTeacherPage: React.FunctionComponent<IPageProps> = props => {
    const [city,setCity]=useState<string[]>([]);
    const [selCity,setSelCity]=useState("");
    const [area,setArea]=useState<string[]>([]);
    const [selArea,setSelArea]=useState("")
    const [schoolName,setSchoolName]=useState<IExSchool[]>([])
    const [selSchool,setSelSchool]=useState("");
    const [inpTeacherName,setInpTeacherName]=useState("");
    const [inpTeacherNo,setInpTeacherNo]=useState("");
    const [inpPwd,setInpPwd]=useState("");
    const [inpPwdAgain,setInpPwdAgain]=useState("");
    const [inpCell,setInpCell]=useState("");
    const [inpEmail,setInpEmail]=useState("");
    const [pressed,setPressed]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const [openModal,setOpenModal]=useState(false);
    const [maxTid,setMaxTid]=useState(0);

    const handleSubmit=async ()=>{
        let noError=true;
        let errMsg=""
        if(selCity==="")
        {
            noError=false;
            errMsg=errMsg+"縣市不可空白,"
        }
        if(selArea==="")
        {
            noError=false;
            errMsg=errMsg+"鄉鎮區不可空白,"
        }
        if(selSchool==="")
        {
            noError=false;
            errMsg=errMsg+"學校不可空白,"
        }
        if(inpTeacherName==="")
        {
            noError=false;
            errMsg=errMsg+"姓名或暱稱不可空白,"
        }
        else
        {
            if(inpTeacherName.length>10)
            {
                noError=false;
                errMsg=errMsg+"姓名或暱稱不可超過10碼,"
            }
        }
        
        if(inpTeacherNo==="")
        {
            noError=false;
            errMsg=errMsg+"帳號不可空白,"
        }
        else
        {
            if((inpTeacherNo.length>12))
            {
                noError=false;
                errMsg=errMsg+"帳號長度不可大於12碼,"
            }
            else
            {

                const tmp=await getExTeacherByTNo(inpTeacherNo);
                console.log(tmp);
                if(tmp!==undefined)
                {
                    noError=false;
                    errMsg=errMsg+"帳號已經存在,"
                }
                
            }
        }
        if(inpPwd==="")
        {
            noError=false;
            errMsg=errMsg+"密碼不可空白,"
        }
        else
        {
            const regChk : RegExp=/^([a-zA-Z0-9]){6,}/; 
            if(!regChk.test(inpPwd))
            {
                noError=false;
                errMsg=errMsg+"密碼設定錯誤(至少6碼並為英數字),"
            }
        }
        if(inpPwdAgain==="")
        {
            noError=false;
            errMsg=errMsg+"確認密碼不可空白,"
        }
        else
        {
            if(inpPwd!==inpPwdAgain)
            {
                noError=false;
                errMsg=errMsg+"密碼與確認密碼輸入不符,"
            }
        }

        if(inpEmail!=="")
        {
            const emailChk : RegExp=/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/; 
            if(!emailChk.test(inpEmail))
            {
                noError=false;
                errMsg=errMsg+"Email格式錯誤,"
            }

        }
       
        const maxTid=await getMaxTID();
        setMaxTid(maxTid.MaxTid);
        if(noError)
        {            
            setPressed(true);
            handleLink("/login")
        }
        else
        {
            setOpenModal(!noError);
            errMsg=errMsg.substring(0,errMsg.length-1);
            setErrMsg(errMsg);
        }       

    }
    useEffect(()=>{
        getExSchoolCities().then(data=>setCity(["(縣市)",...data]));
        let area=document.getElementById('area');
        if(area!==null)
        {
            area.innerHTML=`<option value="" disabled selected>(鄉鎮區)</option>`
        }
        let sch=document.getElementById('school');
        if(sch!==null)
        {
            sch.innerHTML=`<option value="" disabled selected>(學校)</option>`
        }           

    },[])

    useEffect(()=>{
        if(selCity!=="")
        {
            getExSchoolAreaByCity(selCity).then(data=>{
                setArea(data)
                let area=document.getElementById('area');
                if(area!==null)
                {
                    area.innerHTML=`<option value="" disabled selected>(鄉鎮區)</option>`
                    data.map(item=>{
                        if(area!==null)
                        {
                            area.innerHTML=area.innerHTML+` <option value="${item}" >${item}</option>`
                        }
                    })
                }
                let sch=document.getElementById('school');
                if(sch!==null)
                {
                    sch.innerHTML=`<option value="" disabled selected>(學校)</option>`
                }

            }
            )
        }
    },[selCity])

    useEffect(()=>{
        if((selArea!=="") && (selCity!==""))
        {
            getExSchoolAreaByCityArea(selCity,selArea).then(data=>{
                setSchoolName(data);
                let sch=document.getElementById('school');
                if(sch!==null)
                {
                    sch.innerHTML=`<option value="" disabled selected>(學校)</option>`
                    data.map(item=>{
                        if(sch!==null)
                        {
                            sch.innerHTML=sch.innerHTML+`<option value="${item.SchoolNo},${item.SchoolName}" >${item.SchoolName}</option>`
                        }
                    })
                }

            })
        }
    },[selArea])
    useEffect(()=>{
        if(pressed)
        {
            const schDetail=selSchool.split(",");
            const newTid=maxTid+1;
            createExTeacher(
                newTid.toString().trim(),inpTeacherNo,inpTeacherName,schDetail[0],selCity,selArea,schDetail[1],inpPwd,inpCell,inpEmail
            ).then(data=>{
                console.log(data);
            })
        }
    },[pressed])
    return (
        <>
            <div style={{backgroundColor:'#FCF9F3'}}>
                <nav className="navbar navbar-expand-lg py-3" style={{backgroundColor:'#FCF9F3'}}>
                    <div className="container align-items-center">
                        <div className="navbar-translate">
                            <a className="navbar-brand logo" href="/" rel="tooltip" title="數位讀寫網" data-placement="bottom">數位讀寫網</a>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="py-5">
                <div className="container" style={{maxWidth:'500px'}}>
                    <div className="row" style={{ borderRadius:'8px',boxShadow:'0px 0px 10px 5px #d7d7d7'}}>
                        <div className="col-12 bg-primary logreg login px-4">
                            <h4 className="py-3 text-center text-white fw-bolder">教師註冊</h4>

                            <div className="accordion" id="teachregps">
                                <div className="accordion-item mb-4">
                                    <div className="accordion-header" id="headingTwo">
                                        <button className="accordion-button fw-normal text-white w-auto mx-auto collapsed py-0"
                                        data-bs-toggle="collapse" data-bs-target="#teachreg" aria-expanded="false" aria-controls="teachreg"
                                        style={{fontSize:'14px!important'}}>
                                        <div className="me-2">教師帳號註冊說明</div>
                                        <i className="collapse-close fa fa-angle-down pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                        <i className="collapse-open fa fa-angle-up pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div id="teachreg" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#teachregps">
                                        <div className="accordion-body border-top text-start text-sm text-justify mt-3 p-3 pb-0"
                                        style={{color:'#ededed'}}>
                                            <div className="ms-3 mb-2" style={{ textIndent:'-1em'}}> 1. 教師註冊後，可由學生端加入您的帳號，或由您新增學生，即可查看該學生的學習記錄。</div>
                                            <div className="ms-3 mb-2" style={{ textIndent:'-1em'}}>2. 帳號和密碼只使用英文或數字，行動電話和電子郵件可免填。</div>
                                            <div className="ms-3 mb-2" style={{ textIndent:'-1em'}}>3. 申請後，可到【教師主頁】設定第三方平台登入，即可免再輸入帳密登入。</div>
                                            <div className="ms-3 mb-2" style={{ textIndent:'-1em'}}>4. 本網站暫不提供查詢帳號或密碼的服務，若忘記了請重新申請。</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <select name="" id="city" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelCity(e.currentTarget.value)} >
                                <>
                                {
                                    city.map((data,i)=>{
                                        if(i===0)
                                        {
                                            return  <option value="" disabled selected>{data}</option>
                                        }
                                        else
                                        {
                                            return <option value={data} >{data}</option>
                                        }                                                                                                
                                        })                                            
                                }
                                </>
                                </select>
                                <select name="" id="area" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelArea(e.currentTarget.value)} >
                                </select>
                            </div>
                            <div className="row mt-3">
                                <select name="" id="school" className="col mx-2 form-select text-primary fw-bolder" onChange={(e)=>setSelSchool(e.currentTarget.value)}>
                                </select>
                            </div>

                            <div className="row mt-3">
                                <input type="text" className="col mx-2 form-control" placeholder="姓名或暱稱(最多10碼)" onChange={(e)=>setInpTeacherName(e.currentTarget.value)} />
                                <input type="text" className="col mx-2 form-control" placeholder="帳號(最多12碼)" onChange={(e)=>setInpTeacherNo(e.currentTarget.value)} />
                            </div>

                            <div className="row mt-3">
                                <input type="password" className="col mx-2 form-control" placeholder="密碼(至少6碼，英數字)" onChange={(e)=>setInpPwd(e.currentTarget.value)}/>
                                <input type="password" className="col mx-2 form-control" placeholder="確認密碼" onChange={(e)=>setInpPwdAgain(e.currentTarget.value)}/>
                            </div>

                            <div className="row mt-3">
                                <input type="text" className="col mx-2 form-control" placeholder="行動電話(可不填)" onChange={(e)=>setInpCell(e.currentTarget.value)}/>
                                <input type="text" className="col mx-2 form-control" placeholder="電子郵件(可不填)"onChange={(e)=>setInpEmail(e.currentTarget.value)} />
                            </div>

                            <div className="text-center mt-5">
                                <button className="btn btn-round btn-white text-primary mb-0" onClick={()=>handleSubmit()}>送出申請</button>
                            </div>
                            <div className="text-center my-4">
                                <a href="/login" className="text-white" style={{borderBottom:'1px solid #fff'}}>返回登入</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BSModal
                title={'教師註冊欄位輸入錯誤'}
                isOpen={openModal}
                setOpenModal={setOpenModal}                
            >
                {errMsg}
            </BSModal> 
            <FooterPage/>            

        </>
    );
}

export default NewTeacherPage;