import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo } from '../../services/EP/EpApplyStudentService';
import { ReacherArPictureName_before, currentClass, devSection, downloadImage, fetchDataOpinion, fetchDataSubTopic, formatDate, formatDateTime, getLoginInfo, getOpinionAttr, replaceNewline, sTitleName } from './tool/helper';
import { IDropDownGroup, IGroupForExpertS, IGroupInfo, IGroupStudent, IOpinion, IStudentInfo, IsubTopic } from '../../interfaces/EpModal';
import { getDrStudentByGroup } from '../../services/EP/EPApplyGroupService';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { getDrGroupByTeaNo } from '../../services/EP/EpApplyTeacherService';
import ExpertFunctionBar from './component/expertFunctionBar';
import { getAllDrGroup, updateDrGroupExpOpinion } from '../../services/EP/EpExpertService';
import { getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';
import { Modal } from 'react-bootstrap';
import { checkFileExists } from '../../services/DrFileService';

// export interface IGroupForExpertS {
//     Groupno: string;
//     Groupname: string;
//     City: string;
//     Area: string | null;
//     Schoolname: string;
//     Type: string;
//     Class: string;
//     FlagOK: boolean;
//     ExpOpinion: string;
//     ExpMemo: string | null;
//     GroupSet: string;
//     ExpertName: string;
//     Topic :string
//     isSelected?:boolean  ;}
// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpExpertFeedback: React.FunctionComponent<IPageProps> = () => {
    const [opinion, setOpinion] = useState<IOpinion>();
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [groupForExpertList, setGroupForExpertList] = useState<IGroupForExpertS[]>();
    const [groupForExpertDisplayList, setGroupForExpertDisplayList] = useState<IGroupForExpertS[]>();
    const [selectedClassName, setSelectClassName] = useState<string>();
    const [selectedExpert, setSelectedExpert] = useState<{ expAccount: string; groupset: string; exportName: string }>();
    const [dropDownExport, setDropDownExport] = useState<{ expAccount: string; groupset: string; exportName: string }[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<IDropDownGroup>();
    const [dropDownGroup, setDropDownGroup] = useState<IDropDownGroup[]>();
    /** 子題 */
    const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>();
    /**下拉選單 */
    const [classNameList, setClassNameList] = useState<string[]>([]);
    const [groupList, setGroupList] = useState<string[]>([]);
    const [schoolNameList, setSchoolNameList] = useState<string[]>([]);
    const [selectSectionList, setSelectSectionList] = useState<IGroupForExpertS[]>();
    const [formData, setFormData] = useState<{ data: string | any }>({ data: '' });
    const [isEditable, setIsEditable] = useState(false);
    const [isPictureExist ,setIsPictureExist] =useState (false)


    useEffect(()=>{
        // alert("selectedGroup" +JSON.stringify(selectedGroup))
        getIsFileExist()


    },[selectedGroup])
   

   const getIsFileExist = async  () =>{ 
    debugger
        const rsp  = await checkFileExists( `/uploads/upload${currentClass}/${selectedGroup?.groupNo}`,ReacherArPictureName_before,"")
        console.log('是否已存在.. EpExpertFeedback' + `/uploads/upload${currentClass}/${selectedGroup?.groupNo}`,rsp) 
        // alert(`/uploads/upload${currentClass}/${selectedGroup?.groupNo}`)
        if(rsp =='檔案已存在.'){
            setIsPictureExist(true)
          
        } else {
       
            setIsPictureExist(false)
        }
    }
    useEffect(() => {
        if (selectedExpert) setIsEditable(selectedExpert?.expAccount === currentLoginInfo.LoginID);
    }, [selectedExpert]);


    const [isReload, setIsReload] = useState(false);
    useEffect(() => {
        fetchdataGroupInfo();
    }, []);

    useEffect(() => {
        setFilterData();
    }, [selectedClassName]);

    useEffect(() => {
        fetchDataOpinion(selectedGroup?.groupNo, setOpinion);
    }, [selectedGroup, isReload]);

    useEffect(() => {}, [selectedExpert]);

    useEffect(() => {
        reloadDropDownExport(); //從新載入專家
    }, [selectedClassName]);
    /** */

    useEffect(() => {
        // reloadDropDownExport()
        reloadDropDownGroup();
    }, [selectedExpert]);

    const reloadDropDownExport = () => {
        let exportlist: { expAccount: string; groupset: string; exportName: string }[] = [];
        let temp: IGroupForExpertS[] = groupForExpertList?.filter((i) => i.Class === selectedClassName) || [];
        temp.forEach((i) => {
            if (!exportlist.find((x) => x.expAccount === i.exportAccount)) exportlist.push({ expAccount: i.exportAccount, groupset: i.GroupSet, exportName: i.ExpertName });
        });

        setDropDownExport(exportlist);
        if (exportlist.length > 0) setSelectedExpert(exportlist[0]);
    };

    const reloadDropDownGroup = () => {
        let groupListTemp: IDropDownGroup[] = [];
        let temp: IGroupForExpertS[] = groupForExpertList?.filter((i) => i.Class === selectedClassName && i.exportAccount == selectedExpert?.expAccount) || [];
        let num: number = 1;
        temp.forEach((i) => {
            // name : string
            // DisplayName : string
            // uid? : string
            // key? : string
            // groupNo :string

            if (!groupListTemp.find((x) => x.groupNo === i.Groupno))
                groupListTemp.push({ name: i.exportAccount, DisplayName: `${num}-${i.Groupno}-${i.Schoolname}-${i.Groupname}`, groupNo: i.Groupno });
            num++;
        });

        setDropDownGroup(groupListTemp);
        if (groupListTemp.length > 0) setSelectedGroup(groupListTemp[0]);
    };

    /** */
    const setFilterData = () => {
        let result: IGroupForExpertS[] | undefined = [];
        result = groupForExpertList?.filter((item) => {
            return selectedClassName === item.Class && selectedGroup?.name == item.Groupname;
        });
        setGroupForExpertDisplayList(result);
    };

    useEffect(() => {
        getGroupNoList();
    }, [selectedClassName]);

    const getGroupNoList = () => {
        let result: IGroupForExpertS[] =
            groupForExpertList?.filter((item) => {
                return item.Class == selectedClassName;
            }) || [];

        setSelectSectionList(result);
    };

    /** 以專家身分取得  */
    const fetchdataGroupInfo = async () => {
        /** 取得登入資料 */

        let currentLoginInfo = getLoginInfo();
        console.log('目前登入資訊', currentLoginInfo);
        setCurrentLoginInfo(currentLoginInfo);

        try {
            let classNameList: string[] = [];
            let groupNameList: string[] = [];
            groupNameList.push('全部組別');
            let schoolNameList: string[] = [];
            schoolNameList.push('無');

            // let rsp = (await getAllDrGroupByClassExpert(currentClass, 'liue')) as IGroupForExpert[];
            let rsp = (await getAllDrGroup()) as IGroupForExpertS[];
            console.log('rsp', rsp);
            // 處理下拉式選單
            rsp.forEach((item) => {
                if (!classNameList.includes(item.Class)) {
                    classNameList.push(item.Class);
                }
                if (!groupNameList.includes(item.Groupname)) {
                    groupNameList.push(item.Groupname);
                }
                if (!schoolNameList.includes(item.Schoolname)) schoolNameList.push(item.Schoolname);
            });
            setClassNameList(classNameList);
            setGroupList(groupNameList);
            setSchoolNameList(schoolNameList);
            setSelectClassName(classNameList[0]);

            setGroupForExpertList(rsp);
            console.log('取得專家的隊伍', rsp);
        } catch (error) {}
        // const groupInfo
    };

    useEffect(() => {
        if (selectedGroup?.groupNo) {
            fetchDataSubTopic(selectedGroup.groupNo, setSubTopicBeforeFeedbackList);
        }
    }, [selectedGroup]);

    /** 年度下拉式 選單 */
    const renderClassNameDropDown = () => {
        return classNameList.map((item) => (
            <li>
                <div className={`dropdown-item ${item == selectedClassName ? 'sel-active' : ''}`} onClick={() => setSelectClassName(item)}>
                    {item}
                </div>
            </li>
        ));
    };

    /** 專家 欄位2 */
    const renderExpertOption = () => {
        const uniqueExperts = [...new Set(selectSectionList?.map((item) => item.GroupSet + '-' + item.ExpertName))];
        if (uniqueExperts.length > 0) {
            //  setSelectedExpert(uniqueExperts[0])
        }

        return dropDownExport.map((expert) => (
            <li>
                <div className="dropdown-item sel-active" onClick={() => setSelectedExpert(expert)}>
                    {expert.groupset}-{expert.exportName}
                </div>
            </li>
        ));
    };

    const renderOpiniondiv = (displaytitle: string, name: sTitleName) => {
        let attr = getOpinionAttr(displaytitle, name);
        // alert(JSON.stringify(attr))
        if (opinion && attr) {
            if (name.includes('-修改時間')) {
                return <>{formatDateTime(opinion[attr?.attr])}</>;
            } else {
                return <div className="p-3" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion[attr?.attr]) }}></div>;
            }
        } else {
            return <div>未填寫</div>;
        }
    };

    /** 專家 欄位3 */
    const renderGroupOption = () => {
        return dropDownGroup?.map((groupName) => (
            <li>
                <div className="dropdown-item sel-active" onClick={() => setSelectedGroup(groupName)}>
                    {groupName.DisplayName}
                </div>
            </li>
        ));
    };

    /** 儲存專家建議 */
    const saveEditModal = async () => {
        let param = { ExpOpinion: formData.data, ExpMemo: '-NoData-' };
        if (selectedGroup?.groupNo) {
            await updateDrGroupExpOpinion(param, selectedGroup?.groupNo);
        }
        setIsModalOpen(false);
        setIsReload(!isReload);
    };

    /** */
    const OpenModal = () => {
        setFormData({ data: opinion?.ExpOpinion });
        setIsModalOpen(true);
    };
    

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
            <div className="container px-3 px-md-4">
                <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                    <h5 className="title mb-3 me-3">專題探究競賽</h5>
                    <ExpertFunctionBar />
                </div>
                <div className="text-center h5 mt-4 mb-3">探究(回饋前)</div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mb-4">
                    <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                        <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                            {selectedClassName}
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {renderClassNameDropDown()}
                        </ul>
                    </div>
                    <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                        <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                            {selectedExpert?.groupset}-{selectedExpert?.exportName}
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {renderExpertOption()}
                        </ul>
                    </div>

                    <div className="dropdown mx-2" style={{ width: 'fit-content' }}>
                        <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '180px' }}>
                            {selectedGroup?.DisplayName}
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {renderGroupOption()}
                        </ul>
                    </div>
                </div>

                <div className="accordion" id="accordionRental">
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button flex-wrap text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level0" aria-expanded="true" aria-controls="level0">
                                <h4 className="my-0 me-3">專家學者線上回饋</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="collapse show" id="level0">
                            <div className="card p-4">
                                <div className="conRedcard p-3">
                                    <div className="d-flex">
                                        <ol className="mb-0">
                                            {renderOpiniondiv('專家學者線上回饋', '專家學者線上回饋')}
                                            {/* <li>可以多想不同類型的題目來探究。</li>
                                            <li>建議子題重新規劃，子題1和子題4可以合併。也可以探究不同茶種的製作方式、製茶外在環境和技術等不同面向。</li>
                                            <li>探究架構最好有3層以上，對於收集資料的方向會有幫助。</li>
                                            <li>《困難、解決與新學習》可以省思探究學習歷程，建議大家盡量表達。</li>
                                            <li>本次競賽是很好的學習機會，希望大家能好好把握，相信會有很多收穫。</li> */}
                                        </ol>
                                        {isEditable ? (
                                            <span className="material-icons pointer ms-auto" data-bs-toggle="modal" data-bs-target="#editModal0" title="編輯" onClick={() => OpenModal()}>
                                                {' '}
                                                edit{' '}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level1" aria-expanded="true" aria-controls="level1">
                                <h4 className="my-0 me-3">階段1、決定探究題目</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="collapse show" id="level1">
                            <div className="card p-4 pt-3">
                                <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2 text-nowrap">成員</th>
                                                <th className="p-2 text-nowrap">我想探究的題目</th>
                                                <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {opinion?.Pre11.map((item) => (
                                                <tr>
                                                    <td className="text-nowrap">{item.StuName}</td>
                                                    <td>{item.DataA}</td>
                                                    <td>{item.DataB}</td>
                                                    <td>{formatDate(item.UpdateDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">
                                        我們決定探究的題目：
                                        {renderOpiniondiv('1-2 決定探究題目', '前-決定探究題目')}
                                    </div>
                                    <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>
                                    {renderOpiniondiv('1-2 決定探究題目', '前-決定探究題目原因')}
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{renderOpiniondiv('1-2 決定探究題目', "Pre12-修改時間")}</span>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">我們預測可能的結果是：</div>
                                    <div>
                                        一、經由小組分工每人負責工作說明如( )說明。
                                        <br />
                                        二、大家決議上網搜集相關資料，每人至少閱讀三篇參考文獻，並做整理，寫下相關資料和自己的收穫。
                                        <br />
                                        三、預測結果如下:
                                        <br />
                                        <div>{renderOpiniondiv('1-3 預測可能的結果', '前-探究題目預測結果原因')}</div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{renderOpiniondiv('1-3 預測可能的結果', 'Pre13-修改時間')}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段1」遇到的困難：</div>
                                        {renderOpiniondiv('1-4 困難、解決與新學習', '前-遭遇困難')}
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        {renderOpiniondiv('1-4 困難、解決與新學習', '前-解決方式')}
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        {renderOpiniondiv('1-4 困難、解決與新學習', '前-學到的新思維')}
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span> {renderOpiniondiv('1-4 困難、解決與新學習', 'Pre14-修改時間')}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                    <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    {renderOpiniondiv('階段1，指導老師留言', '前-指導老師留言')}
                                    {/* <div>學校裡的新住民子女學生人數大約在70人上下，其中包含許多不同的國家，題目中有些想討論『新住民媽媽』，有些想討論『越南新住民媽媽』，為什麼最後題目會將對象定為『越南新住民媽媽』？ */}
                                </div>
                                <div className="pt-3 pb-2">
                                    <span className="fw-bolder text-second">日期：</span>
                                    <span>{renderOpiniondiv('階段1，指導老師留言', 'Pre19-修改時間')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level2" aria-expanded="true" aria-controls="level2">
                                <h4 className="my-0 me-2">階段2、規劃探究架構</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="collapse show" id="level2">
                            <div className="card p-4 pt-3">
                                <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                <div className="d-flex flex-wrap align-items-end">
                                    <div className="fw-bolder text-second pt-3 pb-2">
                                        本隊探究題目：
                                        {/* <span className="text-primary">越南新住民媽媽如何融入臺灣的社會生活？</span> */}
                                        {renderOpiniondiv("1-2 決定探究題目", "前-決定探究題目")}
                                    </div>
                                </div>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2">#</th>
                                                <th className="p-2 text-nowrap">子題名稱</th>
                                                <th className="p-2 text-nowrap">負責隊員</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subTopicBeforeFeedbackList.map((item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.SubName}</td>
                                                    <td className="text-nowrap">{item.Description}</td>
                                                    <td>{formatDate(item.UpdateDate)}</td>
                                                </tr>
                                            ))}
                                            {/* <tr>
                                                <td>1</td>
                                                <td>越南跟臺灣的飲食文化有哪些相異處？</td>
                                                <td className="text-nowrap">趙小一</td>
                                                <td>02/13</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>越南新住民媽媽如何面對家中經濟重擔？</td>
                                                <td className="text-nowrap">葉巧宜</td>
                                                <td>02/13</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>越南新住民媽媽如何適應臺灣交通問題？</td>
                                                <td className="text-nowrap">蔡沛軒</td>
                                                <td>02/13</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>越南新住民媽媽思念故鄉時有哪些方式能做為心靈寄託嗎？</td>
                                                <td>羅湘琳、趙小一、蔡沛軒、葉巧宜</td>
                                                <td>02/13</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">2-2 繪製探究架構</h5>
                                <div className="rounded-3 mx-auto p-3 imgborder text-center ">
                              



                                        {isPictureExist ? (
                      
                                     
                           <div className="d-flex justify-content-center align-items-start">
      <img className='imgs'
      src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`}
      width="500"
      alt=""
      onClick={() => downloadImage(`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}
  />        </div>
    

) : (
 <> 架構圖未上傳 </>
)}
                                            {/* <img
                                                src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`}
                                                alt=""
                                                width="500"
                                                onClick={() => downloadImage(`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}
                                            /> */}
           
                              
                                </div>
                                <hr className="horizontal dark" />

                                <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">我們在「階段2」遇到的困難：</div>

                                    {renderOpiniondiv('2-3 困難、解決與新學習', '前-遭遇困難')}
                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>

                                    {renderOpiniondiv('2-3 困難、解決與新學習', '前-解決方式')}
                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>

                                    {renderOpiniondiv('2-3 困難、解決與新學習', '前-學到的新思維')}
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{renderOpiniondiv('2-3 困難、解決與新學習', 'Pre24-修改時間')} </span>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                {renderOpiniondiv('階段2，指導老師留言', '前-指導老師留言-2')}
                                <div className="pt-3 pb-2">
                                    <span className="fw-bolder text-second">日期：</span>
                                    <span>{renderOpiniondiv('階段2，指導老師留言', 'Pre29-修改時間-2')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                    <Modal.Header style={{ maxWidth: '800px' }}>
                        {/* <Modal.Title><h5 className="modal-title">{editMode}新增/修改探究題目</h5></Modal.Title> */}
                        <Modal.Title>
                            <h5 className="modal-title">專家學者線上回饋</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" px-3 pb-4">
                        <div>
                            <form role="form text-left">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="">建議</label>
                                        <div className="input-group">
                                            <textarea
                                                rows={5}
                                                className="form-control text-wrap"
                                                placeholder=""
                                                value={formData.data}
                                                onChange={(e) => setFormData({ ...formData, data: e.target.value })}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsModalOpen(false)}>
                            取消
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => saveEditModal()}>
                            儲存
                        </button>
                        {/* <button type="button" className="btn btn-primary m-0" >關閉</button> */}
                    </Modal.Footer>
                </Modal>

                <div className="pt-3 mt-4"></div>
            </div>
</div>
            <FooterPage />
        </>
    );
};

export default EpExpertFeedback;
