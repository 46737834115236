import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import IPageProps from '../../interfaces/page'
import { sdgsList } from '../../config/refList';
import { getDPByGrpLvl, getDPByGrpLvlSid } from '../../services/ExDPService';
import { IExDP } from '../../interfaces/ExDP';
import { handleLink } from '../../modules/utils';
import { set } from 'date-fns';
const DPSDGsPage: React.FunctionComponent<IPageProps> = props => {
    const [hardLevel,setHardLevel]=useState(0);
    const [SDGsTopics,setSDGsTopics]=useState<IExDP[]>([])
    const [filterSDGsTopics,setFilterSDGsTopics]=useState<IExDP[]>([])
    const [selSDGsTitle,setSelSDGsTitle]=useState("永續發展目標(SDGs)")    
    const acqSDGsTopic=async (lvl:number)=>{
        setHardLevel(lvl)
       /* if(sessionStorage.getItem("LoginStatus")==="1")
            getDPByGrpLvlSid('SDGs',lvl,Number(sessionStorage.getItem("LoginID"))).then(data=>setSDGsTopics(data))
        else
            getDPByGrpLvl('SDGs',lvl).then(data=>setSDGsTopics(data))
       */     
        //setSDGsTopics(await getDPByGrpLvl('SDGs',lvl));
        if(lvl===0)
        {
            if(selSDGsTitle==="永續發展目標(SDGs)")
            {
                setFilterSDGsTopics(SDGsTopics);
            }
            else
            {
                const itemIdx=selSDGsTitle.slice(0,2);
                const filtered=SDGsTopics.filter((item,i)=>{

                    const subj=item.Subject.split(',');
                    return subj.includes(itemIdx)
                })
                setFilterSDGsTopics(filtered);
            }
        }
        else
        {
            
            if(selSDGsTitle==="永續發展目標(SDGs)")
            {
                const filtered=SDGsTopics.filter((item,i)=>{
                    return item.Difficult===lvl
                })
                
                setFilterSDGsTopics(filtered);
            }
            else
            {
                const itemIdx=selSDGsTitle.slice(0,2);
                const filtered=SDGsTopics.filter((item,i)=>{

                    const subj=item.Subject.split(',');
                    if((item.Difficult===lvl) && subj.includes(itemIdx))
                    return subj.includes(itemIdx)
                })
                setFilterSDGsTopics(filtered);
            }
        }
    

    }
    const handelFilteredSDGsTopic=async (sdgs:string)=>{
        setSelSDGsTitle(sdgs);
        const itemIdx=sdgs.slice(0,2);
        if(sdgs!=="永續發展目標(SDGs)")
        {
            let filtered:IExDP[]=[];

            SDGsTopics.map((item,i)=>{
                if(item.Subject.match(itemIdx))
         //       const subj=item.Subject.split(',');
         //       if(subj.includes(itemIdx))
                {
                    filtered.push(item);
                }                
            })
            setFilterSDGsTopics(filtered);
        }
        else
        {
            setFilterSDGsTopics(SDGsTopics);
        }

    }
    const handleDPPractice=async (_exdp:IExDP)=>{
        sessionStorage.setItem("DPGroup","SDGs");
        sessionStorage.setItem("DPNo",_exdp.ArticleNo);
        sessionStorage.setItem("DPTopic",_exdp.DPTopic);
        sessionStorage.setItem("DPImage",_exdp.ArticleImg);
        sessionStorage.setItem("DPCnt",(_exdp.DPCnt)?_exdp.DPCnt.toString():"0");
        handleLink('/dppractice')
    }
    const getDiffStr=(lvl:number):string=>{
        let ret=""
        switch (lvl){
            case 1: 
                ret="★☆☆";
                break;
            case 2: 
                ret="★★☆";
                break;
            case 3: 
                ret="★★★";
                break;
        }
        return ret
    }

    useEffect(()=>{
        if(sessionStorage.getItem("LoginStatus")==="1")
        {
            getDPByGrpLvlSid('SDGs',0,Number(sessionStorage.getItem("LoginID"))).then(data=>setSDGsTopics(data))
            getDPByGrpLvlSid('SDGs',0,Number(sessionStorage.getItem("LoginID"))).then(data=>setFilterSDGsTopics(data))
        }
        else
        {
            getDPByGrpLvl('SDGs',0).then(data=>setSDGsTopics(data))
            getDPByGrpLvl('SDGs',0).then(data=>setFilterSDGsTopics(data))
        }
    },[])
    return (
        <>
        <Dropdown className="dropdown mx-auto d-flex justify-content-center" style={{width:'fit-content',marginTop:'20px'}}>
            <Dropdown.Toggle className="btn-outline-secondary mb-0">
            {selSDGsTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-2 py-3'>
                <Dropdown.Item onClick={()=>handelFilteredSDGsTopic("永續發展目標(SDGs)")}>永續發展目標 (SDGs)</Dropdown.Item>
                {
                    sdgsList.map((item,idx)=>{
                       return <Dropdown.Item onClick={()=>handelFilteredSDGsTopic(item.idx+"."+item.goal)}>{item.idx}.{item.goal}</Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>

        <div className="d-flex justify-content-center mb-4" style={{marginTop:'20px'}}>
            <div className="me-4" style={{color:'#5c71a2'}}>難易度</div>
            <div className="form-check me-4">
                <input className="form-check-input" type="radio" name="degree50" id="degree50-0" checked={hardLevel===0?true:false} onClick={()=>acqSDGsTopic(0)}/>
                <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-0">全部</label>
            </div>
            <div className="form-check me-4">
                <input className="form-check-input" type="radio" name="degree50" id="degree50-1" checked={hardLevel===1?true:false} onClick={()=>acqSDGsTopic(1)}/>
                <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-1">★☆☆</label>
            </div>
            <div className="form-check me-4">
                <input className="form-check-input" type="radio" name="degree50" id="degree50-2" checked={hardLevel===2?true:false} onClick={()=>acqSDGsTopic(2)}/>
                <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-2">★★☆</label>
            </div>
            <div className="form-check me-4">
                <input className="form-check-input" type="radio" name="degree50" id="degree50-3" checked={hardLevel===3?true:false} onClick={()=>acqSDGsTopic(3)}/>
                <label className="custom-control-label" style={{color:'#5c71a2'}} htmlFor="degree50-3">★★★</label>
            </div>
        </div>
        <div className="tab-content mt-4" id="sdgsContent">
            <div className="card p-2">
            <div className="table-responsive">
                <table className="table table-striped table-hover align-items-center mb-0">
                <thead className="">
                    <tr style={{color:'#5c71a2'}}>
                    <th className="text-sm font-weight-bolder text-nowrap p-2 text-center">題號
                    </th>
                    <th className="text-sm font-weight-bolder text-nowrap text-center p-2">
                        永續發展目標<br/>(SDGs)</th>
                    <th className="text-sm font-weight-bolder text-nowrap p-2">難易度</th>
                    <th className="text-sm font-weight-bolder text-nowrap px-2 px-md-3">題組名稱</th>
                    <th className="p-0"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filterSDGsTopics.map((item,i)=>{
                            const subj=item.Subject.split(',');
                            return  <tr>
                                        <td>
                                            <p className="text-sm font-weight-bold text-center my-2">{item.ArticleNo}</p>
                                        </td>
                                        <td className="text-center">
                                            {
                                                subj.map((str,i)=>{
                                                    return <span className="badge text-second bg-second mx-1">{str}</span>
                                                })
                                            }
                                        </td>
                                        <td className="text-md text-second">{getDiffStr(item.Difficult)}</td>
                                        <td className="align-middle">
                                            <p className="text-wrap my-2">{item.DPTopic}</p>
                                        </td>
                                        <td><a type="button" className="btn btn-link mb-0 float-end" onClick={()=> handleDPPractice(item)}>
                                            練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                                        </a>
                                        </td>
                                    </tr>
                        })
                    }
                </tbody>
                </table>
            </div>
            </div>
        </div>


        </>
    );
}

export default DPSDGsPage;


