import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExDP } from '../interfaces/ExDP';
import { IExDPPirlsTopic } from '../interfaces/ExDPPrilsTopic';
import { IExDPSDGsTopic } from '../interfaces/ExDPSDGsTopic';
import { IExDPTopic } from '../interfaces/ExDPTopic';



export const getDPTopicByLevel=(level:number)=>{
    let url=''
    if(level===0)
    {
        url=config.server.url+`/api/ExDP/group-dp/topic`
    }
    else
    {
        url=config.server.url+`/api/ExDP/group-dp/topic/level/${level}`
    }

    return axios.get(url).then((res)=>{
        const rest:IExDPTopic[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPSDGsTopicByLevel=(level:number)=>{
    let url=''
    if(level===0)
    {
        url=config.server.url+`/api/ExDP/group-sdgs/topic`
    }
    else
    {
        url=config.server.url+`/api/ExDP/group-sdgs/topic/level/${level}`
    }

    return axios.get(url).then((res)=>{
        const rest:IExDPSDGsTopic[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPPirlTopicBySubject=(subj:string)=>{
    let url=''
    if(subj==='全部')
    {
        url=config.server.url+`/api/ExDP/group-pirls/topic`
    }
    else
    {
        url=config.server.url+'/api/ExDP/group-pirls/topic/subject/'+subj
    }

    return axios.get(url).then((res)=>{
        const rest:IExDPPirlsTopic[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPTopic=()=>{
    const url=config.server.url+`/api/ExDP/group-dp/topic`
    return axios.get(url).then((res)=>{
        const rest:IExDPTopic[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPByGrpSubj=(grp:string,subj:string)=>{
    let url=''
    if(subj==='全部')
    {
        url=config.server.url+`/api/ExDP/group/`+grp;
    }
    else
    {
        url=config.server.url+'/api/ExDP/group/'+grp+'/subject/'+subj
    }

    return axios.get(url).then((res)=>{
        const rest:IExDP[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPByGrpSubjSid=(grp:string,subj:string,sid:number)=>{
    let url=''
    if(subj==='全部')
    {
        url=config.server.url+`/api/ExDP/group/`+grp+`/sid/${sid}`;
    }
    else
    {
        url=config.server.url+'/api/ExDP/group/'+grp+'/subject/'+subj+`/sid/${sid}`
    }

    return axios.get(url).then((res)=>{
        const rest:IExDP[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPByGrpLvl=(grp:string,lvl:number)=>{
    let url=''
    if(lvl===0)
    {
        url=config.server.url+`/api/ExDP/group/`+grp;
    }
    else
    {
        url=config.server.url+'/api/ExDP/group/'+grp+`/level/${lvl}`
    }

    return axios.get(url).then((res)=>{
        const rest:IExDP[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPByGrpLvlSid=(grp:string,lvl:number,sid:number)=>{
    let url=''
    if(lvl===0)
    {
        url=config.server.url+`/api/ExDP/group/`+grp+`/sid/${sid}`;
    }
    else
    {
        url=config.server.url+'/api/ExDP/group/'+grp+`/level/${lvl}/sid/${sid}`
    }

    return axios.get(url).then((res)=>{
        const rest:IExDP[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getDPLearnTaskByNo=async (no:string)=>{
    let url=''
    url=config.server.url+`/api/ExDP/learntask/no/${no}`
    

    try {
        const res = await axios.get(url);
        const rest: { LearnTask: string; }[] = res.data;
        return rest[0].LearnTask;
    } catch (error) {
        throw error;
    }

}
