import React, { useEffect, useState, useRef } from 'react'
import IPageProps from '../../interfaces/page'
import TopNavPage2 from '../topNav2';
import FooterPage from '../footer';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { addExTeaStuBySidTid, addExTeaStusBySidTid, createExStudent, createExStudents, deleteExStudentBySids, deleteExTeaStuBySidsTid, getDistGradeClassExStuByTid, getExStudentByTeacherID, getExStudentByTidGradeClass, getExStudentByTidKeyword, getExTeacherBySchoolNo, getLastStuNo, getMaxStuID, getNewStuNo, updateExStuGradeClassBySids, updateOpenToExStudent } from '../../services/ExStuTeService';
import TopNavPage1 from '../topNav1';
import { IExStudent } from '../../interfaces/ExStudent';
import Excel from 'exceljs';
import FileSaver from 'file-saver';

import { Dropdown } from 'react-bootstrap';
import { IExGradeClass } from '../../interfaces/ExGradeClass';
import StuPPPage from '../students/StuPP';
import StuDPPage from '../students/StuDP';
import StuPirlsPage from '../students/StuPirls';
import StuSDGSPage from '../students/StuSDGs';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TeacherStuStatisticPage: React.FunctionComponent<IPageProps> = props => {
  const [loginTeacher,setLoginTeaacher]=useState<IExTeacher>();
  const [refresh,setRefresh]=useState(false);

  const [beginDate,setBeginDate]=useState<Date|null>(null);
  const [endDate,setEndDate]=useState<Date|null>(null);

  const [openToStu,setOpenToStu]=useState<IExStudent[]>([]); //開放班級學生

  const [entPressed,setEntPressed]=useState(false);
  const [inpKeyword,setInpKeyword]=useState("")

  const [reload,setReload]=useState(false);

  const handleKeyDown=(e: React.KeyboardEvent<HTMLElement>)=>{
    if(e.key==="Enter") setEntPressed(true);    
  }

  const handleInpKeyword=(e:React.FormEvent<HTMLInputElement>)=>{
    setInpKeyword(e.currentTarget.value.replaceAll("/","%2F"));
  }


    
  const handleStuExport=async ()=>{
    if(loginTeacher===undefined) return;
    const wb=new Excel.Workbook();
    const ws=wb.addWorksheet('學生學習統計');
    let workingCell=ws.getCell('E1');
    let endWrokingCell=ws.getCell('G1');
    ws.mergeCells('E1','G1');
    workingCell.value="快打高手";

    workingCell=ws.getCell('H1');
    endWrokingCell=ws.getCell('J1');
    ws.mergeCells('H1','J1');
    workingCell.value="句句實言";

    workingCell=ws.getCell('K1');
    workingCell.value="長話短說";
    workingCell=ws.getCell('L1');
    workingCell.value="讀出滋味";
    workingCell=ws.getCell('M1');
    workingCell.value="深度讀寫";

    let rowItem=['年級','座號','登入號','姓名','答對數','答錯數','正確率','答對數','答錯數','正確率','作答數','作答數','作答數'];
    ws.addRow(rowItem);
    openToStu.map((item,index)=>{
      rowItem=[];
      rowItem.push(item.Grade.toString());
      rowItem.push(item.Seat.toString());
      rowItem.push(item.StuNo.toString());
      rowItem.push(item.StuName.toString());
      rowItem.push(item.PP1Correct.toString());
      rowItem.push(item.PP1Wrong.toString());
      rowItem.push(((item.PP1Correct+item.PP1Wrong)===0)? '0':((item.PP1Correct/(item.PP1Correct+item.PP1Wrong))*100).toFixed(2).toString());
      rowItem.push(item.PP4Correct.toString());
      rowItem.push(item.PP4Wrong.toString());
      rowItem.push(((item.PP4Correct+item.PP4Wrong)===0)? '0':((item.PP4Correct/(item.PP4Correct+item.PP4Wrong))*100).toFixed(2).toString());
      rowItem.push(item.PP5Count.toString());
      rowItem.push(item.PP6Count.toString());
      rowItem.push(item.DPCount.toString());
      ws.addRow(rowItem);
    });
    const dateNow=new Date().toLocaleString('zh-Hans').replaceAll("/","-");
    await wb.xlsx.writeBuffer().then(buffer=> FileSaver.saveAs(new Blob([buffer]), `學生學習統計-${dateNow}.xlsx`))
  }
  

  const fetchData=async(keyword:string,mode:string)=>{
    if(sessionStorage.getItem("LoginStatus")==="2") //教師登入
    {
      if (sessionStorage.getItem("LoginTeacher") !== null) {

        const lTea = JSON.parse(sessionStorage.getItem("LoginTeacher")!)
        setLoginTeaacher(lTea);

        //取得開放班級及學生
        await getExStudentByTidKeyword(lTea.Tid.toString().trim(),keyword,mode).then((iStus)=>{
            setOpenToStu(iStus);
          });;
      }
    }
  }

  const handleBeginDate = (date: Date | null) => {
    // Check if the selected start date is later than the end date
    if (endDate && date && date > endDate) {
      setEndDate(null); // Reset the end date if necessary
    }
    setBeginDate(date);
  }; 

  const handleEndDate = (date: Date | null) => {
    // Check if the selected start date is later than the end date
    if (beginDate && date && date < beginDate) {
      setBeginDate(null); // Reset the end date if necessary
    }
    setEndDate(date);
  }; 


  useEffect(() => {
    fetchData('*','0');
    setInpKeyword("");
  }, []);

  useEffect(() => {
    if(refresh)
    {
      fetchData('*','0');
      setInpKeyword("");
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(()=>{
    if(entPressed)
    {
      if(inpKeyword==="")
      {
        fetchData('*','0');
      }
      else
      {
        fetchData(inpKeyword.trim(),'2');
      }
      setEntPressed(false);
    }
  },[entPressed])




  return (
  <>
    <TopNavPage2/>
    <div className="section pt-3">
      <div className="container px-3 px-md-4">
      <div className="d-flex align-items-center flex-column flex-lg-row">
          <h5 className="title mb-3 me-3">教師專區</h5>
          <div className="mx-auto ms-auto me-md-0 text-center">
            <a href="/teacheradmin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
              操作說明</a>
            <a href="/teacherstumgn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 " type="button">
              學生管理</a>
            <a href="/teacherstulearn" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 " type="button">
              學習記錄</a>
            <a href="/teacherstustat" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active" type="button">
              學習統計</a>
            <a href="/teacherstulogin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
              登入記錄</a>
          </div>
        </div>
      
        <div className="text-center h5 mb-3">學生學習統計</div>
        <div className="mx-auto" style={{maxWidth:'450px'}}>
            <div className="input-group">
              <span className="input-group-text"><span className="material-icons-outlined text-muted">search</span></span>
              <input className="form-control ps-3" placeholder="關鍵字篩選：年班、姓名、登入號、座號" type="text" onChange={handleInpKeyword} onKeyDown={handleKeyDown}/>
            </div>
        </div>
        <div style={{textAlign:'center', marginTop:'20px'}}>
          <div className="btn text-second bg-second mb-3 " onClick={()=>handleStuExport()}>匯出學生學習統計</div>
          <div className="btn text-second bg-second mb-3 " style={{marginLeft:'20px'}} onClick={()=> setEntPressed(true)}>重新整理</div>
        </div>

      <div className="d-flex flex-wrap justify-content-center align-items-center my-4">
        {/*<div className="form-group d-flex align-items-center" style={{width:'280px'}}>
          <DatePicker
              selected={beginDate}
              onChange={handleBeginDate}
              dateFormat="yyyy-MM-dd"
              isClearable
              placeholderText="開始日期"
              className="form-control custom-datepicker"
          />
          <span className="p-2">到</span>
          <DatePicker
              selected={endDate}
              onChange={handleEndDate}
              dateFormat="yyyy-MM-dd"
              isClearable
              placeholderText="開始日期"
              className="form-control custom-datepicker me-1"
         /> 
          <span className="me-3"></span>
  </div> */} 
        <div className="card p-3 table-responsive">
          <div className="text-muted">PP1:快打高手 / PP2:句句實言 / PP3:長話短說 / PP4:讀出滋味 / DP:深度讀寫 </div>
            <table className="text-sm table table-striped mb-0">
              <thead>
                <tr className="font-weight-bolder text-second text-nowrap" style={{background:'#d9dce6'}}>
                  <th className="p-2">No.</th>
                  <th className="p-2 order-by">學校</th>
                  <th className="p-2 order-by">年班</th>
                  <th className="p-2 order-by">座號</th>
                  <th className="p-2 order-by">姓名</th>
                  <th className="p-2 order-by">登入號</th>
                  <th className="p-2 order-by">PP1正確</th>
                  <th className="p-2 order-by">PP1錯誤</th>
                  <th className="p-2 order-by">PP1正確率</th>
                  <th className="p-2 order-by">PP2正確</th>
                  <th className="p-2 order-by">PP2錯誤</th>
                  <th className="p-2 order-by">PP2正確率</th>
                  <th className="p-2 order-by">PP3作答數</th>
                  <th className="p-2 order-by">PP4作答數</th>
                  <th className="p-2 order-by">DP作答數</th>
                </tr>
              </thead>
              <tbody>
                {
                  openToStu.map((item,index)=>{
                    return(
                      <>
                      <tr>
                        <td>{index+1}</td>
                        <td className="">{item.SchoolName}</td>
                        <td className="">{item.Grade}年{item.Class}班</td>
                        <td className="">{item.Seat}</td>
                        <td className="">{item.StuName}</td>
                        <td className="">{item.StuNo}</td>
                        <td>{item.PP1Correct}</td>
                        <td>{item.PP1Wrong}</td>
                        <td>{(item.PP1Correct+item.PP1Wrong===0)? 0:((item.PP1Correct/(item.PP1Correct+item.PP1Wrong))*100).toFixed(2)}%</td>
                        <td>{item.PP4Correct}</td>
                        <td>{item.PP4Wrong}</td>
                        <td>{(item.PP4Correct+item.PP1Wrong===0)? 0:((item.PP4Correct/(item.PP4Correct+item.PP1Wrong))*100).toFixed(2)}%</td>
                        <td>{item.PP5Count}</td>
                        <td>{item.PP6Count}</td>
                        <td>{item.DPCount}</td>
                      </tr>  
                      </>
                    )
                  })
                }                
              </tbody>
            </table>
          </div>
        </div>    
      </div>


      <div className="pt-3 mt-4"></div>
    </div> 
    <FooterPage/>
  </>
  )
}

export default TeacherStuStatisticPage