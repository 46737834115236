import IPageProps from '../interfaces/page'
import { genFerrisImageURL, handleLink } from '../modules/utils';
import FooterPage from './footer';
import TopNavPage1 from './topNav1';
import * as React from 'react'

import { getLearnRecCurrVal, getLearnRecCurrValStr, getLearnRecVal, loadLearnRecSection, newLearnRecCurr, setLearnRecVal } from '../modules/SessionTestCnt';
import { AddExStuRec, getExStuTestCntBySid, getExStuTestCntBySidTest } from '../services/ExStuRecService';
import { getUpdExStuLearnRec } from '../services/ExStuTeService';

import  { useSysInfoContext } from '../components/sysContext'; 

const PPTestPage: React.FunctionComponent<IPageProps> = props => {
    const {sharedState} = useSysInfoContext();
    const [refresh,setRefresh]=React.useState(false);

    //let Rec=getPPRec();
    //const chkPPCount3=()=>{
    //  ppRec=getPPRec();
    //}
    //const chkPPCount4=()=>{
    //  ppRec=getPPRec();
    //}
    const redirPP=(ppType:string)=>{
      switch (ppType)
      {
        case "PP1":
          if(sessionStorage.getItem("LoginStatus")==="1")
          {
            newLearnRecCurr(Number(sessionStorage.getItem("LoginID")),"PP1","");
          }
          handleLink('/pptest1');
          break;
        case "PP4":
          if(sessionStorage.getItem("LoginStatus")==="1")
          {
            newLearnRecCurr(Number(sessionStorage.getItem("LoginID")),"PP4","");
          }
          handleLink('/pptest2');
          break;
        case "PP5":
          //newLearnRecCurr(Number(sessionStorage.getItem("LoginID")),"PP5","");
          handleLink('/pptest3');
          break;
        case "PP6":
          //newLearnRecCurr(Number(sessionStorage.getItem("LoginID")),"PP6","");
          handleLink('/pptest4');
          break;  
      }
    }

    const genPPFerrisURL=(idx:number):string=>{
      let key=0;
      let intval=30;
      switch (idx)
      {
        case 0:
          key=getLearnRecVal("sCorrectPP1");
          intval=30;
          break;
        case 1:
          key=getLearnRecVal("sCorrectPP4");
          intval=10;
          break;          
        case 2:
          key=getLearnRecVal("sCountPP5");
          intval=10;
          break;
        case 3:
          key=getLearnRecVal("sCountPP6");
          intval=10;
          break; 
      }
      return genFerrisImageURL(key,idx,intval);
    }
    React.useEffect(()=>{
      if(sessionStorage.getItem("LearnRecCurr")!=="")
      {
        AddExStuRec(
          getLearnRecCurrVal("userID"),
          getLearnRecCurrValStr("TType"),
          getLearnRecCurrValStr("TQno"),
          getLearnRecCurrVal("sCorrect"),
          getLearnRecCurrVal("sWrong"),
          getLearnRecCurrVal("sSecond"),)
        sessionStorage.setItem("LearnRecCurr","");
      }
      setRefresh(true);
      if(sessionStorage.getItem("LoginStatus")==='1')
      {
        getUpdExStuLearnRec(sessionStorage.getItem("LoginID")!).then(data=>{
          sessionStorage.setItem("LearnRec",JSON.stringify(data));
        })
      }
      console.log("PPTestPage:useEffect:"+sharedState);
    },[])

    React.useEffect(()=>{
      if(refresh)
      {
        setRefresh(false);
      } 
    },[refresh])
    return (
      <>

      <TopNavPage1/>

    <div className="section pt-3">
        <div className="container">
          <div className="d-flex align-items-center">
            <h5 className="title mb-3 me-3">基礎讀寫<small className="ms-2">PP,Primary Process</small></h5>
            <button className="btn btn-outline-secondary btn-round ms-auto text-nowrap" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              查閱說明
            </button>
          </div>
          <div className="collapse" id="collapseExample">
            <div className="d-flex align-items-center">
              <img src="./assets/img/PP-00.svg" className="me-2" style={{width: '200px'}}/>
              <h5 className="description text-start">
                <p>基礎讀寫(PP)的目的，在促進讀者對詞彙、句、段、篇的認識與理解。</p>
                <p>「快打高手」和「句句實言」取材自國小國語三版本的教科書之詞彙與句子，經篩選後提供讀者擴充詞彙、重組句子的基礎學習，有正確與錯誤之分。</p>
                <p className="mb-0">「長話短說」和「讀出滋味」則以段落或短文為素材，閱讀後要能摘取大意、提取關鍵字、推論情緒，是更進階的練習，沒有絕對的標準答案，各題的參考答案為的是幫助你思考，你可以不同意。</p>
              </h5>
            </div>
          </div>
          <div className="space-top"></div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="card level-h pointer">
                <div className="card-header">
                  <img className="img w-100" src="./assets/img/PP-01.svg" onClick={()=>redirPP("PP1")}/>
                </div>
                <div className="card-body pt-0">
                  <p className="card-description text-center">
                    依題示，在格子裡輸入相同的字詞。
                  </p>                  
                  { (sessionStorage.getItem("LoginStatus")==="1") &&
                  <>
                  <hr className="horizontal dark"/>
                  <img src={genPPFerrisURL(0)} style={{width:'100%'}}/>
                  <div className="d-flex text-center">
                    <div className="flex-fill">
                      <h5 className="font-weight-bolder mb-0 mt-3"><>{getLearnRecVal("sCountPP1")}</></h5>
                      <span className="text-sm">次數</span>
                    </div>
                    <div className="flex-fill mx-1">
                      <h5 className="font-weight-bolder mb-0 mt-3"><>{getLearnRecVal("sCorrectPP1")}</></h5>
                      <span className="text-sm">答對</span>
                    </div>
                    <div className="flex-fill">
                      <h5 className="font-weight-bolder mb-0 mt-3">{getLearnRecVal("sWrongPP1")}</h5>
                      <span className="text-sm">答錯</span>
                    </div>
                  </div>
                  </>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card level-h pointer" >
                <div className="card-header">
                  <img className="img w-100" src="./assets/img/PP-02.svg" onClick={()=>redirPP("PP4")}/>
                </div>
                <div className="card-body pt-0">
                  <p className="card-description text-center">
                    將字詞組合成完整通順的句子。
                  </p>
                  {(sessionStorage.getItem("LoginStatus")==="1") &&
                  <>
                  <hr className="horizontal dark"/>
                  <img src={genPPFerrisURL(1)} style={{width: '100%'}}/>
                  <div className="d-flex text-center">
                    <div className="flex-fill">
                      <h5 className="font-weight-bolder mb-0 mt-3">{getLearnRecVal("sCountPP4")}</h5>
                      <span className="text-sm">次數</span>
                    </div>
                    <div className="flex-fill mx-1">
                      <h5 className="font-weight-bolder mb-0 mt-3">{getLearnRecVal("sCorrectPP4")}</h5>
                      <span className="text-sm">答對</span>
                    </div>
                    <div className="flex-fill">
                      <h5 className="font-weight-bolder mb-0 mt-3">{getLearnRecVal("sWrongPP4")}</h5>
                      <span className="text-sm">答錯</span>
                    </div>
                  </div>
                  </>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card level-h pointer" >
                <div className="card-header">
                  <img className="img w-100" src="./assets/img/PP-03.svg" onClick={()=>redirPP("PP5")}/>
                </div>
                <div className="card-body pt-0">
                  <p className="card-description text-center">
                    閱讀短文，寫出摘要，選出文章中的關鍵字。
                  </p>
                  {(sessionStorage.getItem("LoginStatus")==="1") &&
                  <>
                  <hr className="horizontal dark"/>
                  <img src={genPPFerrisURL(2)} style={{width: '100%'}}/>
                  <div className="text-center">
                    <h5 className="font-weight-bolder mb-0 mt-3">{getLearnRecVal("sCountPP5")}</h5>
                    <span className="text-sm">已學習短文數</span>
                  </div>
                  </>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card level-h pointer" >
                <div className="card-header">
                  <img className="img w-100" src="./assets/img/PP-04.svg" onClick={()=>redirPP("PP6")} />
                </div>
                <div className="card-body pt-0">
                  <p className="card-description text-center">
                    閱讀短文，讀出文章背後的滋味。
                  </p>
                  <div>
                    {(sessionStorage.getItem("LoginStatus")==="1") &&
                    <>
                    <hr className="horizontal dark"/>
                    <img src="./assets/img/Maten6_0.png" style={{width: '100%'}}/>
                    <div className="text-center">
                      <h5 className="font-weight-bolder mb-0 mt-3">{getLearnRecVal("sCountPP6")}</h5>
                      <span className="text-sm">已學習短文數</span>
                    </div>
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <FooterPage/>
    </>
    );
}

export default PPTestPage;