import React from 'react'
import { handleLink } from '../../modules/utils';
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';


const EPAboutPage: React.FunctionComponent = props => {
    return (
        <>
        <TopNavPage2 />
        <div className="section pt-3">
            <div className="container px-3 px-md-4">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h5 className="title mb-3 me-3">線上探究<small className="ms-2">EP,Explore Process</small></h5>
                    <h5 className="order-2 order-md-1 mb-3">EP 說明</h5>
                    <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width: '232px'}}>
                        <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/eptest')}>
                            <i className="fas fa-chevron-left"></i>
                            返回 EP 線上探究
                        </button>
                    </div>
                </div>

                <div id="carouselEP" className="carousel slide w-100 w-lg-75 mx-auto" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselEP" data-bs-slide-to="0" className="active" aria-current="true"
                            aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselEP" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselEP" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselEP" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner border-radius-md">
                        <div className="carousel-item active">
                            <img src="./assets/img/ep/EpAbout_Pic5.png" className="d-block w-100" alt="" />
                        </div>
                        <div className="carousel-item">
                            <img src="./assets/img/ep/EpAbout_Pic2.png" className="d-block w-100" alt="" />
                        </div>
                        <div className="carousel-item">
                            <img src="./assets/img/ep/EpAbout_Pic3.png" className="d-block w-100" alt="" />
                        </div>
                        <div className="carousel-item">
                            <img src="./assets/img/ep/EpAbout_Pic4.png" className="d-block w-100" alt="" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselEP" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselEP" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>     
                <div className="card mt-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 mx-auto">
                            <img src="./assets/img/ep/web_EPAbout2.png" alt="" className="w-100 p-3 p-lg-4 pe-3 pe-lg-0" />
                        </div>
                        <div className="col-12 col-lg-6 text-justify mx-auto mb-4">
                            <div className="p-3 p-lg-4 ps-3 ps-lg-0">
                                <p className="text-dblue">人手一機已是事實，政府更是積極建置高速網路，生活中處處可見善用數位載具及網路帶來的進展與方便，
                                    如何應用並成為師生學習有利的工具，是教育界必須面對的議題。</p>
                                <p className="text-dblue">基於網路上資訊豐沛，教科書不再是知識唯一來源，上網可說是實踐海闊天空、自我學習的重要路徑。</p>
                                <p className="text-dblue">但不可否認，網路資訊是兩面刃，這就需要一個有鷹架功能的平台，學生得以自主地與同儕在其中學習，
                                    逐漸認識並能判斷網路資訊的複雜與多樣，進而知道如何設定學習目標，選擇、判斷、整合資訊，以獲得知識與能力上的成長。</p>
                                <p className="text-dblue">本計畫提供一個有鷹架的線上數位閱讀平台，以專題探究為軸，小組自學為輔，鼓勵全國師生參與，共同達成12年國教自主學習的目標。</p>
                                <div className="text-end text-second fw-bolder">— 柯華葳教授</div>
                            </div>
                        </div>
                    </div>
                    <hr className="horizontal dark" />

                    <div className="col-12 col-lg-7 video-box p-3 mx-auto my-5">
                        <div className="ratio ratio-16x9">
                            <iframe src="https://www.youtube.com/embed/e-uz39S2Gx0" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pt-3 mt-4"></div>            

        <FooterPage/>
        </>
    );
}

export default EPAboutPage;