import axios, { AxiosError } from 'axios'
import  config from '../../config/config';
import { IExStudent } from '../../interfaces/ExStudent';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { IExGradeClass } from '../../interfaces/ExGradeClass';
import { IExStuLearnRec } from '../../interfaces/ExStuLearnRec';
import { IInsertApply, IInsertTeacher, IUpdateTeacher, IupdateApply } from '../../interfaces/EpModal';



// 新增一名教師 依據申請編號選出最後一筆資料+"-"+英文字母
// Add DrTeacher by ApplyNo
// Post /api/DrTeacher/newapply/applyno/:applyno
// const createDrTeacherByApplyNo = asyncHandler(async(req: Request, res: Response) => {
// post
  export const insertDrTeacherByApplyNo =async (param:IInsertTeacher ,teacherNo :string)=>{

      console.log("UpdateApplyBaseInfo",);
      const url=config.server.url+`/api/DrTeacher/newapply/applyno/${teacherNo}`
      console.log('url',url)
      return new Promise((resolve, reject) => {
          axios.post(url,param)
            .then(response => {
              console.log("getLoginInfoByAccountAndPass ",response.data);
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });

  }




  // Add DrApply by main fields
// Post /api/DrApply/newapply/prefix/:prefix
// const createDrApplyByPrefix = asyncHandler(async(req: Request, res: Response)
export const insertDrApplyByPrefix  =async (param:IInsertApply ,prefix :string)=>{

    const url=config.server.url+`/api/DrApply/newapply/prefix/${prefix}`
    console.log('url',url)
    return new Promise((resolve, reject) => {
        axios.post(url,param)
          .then(response => {
            console.log("回傳..applyNo ",response.data);
            resolve(response.data);
            return response.data;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });

}

