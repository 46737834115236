
import config from '../config/config'


export const handleLink =(innerLink:string)=>{
    window.location.assign(innerLink);
}

export const handleLinkWithBlank =(innerLink:string)=>{
  window.open(innerLink,'_blank');
}

export const localStorageGetNum = (key:string):number=>{
    const tmp=localStorage.getItem(key);
    if(tmp)
    {
        return JSON.parse(tmp);
    } else {
        localStorage.setItem(key,JSON.stringify(0));
    }
    return 0;
}

export const sessionStorageGetNum = (key:string):number=>{
    const tmp=sessionStorage.getItem(key);
    if(tmp)
    {
        return JSON.parse(tmp);
    } else {
        sessionStorage.setItem(key,JSON.stringify(0));
    }
    return 0;
}

export const genFerrisImageURL=(key:number,idx:number,intval:number):string=>{
    const lvl=key/intval;
    let lvlStr=lvl.toString()
    if(lvlStr.includes("."))
    {
      lvlStr=lvlStr.substring(0,lvlStr.indexOf("."));
    }
    if(Number(lvlStr)>6)
    {
      return config.ppFerrisPrefix+config.FerrisURL[idx][6];
    }
    else
    {
      return config.ppFerrisPrefix+config.FerrisURL[idx][Number(lvlStr)];
    }
}

export const calcPercentage=(c1:number,c2:number,dec:number):string=>{
  if(c1===0) return '0';
  const res=(c1/(c1+c2))*100;
  return res.toFixed(dec);
}

export const padZero=(num:number|undefined,dec:number):string=>{
  if(!num) return ''
  let result=num? num.toString():''

  for(let i=1;i<=(dec-String(num).length);i++)
  {
    result='0'+result;
  }
  return result;
}

/*export const arrInsert=(arr:typeof Array, index:number, newItem:typeof Object): tyooeof Object=>{
const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]
}
*/
/*export const myIP=():string=>{
  const netInterfaces=os.networkInterfaces();
  for(let devName in netInterfaces)
  {
    let iface=netInterfaces[devName]
    if(iface!==undefined)
    {
      for(let i=0;i<iface.length;i++)
      {
        const alias=iface[i]
        if(alias.family==='IPv4' && alias.address!=='127.0.0.1' && !alias.internal)
          return alias.address
      }

    }
  }
  return '0.0.0.0';
}*/