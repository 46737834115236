import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { crtlInfo } from '../../../interfaces/EpModal';
import { currentClassForBtn, getcontrol, logOut } from '../tool/helper';
import { getDrControlByClassNow } from '../../../services/EP/EPbtnEableService';
import { MyContext } from '../../home';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const StudentFunctionBar: React.FunctionComponent<IPageProps> = (props) => {
    const context = useContext(MyContext);
    const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[]>([]);
    const [curTarget ,setCurTarget] =useState<string>('epStudentHome');
    interface IProp {
        currentPage? :string 
     
    }
    const getCtrl = (control: string) => {
        return true 
        let ctrl = crtlInfoList.find((x) => x.Control == control);

        if (ctrl) {
            return true;
        } else {
            return false;
        }
    };
    const link = (ctrl: string, route: string) => {
        if (getCtrl(ctrl)) {
            handleLink(route);
        } else {
        }
    };

    const disableStyle = {
        color: 'gray',
        background: '#dddddd'
    };


    // <a
                    // href={getCtrl('6-4-14') ? '/epApplyHome' : undefined}
                    // onClick={() => {
                    //     link('6-4-14' ,'/epApplyHome');
                    // }}
    //                 className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 active"
    //                 style={getCtrl('6-4-14') ? {} :disableStyle }
    //                 type="button"
    //             >
    //                 指導老師主頁
    //             </a>

    return (
        <>
            <div className="text-center text-lg-end">
            <a
                    href={getCtrl('6-4-8') ||true? '/epStudentHome' : undefined}
                    onClick={() => {
                        link('6-4-8' ,'/epStudentHome');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    主頁
                </a>
                {/* <a
                    href={getCtrl('6-4-8') ||true? '/epStudentHome' : undefined}
                    onClick={() => {
                        link('6-4-8' ,'/epStudentHome');
                    }}
                    className={`btn ${curTarget === 'epStudentHome' ? 'btn-secondary' :'btn-outline-secondary'} btn-round text-nowrap me-2 me-sm-3 active`}
                    type="button"
                >
                    主頁
                </a> */}
                
                <a
                    href={getCtrl('6-4-9')||true ? '/epStudLevel1' : undefined}
                    onClick={() => {
                           link('6-4-9' ,'/epStudLevel1');
                       }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button">
                    階段1和2 
                </a>
                
                <a  href={getCtrl('6-4-9')||true ? '/epStudExpert': undefined }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button"
                    >
        
                    專家回饋
                </a>
                <a href={getCtrl('6-4-9')||true ? '/epStudfeedback' :undefined } 
                className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button"
               >
                    階段1和2(回饋後)
                </a>
                <a href={getCtrl('6-4-9')||true ? '/epStudLevel3' :undefined } className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button"
                    >
                    階段3和4
                </a>
                <a href={getCtrl('6-4-9')||true ? '/epStudResult' :undefined } className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button"
                    >
                   成果報告
                </a>
                <a
                    onClick={() => {
                        logOut();
                    }}
                    style={getCtrl('6-4-9') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            
            </div>
        </>
    );
};
export default StudentFunctionBar;
