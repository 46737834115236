import Modal from 'react-bootstrap/Modal'
import { handleLinkWithBlank } from '../../modules/utils';

interface ModalProps {
    title: string;
    isOpen: boolean;
    setOpenRedirModal: (val:boolean) => void;
    children?:React.ReactNode
    redirURL:string;
  }
  
  export const RedirModal: React.FC<ModalProps> = (props) => {
  
    const handleRedirect=()=>{
        props.setOpenRedirModal(false);
        handleLinkWithBlank(props.redirURL);
    }
  
    return <Modal show={props.isOpen} onHide={()=>props.setOpenRedirModal(false)}>
                <Modal.Header> 
                    <Modal.Title><h5 className="mx-auto">{props.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-auto'>{props.children}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" style={{marginLeft:'10px'}} onClick={()=>props.setOpenRedirModal(false)}>取消</button>
                    <button type="button" className="btn btn-primary" style={{marginLeft:'10px'}} onClick={()=>handleRedirect()}>繼續</button>
                </Modal.Footer>
            </Modal>

  };