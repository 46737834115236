import React, { useEffect, useState } from 'react';



import { IsubTopicForModal, alertEx, currentClass, currentClassForBtn, formatDate, geIndexBytOpinionCode, getClassNameForShow, getLoginInfo, getModalConfigs, replaceNewline, sourceType, validate } from '../tool/helper';
import { IOpinion, IsubTopic, Param11OrPost11, crtlInfo, subTopicParam } from '../../../interfaces/EpModal';
import { StudentDisplay } from '../epStudentHome';
import {  createOpinion, deleteOpinionByUID, deleteSubTopicByGroupNoSubNo, getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateOpinionByGroupOpIdxUID, updateSubTopicByUid } from '../../../services/EP/OpinionService';
import {
    Action as action,
    IOpinionForModal,
    formatDateTime,
    OpinCode,

} from '../tool/helper';
import { getOpinionByStuNo } from '../../../services/EP/EpApplyStudentService';
import { Modal } from 'react-bootstrap';
import EditModal from './EditModal';
import { getSubTopicByGroupNo } from '../../../services/EP/EpApplyExploreService';
interface IProp{
    studentInfo :StudentDisplay|undefined
    /** 登入者狀態 */
    currentLoginInfo :any 
    setStudentInfo : (studentDisplay :StudentDisplay) => void;
    isfeedback : 0|1 
    groupNo :string |undefined 
    role :string 

}





// 1-1 提出探究題目 (回覆後)
const SubTopicTable: React.FunctionComponent<IProp> = (props) => {

    const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
    const [opinion, setOpinion] = useState<IOpinion>();
    const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
    const [isReload, setIsReload] = useState<boolean>(true);
    const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState<any>();
    /**取的opinion 資料 */
    const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>();

useEffect(()=>{
    fetchDataSubTopic()
},[isReload ,isAddRsearchModalOpen])
useEffect(()=>{
    fetchDataSubTopic()
},[props.groupNo])


    const handleCheckboxClick = (index :number ) => {
    
        const updatedTeam = [...(props.studentInfo?.Team||[])];
        updatedTeam.forEach(x=>{
            if(formData?.Description?.includes(x.TStuName.trim())){
                x.checked =true;
            }
        })
        updatedTeam[index].checked = !updatedTeam[index].checked;
        props.setStudentInfo({
            ...props.studentInfo,
            Team: updatedTeam,
          } as StudentDisplay);

   let studentString  = updatedTeam.filter((i)=>i.checked).map(x=>x.TStuName.trim()).join('、')
        setFormData({ ...formData, Description:studentString})

      };
          /** 取得子題資料*/
    const fetchDataSubTopic = async () => {
        if (props.groupNo) {
            let subTopicbefore = (await getSubTopicByGroupNo(props.isfeedback,props.groupNo)) as IsubTopic[];
            console.log('subTopicbefore 子題(回覆前)', subTopicbefore);
            setSubTopicBeforeFeedbackList(subTopicbefore);
        }
    };


    const openEditModal = async (opinCode: OpinCode, action: action, param11?: Param11OrPost11, subTopic?: IsubTopic,enable?:boolean ) => {
        if(enable ){
            return 
        }
        let modalConfig = getModalConfigs(opinCode, action ,1);
        let params = {
            UID: undefined,
            ParamType: modalConfig?.sourceType,
            GroupNo: props.groupNo,
            MemberNo: props.studentInfo?.StuNo,
            DataA: '',
            DataB: '',
            DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        /**  彈出視窗設定檔*/
        if (props.groupNo) {
            if (action === '新增') {
                params = {
                    action: '新增',
                    UID: undefined,
                    ParamType: modalConfig?.sourceType,
                    GroupNo: props.groupNo,
                    MemberNo: props.studentInfo?.StuNo,
                    DataA: '',
                    DataB: '',
                    DataC: ''
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
                //需要有設定檔 跟 修改對象 ID
            } else if (action === '修改') {
                // fectchData todo
                if (modalConfig) {
                    //如果沒有 傳進來的物件
                    if (param11 && !subTopic) {
                        // params
                        debugger 
                        alert(JSON.stringify(param11))
                        params = {
                            action: '修改',
                            UID: param11.OpinionID,
                            ParamType: modalConfig.sourceType,
                            GroupNo: props.groupNo,
                            MemberNo: props.studentInfo?.StuNo,
                            DataA: param11.DataA,
                            DataB: param11.DataB,
                            DataC: '',
                            termsStringList : param11.DataB.split('、')
                        } as paramsCreateOpinionSubTopic;
                        setFormData(params);
                    } else if (!param11 && subTopic) {
                        // 沒有物件
                        try {
                            params = {
                                action: '修改',
                                UID: subTopic.uid,
                                ParamType: modalConfig.sourceType,
                                GroupNo: props.groupNo,
                                MemberNo: props.studentInfo?.StuNo,
                                SubNo: subTopic.SubNo + '',
                                SubName: subTopic.SubName,
                                Description: subTopic.Description,
                                Keyword :subTopic.Keyword
                            } as paramsCreateOpinionSubTopic;
                   
                            setFormData(params);
                        } catch (ex) {
                            alert('ex:' + JSON.stringify(ex));
                        }
                    }
                }
            } else if (action === '新增或修改') {
                if (modalConfig) {
                    // 有moaal 設定檔
                    if (!param11 && !subTopic) {
                        // topic
                        try {
                            let optionCode = geIndexBytOpinionCode(opinCode);
                            let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, props.groupNo)) as any[];
                            if (rsp.length > 0) {
                                // 原本有資料 設定修改
                                params = {
                                    action: '修改',
                                    UID: rsp[0].OpinionID || rsp[0].uid,
                                    ParamType: modalConfig.sourceType,
                                    GroupNo: props.groupNo,
                                    MemberNo: props.studentInfo?.StuNo,
                                    DataA: rsp[0].DataA || null,
                                    DataB: rsp[0].DataB || null,
                                    DataC: rsp[0].DataC || null
                                } as paramsCreateOpinionSubTopic;

                                setFormData(params);
                            } else {
                                // 沒有資料就設定  新增
                                params = {
                                    action: '新增',
                                    UID: undefined,
                                    ParamType: modalConfig?.sourceType,
                                    GroupNo: props.groupNo,
                                    MemberNo: props.studentInfo?.StuNo,
                                    DataA: '',
                                    DataB: '',
                                    DataC: ''
                                } as paramsCreateOpinionSubTopic;
                                setFormData(params);
                            }
                        } catch (ex) {
                            alertEx('ex:' + JSON.stringify(ex));
                        }
                    } else if (!param11 && subTopic) {
                    } else if (param11 && !subTopic) {
                        //opinion 一對一
                    }
                }
            } else if (action === '刪除') {
            }
        }

        // console.log( getModalConfigs(stage,modalName) )
        if (getModalConfigs(opinCode, action,1)) {
            setCurrentShowModal(getModalConfigs(opinCode, action,1));
        }
        setIsAddRsearchModalOpen(true);
    };

    
    const deleteItem = async (deleteType: sourceType, subTopic?: IsubTopic, param11?: Param11OrPost11,enable?:boolean) => {
        if(enable){
            return 
        }
     
        if (window.confirm('確定刪除?')) {
            try {
                if (deleteType === 'subTopic' && subTopic) {
                    if (subTopic.GroupNo && subTopic.uid) {
                        await deleteSubTopicByGroupNoSubNo(1, subTopic.GroupNo, subTopic.SubNo);
                    }
                } else if (deleteType === 'opinion' && param11) {
                    await deleteOpinionByUID(1, param11.OpinionID);
                }
                alertEx('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                console.log();
            }
        }
    };
    /** */

    return (
      <>
    <div  >
{/* {  subTopicBeforeFeedbackList?.length} */}
  
    <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ backgroundColor: '#d9dce6' }}>
                                                    <th className="p-2">#</th>
                                                    <th className="p-2 text-nowrap">子題名稱</th>
                                                    <th className="p-2 text-nowrap">負責隊員</th>
                                                    <th className="p-2 text-nowrap">關鍵字詞</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th 
                                                    // className="p-2 text-nowrap print-remove"
                                                      className={ getClassNameForShow(['DrStudent'], props.role ,"p-2 text-nowrap print-remove"  )}
                                                    >功能</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {JSON.stringify(subTopicBeforeFeedbackList)} */}
                                                {subTopicBeforeFeedbackList?.map((item) => (
                                                    <tr>
                                                        <td>{item.SubNo}</td>
                                                        <td>{item.SubName}</td>
                                                        <td className="text-nowrap">{item.Description||''}</td>
                                                        <th className="p-2 text-nowrap">{item.Keyword||''}</th>
                                                        <td>{formatDate(item.UpdateDate)}</td>
                                                        <td 
                                                        // className="text-nowrap print-remove"
                                                        className={ getClassNameForShow(['DrStudent'], props.role ,"text-nowrap print-remove" )}
                                                        >
                                                            <span
                                                                className="material-icons text-dark pointer"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editModal2-1"
                                                                title="編輯"
                                                                onClick={() => openEditModal('修改關鍵字詞', '修改', undefined, item)}
                                                            >
                                                                edit
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

            <EditModal  groupNo={props.groupNo}
                         isAddRsearchModalOpen={isAddRsearchModalOpen}  
                        setIsAddRsearchModalOpen ={setIsAddRsearchModalOpen}
                         currentShowModal ={currentShowModal}
                         studentInfo = {props.studentInfo}
                         setStudentInfo = {props.setStudentInfo}
                         formData={ formData}
                         setFormData={setFormData}
                         onFinish={()=>setIsReload(!isReload)}
                        />
                 
      </div>
      </>
    );
};
export default SubTopicTable;


