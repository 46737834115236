import React from 'react'
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';
import { handleLink, handleLinkWithBlank } from '../../modules/utils';


const CooperatePage: React.FunctionComponent = props => {
    return (
        <>
        <TopNavPage2 />
        <div className="section pt-3">
            <div className="container px-3 px-md-4">

                <h5 className="mb-3 text-center"  style={{fontSize: '24px', fontWeight:'bold'}}>合作單位</h5>

            <div className="d-flex flex-wrap my-5">

                <div className="col-6 d-flex justify-content-center align-items-center bg-white">
                <img src="./assets/img/tut/Threelogo.png" alt="" className="w-100 p-5" style={{maxWidth: '500px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://www.hwaweiko.tw/")}/>
                </div>
                <div className="col-6 d-flex justify-content-center flex-column p-4 p-md-5 mb-5 mb-md-0" style={{background:'#7d9fc5', minHeight: '400px', cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://www.hwaweiko.tw/")}>
                <div className="h4 text-white fw-bolder mb-4">國立清華大學柯華葳教授閱讀研究中心</div>
                <div className="text-white text-justify text-lg">「數位讀寫網」的架構，係由柯華葳教授、陳明蕾教授及林玫伶校長指導建立。 並由他們帶領清華大學三研而立團隊協助命題， 從字詞句段篇的基礎讀寫(PP)、文圖表的多文本深度讀寫(DP)， 到線上數位閱讀專題探究(EP)， 有系統的幫助學生培養面對數位時代應有的能力。</div>
                <a href="https://www.hwaweiko.tw/" target="_blank" className="fw-bolder text-white mt-2 text-end">柯華葳閱讀研究中心</a>
                </div>

                <div className="col-6 d-flex justify-content-center flex-column p-4 p-md-5 mb-5 mb-md-0" style={{background:'#9c8e8e', minHeight:'400px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://www.meps.tp.edu.tw/nss/p/index")}>
                <div className="h4 text-white fw-bolder mb-4">臺北市國語實驗國民小學</div>
                <div className="text-white text-justify text-lg">「線上數位閱讀專題探究」(EP)由柯華葳教授及時任臺北市國語實小林玫伶校長於2018年規劃建立，參酌2016 PIRLS團隊研發之數位閱讀素養能力指標，觀摩國語實小教師實驗課程成果後建立之，並邀請國語實小團隊撰寫各階段教學簡報。</div>
                <a href="https://www.meps.tp.edu.tw/nss/p/index" target="_blank" className="fw-bolder text-white mt-2 text-end">臺北市國語實驗國民小學</a>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center bg-white">
                <img src="./assets/img/tut/TMEElogo.png" alt="" className="w-100 p-5" style={{maxWidth:'450px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://www.meps.tp.edu.tw/nss/p/index")}/>
                </div>

                <div className="col-6 d-flex justify-content-center align-items-center bg-white">
                <img src="./assets/img/tut/Coop-Librarian.png" alt="" className="w-100 p-5" style={{maxWidth:'550px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://cirn.moe.edu.tw/Module/index.aspx?sid=1186")}/>
                </div>
                <div className="col-6 d-flex justify-content-center flex-column p-4 p-md-5 mb-5 mb-md-0" style={{background:'#90bfaa',minHeight: '400px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://cirn.moe.edu.tw/Module/index.aspx?sid=1186")}>
                <div className="h4 text-white fw-bolder mb-4">全國圖書教師輔導團</div>
                <div className="text-white text-justify text-lg">在各國致力將聯合國「2030永續發展目標」(SDGs)帶入兒少教育的同時，2022年數位讀寫網於深度閱讀(DP)決定增加SDGs練習題組，並獲得全國圖書教師輔導團的支持，邀請圖書教師自願加入撰文及命題行列。
                </div>
                <a href="https://cirn.moe.edu.tw/Module/index.aspx?sid=1186" target="_blank" className="fw-bolder text-white mt-2 text-end">全國圖書教師輔導團</a>
                </div>

                <div className="col-6 d-flex justify-content-center flex-column p-4 p-md-5 mb-5 mb-md-0" style={{background:'#de9fa6',minHeight:'400px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://kids.twreporter.org/")}>
                <div className="h4 text-white fw-bolder mb-4">財團法人報導者文化基金會</div>
                <div className="text-white text-justify text-lg">「財團法人報導者文化基金會」是台灣第一個公益基金會成立的網路媒體，致力於公共領域深度報導，今年(2022)推出「少年報導者」兒少新聞平台，展開與10到15歲的孩子對話。</div>
                <div className="text-white text-justify text-lg">讓孩子透過資訊探查，了解社會嚴肅事件的脈絡，是民主社會重要的事，透過林玫伶顧問獲悉柯華葳線上探究競賽，《報導者》希望藉由「報導者特別獎」的形式，彰顯此一活動除具教育意義，更有提升公民社會的價值。</div>
                <a href="https://kids.twreporter.org/" target="_blank" className="fw-bolder text-white mt-2 text-end">少年報導者</a>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center bg-white">
                <img src="./assets/img/tut/KidsTwreporter.png" alt="" className="w-100 p-5" style={{maxWidth: '550px',cursor:'pointer'}} onClick={()=>handleLinkWithBlank("https://kids.twreporter.org/")}/>
                </div>


            </div>
            </div>

            <div className="pt-3 mt-4"></div>
            
        </div>
        <FooterPage/>
        </>
    );
}

export default CooperatePage;