// import { opinCode } from './helper';
import { IGroupForExpertS, IGroupInfo, ILoginUserInfo, IOpinion, IsubTopic, TeacherGroupInfo, crtlInfo } from '../../../interfaces/EpModal';
import Excel from 'exceljs';
import { getOpinionByGroupNo, getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo } from '../../../services/EP/EpApplyExploreService';
import { getAllDrGroup } from '../../../services/EP/EpExpertService';
import { getDrControlByClassNow } from '../../../services/EP/EPbtnEableService';
import { useContext } from 'react';
import { MyContextType } from '../../home';
import * as XLSX from 'xlsx';
import { StudentDisplay } from '../epStudentHome';
import { paramsCreateOpinionSubTopic } from '../../../services/EP/OpinionService';
import { handleLink } from '../../../modules/utils';
/** */
export const printFun = () => {



}


export let modeGlobal: "開發" | "發布" | "測試" = "開發"
export const setModeGlobal = (newMode: typeof modeGlobal) => {
    modeGlobal = newMode;
};

export const currentClass = "112年度"
export const currentClassForBtn = "113年度"
export const currentTempGroup = 'EA011-01'
export const ReacherArPictureName_before = "research_pic_before.png"
export const ReacherArPictureName_after = "research_pic_after.png"
// export const ReacherArPictureName = "research_pic.png"
export const SummaryFileName = "subTopic_summary_pdf.pdf"

export type sTitleName = "專家學者線上回饋" | "前-決定探究題目" | "前-決定探究題目原因" | "Pre12-修改時間" | "前-探究題目預測結果原因" |
    "Pre13-修改時間" | "前-遭遇困難" | "前-解決方式" | "前-學到的新思維" | "Pre14-修改時間" | "前-指導老師留言" | "Pre19-修改時間" |
    "前-遭遇困難" | "前-解決方式" | "前-學到的新思維" | "Pre24-修改時間" | "前-指導老師留言" | "Pre29-修改時間" | "專家回饋" |
    "後-決定探究題目" | "後-決定探究題目原因" | "Post12-修改時間" | "後-探究題目預測結果原因" |
    "Post13-修改時間" | "後-遭遇困難" | "後-解決方式" | "後-學到的新思維" | "Post14-修改時間" | "後-指導老師留言" |
    "修改時間" | "後-遭遇困難" | "後-解決方式" | "後-學到的新思維" | "Post24-修改時間" | "後-指導老師留言" | "Post29-修改時間" |
    "後-回饋意見後修改" | "Post30-修改時間" | "後-遭遇困難" | "後-解決方式" | "後-學到的新思維" | "Post33-修改時間" | "後-指導老師留言" |
    "Post39-修改時間" | "後-提出結論與發現" | "Post42-修改時間" | "後-比較探究結論" | "Post43-修改時間" | "後-遭遇困難" | "後-解決方式" |
    "後-學到的新思維" | "Post44-修改時間" | "後-指導老師留言" | "Post49-修改時間" | "後-更改前探究題目" | "Post51-修改時間" |
    "後-參加本活動的省思" | "Post54-修改時間" | "後-指導老師心得" | "Post55-修改時間" | "前-指導老師留言-2" | "Pre29-修改時間-2";

let opinionMap: { attr: string, name: sTitleName, displayTitle?: string }[] =
    [{ attr: "Pre12_DataA", name: "前-決定探究題目", displayTitle: "1-2 決定探究題目" },
    { attr: "Pre12_DataB", name: "前-決定探究題目原因", displayTitle: "1-2 決定探究題目" },
    { attr: "Pre12_UpdateDate", name: "Pre12-修改時間", displayTitle: "1-2 決定探究題目" },
    { attr: "Pre13_DataB", name: "前-探究題目預測結果原因", displayTitle: "1-3 預測可能的結果" },
    { attr: "Pre13_UpdateDate", name: "Pre13-修改時間", displayTitle: "1-3 預測可能的結果" },
    { attr: "Pre14_DataA", name: "前-遭遇困難", displayTitle: "1-4 困難、解決與新學習" },
    { attr: "Pre14_DataB", name: "前-解決方式", displayTitle: "1-4 困難、解決與新學習" },
    { attr: "Pre14_DataC", name: "前-學到的新思維", displayTitle: "1-4 困難、解決與新學習" },
    { attr: "Pre14_UpdateDate", name: "Pre14-修改時間", displayTitle: "1-4 困難、解決與新學習" },
    { attr: "Pre19_DataA", name: "前-指導老師留言", displayTitle: "階段1，指導老師留言", },
    { attr: "Pre19_UpdateDate", name: "Pre19-修改時間", displayTitle: "階段1，指導老師留言", },
    { attr: "Pre24_DataA", name: "前-遭遇困難", displayTitle: "2-3 困難、解決與新學習", },
    { attr: "Pre24_DataB", name: "前-解決方式", displayTitle: "2-3 困難、解決與新學習", },
    { attr: "Pre24_DataC", name: "前-學到的新思維", displayTitle: "2-3 困難、解決與新學習", },
    { attr: "Pre24_UpdateDate", name: "Pre24-修改時間", displayTitle: "2-3 困難、解決與新學習", },
    { attr: "Pre29_DataA", name: "前-指導老師留言-2", displayTitle: "階段2，指導老師留言" },
    { attr: "Pre29_UpdateDate", name: "Pre29-修改時間-2", displayTitle: "階段2，指導老師留言" },
    { attr: "ExpoOpinion", name: "專家回饋", },
    { attr: "Post12_DataA", name: "後-決定探究題目", },
    { attr: "Post12_DataB", name: "後-決定探究題目原因", },
    { attr: "Post12_UpdateDate", name: "Post12-修改時間" },
    { attr: "Post13_DataB", name: "後-探究題目預測結果原因", },
    { attr: "Post13_UpdateDate", name: "Post13-修改時間" },
    { attr: "Post14_DataA", name: "後-遭遇困難", },
    { attr: "Post14_DataB", name: "後-解決方式", },
    { attr: "Post14_DataC", name: "後-學到的新思維", },
    { attr: "Post14_UpdateDate", name: "Post14-修改時間" },
    { attr: "Post19_DataA", name: "後-指導老師留言", },
    { attr: "Post19_UpdateDate", name: "修改時間", },
    { attr: "Post24_DataA", name: "後-遭遇困難", },
    { attr: "Post24_DataB", name: "後-解決方式", },
    { attr: "Post24_DataC", name: "後-學到的新思維", },
    { attr: "Post24_UpdateDate", name: "Post24-修改時間" },
    { attr: "Post29_DataA", name: "後-指導老師留言", },
    { attr: "Post29_UpdateDate", name: "Post29-修改時間" },
    { attr: "Post30_DataA", name: "後-回饋意見後修改", },
    { attr: "Post30_UpdateDate", name: "Post30-修改時間" },
    { attr: "Post33_DataA", name: "後-遭遇困難", },
    { attr: "Post33_DataB", name: "後-解決方式", },
    { attr: "Post33_DataC", name: "後-學到的新思維", },
    { attr: "Post33_UpdateDate", name: "Post33-修改時間" },
    { attr: "Post39_DataA", name: "後-指導老師留言", },
    { attr: "Post39_UpdateDate", name: "Post39-修改時間" },
    { attr: "Post42_DataA", name: "後-提出結論與發現", },
    { attr: "Post42_UpdateDate", name: "Post42-修改時間" },
    { attr: "Post43_DataA", name: "後-比較探究結論", },
    { attr: "Post43_UpdateDate", name: "Post43-修改時間" },
    { attr: "Post44_DataA", name: "後-遭遇困難", },
    { attr: "Post44_DataB", name: "後-解決方式", },
    { attr: "Post44_DataC", name: "後-學到的新思維", },
    { attr: "Post44_UpdateDate", name: "Post44-修改時間" },
    { attr: "Post49_DataA", name: "後-指導老師留言", },
    { attr: "Post49_UpdateDate", name: "Post49-修改時間" },
    { attr: "Post51_DataA", name: "後-更改前探究題目", },
    { attr: "Post51_UpdateDate", name: "Post51-修改時間" },
    { attr: "Post54_DataA", name: "後-參加本活動的省思", },
    { attr: "Post54_UpdateDate", name: "Post54-修改時間" },
    { attr: "Post55_DataA", name: "後-指導老師心得", },
    { attr: "Post55_UpdateDate", name: "Post55-修改時間" },
    { attr: "ExpOpinion", name: "專家學者線上回饋", displayTitle: "專家學者線上回饋" },
    ]

export const getOpinionAttr = (displayTitle: string, name: string) => {
    // let str =""
    opinionMap.map((item) => {

        //    str +=`"${item.name}"|`
    })


    let rs = opinionMap.filter((item) => {
        return item.displayTitle == displayTitle && item.name == name
    })

    if (rs.length === 1) {
        return rs[0]
    } else {
        return null
    }
}

/** 取得目前登入者資料 */
export const getLoginInfo = (): ILoginUserInfo => {

    return {
        LoginStatus: sessionStorage.getItem('LoginStatus')?.trim(),
        LoginName: sessionStorage.getItem('LoginName')?.trim(),
        LoginID: sessionStorage.getItem('LoginID')?.trim()
    } as ILoginUserInfo;
};

/** 設定目前 session
 * params :userInfo
 */
export const setLoginInfoOnSession = (userInfo: any) => {
    sessionStorage.setItem('LoginStatus', userInfo.LoginStatus);
    sessionStorage.setItem('LoginStatus', userInfo.LoginStatus);
    sessionStorage.setItem('LoginName', userInfo.LoginName);
    sessionStorage.setItem('LoginID', userInfo.LoginID);

};

export const setSessionStudentDisplayInfo = (studentDisplay: StudentDisplay) => {

    sessionStorage.setItem('StuNo', studentDisplay.StuNo);
    sessionStorage.setItem('StuName', studentDisplay.StuName);
    sessionStorage.setItem('SchoolName', studentDisplay.SchoolName);
    sessionStorage.setItem('GroupName', studentDisplay.GroupName);
    sessionStorage.setItem('GroupNo', studentDisplay.GroupNo);
};
export interface studentloginInfo {
    StuNo: string,
    StuName: string
    SchoolName: string,
    GroupName: string
    GroupNo: string
}

export const getSessionStudentDisplayInfo = (): studentloginInfo => {

    return {
        StuNo: sessionStorage.getItem('StuNo')?.trim(),
        StuName: sessionStorage.getItem('StuName')?.trim(),
        SchoolName: sessionStorage.getItem('SchoolName')?.trim(),
        GroupName: sessionStorage.getItem('GroupName')?.trim(),
        GroupNo: sessionStorage.getItem('GroupNo')?.trim()

    } as studentloginInfo

};


export const getClassNameForShow = (EableRole: string[], currentRole: string, baseString: string, readonly?: boolean) => {
    // debugger
    if (EableRole.includes(currentRole)) {
        //  let cssString  = 'material-icons pointer ms-2' 
        if (readonly) {

            baseString += " text-gray"
        } else {
            baseString += " text-dblue cursor-pointer "
        }
        return baseString;
    } else {
        return baseString += ' hidden'
    }
};


export const logFunctionName = (...args: any[]) => {
    //@ts-ignore
    const functionName = args.callee?.name || 'Anonymous';
    console.log(`Function name: ${functionName}`);
}



export const replaceNewline = (text: string): string => {

    if (text) {
        const result = text.replace(/\n/g, '<br/>');

        return result;

    } else {

        return ''
    }

};

/** 格式化時間 */
export const formatDateTime = (inputDate: string | any, isAdd8: boolean = true ): string => {
    
    if (!inputDate) {
        return ""; // 如果輸入日期為空，返回空字符串
    }

    // 將日期轉換為台灣時間
    const taiwanTime = new Date(inputDate);
    taiwanTime.setHours(taiwanTime.getHours()) // 資料庫時間不一樣
    if(isAdd8) taiwanTime.setHours(taiwanTime.getHours() - 8); 
    
    // 格式化日期
    const formattedDate = taiwanTime.toLocaleString('zh-TW', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        hour12: true
    });

    // 返回格式化後的日期加上原始輸入的日期
    return formattedDate

};



export const formatDateTime2 = (inputDate: string | any): string => {
    if (!inputDate) {
        return ""; // 如果輸入日期為空，返回空字符串
    }

    // 將日期轉換為台灣時間
    const taiwanTime = new Date(inputDate);
    taiwanTime.setHours(taiwanTime.getHours());

    // 格式化日期
    const formattedDate = taiwanTime.toLocaleString('zh-TW', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        hour12: true
    });

    // 返回格式化後的日期加上原始輸入的日期
    return formattedDate

};
/** 格式化成日期 */
export const formatDate = (inputDate: string | any): string => {
    if (inputDate && inputDate != "1900-01-01T00:00:00.000Z") {
        const originalDate = new Date(inputDate);

        const formattedDate = originalDate.toLocaleDateString('zh-TW', {
            month: '2-digit',
            day: '2-digit'
        });

        return formattedDate;
    } else {
        return '--';
    }
};



export const getFileNameString = (groupInfo: IGroupInfo | TeacherGroupInfo | undefined | any, student: StudentDisplay | undefined): string => {

    if (groupInfo) {
        return `${groupInfo.Schoolname?.trim() || groupInfo.SchoolName?.trim()} - ${groupInfo.GroupNo?.trim() || groupInfo.GroupNo?.trim()}(${groupInfo.Groupname?.trim() || groupInfo.GroupName?.trim()})`
    } else if (student) {
        return `${student.SchoolName.trim()}- ${student.GroupNo.trim()}(${student.GroupName.trim()}) `
    }
    return ''



}


/***
 * 
 */
export const Export2Word = (element: string, filename: string, groupTitleInfo: string): void => {

    const preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = '</body></html>';
    const tableStyle = `
        <style>
            table { border-collapse: collapse; width: 100%; } 
            table, th, td { border: 1px solid black; white-space: pre-line !important; }    
            th, td { text-align: left; }
            h5 { font-size:20px; }  
            #title-info { font-size: 28px !important; display: inline !important; width:80%; } 
        </style> `;

    const mainElement = document.getElementById(element);
    if (mainElement) {
        let elements = mainElement.querySelectorAll('button , .material-icons , .material-icon , .print-remove , .btn')

        mainElement.querySelectorAll('button , .material-icons , .material-icon , .print-remove , .btn , img').forEach(element => {
            element.remove();
        });
    }

    const html = preHtml + tableStyle + (document.getElementById('title-info')?.innerHTML)
        + '<div class="float-right d-inline "  style="text-align: right; font-size :10px">' + formatDateTime2(new Date().toString()) + '</div>' + (mainElement?.innerHTML || '')
        + postHtml;
    const blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
    });

    // Specify link url
    const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    // filename = filename ? filename+'-'+document.getElementById('title-info')?.innerText +formatDateTime2(new Date().toString()) + '.doc' : 'document.doc';
    filename = filename ? filename + ' ' + groupTitleInfo + '-' + formatDateTime2(new Date().toString()) + '.doc' : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    let navigator1 = navigator as any;
    if (navigator1.msSaveOrOpenBlob) {
        navigator1.msSaveOrOpenBlob(blob, filename);
    } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        // triggering the function
        downloadLink.click();
    }

    document.body.removeChild(downloadLink);
};

export interface updateParam { }

const OpinionCode: {
    displayTitle: string
    index: string;
    opinCode: string;
    member: string;
}[] = [
        { displayTitle: "1-1 提出探究題目", index: '11', opinCode: '1_1提出探究題目', member: '1' },
        { displayTitle: "1-2 決定探究題目", index: '12', opinCode: '1_2決定探究題目', member: '0' },
        { displayTitle: "1-3 預測可能的結果", index: '13', opinCode: '1_3探究題目預測結果', member: '0' },
        { displayTitle: "1-4 困難、解決與新學習", index: '14', opinCode: '1_4困難、解決與新學習', member: '0' },
        { displayTitle: "階段1，指導老師留言", index: '19', opinCode: '1_9指導老師留言', member: '0' },
        { displayTitle: "2-3 困難、解決與新學習", index: '24', opinCode: '2_4困難、解決與新學習', member: '0' },
        { displayTitle: "階段2，指導老師留言", index: '29', opinCode: '2_9指導老師留言', member: '0' },
        { displayTitle: "", index: '30', opinCode: '3_0回饋意見後修改', member: '0' },
        { displayTitle: "", index: '33', opinCode: '3_3困難、解決與新學習', member: '0' },
        { displayTitle: "", index: '39', opinCode: '3_9指導老師留言', member: '0' },
        { displayTitle: "", index: '42', opinCode: '4_2提出結論與發現', member: '0' },
        { displayTitle: "", index: '43', opinCode: '4_3比較探究結論', member: '0' },
        { displayTitle: "", index: '44', opinCode: '4_4困難、解決與新學習', member: '0' },
        { displayTitle: "", index: '49', opinCode: '4_9指導老師留言', member: '0' },
        { displayTitle: "", index: '51', opinCode: '5_1更改前探究題目', member: '0' },
        { displayTitle: "", index: '54', opinCode: '5_4參加本活動的省思', member: '0' },
        { displayTitle: "", index: '55', opinCode: '5_5指導老師心得', member: '0' }
    ];

export const geIndexBytOpinionCode = (opinCode: OpinCode): index => {
    const rs = OpinionCode.filter((item) => {
        return item.opinCode === opinCode;
    });
    return rs[0].index as index;
};

/** 取得畫面 title 取得 code */
export const geIndexByDisplayTitle = (displayTitle: string): index | undefined => {
    if (!displayTitle) {
        return undefined
    }
    const rs = OpinionCode.filter((item) => {
        return item.displayTitle === displayTitle;
    });
    return rs[0].index as index;
};

export type stage = '階段1' | '階段2';

export type modalName = '新增探究題目' | '修改探究題目' | '決定探究題目' | '預測可能的結果' | '困難、解決與新學習' | '階段1，指導老師留言' | '新增探究子題' | '修改探究子題' | '修改關鍵字詞' | string;
export type sourceType = 'opinion' | 'subTopic'
export type Action = '新增' | '修改' | '刪除' | '新增或修改' | '';
export type role = 'student' | 'teacher' | 'apply';
export type dataABC = 'DataA' | 'DataB' | 'DataC' | 'SubNo' | 'SubName' | 'Description' | 'Keyword';
export type index = '11' | '12' | '13' | '14' | '19' | '24' | '29' | '30' | '33' | '39' | '42' | '43' | '44' | '49' | '51' | '54' | '55';
export type OpinCode =
    | '1_1提出探究題目'
    | '1_2決定探究題目'
    | '1_3探究題目預測結果'
    | '1_4困難、解決與新學習'
    | '1_9指導老師留言'
    | '2_4困難、解決與新學習'
    | '2_9指導老師留言'
    | '3_0回饋意見後修改'
    | '3_3困難、解決與新學習'
    | '3_9指導老師留言'
    | '4_2提出結論與發現'
    | '4_3比較探究結論'
    | '4_4困難、解決與新學習'
    | '4_9指導老師留言'
    | '5_1更改前探究題目'
    | '5_4參加本活動的省思'
    | '5_5指導老師心得'
    | '修改關鍵字詞'
    | '修改探究子題'
    | '新增探究子題'
    | string;

export interface inputItems {
    inputLable: string;
    type: string;
    attrArefix: string;
    dataABC: dataABC;
    regex: RegExp | any;
    validateWord: string;
    placeholder?: string;
    disable?: boolean
    checkSection?: boolean
    readOnly?: boolean;
}


/** 顯示ModalConfig*/
export interface IOpinionForModal {
    /** 資料來源 */
    sourceType: sourceType;
    /** */
    opinCode: OpinCode;
    /** 階段 */
    stage: stage;
    /** 畫面顯示前標 */
    viewOrder: string;
    /** 彈跳視窗名稱 title */
    modalName: modalName;
    /**  */
    page: string;
    /**  */
    AttrPrefix: string;
    /**  */
    inputNum: number;
    /** triggeer 的 Name  */
    triggerButtonName: string;
    /** 動作  { '新增' | '修改' | '刪除'}*/
    action: Action; // 判斷要呼叫哪一支api
    /** 角色 */
    role: role;
    /** 顯示 title  */
    modalTitleDisplay?: string;
    /** 回饋前 回饋後 */
    isfeedback: 0 | 1;
    /**  */
    index: index;
    /**  */
    inputItems: inputItems[];
}

/** */
export interface IsubTopicForModal {
    opinCode: OpinCode;
    sourceType: sourceType;
    modalName: modalName;
    modalTitleDisplay: string;
    isfeedback: 0 | 1;
    action: Action;
    page: string;
    triggerButtonName: '';
    role: role;
    inputItems: inputItems[];
    index?: index;
}
/** 模型 */
const ModalConfigsBefore: (IOpinionForModal | IsubTopicForModal)[] = [
    {
        sourceType: 'opinion',
        opinCode: '1_1提出探究題目',
        index: geIndexBytOpinionCode('1_1提出探究題目'),
        stage: '階段1',
        viewOrder: '1-1', //key
        modalName: '新增探究題目',
        modalTitleDisplay: '新增探究題目',
        action: '新增',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',
        AttrPrefix: 'Pre11',
        inputNum: 2,
        isfeedback: 0,

        inputItems: [
            { inputLable: '我想探究的題目', type: 'input', attrArefix: '11', dataABC: 'DataA', regex: /^(?!\s*$).{1,100}$/, validateWord: '不得空白，最多100個字元', placeholder: '最多100個字元' },
            { inputLable: '想探究這個題目的理由', type: 'textarea', attrArefix: '11', dataABC: 'DataB', regex: /\S/, validateWord: '不可以空白' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_1提出探究題目',
        stage: '階段1',
        viewOrder: '1-1', //key
        modalName: '修改探究題目',
        modalTitleDisplay: '修改探究題目',
        action: '修改',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',
        AttrPrefix: 'Pre11',
        inputNum: 2,
        isfeedback: 0,
        index: geIndexBytOpinionCode('1_1提出探究題目'),

        inputItems: [
            { inputLable: '我想探究的題目', type: 'input', attrArefix: '11', dataABC: 'DataA', regex: /^(?!\s*$).{1,100}$/, validateWord: '不得空白，最多100個字元', placeholder: '最多100個字元' },
            { inputLable: '想探究這個題目的理由', type: 'textarea', attrArefix: '11', dataABC: 'DataB', regex: /\S/, validateWord: '' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_2決定探究題目',
        index: '12',
        stage: '階段1',
        viewOrder: '1-2',
        modalName: '決定探究題目',
        modalTitleDisplay: '決定探究題目',
        triggerButtonName: '',
        role: 'student',
        page: 'epStudLevel1.tsx',
        AttrPrefix: 'Pre12',
        action: '新增或修改',
        isfeedback: 0,
        inputNum: 2,
        inputItems: [
            { inputLable: '我們決定探究的題目', type: '', attrArefix: '12', dataABC: 'DataA', regex: /^[^\s]{1,300}$/, validateWord: '' },
            { inputLable: '決定原因', type: '', attrArefix: '12', dataABC: 'DataB', regex: /^[^\s]{1,300}$/, validateWord: '' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_3探究題目預測結果',
        index: '13',
        stage: '階段1',
        role: 'student',
        triggerButtonName: '',
        viewOrder: '1-3',
        modalTitleDisplay: '預測可能的結果',
        modalName: '預測可能的結果',
        AttrPrefix: 'Pre13',
        action: '新增或修改',
        isfeedback: 0,
        page: 'epStudLevel1.tsx',
        inputNum: 1,
        inputItems: [{ inputLable: '我們預測可能的結果是', type: '', attrArefix: '13', dataABC: 'DataB', regex: '', validateWord: '' }]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_4困難、解決與新學習',
        stage: '階段1',
        role: 'student',
        triggerButtonName: '',
        viewOrder: '1-4',
        modalTitleDisplay: '困難、解決與新學習',
        modalName: '困難、解決與新學習',
        AttrPrefix: 'Pre14',
        index: '14',
        action: '新增或修改',
        isfeedback: 0,
        page: 'epStudLevel1.tsx',
        inputNum: 1,
        inputItems: [
            { inputLable: '我們在「階段1」遇到的困難', type: '', attrArefix: '14', dataABC: 'DataA', regex: '', validateWord: '' },
            { inputLable: '我們最後如何解決', type: '', attrArefix: '14', dataABC: 'DataB', regex: '', validateWord: '' },
            { inputLable: '我們學到的新思維', type: '', attrArefix: '14', dataABC: 'DataC', regex: '', validateWord: '' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '2_4困難、解決與新學習',
        modalName: '困難、解決與新學習',
        stage: '階段1',
        triggerButtonName: '',
        role: 'student',
        viewOrder: '2-4',
        modalTitleDisplay: '2-3困難、解決與新學習',
        AttrPrefix: '',
        index: '24',
        action: '新增或修改',
        page: 'epStudLevel1.tsx',
        inputNum: 3,
        isfeedback: 0,
        inputItems: [
            { inputLable: '我們在「階段1」遇到的困難', type: '', attrArefix: '24', dataABC: 'DataA', regex: '', validateWord: '' },
            { inputLable: '我們最後如何解決', type: '', attrArefix: '24', dataABC: 'DataB', regex: '', validateWord: '' },
            { inputLable: '我們學到的新思維', type: '', attrArefix: '24', dataABC: 'DataC', regex: '', validateWord: '' }
        ]
    },

    {
        sourceType: 'opinion',
        opinCode: '1_9指導老師留言',
        modalName: '階段1，指導老師留言',
        stage: '階段1',
        triggerButtonName: '',
        role: 'teacher',
        viewOrder: '1-4',
        modalTitleDisplay: 'Pre14',
        AttrPrefix: '',
        index: '14',
        action: '新增',
        page: 'epStudLevel1.tsx',
        inputNum: 3,
        isfeedback: 0,
        inputItems: [{ inputLable: '我們在「階段1」遇到的困難', type: '', attrArefix: '12', dataABC: 'DataA', regex: '', validateWord: '' }]
    },
    {
        opinCode: '新增探究子題',
        sourceType: 'subTopic',
        modalName: '新增探究子題',
        modalTitleDisplay: '新增探究子題',
        isfeedback: 0,
        action: '新增',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',

        inputItems: [
            { inputLable: '子題名稱', type: 'input', attrArefix: '', dataABC: 'DataB', regex: /^(?!\s*$).{1,50}$/, validateWord: '不可空白或不可超過50字' },
            { inputLable: '這個子題由誰負責探究(可多人)', type: 'input', attrArefix: '', dataABC: 'Description', regex: '', validateWord: '', checkSection: true }
        ]
    },
    {
        opinCode: '修改探究子題',
        sourceType: 'subTopic',
        modalName: '修改探究子題',
        modalTitleDisplay: '修改探究子題',
        isfeedback: 0,
        action: '修改',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',

        inputItems: [
            { inputLable: '子題編號', type: 'input', attrArefix: '', dataABC: 'SubNo', regex: '', validateWord: '', readOnly: true },
            { inputLable: '子題名稱', type: 'input', attrArefix: '', dataABC: 'SubName', regex: '', validateWord: '' },
            { inputLable: '這個子題由誰負責探究(可多人)', type: 'input', attrArefix: '', dataABC: 'Description', regex: '', validateWord: '', checkSection: true }
        ]
    }


];



/** 回饋後題目 */
const ModalConfigsAfter: (IOpinionForModal | IsubTopicForModal)[] = [
    {
        sourceType: 'opinion',
        opinCode: '1_1提出探究題目',
        index: geIndexBytOpinionCode('1_1提出探究題目'),
        stage: '階段1',
        viewOrder: '1-1', //key
        modalName: '新增探究題目',
        modalTitleDisplay: '新增探究題目',
        action: '新增',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',
        AttrPrefix: 'Post11',
        inputNum: 2,
        isfeedback: 1,

        inputItems: [
            { inputLable: '我想探究的題目', type: 'input', attrArefix: '11', dataABC: 'DataA', regex: /^(?!\s*$).{1,100}$/, validateWord: '不得空白，最多100個字元', placeholder: '最多100個字元' },
            { inputLable: '想探究這個題目的理由', type: 'textarea', attrArefix: '11', dataABC: 'DataB', regex: /\S/, validateWord: '不可以空白' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_1提出探究題目',
        stage: '階段1',
        viewOrder: '1-1', //key
        modalName: '修改探究題目',
        modalTitleDisplay: '修改探究題目',
        action: '修改',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',
        AttrPrefix: 'Post11',
        inputNum: 2,
        isfeedback: 1,
        index: geIndexBytOpinionCode('1_1提出探究題目'),

        inputItems: [
            { inputLable: '我想探究的題目', type: 'input', attrArefix: '11', dataABC: 'DataA', regex: /^(?!\s*$).{1,100}$/, validateWord: '不得空白，最多100個字元', placeholder: '最多100個字元' },
            { inputLable: '想探究這個題目的理由', type: 'textarea', attrArefix: '11', dataABC: 'DataB', regex: /\S/, validateWord: '' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_2決定探究題目',
        index: '12',
        stage: '階段1',
        viewOrder: '1-2',
        modalName: '決定探究題目',
        modalTitleDisplay: '決定探究題目',
        triggerButtonName: '',
        role: 'student',
        page: 'epStudLevel1.tsx',
        AttrPrefix: 'Post12',
        action: '新增或修改',
        isfeedback: 1,
        inputNum: 2,
        inputItems: [
            { inputLable: '我們決定探究的題目', type: '', attrArefix: '12', dataABC: 'DataA', regex: /^[^\s]{1,300}$/, validateWord: '' },
            { inputLable: '決定原因', type: '', attrArefix: '12', dataABC: 'DataB', regex: /^[^\s]{1,300}$/, validateWord: '' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_3探究題目預測結果',
        index: '13',
        stage: '階段1',
        role: 'student',
        triggerButtonName: '',
        viewOrder: '1-3',
        modalTitleDisplay: '預測可能的結果',
        modalName: '預測可能的結果',
        AttrPrefix: 'Post13',
        action: '新增或修改',
        isfeedback: 1,
        page: 'epStudLevel1.tsx',
        inputNum: 1,
        inputItems: [{ inputLable: '我們預測可能的結果是', type: '', attrArefix: '13', dataABC: 'DataB', regex: '', validateWord: '' }]
    },
    {
        sourceType: 'opinion',
        opinCode: '1_4困難、解決與新學習',
        stage: '階段1',
        role: 'student',
        triggerButtonName: '',
        viewOrder: '1-4',
        modalTitleDisplay: '困難、解決與新學習',
        modalName: '困難、解決與新學習',
        AttrPrefix: 'Post14',
        index: '14',
        action: '新增或修改',
        isfeedback: 1,
        page: 'epStudLevel1.tsx',
        inputNum: 1,
        inputItems: [
            { inputLable: '我們在「階段1」遇到的困難', type: '', attrArefix: '14', dataABC: 'DataA', regex: '', validateWord: '' },
            { inputLable: '我們最後如何解決', type: '', attrArefix: '14', dataABC: 'DataB', regex: '', validateWord: '' },
            { inputLable: '我們學到的新思維', type: '', attrArefix: '14', dataABC: 'DataC', regex: '', validateWord: '' }
        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '2_4困難、解決與新學習',
        modalName: '困難、解決與新學習',
        stage: '階段1',
        triggerButtonName: '',
        role: 'student',
        viewOrder: '2-4',
        modalTitleDisplay: '2-3困難、解決與新學習',
        AttrPrefix: '',
        index: geIndexBytOpinionCode('2_4困難、解決與新學習'),
        action: '新增或修改',
        page: 'epStudLevel1.tsx',
        inputNum: 3,
        isfeedback: 1,
        inputItems: [
            { inputLable: '我們在「階段1」遇到的困難', type: '', attrArefix: '24', dataABC: 'DataA', regex: '', validateWord: '' },
            { inputLable: '我們最後如何解決', type: '', attrArefix: '24', dataABC: 'DataB', regex: '', validateWord: '' },
            { inputLable: '我們學到的新思維', type: '', attrArefix: '24', dataABC: 'DataC', regex: '', validateWord: '' }
        ]
    },

    {
        sourceType: 'opinion',
        opinCode: '1_9指導老師留言',
        modalName: '階段1，指導老師留言',
        stage: '階段1',
        triggerButtonName: '',
        role: 'teacher',
        viewOrder: '1-4',
        modalTitleDisplay: '階段1，指導老師留言',
        AttrPrefix: '',
        index: geIndexBytOpinionCode('1_9指導老師留言'),
        action: "新增或修改",
        page: 'epStudLevel1.tsx',
        inputNum: 1,
        isfeedback: 1,
        inputItems: [{ inputLable: '老師留言', type: '', attrArefix: '12', dataABC: 'DataA', regex: '', validateWord: '' }]
    },
    {
        opinCode: '新增探究子題',
        sourceType: 'subTopic',
        modalName: '新增探究子題',
        modalTitleDisplay: '新增探究子題',
        isfeedback: 1,
        action: '新增',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',

        inputItems: [
            { inputLable: '子題名稱', type: 'input', attrArefix: '', dataABC: 'DataB', regex: /^(?!\s*$).{1,50}$/, validateWord: '不可空白或不可超過50字' },
            { inputLable: '這個子題由誰負責探究(可多人)', type: 'input', attrArefix: '', dataABC: 'Description', regex: '', validateWord: '', checkSection: true }
        ]
    },
    {
        opinCode: '修改探究子題',
        sourceType: 'subTopic',
        modalName: '修改探究子題',
        modalTitleDisplay: '修改探究子題',
        isfeedback: 1,
        action: '修改',
        page: 'epStudLevel1',
        triggerButtonName: '',
        role: 'student',

        inputItems: [
            { inputLable: '子題編號', type: 'input', attrArefix: '', dataABC: 'SubNo', regex: '', validateWord: '', readOnly: true },
            { inputLable: '子題名稱', type: 'input', attrArefix: '', dataABC: 'SubName', regex: '', validateWord: '' },
            { inputLable: '這個子題由誰負責探究(可多人)', type: 'input', attrArefix: '', dataABC: 'Description', regex: '', validateWord: '', checkSection: true }
        ]
    }, {
        opinCode: '修改關鍵字詞',
        sourceType: 'subTopic',
        modalName: '修改關鍵字詞',
        modalTitleDisplay: '修改關鍵字詞',
        isfeedback: 1,
        action: '修改',
        page: 'epStudLevel3',
        triggerButtonName: '',
        role: 'student',

        inputItems: [
            { inputLable: '子題編號', type: 'input', attrArefix: '', dataABC: 'SubNo', regex: '', validateWord: '', readOnly: true },
            { inputLable: '子題名稱', type: 'input', attrArefix: '', dataABC: 'SubName', regex: '', validateWord: '', readOnly: true },
            { inputLable: '這個子題由誰負責探究(可多人)', type: 'input', attrArefix: '', dataABC: 'Description', regex: '', validateWord: '', readOnly: true },
            { inputLable: '關鍵字詞(每個關鍵字詞之間用小寫逗號(,)分開)', type: 'input', attrArefix: '', dataABC: 'Keyword', regex: '', validateWord: '' },
        ]
    }
    , {
        opinCode: '3_3困難、解決與新學習',
        index: geIndexBytOpinionCode('3_3困難、解決與新學習'),
        sourceType: 'opinion',
        modalName: '困難、解決與新學習',
        modalTitleDisplay: '困難、解決與新學習',
        isfeedback: 1,
        action: '新增或修改',
        page: 'epStudLevel3',
        triggerButtonName: '',
        role: 'student',
        inputItems: [
            { inputLable: '我們在「階段3」遇到的困難', type: '', attrArefix: '', dataABC: 'DataA', regex: '', validateWord: '', readOnly: true },
            { inputLable: '我們最後如何解決', type: '', attrArefix: '', dataABC: 'DataB', regex: '', validateWord: '', readOnly: true },
            { inputLable: '我們學到的新思維', type: '', attrArefix: '', dataABC: 'DataC', regex: '', validateWord: '', readOnly: true },
        ]
    },
    {
        opinCode: '3_0回饋意見後修改',
        index: geIndexBytOpinionCode('3_0回饋意見後修改'),
        sourceType: 'opinion',
        modalName: '編輯我們不修改、修改或重做的原因',
        modalTitleDisplay: '編輯我們不修改、修改或重做的原因',
        isfeedback: 1,
        action: '新增或修改',
        page: 'epStudLevel3',
        triggerButtonName: '',
        role: 'student',
        inputItems: [
            { inputLable: '原因', type: '', attrArefix: '', dataABC: 'DataA', regex: '', validateWord: '', readOnly: true },

        ]
    },
    {
        opinCode: '4_2提出結論與發現',
        index: geIndexBytOpinionCode('4_2提出結論與發現'),
        sourceType: 'opinion',
        modalName: '編輯提出探究題目的結論',
        modalTitleDisplay: '編輯提出探究題目的結論',
        isfeedback: 1,
        action: '新增或修改',
        page: 'epStudLevel3',
        triggerButtonName: '',
        role: 'student',
        inputItems: [
            { inputLable: '提出結論或發現', type: 'textarea', attrArefix: '', dataABC: 'DataB', regex: '', validateWord: '', readOnly: true },
            { inputLable: '我們的結論可能的限制', type: 'textarea', attrArefix: '', dataABC: 'DataC', regex: '', validateWord: '', readOnly: true },
        ]
    },
    {
        opinCode: '4_3比較探究結論',
        index: geIndexBytOpinionCode('4_3比較探究結論'),
        sourceType: 'opinion',
        modalName: '與最後的結論相比較',
        modalTitleDisplay: '較探究結論與先前的預測',
        isfeedback: 1,
        action: '新增或修改',
        page: 'epStudLevel3',
        triggerButtonName: '',
        role: 'student',
        inputItems: [
            { inputLable: '與最後的結論相比較', type: '', attrArefix: '', dataABC: 'DataB', regex: '', validateWord: '', readOnly: true },

        ]
    },
    {
        opinCode: '4_4困難、解決與新學習',
        index: geIndexBytOpinionCode('4_4困難、解決與新學習'),
        sourceType: 'opinion',
        modalName: '困難、解決與新學習',
        modalTitleDisplay: '困難、解決與新學習',
        isfeedback: 1,
        action: '新增或修改',
        page: 'epStudLevel3',
        triggerButtonName: '',
        role: 'student',
        inputItems: [
            { inputLable: '我們在「階段4」遇到的困難', type: '', attrArefix: '', dataABC: 'DataA', regex: '', validateWord: '', readOnly: true },
            { inputLable: '我們最後如何解決：', type: '', attrArefix: '', dataABC: 'DataB', regex: '', validateWord: '', readOnly: true },
            { inputLable: '我們學到的新思維：', type: '', attrArefix: '', dataABC: 'DataC', regex: '', validateWord: '', readOnly: true },

        ]
    },
    {
        sourceType: 'opinion',
        opinCode: '2_9指導老師留言',
        modalName: '階段2，指導老師留言',
        stage: '階段1',
        triggerButtonName: '',
        role: 'teacher',
        viewOrder: '1-4',
        modalTitleDisplay: '階段2，指導老師留言',
        AttrPrefix: '',
        index: geIndexBytOpinionCode('2_9指導老師留言'),
        action: '新增或修改',
        page: 'epStudLevel1.tsx',
        inputNum: 3,
        isfeedback: 1,
        inputItems: [{ inputLable: '老師留言', type: '', attrArefix: '12', dataABC: 'DataA', regex: '', validateWord: '' }]
    }, {
        sourceType: 'opinion',
        opinCode: '4_9指導老師留言',
        modalName: '階段4，指導老師留言',
        stage: '階段1',
        triggerButtonName: '',
        role: 'teacher',
        viewOrder: '1-4',
        modalTitleDisplay: '階段4，指導老師留言',
        AttrPrefix: '',
        index: geIndexBytOpinionCode('4_9指導老師留言'),
        action: '新增或修改',
        page: 'epStudLevel1.tsx',
        inputNum: 3,
        isfeedback: 1,
        inputItems: [{ inputLable: '老師留言', type: '', attrArefix: '12', dataABC: 'DataA', regex: '', validateWord: '' }]
    },
    {
        sourceType: 'opinion',
        opinCode: '3_9指導老師留言',
        modalName: '階段3，指導老師留言',
        stage: '階段1',
        triggerButtonName: '',
        role: 'teacher',
        viewOrder: '1-4',
        modalTitleDisplay: '階段3，指導老師留言',
        AttrPrefix: '',
        index: geIndexBytOpinionCode('3_9指導老師留言'),
        action: '新增或修改',
        page: 'epStudLevel1.tsx',
        inputNum: 3,
        isfeedback: 1,
        inputItems: [{ inputLable: '老師留言', type: '', attrArefix: '12', dataABC: 'DataA', regex: '', validateWord: '' }]
    },
    {
        sourceType: 'opinion',
        opinCode: '5_5指導老師心得',
        modalName: '階段5 指導老師心得',
        stage: '階段1',
        triggerButtonName: '',
        role: 'teacher',
        viewOrder: '1-4',
        modalTitleDisplay: '階段5 指導老師心得',
        AttrPrefix: '',
        index: geIndexBytOpinionCode('5_5指導老師心得'),
        action: '新增或修改',
        page: 'epStudLevel1.tsx',
        inputNum: 1,
        isfeedback: 1,
        inputItems: [{ inputLable: '老師留言', type: '', attrArefix: '12', dataABC: 'DataA', regex: '', validateWord: '' }]
    },
];


/**
 *依
 * @param stage
 * @returns
 */

export const getModalConfigs = (opinCode: OpinCode | string, action: Action, isfeedback: 0 | 1): IOpinionForModal | IsubTopicForModal | undefined => {
    let target: IsubTopicForModal | IOpinionForModal | undefined = undefined

    if (isfeedback === 0) {
        target = ModalConfigsBefore.find((item) => {
            return item.opinCode === opinCode && item.action === action && item.isfeedback === isfeedback;
        });


    } else if (isfeedback === 1) {

        target = ModalConfigsAfter.find((item) => {

            return item.opinCode === opinCode && item.action === action && item.isfeedback === isfeedback;
        });

        alertEx(target)
    }

    return target;
};


/** 比較陣列裡面是相同物件是否有想同 */
export const matchAtt = (arrayItem: any, attrName: string, matchTarget: string | any) => {
    if (!arrayItem || !matchTarget || !attrName) return false

    return arrayItem[attrName] === matchTarget


}


/** 取得opinion */
export const fetchDataOpinion = async (groupNo: string | undefined, setOpinion: React.Dispatch<React.SetStateAction<IOpinion | undefined>>) => {

    if (groupNo) {
        try {
            let opinion = (await getOpinionByGroupNo(groupNo.trim())) as IOpinion[];

            setOpinion(opinion[0]);


        } catch (ex) {
            alert("ex" + JSON.stringify(ex))
        }
    }
};

export const fetchDataSubTopic = async (groupNo: string, setSubTopicBeforeFeedbackList: React.Dispatch<React.SetStateAction<IsubTopic[]>>) => {
    //取得回饋前 子題
    if (groupNo) {
        let subTopicbefore = (await getSubTopicByGroupNo(0, groupNo)) as IsubTopic[];
        setSubTopicBeforeFeedbackList(subTopicbefore);

    }

};




/** 顯示開發中必要資訊 */
export const devSection = (arrg: any[]) => {
    const myStyle = {
        position: 'fixed',
        fontWeight: 'bold',
        top: '0px',
        right: '0px',
        float: "right",
        zIndex: "9999",
        background: "#96E9C6",
        padding: '10px',
        height: '200px'
    } as React.CSSProperties;

    return (arrg.map(item => (
        <>
            <div style={myStyle}>
                {JSON.stringify(item)}
                <hr />
            </div>
        </>)))
        ;
};




/** 以專家身分取得組員資料  */
export const fetchdataGroupInfo = async (setCurrentLoginInfo: (value: any) => void, setAllGroup: (value: any) => void) => {
    /** 取得登入資料 */

    let currentLoginInfo = getLoginInfo();
    console.log('目前登入資訊', currentLoginInfo);
    setCurrentLoginInfo(currentLoginInfo);

    try {
        let classNameList: string[] = [];
        let groupNameList: string[] = [];
        groupNameList.push('全部組別');
        let schoolNameList: string[] = [];
        schoolNameList.push('無');
        let rsp = (await getAllDrGroup()) as IGroupForExpertS[];
        setAllGroup(rsp)
        console.log('取得專家的隊伍...', rsp);
        return rsp
    } catch (error) { }
    alert("發生錯誤!")
};


export const exportData = (selector: string, filename: string) => {
    var tableSelect = document.getElementById(selector);
    var newTable = document.createElement("table");

    if (tableSelect) {
        newTable.innerHTML = tableSelect.innerHTML;
        //  顯示文字描述全文
        var tds = newTable.querySelectorAll('td[data]:not([data=""])');
        for (var i = 0; i < tds.length; i++) {
            (tds[i] as HTMLElement).innerText = (tds[i] as HTMLElement).title;
        }

        //  表格畫框線、文字置中對齊
        newTable.setAttribute('style', 'border:1px solid #dee2e6; text-align:center; border-collapse:collapse; width : 1200px');

        // 將新表格轉換為Blob對象
        var blob = new Blob([newTable.outerHTML], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        // 下載的檔案名稱
        var filename = filename;

        // 使用Blob URL創建下載鏈接
        var downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);

        // 設置下載的檔案名稱
        downloadLink.download = filename;

        // 觸發點擊下載鏈接
        downloadLink.click();
    } else {
        console.error(`Table with id "${selector}" not found`);
    }
}


interface TableToExcelProps {
    tableId: string;
    fileName: string;
}


export const exportToExcel = (tableId: string, fileName: string) => {
    const tableSelect: HTMLElement | null = document.getElementById(tableId);

    if (tableSelect) {
        // 创建工作簿
        const wb: XLSX.WorkBook = XLSX.utils.table_to_book(tableSelect);

        // 导出 Excel 文件
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    } else {
        console.error(`Table with id "${tableId}" not found`);
    }
};




export const exportTableToExcel = async (tableId: string, filename: string) => {
    // 获取表格元素
    const table = document.getElementById(tableId);
    if (!table) {
        console.error(`Table with id "${tableId}" not found`);
        return;
    }

    // 创建一个新的工作簿
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 从表格中抓取数据并填充工作表
    const rows = table.querySelectorAll('tr');
    rows.forEach(row => {
        const rowData: any[] = [];
        row.querySelectorAll('td').forEach(cell => {
            rowData.push(cell.innerText);
        });
        worksheet.addRow(rowData);
    });

    // 将工作簿写入文件
    await workbook.xlsx.writeFile(`${filename}.xlsx`);
    console.log(`Excel file "${filename}.xlsx" created successfully`);
};




export const downloadImage = (imagePath: string, imageName: string): void => {
    fetch(imagePath)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', imageName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => console.error('下載檔案失敗', error));
};



export const alertEx = (all: any, lable?: string) => {

    if (modeGlobal !== '開發') { return }
    console.log("target", all)
    // alert( lable||"除錯物件:"+JSON.stringify(all)) ;
}


interface FormData {
    [key: string]: string;
}



/** 送出前驗證欄位 */
export const validateSubmit = (e?: React.FormEvent<HTMLFormElement>) => {


    const inputs = Array.from(document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input ,textarea'));
    debugger
    // const formDataObj: Partial<FormData> = {};
    const errorMsgs: string[] = []


    inputs.forEach(input => {
        const pattern = input.dataset.pattern || '';
        const colname = input.dataset.colname || '';
        const placeholder = input.getAttribute('placeholder') || '';
        const value = input.value;

        // 根据 data-pattern 属性执行相应的验证逻辑
        if (new RegExp(pattern).test(value)) {

            // formDataObj[input?.name] = value;
            console.log(`输入NameA ${input.name}/${colname}/${placeholder}/${pattern}`);

        } else {
            // 处理验证失败的情况

            errorMsgs.push(`${input.name}:${placeholder}`)
            console.log(`输入字段 ${input.name} 的值 ${value} 不符合规则`);
        }
    });


    return errorMsgs

    // 在这里使用 formDataObj 来进行进一步的处理

};

export const getcontrol = async (setCtrlInfoList: (crtl: crtlInfo[]) => void) => {
    const crtl = (await getDrControlByClassNow(currentClassForBtn)) as crtlInfo[];
    setCtrlInfoList(crtl);
};


export const getCtrl = async (context: React.Context<MyContextType | undefined>) => {


}



export const validate = (target: IOpinionForModal | IsubTopicForModal | undefined, formatDate: paramsCreateOpinionSubTopic, source: string): boolean => {

    let errMsg = '';
    let result = true;
    if (!target) { return false }
    target.inputItems.forEach((item) => {
        let reg = item.regex;
        if (!reg) {
            return;
        }
        const isPass = reg.test(formatDate[item.dataABC].trim());
        if (!isPass) {
            result = false;

            errMsg += item.inputLable + ':' + item.validateWord;
            errMsg += '\n';
        }
    });

    debugger;
    // 顯示錯誤訊息

    // errMsg = '';
    if (errMsg) {
        alert(errMsg);
    }
    return result;
};


/** */
export const showJsonObj = (objArray: any[]|any) => {

    return JSON.stringify(objArray)
    // 检查是否有对象数组
  
};

export const logOut = () => {
    sessionStorage.clear() ;
    handleLink('/eptest');
    return 
}


export const getStart = (num: string) => {
    let number = parseInt(num);
    if (number === 5) {
        return '★★★★★';
    }
    if (number === 4) {
        return '★★★★';
    }
    if (number === 3) {
        return '★★★';
    }
    if (number === 2) {
        return '★★';
    }
    if (number === 1) {
        return '★';
    }
    return '';
};
// 階段二中角國小         測試小隊           (GC003-01)2024_04_12 上午01_24
export const getFileName = (obj: any) => {

    // GC003-01-3
}


export const TutorialModal = ({ id, title, src, href }: any) => (
    <div className="modal fade" id={id} tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
            <div className="modal-content mx-3">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>&times;</span>
                    </button>
                </div>
                <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                    <iframe src={src} className="w-100 border-radius-lg" style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }} allowFullScreen></iframe>
                    <div>
                        <a href={href} title={title} target="_blank">
                            {title}
                        </a>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                </div>
            </div>
        </div>
    </div>
);
