import axios, { AxiosError } from 'axios'
import  config from '../../config/config';
import { IInsertApplyGroup, IUpdateApplyGroup, IUpdateApplyStudent } from '../../interfaces/EpModal';

// 以專家帳號取得年度分配之通過審核的參賽小組、題目(未回饋)、專家回饋以年度及組別排序
// Get DrGroup by Expert Account and Class order by year and type
// Get /api/DrGroup/allgroups/class/:class/account/:account
// const getAllDrGroupByClassExpert = asyncHandler(async(req: Request, res: Response) => {
    /** 取得專家  */
    export const getAllDrGroupByClassExpert = async  (className :string ,account:string)=>{
        const url=config.server.url+`/api/DrGroup/allgroups/class/${className}/account/${account}`
        console.log("url:"+url)
            return new Promise((resolve, reject) => {
              axios.get(url)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                  reject(error); //
                });
            });
      
      
      }



      // 取得所有通過審核的參賽小組、題目(未回饋)、專家回饋以年度及組別排序
// Get DrGroup order by year and type
// Get /api/DrGroup/allgroups
// const getAllDrGroup = asyncHandler(async(req: Request, res: Response) => {

export const getAllDrGroup = async  ()=>{
    const url=config.server.url+`/api/DrGroup/allgroups`
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }



  // 更新專家回饋意見
// Update ExpOpinion and ExpMemo by groupno
// Put /api/DrGroup/expopinion/groupno/:groupno
/*
req.body=
{
  "ExpOpinion":"專家意見",
  "ExpMemo":"專家備註"
}
*/

// const updateDrGroupExpOpinion = asyncHandler(async(req: Request, res: Response) => {
  export const updateDrGroupExpOpinion = async (param:{ExpOpinion:string ,ExpMemo?:string } ,groupno :string)=>{
      const url=config.server.url+`/api/DrGroup/expopinion/groupno/${groupno}`
      console.log('url',url)
      return new Promise((resolve, reject) => {
          axios.put(url,param)
            .then(response => {
              console.log("getLoginInfoByAccountAndPass ",response.data);
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });

  }
  