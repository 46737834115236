import React, { useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import { handleLink } from '../../modules/utils';
import { getExStudentByGmail, getExStudentByStuNoPwd, getExTeacherByGmail, getExTeacherByTNoPwd, updateGmailExStudent, updateGmailExTeacher } from '../../services/ExStuTeService';
import FooterPage from '../footer';
import { BSModal } from '../../components/modal/bsmodal';
import { getExStuTestCntBySid } from '../../services/ExStuRecService';
import { IExStudent } from '../../interfaces/ExStudent';
import { getMyip } from '../../services/AdSessionService';
import { addAdLogin } from '../../services/AdLoginService';
import { IExTeacher } from '../../interfaces/ExTeacher';
import { ObjectModal } from '../../components/modal/objectmodal';

const ReLoginGooglePage: React.FunctionComponent<IPageProps> = (props) => {
    const [account, setAccount] = useState('');
    const [pwd, setPwd] = useState('');
    const [pressed, setPressed] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [loginStu, setLoginStu] = useState<IExStudent>();
    const [loginTea, setLoginTea] = useState<IExTeacher>();
    const [openObjectModal,setOpenObjectModal]=useState(false);
    const [googleProfile, setGoogleProfile] = useState<{
        uuid: string;
        firstName: string;
        lastName: string;
        language: string;
        mail: string;
    }>({
        uuid: '',
        firstName: '',
        lastName: '',
        language: '',
        mail: '',
    });
    const [quit, setQuit] = useState(false);

        // 取得Session中的Google資料

                
    const handleAccInp = (e: React.FormEvent<HTMLInputElement>) => {
        setAccount(e.currentTarget.value);
    };
    const handlePwdInp = (e: React.FormEvent<HTMLInputElement>) => {
        setPwd(e.currentTarget.value);
    };

    // LoginState in session variables
    // 0: guest
    // 1: student
    // 2: teacher
   //Jean-ref
    const chkLogin = () => {
        if (account !== '' && pwd !== '') {
            //setPressed(true);
            chkAccPwd();
            //handleLink("/");
        } else {
            setOpenModal(true);
            if (account === '' && pwd === '') {
                setErrMsg('帳號及密碼不可空白');
                setPressed(false);
            } else {
                if (account === '') setErrMsg('帳號不可空白');
                
                if (pwd === '') setErrMsg('密碼不可空白');
                setPressed(false);
            }
            //toggleModal();
        }
    };


    const  chkAccPwd = async () => {
        const stu=await getExStudentByStuNoPwd(account.toLowerCase().trim(), pwd.trim());
        const te=await getExTeacherByTNoPwd(account.toLowerCase().trim(), pwd.trim());
        if((stu===undefined)&& (te===undefined)) //沒有帳號
        {
            sessionStorage.setItem('LoginStatus', '0');
            sessionStorage.setItem('LoginID', JSON.stringify(null));
            sessionStorage.setItem('LoginSchoolNo', JSON.stringify(null));
            sessionStorage.setItem('LoginStudent', JSON.stringify(null));
            sessionStorage.setItem('LearnRec',JSON.stringify(null));
            sessionStorage.setItem('LoginTeacher', JSON.stringify(null));
            handleLink('/');
        }
        if((stu!==undefined)&& (te===undefined)) //登入學生帳號
        {
            sessionStorage.setItem('LoginStatus', '1');
            sessionStorage.setItem('LoginID', stu.Sid.toString());
            sessionStorage.setItem('LoginSchoolNo', stu.SchoolNo);
            sessionStorage.setItem('LoginStudent', JSON.stringify(stu));
            sessionStorage.setItem('LearnRec', JSON.stringify(await getExStuTestCntBySid(stu.Sid)));

            sessionStorage.setItem('LoginTeacher', JSON.stringify(null));

            getMyip().then(data => {
                if (data.ip !== '::1') {
                    const ippart=data.ip.split(":")
                    addAdLogin(stu.Sid.toString(),'E',ippart[3]);
                }
            })
            updateGmailExStudent(stu.Sid.toString(), googleProfile.mail.trim());
            stu.Gmail = googleProfile.mail.trim();
            setLoginStu(stu);
            sessionStorage.setItem('LoginStudent', JSON.stringify(stu));
            handleLink('/');
        }

        if((stu===undefined)&& ((te!==undefined) || (te!==null))) //登入教師帳號
        {
            sessionStorage.setItem('LoginStatus', '2');
            sessionStorage.setItem('LoginID', te.Tid.toString());
            sessionStorage.setItem('LoginSchoolNo', te.SchoolNo);
            sessionStorage.setItem("LoginTeacher",JSON.stringify(te));

            sessionStorage.setItem('LoginStudent', JSON.stringify(null));
            sessionStorage.setItem('LearnRec', JSON.stringify(null));

            getMyip().then(data => {
                if (data.ip !== '::1') {
                    const ippart=data.ip.split(":")
                    addAdLogin(te.Tid.toString(),'T',ippart[3]);
                }
            })
            updateGmailExTeacher(te.Tid.toString(), googleProfile.mail.trim());
            te.Gmail = googleProfile.mail.trim();
            setLoginTea(te);
            sessionStorage.setItem('LoginTeacher', JSON.stringify(te));
            handleLink('/');
        }

    }

    useEffect(() => {
        if (pressed) {
            console.log("pressed");

            chkAccPwd();            
        }
    }, [pressed]);

    // 首次載入時，檢查是否有Google登入資料
    useEffect(() => {
        if (sessionStorage.getItem('GoogleInfo') === null) {
            handleLink('/');
        } else {
            const googleInfo: {
                uuid: string;
                firstName: string;
                lastName: string;
                language: string;
                mail: string;
            } = JSON.parse(sessionStorage.getItem('GoogleInfo')!);
            setGoogleProfile(googleInfo);
        }
    }, []);

    return (
        <>
        { (googleProfile!==null) ?
            <>
            <div style={{ backgroundColor: '#FCF9F3' }}>
                <nav className="navbar navbar-expand-lg py-3" style={{ backgroundColor: '#FCF9F3' }}>
                    <div className="container align-items-center">
                        <div className="navbar-translate">
                            <a className="navbar-brand logo" href="/" rel="tooltip" title="數位讀寫網" data-placement="bottom">
                                數位讀寫網
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="py-5">
                <div className="container" style={{ maxWidth: '870px' }}>
                    <div className="row" style={{ borderRadius: '8px', boxShadow: '0px 0px 10px 5px #d7d7d7' }}>
                        <div className="col-12 col-md-6 order-2 order-md-1 bg-primary logreg px-4">
                            <h4 className="py-3 text-center text-white fw-bolder">註冊說明</h4>
                            <div className="text-white">
                                <div className="text-start my-4">
                                    <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>1. 使用本網站，請了解和遵守<a href="#" onClick={(e) => { e.preventDefault(); setOpenObjectModal(true); }}>隱私權及資訊安全政策</a></div>
                                    <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>2. 沒有註冊，也可以進入讀寫練習(PP&DP)。有註冊的同學，可以保留和查詢作答記錄。</div>
                                </div>
                            </div>     
    
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2 font-weight-bold bg-white login px-4">
                            <h4 className="py-3 text-center text-primary fw-bolder">綁定Google</h4>
                            <hr className="hr1 my-1" />
                            <div className="tab-content mt-4">
                                <div className="text-center">
                                    姓名: {/*{getGoogleInfo('lastname')} {getGoogleInfo('firstname')}*/} {googleProfile.lastName} {googleProfile.firstName}
                                </div>
                               {/* <div className="text-center"> mail:{getGoogleInfo('mail')}</div> */}
                                <div className="text-center"> Email:{googleProfile.mail}</div>
                                <div className="text-center tab-pane fade show active" id="loginpp" role="tabpanel" aria-labelledby="loginpp-tab">
                                    {   <>
                                            <div>帳號 或 Email</div>
                                            <input type="text" className="form-control mb-3" placeholder="請輸入帳號 / Email" onChange={handleAccInp} />
                                            <div>密碼</div>
                                            <input type="password" className="form-control mb-3" placeholder="請輸入密碼" onChange={handlePwdInp} />
                                            <button className="btn btn-round btn-primary my-2 text-nowrap" onClick={() => chkLogin()}>
                                                登入帳號後綁定
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BSModal title={'提醒您'} isOpen={openModal} setOpenModal={setOpenModal}>
                {errMsg}
            </BSModal>
            <ObjectModal 
                title={'隱私權及資訊安全政策'}
                isOpen={openObjectModal}
                setOpenModal={setOpenObjectModal}
                objectURL={"./imageDoc/數位讀寫網站-隱私權及資訊安全政策.pdf"}
            />
            <FooterPage />
            </>
            :null }
            
        </>
    );
};

export default ReLoginGooglePage;


