import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IOpinionForModal, IsubTopicForModal, validate } from '../tool/helper';
import {  createOpinion, createSubTopic, paramsCreateOpinionSubTopic, subTopicParam, updateOpinionByGroupOpIdxUID, updateSubTopicByUid } from '../../../services/EP/OpinionService';
import { StudentDisplay } from '../epStudentHome';

interface IProp{
    isAddRsearchModalOpen :boolean ;
    setIsAddRsearchModalOpen :(a:boolean)=>void ;
    currentShowModal : IOpinionForModal | IsubTopicForModal |undefined ;
    studentInfo :StudentDisplay|undefined ;
    setStudentInfo : ((studentDisplay :StudentDisplay) => void) |undefined;
    formData :paramsCreateOpinionSubTopic ;
    setFormData : (paramsCreateOpinionSubTopic :paramsCreateOpinionSubTopic) => void;
    onFinish : ()=>void 
    groupNo :string  |undefined
}
// 編輯彈跳視窗
const EditModal: React.FunctionComponent<IProp> = 
    ({ isAddRsearchModalOpen
     , setIsAddRsearchModalOpen
     , currentShowModal
     , studentInfo
     , setStudentInfo
     , formData 
     , setFormData
     , onFinish
     ,groupNo

    }) => {

const handleCheckboxClick = (index :number ) => {
    
    const updatedTeam = [...(studentInfo?.Team||[])];
    updatedTeam.forEach(x=>{
        if(formData?.Description?.includes(x.TStuName.trim())){
            x.checked =true;
        }
    })
    updatedTeam[index].checked = !updatedTeam[index].checked;
    if(setStudentInfo){

        setStudentInfo({
            ...studentInfo,
            Team: updatedTeam,
          } as StudentDisplay);

    }

let studentString  = updatedTeam.filter((i)=>i.checked).map(x=>x.TStuName.trim()).join('、')
    setFormData({ ...formData, Description:studentString})

  };
  
  const saveEditModal = async (target: IOpinionForModal | IsubTopicForModal |undefined ) => {
    if (!target||!groupNo) {
        alert('發生錯誤!');
        return;
    }
    let isPass = validate(target, formData, '');
    if (!isPass) {
        return false;
    }
 
    //  往下繼續執行
    if (formData.action === '新增') {
        if (target.sourceType === 'opinion' &&target.index && !(target.opinCode === '1_1提出探究題目')) {
   
            let params = { GroupNo: groupNo,
                             DataA: formData.DataA ||'', 
                             DataB: formData.DataB ||'', 
                             DataC: formData.DataC ||'' } as paramsCreateOpinionSubTopic;
            try {
                await createOpinion(params, target.isfeedback, target.index);
                alert('儲存成功!');
                setIsAddRsearchModalOpen(false);
            } catch (ex) {
                alert('err: ' + JSON.stringify(ex));
            }
        } else if (target.sourceType === 'opinion' &&target.index && target.opinCode === '1_1提出探究題目') {
       
            let params = { GroupNo: groupNo, MemberNo: studentInfo?.StuNo, DataA: formData.DataA, DataB: formData.DataB, DataC: formData.DataC } as paramsCreateOpinionSubTopic;

          
            try {
                await createOpinion(params, target.isfeedback, target.index);
                alert('儲存成功!');
                setIsAddRsearchModalOpen(false);
            } catch (ex) {
                alert('err: ' + JSON.stringify(ex));
            }
        } else if (target.sourceType === 'subTopic') {
            if (!groupNo) {
                alert('發生錯誤!');
                return;
            }
            let params = {
                GroupNo: groupNo?.trim(),
                SubName: formData.DataB?.trim()||'',
                Description: formData.Description?.trim()||''
            } as subTopicParam;

     
            try {
                await createSubTopic(params, target.isfeedback);
                setIsAddRsearchModalOpen(false);
            } catch (ex) {
                alert('err: ' + JSON.stringify(ex));
        
            }
        }

        setIsAddRsearchModalOpen(false);
    } else if (formData.action === '修改' && formData.UID) {
     
        if (target.sourceType === 'subTopic') {
            let params = {
                uid: formData.UID,
                GroupNo: groupNo,
                SubNo: formData.SubNo,
                SubName: formData.SubName,
                Description: formData.Description||'',
                Keyword: formData.Keyword||''
            };
            try {
                await updateSubTopicByUid(params, 1, formData.UID);
                alert('修改成功')
            } catch (ex) {
                alert(JSON.stringify(ex));
            }
        } else if(target.sourceType === 'opinion' && target.index) {
            try {
                let params = {
                    GroupNo: groupNo,
                    MemberNo: studentInfo?.StuNo,
                    DataA: formData.DataA ||'',
                    DataB: formData.DataB ||'',
                    DataC: formData.DataC ||'' 
                } as paramsCreateOpinionSubTopic;

                await updateOpinionByGroupOpIdxUID(params, 1, target.index, groupNo, formData.UID);

                alert('儲存成功!');
                setIsAddRsearchModalOpen(false);
            } catch (ex) {
              alert("儲存發生錯誤。")
            }
        }
    } else if (formData.action === '刪除') {
        if (window.confirm('確定刪除?')) {
        }
    }
    onFinish()
    setIsAddRsearchModalOpen(false);
};



    return (
        <>
     

     
     <Modal show={isAddRsearchModalOpen} onHide={() => setIsAddRsearchModalOpen(false)}>
                <Modal.Header style={{ maxWidth: '800px' }}>
                  
                    <Modal.Title>
                        <h5 className="modal-title">{currentShowModal?.modalTitleDisplay}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=" px-3 pb-4">
                    <div>
                        <form role="form text-left">
                            {currentShowModal?.inputItems.map((item) => (
                                <div className="row">
                                    <div className="col-12  pb-4">
                                        <label className="">{item.inputLable} </label>
                                        <div className="">
                                            {item.type === 'input' ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={item.placeholder}
                                                        value={formData ? formData[item.dataABC] : undefined}
                                                        onChange={(e) => setFormData({ ...formData, [item.dataABC]: e.target.value })}
                                                        disabled={item.disable}
                                                        hidden ={item.checkSection }
                                                        readOnly ={item.readOnly}
                                                        
                                                    />
                                                    {/* {JSON.stringify(formData)} */}
                                                    {item.checkSection ? 
                                                  
                                                     (<div  style={{padding:"5px" }} >{ studentInfo?.Team.map((item,index)=>(
                                                        <>
                                                        <input   style={{marginLeft:"10px"  ,padding:"10px" }}  name ='student' type="checkbox" checked={(item.checked ||formData.Description?.includes(item.TStuName.trim()))}
                                                          onClick={() => handleCheckboxClick(index)} /> 
                                                        <label htmlFor="student"  onClick={()=>handleCheckboxClick(index)} >{item.TStuName}</label>
                                                        </>
                                                    
                                                    ))}</div>) : <></>}
                                                </>
                                            ) : (
                                                <textarea
                                                    rows={3}
                                                    className="form-control"
                                                    placeholder={item.placeholder}
                                                    value={formData ? formData[item.dataABC] : undefined}
                                                    onChange={(e) => setFormData({ ...formData, [item.dataABC]: e.target.value })}
                                                ></textarea>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsAddRsearchModalOpen(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => saveEditModal(currentShowModal)}>
                        儲存
                    </button>
            
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default EditModal;
