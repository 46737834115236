import React, { useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { TeacherGroupInfo, crtlInfo } from '../../../interfaces/EpModal';
import { Export2Word } from '../tool/helper';

interface Iprop {
    // 定义外部传入的 props 类型
    selectGroupInfo: TeacherGroupInfo |undefined;
    teaccherGroupInfoList: TeacherGroupInfo[];
    setSelectGroupInfo :React.Dispatch<React.SetStateAction<TeacherGroupInfo |undefined>>;
}



/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const GroupSelect: React.FunctionComponent<Iprop> = (props) => {
    

    return (
        <>
            <div className="dropdown" style={{ width: 'fit-content' }}>
                <button
                    className="btn text-second bg-second dropdown-toggle mb-3"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ minWidth: '200px' }}
                >
                    <span  > {props.selectGroupInfo?.GroupName} </span>
                </button>
                <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                    {props.teaccherGroupInfoList?.map((groupInfo) => (
                        <li>
                            <div className="dropdown-item d-flex" onClick={() => props.setSelectGroupInfo(groupInfo)}>
                                {groupInfo.GroupName}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};
export default GroupSelect;
