import { IExStuLearnRec } from '../interfaces/ExStuLearnRec';
import IlearnRecCurr from '../models/learnRecCurr';
import  IlearnRecord from '../models/learnRecord'
import { getExStuTestCntBySid, getExStuTestCntBySidTest } from '../services/ExStuRecService';
import { getUpdExStuLearnRec } from '../services/ExStuTeService';
export let learnRec:IExStuLearnRec={
    Sid:0,
    PP1Correct: 0,
    PP1Wrong: 0,
    PP4Correct: 0,
    PP4Wrong: 0,
    PP5Count: 0,
    PP6Count: 0,
    DPCount:0,
}

export let learnRecCurr:IlearnRecCurr={
    sCorrect:0,
    sWrong:0,
    sSecond:0,
    userID:0,
    TType:"", // Map to field 'Test' in ExStuRecord
    TQno:""   // Map to field 'Words' in ExStuRecord
}


export const newLearnRecCurr=(sid:number,TType:string,TQno:string)=>{
    learnRecCurr.sCorrect=0;
    learnRecCurr.sWrong=0;
    learnRecCurr.sSecond=0;
    learnRecCurr.userID=sid;
    learnRecCurr.TType=TType;
    learnRecCurr.TQno=TQno;
    sessionStorage.setItem("LearnRecCurr",JSON.stringify(learnRecCurr));
}

export const getLearnRecCurr=():IlearnRecCurr=>{
    const tmp=sessionStorage.getItem("LearnRecCurr");
    if(tmp)
    {
        learnRecCurr= JSON.parse(tmp);
    }
    return(learnRecCurr);
}

export const setLearnRecCurrVal=(key:string,val:number)=>{
    const tmp=sessionStorage.getItem("LearnRecCurr");
    if(tmp)
    {
        learnRecCurr= JSON.parse(tmp);
    }    
    switch (key)
    {
        case "sCorrect":
            learnRecCurr.sCorrect=val;
            break;
        case "sWrong":
            learnRecCurr.sWrong=val;
            break;
        case "sSecond":
            learnRecCurr.sSecond=val;
             break;        
    }        
    sessionStorage.setItem("LearnRecCurr",JSON.stringify(learnRecCurr));
}


export const setLearnRecCurrValStr=(key:string,val:string)=>{
    const tmp=sessionStorage.getItem("LearnRecCurr");
    if(tmp)
    {
        learnRecCurr= JSON.parse(tmp);
    }    
    switch (key)
    {
        case "TType":
            learnRecCurr.TType=val;
            break;
        case "TQno":
            learnRecCurr.TQno=val;
            break;
    }        
    sessionStorage.setItem("LearnRecCurr",JSON.stringify(learnRecCurr));
}

export const getLearnRecCurrVal=(key:string):number=>{
    const tmp=sessionStorage.getItem("LearnRecCurr");
    if(tmp)
    {
        learnRecCurr= JSON.parse(tmp);
    }
    let val=0 ;
    switch (key)
    {
     
        case "sCorrect":
            val=learnRecCurr.sCorrect;
            break;
        case "sWrong":
            val=learnRecCurr.sWrong;
            break;
        case "sSecond":
            val=learnRecCurr.sSecond;
            break;           
        case "userID":
            val= learnRecCurr.userID;
            break;                        
    } 
    return val;
}

export const getLearnRecCurrValStr=(key:string):string=>{
    const tmp=sessionStorage.getItem("LearnRecCurr");
    if(tmp)
    {
        learnRecCurr= JSON.parse(tmp);
    }
    let val="" ;
    switch (key)
    {
        case "TType":
            val=learnRecCurr.TType;
            break;            
        case "TQno":
            val=learnRecCurr.TQno;
            break;                     
    } 
    return val;
}

export const getLearnRec=():IExStuLearnRec=>{
    const tmp=sessionStorage.getItem("LearnRec");

    if(tmp)  
    {
        learnRec= JSON.parse(tmp);
    }

    return(learnRec);
}

export const setLearnRecVal=(key:string,val:number)=>{
    const tmp=sessionStorage.getItem("LearnRec");
    if(tmp)
    {
        learnRec= JSON.parse(tmp);
        switch (key)
        {
            case "sCorrectPP1":
                learnRec.PP1Correct=val;
                break;
            case "sWrongPP1":
                learnRec.PP1Wrong=val;
                break;  
            case "sCorrectPP4":
                learnRec.PP4Correct=val;
                break;
            case "sWrongPP4":
                learnRec.PP4Wrong=val;
                break;
            case "sCountPP5":
                learnRec.PP5Count=val;
                break;        
            case "sCountPP6":
                learnRec.PP6Count=val;
                break;
            case "sCountDP":
                learnRec.DPCount=val;
                break;
        }
    }        
    sessionStorage.setItem("LearnRec",JSON.stringify(learnRec));
}

export const getLearnRecVal=(key:string):number=>{
    const tmp=sessionStorage.getItem("LearnRec");

    if(tmp)
    {
        learnRec= JSON.parse(tmp);
    }
    let val=0 ;
    switch (key)
    {
        case "sCountPP1":
            val=learnRec.PP1Correct+learnRec.PP1Wrong;
            break;            
        case "sCorrectPP1":
            val=learnRec.PP1Correct;
            break;
        case "sWrongPP1":
            val=learnRec.PP1Wrong;
            break;
        case "sCountPP4":
            val=learnRec.PP4Correct+learnRec.PP4Wrong;
            break;            
        case "sCorrectPP4":
            val=learnRec.PP4Correct;            
            break;
        case "sWrongPP4":
            val=learnRec.PP4Wrong;
            break;
        case "sCountPP5":
            val=learnRec.PP5Count;
            break;
        case "sCountPP6":
            val=learnRec.PP6Count;
            break;
        case "sCountDP":
            val=learnRec.DPCount;
            break;                      
    } 
    return val;
}

export const loadLearnRecSection=async (sid:number)=>{
   /* let lRec={
        TestCnt:0,
        CorrectCnt:0,
        WrongCnt:0
    }
    lRec=await getExStuTestCntBySidTest(sid,"PP1")
    learnRec.sCountPP1=lRec.TestCnt;
    learnRec.sCorrectPP1=(lRec.CorrectCnt===null)? 0:lRec.CorrectCnt;
    learnRec.sWrongPP1=(lRec.WrongCnt===null)? 0: lRec.WrongCnt;        

    lRec=await getExStuTestCntBySidTest(sid,"PP4")
    learnRec.sCountPP4=lRec.TestCnt;
    learnRec.sCorrectPP4=(lRec.CorrectCnt===null)? 0:lRec.CorrectCnt;
    learnRec.sWrongPP4=(lRec.WrongCnt===null)? 0: lRec.WrongCnt;
    
    lRec=await getExStuTestCntBySidTest(sid,"PP5")
    learnRec.sCountPP5=lRec.TestCnt;

    lRec=await getExStuTestCntBySidTest(sid,"PP6")
    learnRec.sCountPP5=lRec.TestCnt;

    lRec=await getExStuTestCntBySidTest(sid,"DP")
    learnRec.sCountDP=lRec.TestCnt;*/

    learnRec=await getUpdExStuLearnRec(sid.toString())
    sessionStorage.setItem("LearnRec",JSON.stringify(learnRec));

}

    

