import React from 'react'
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';


const IntoPage: React.FunctionComponent = props => {
    return (
        <>
        <TopNavPage2 />

        <div className="section pt-3">
            <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h5 className="title mb-3 me-3">關於我們</h5>
                        <h5 className="order-2 order-md-1 mb-3">網站介紹</h5>
                        <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width: '232px'}}>
                        <a href="/about" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
                            緣起</a>
                    </div>
                </div>

                <div className="w-100 w-lg-75 mx-auto">
                    <img src="./assets/img/Manual1.png" alt="" className="w-100 border-radius-lg"/>
                    <hr className="horizontal dark my-5"/>
                    <img src="./assets/img/Manual2.png" alt="" className="w-100 border-radius-lg"/>
                    <hr className="horizontal dark my-5"/>
                    <img src="./assets/img/Manual3.png" alt="" className="w-100 border-radius-lg"/>
                    <hr className="horizontal dark my-5"/>
                    <img src="./assets/img/Manual4.png" alt="" className="w-100 border-radius-lg"/>
                    <hr className="horizontal dark my-5"/>
                    <img src="./assets/img/Manual5.png" alt="" className="w-100 border-radius-lg"/>
                </div>
                <div className="pt-3 mt-4"></div>
            </div>
        </div>

        <FooterPage/>
        </>
    );
}

export default IntoPage;