
import axios, { AxiosError } from 'axios'
import  config from '../../config/config';
import { IInsertApplyGroup, IUpdateApplyGroup, IUpdateApplyStudent } from '../../interfaces/EpModal';

// 以小隊編號取的小隊各階段的意見內容
// Get /api/OpinionTable/allstage/groupno/:groupno
/* Response JSON Sample: 
 {
    "GroupID":"1",
    "GroupNo":"1".
    "GroupName":"小隊一",
    "Pre11":[
      {
        "StuID":"1",
        "StuNo":"2",
        "StuName":"小明",
        "DataA":"探究題目"
        "DataB""探究題目理由",
        UpdateDate:"2021-01-01 12:00:00"
      }
    ],
    "Post11":[
      {
        "StuID":"1",
        "StuNo":"2",
        "StuName":"小明",
        "DataA":"探究題目"
        "DataB""探究題目理由",
        UpdateDate:"2021-01-01 12:00:00"
      }
    ],
    "Pre12_DataA":"前-決定探究題目",
    "Pre12_DataB":"前-決定探究題目原因",
    "Pre12_UpdateDate":"2021-01-01 12:00:00"  
    "Pre13_DataB":"前-探究題目預測結果原因",
    "Pre13_UpdateDate":"2021-01-01 12:00:00"
    "Pre14_DataA":"前-遭遇困難",
    "Pre14_DataB":"前-解決方式",
    "Pre14_DataC":"前-學到的新思維",
    "Pre14_UpdateDate":"2021-01-01 12:00:00"
    "Pre19_DataA":"前-指導老師留言",
    "Pre19_UpdateDate":"2021-01-01 12:00:00",
    "Pre24_DataA":"前-遭遇困難",
    "Pre24_DataB":"前-解決方式",
    "Pre24_DataC":"前-學到的新思維",
    "Pre24_UpdateDate":"2021-01-01 12:00:00"
    "Pre29_DataA":"前-指導老師留言",
    "Pre29_UpdateDate":"2021-01-01 12:00:00"
    "ExpoOpinion":"專家回饋",
    "Post12_DataA":"後-決定探究題目",
    "Post12_DataB":"後-決定探究題目原因",
    "Post12_UpdateDate":"2021-01-01 12:00:00"  
    "Post13_DataB":"後-探究題目預測結果原因",
    "Post13_UpdateDate":"2021-01-01 12:00:00"
    "Post14_DataA":"後-遭遇困難",
    "Post14_DataB":"後-解決方式",
    "Post14_DataC":"後-學到的新思維",
    "Post14_UpdateDate":"2021-01-01 12:00:00"
    "Post19_DataA":"後-指導老師留言",
    "Post19_UpdateDate":"2021-01-01 12:00:00",
    "Post24_DataA":"後-遭遇困難",
    "Post24_DataB":"後-解決方式",
    "Post24_DataC":"後-學到的新思維",
    "Post24_UpdateDate":"2021-01-01 12:00:00"
    "Post29_DataA":"後-指導老師留言",
    "Post29_UpdateDate":"2021-01-01 12:00:00"
    "Post30_DataA":"後-回饋意見後修改",
    "Post30_UpdateDate":"2021-01-01 12:00:00"
    "Post33_DataA":"後-遭遇困難",
    "Post33_DataB":"後-解決方式",
    "Post33_DataC":"後-學到的新思維",
    "Post33_UpdateDate":"2021-01-01 12:00:00"
    "Post39_DataA":"後-指導老師留言",
    "Post39_UpdateDate":"2021-01-01 12:00:00"
    "Post42_DataA":"後-提出結論與發現",
    "Post42_UpdateDate":"2021-01-01 12:00:00"
    "Post43_DataA":"後-比較探究結論",
    "Post43_UpdateDate":"2021-01-01 12:00:00"
    "Post44_DataA":"後-遭遇困難",
    "Post44_DataB":"後-解決方式",
    "Post44_DataC":"後-學到的新思維",
    "Post44_UpdateDate":"2021-01-01 12:00:00"
    "Post49_DataA":"後-指導老師留言",
    "Post49_UpdateDate":"2021-01-01 12:00:00"
    "Post51_DataA":"後-更改前探究題目",
    "Post51_UpdateDate":"2021-01-01 12:00:00"
    "Post54_DataA":"後-參加本活動的省思",
    "Post54_UpdateDate":"2021-01-01 12:00:00"
    "Post55_DataA":"後-指導老師心得",
    "Post55_UpdateDate":"2021-01-01 12:00:00"
  }
 
*/
// const getOpinionByGroupNo = asyncHandler(async(req: Request, res: Response) => {
    export const getOpinionByGroupNo = async  (groupno:string)=>{
        const url=config.server.url+`/api/OpinionTable/allstage/groupno/${groupno}`
    
            return new Promise((resolve, reject) => {
              axios.get(url)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => { 
                  console.error('Error fetching data:', error);
                  reject(error); //
                });
            });
      
      
      }

// Get SubTopic and SubTopicSure by Groupno 
// Get /api/SubTable/subtopic/isfeedback/:isfeedback/groupno/:groupno   回饋前 isfeedback:0 回饋後 isfeedback:1
// const getSubTopicByGroupNo = asyncHandler(async(req: Request, res: Response) => {
    export const getSubTopicByGroupNo = async  (isfeedback :number ,groupno:string)=>{
        const url=config.server.url+`/api/SubTable/subtopic/isfeedback/${isfeedback}/groupno/${groupno}`
    
            return new Promise((resolve, reject) => {
              axios.get(url)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => { 
                  console.error('Error fetching data:', error);
                  reject(error); //
                });
            });
      
      
      }


      // 以小隊編號取得所有探究子題及重點整理與資料來源(以包含回饋前及回饋後)
// Get SubSummary and SubCollect by Groupno 
// Get /api/SubTable/sumcollect/isfeedback/:isfeedback/groupno/:groupno 回饋前 isfeedback:0 回饋後 isfeedback:1
// response json
/* 
[
	{
		"SubNo":"1",
		"SubName":"xyz",
		"Summaries:[
			{
				"SummaryID":"222"
				"SummaryNo":"3",
				"Summary:"22222222",
				"Collects":[
					{
						"CollectID":"111"
						"CollectNo":"2",
						"Source":"ttttt",
						"Web":"22222123",
						"WebDate":"22222",
						"Credibility":"4",
						"Reason":"521212"
					}
				]			
			}
		]
	}
]
*/

// const getSubSummarySubCollectByGroupNo = asyncHandler(async(req: Request, res: Response) => {
  export const getSubSummarySubCollectByGroupNo = async  (isfeedback :number ,groupno:string)=>{
    const url=config.server.url+`/api/SubTable/sumcollect/isfeedback/${isfeedback}/groupno/${groupno}`

        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => { 
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }




  // 以小隊編號及探究子題編號取得重點整理與資料來源(包含回饋前及回饋後)
// Get SubSummary and SubCollect by Groupno and SubNo
// Get /api/SubTable/sumcollect/isfeedback/isfeedback/groupno/:groupno/subno/:subno 回饋前 isfeedback:0 回饋後 isfeedback:1
// response json
/* 
[
  {
    "SummaryID":"222"
    "SummaryNo":"3",
    "Summary:"22222222",
    "Collects":[
      {
        "CollectID":"111"
        "CollectNo":"2",
        "Source":"ttttt",
        "Web":"22222123",
        "WebDate":"22222",
        "Credibility":"4",
        "Reason":"521212"
      }
    ]			
  }
]
*/
// const getSubSummarySubCollectByGroupNoSubNo = asyncHandler(async(req: Request, res: Response) => {
  export const getSubSummarySubCollectByGroupNoSubNo = async  (isfeedback :number ,groupno:string)=>{
    const url=config.server.url+`/api/SubTable/sumcollect/isfeedback/${isfeedback}/groupno/${groupno}`

        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => { 
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }








// 以小隊編號及意見編碼修改小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1
// Get Opinion or OpinionSure by groupno and opidx
// Put /api/OpinionTable/updopinion/isfeedback/:isfeedback/opidx/:opidx/groupno/:groupno
// Request Body JSON Sample:
/*
{
  "DataA":"",
  "DataB":"",
  "DataC":""
}
*/
// const updateOpinionByGroupOpIdx = asyncHandler(async(req: Request, res: Response) => {
  export const updateOpinionByGroupOpIdx = async (param :any ,isfeedback:'0'|'1',opidx:string ,groupno :string)=>{
      debugger
      //Here
      console.log("updateOpinionByGroupOpIdx",);
      const url=config.server.url+`/api/OpinionTable/updopinion/isfeedback/${isfeedback}/opidx/${opidx}/groupno/${groupno}`
      console.log('updateOpinionByGroupOpIdx',param)
      return new Promise((resolve, reject) => {
          axios.put(url,param)
            .then(response => {
              console.log("getLoginInfoByAccountAndPass ",response.data);
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  }




// 以小組編號更新多組專家回饋意見
// Update ExpOpinion and ExpMemo by groupno
// Put /api/DrGroup/expopinion/groupnos
/*
req.body=
[
  {
    "GroupNo":"1",
    "ExpOpinion":"專家意見",
    "ExpMemo":"專家備註"
  },
  {
    "GroupNo":"2",
    "ExpOpinion":"專家意見",
    "ExpMemo":"專家備註"
  }
]
*/

// const updateDrGroupExpOpinions = asyncHandler(async(req: Request, res: Response) => {

export const updateDrGroupExpOpinions = async (param :any)=>{


  const url=config.server.url+`/api/DrGroup/expopinion/groupnos`
  console.log('url',url)
  return new Promise((resolve, reject) => {
      axios.put(url,param)
        .then(response => {
  
          resolve(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          reject(error); //
        });
    });
}




  
