
import React, { useEffect, useState } from 'react';
import { getDrTeacherByApplyNo } from '../../../services/EP/EpApplyTeacherService';
import { alertEx } from '../tool/helper';
import { getDrTeaGroupByGroupNo } from '../../../services/EP/EPApplyGroupService';
import IPageProps from '../../../interfaces/page';




const Schedule: React.FunctionComponent<IPageProps> = (props) => {

    return (
        
        <div className="flex-parent mt-5">
        <div className="input-flex-container timelined"> 
            <div className="input text-black" style={{background: '#025224'}} id="readyGo" >
                <span data-year="" data-info="Ready Go!"></span>
            </div>
            <div className="input text-white fw-bolder text-center" >1
                <span className="text-black" data-year="2/15-3/1" data-info="報名"></span>
            </div>
            <div className="input text-white fw-bolder text-center" >2
                <span data-year="3/1" data-info="上傳報名表截止"></span>
            </div>
            <div className="input text-white fw-bolder text-center">3
                <span data-year="3/4" data-info="報名完成公告"></span>
            </div>
            <div className={"input text-white fw-bolder text-center"}>4
                <span data-year="3/6~3/25" data-info="第1&2階段"></span>
            </div>
            <div className="input text-white fw-bolder text-center">5
                <span data-year="4/18" data-info="線上回饋公告"></span>
            </div>
            <div className="input text-white fw-bolder text-center">6
                <span data-year="4/19~6/11" data-info="第3&4&5階段"></span>
            </div>
            <div className="input text-white fw-bolder text-center">7
                <span data-year="6/12~6/27" data-info="線上初審"></span>
            </div>
            <div className="input text-white fw-bolder text-center">8
                <span data-year="6/28" data-info="初賽結果公告"></span>
            </div>
            <div className="input text-white fw-bolder text-center">9
                <span data-year="6/29~7/16" data-info="上傳決賽發表簡報"></span>
            </div>
            <div className="input text-white fw-bolder text-center" style={{background: '#025224'}} id="finish">
                <span data-year="7/24~7/25" data-info="決賽"></span>
            </div>
        </div>
     </div>

    );
}

export default Schedule;
