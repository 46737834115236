import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExDPStu } from '../interfaces/ExDPStu';



export const GetExStuDPByAGSid=(agroup:string,sid:number)=>{
    const url=config.server.url+`/api/ExStuDP/agroup/${agroup}/sid/${sid.toString().trim()}`;
    return axios.get(url).then((res)=>{
        const rest:{
            ArticleGroup:string,
            ArticleNo:string,
            DPTopic:string,
            StuAnswer:string,
            Answer:string,
            AddTime:string,
            AnswerType:string
        }[]=res.data;
        let temp:IExDPStu[]=[];
        rest.map((item)=>{
            temp.push({
                ArticleGroup: item.ArticleGroup,
                ArticleNo: item.ArticleNo,
                DPTopic: item.DPTopic,
                StuAnswer: item.StuAnswer.split('//').slice(0,-1),
                Answer: item.Answer.split('//').slice(0,-1),
                AddTime: item.AddTime,
                AnswerType: item.AnswerType.split('//')
            });
        });
        console.log(temp);
        return temp
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const GetExStuDPBySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuDP/sid/${sid.toString().trim()}`;
    return axios.get(url).then((res)=>{
        const rest:{
            ArticleGroup:string,
            ArticleNo:string,
            DPTopic:string,
            StuAnswer:string,
            Answer:string,
            AddTime:string,
            AnswerType:string
        }[]=res.data;
        let temp:IExDPStu[]=[];
        rest.map((item)=>{
            temp.push({
                ArticleGroup: item.ArticleGroup,
                ArticleNo: item.ArticleNo,
                DPTopic: item.DPTopic,
                StuAnswer: item.StuAnswer.split('//').slice(0,-1),
                Answer: item.Answer.split('//').slice(0,-1),
                AddTime: item.AddTime,
                AnswerType: item.AnswerType.split('//')
            });
        });
        return temp
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
