import React, { useState } from 'react'
import IPageProps from '../../interfaces/page'
import { RedirModal } from '../../components/modal/redirmodal';


const DPInterPage: React.FunctionComponent<IPageProps> = props => {
    const [openRedirModal, setOpenRedirModal] = useState(false);
    const [redirURL, setRedirURL] = useState("");

    const handleRedir=(url:string)=>{
        setRedirURL(url);
        setOpenRedirModal(true);        
    }
    return (
        <>
          <div className="row" style={{marginTop:'20px'}}>
            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("https://www.iea.nl/studies/iea/pirls/2021/take-the-epirls-assessment")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN07.png" alt="06" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">ePIRLS 2021試題</div>
                    <div className="fw-bold text-second">African Animals On the Move</div>
                    <div className="fw-bold text-second">The Story of Troy</div>
                    <div>
                        <span className="material-icons-outlined align-top">link</span>
                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("http://timssandpirls.bc.edu/pirls2016/international-results/take-the-epirls-assessment/Blackwell/")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN01.png" alt="01" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">ePIRLS 2016試題</div>
                    <div className="fw-bold text-second">Elizabeth</div>
                    <div>
                        <span className="material-icons-outlined align-top">link</span>
                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("http://timssandpirls.bc.edu/pirls2016/international-results/take-the-epirls-assessment/Mars/")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN02.png" alt="02" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">ePIRLS 2016試題</div>
                    <div className="fw-bold text-second">Mars</div>
                    <div>
                        <span className="material-icons-outlined align-top">link</span>
                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("http://timssandpirls.bc.edu/pirls2016/international-results/take-the-epirls-assessment/Mars/")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN03.png" alt="03" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">ePIRLS 中文樣題</div>
                    <div className="fw-bold text-second">原住民文化</div>
                    <div>
                        <span className="material-icons-outlined align-top">link</span>
                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("https://pisa2018-questions.oecd.org/platform/index.html?user=&domain=REA&unit=R548-ChickenForum&lang=zho-TAP")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN04.png" alt="04" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">PISA 2018閱讀樣本試題</div>
                    <div className="fw-bold text-second">家禽論壇</div>
                    <div>
                        <span className="material-icons-outlined align-top">link</span>
                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                    </div>

                </div>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("https://pisa2018-questions.oecd.org/platform/index.html?user=&domain=REA&unit=R551-RapaNui&lang=zho-TAP")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN05.png" alt="05" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">PISA 2018閱讀樣本試題</div>
                    <div className="fw-bold text-second">復活節島</div>
                    <div>
                        <span className="material-icons-outlined align-top" >link</span>
                        練習<i className="fas fa-angle-right ms-1 " aria-hidden="true" ></i>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card pointer h-100" onClick={()=>handleRedir("https://pisa2018-questions.oecd.org/platform/index.html?user=&domain=REA&unit=R557-CowsMilk&lang=zho-TAP")}>
                <div className="card-body d-flex p-3">
                <img src="./assets/img/dp/DpN06.png" alt="06" className="rounded-2 w-50 me-2"/>
                <div className="d-flex flex-column justify-content-around w-50">
                    <div className="">PISA 2018閱讀樣本試題</div>
                    <div className="fw-bold text-second">牛奶</div>
                    <div>
                        <span className="material-icons-outlined align-top">link</span>
                        練習<i className="fas fa-angle-right ms-1" aria-hidden="true"></i>
                    </div>
                </div>
                </div>
            </div>
            </div>


        </div>
        <RedirModal title={"提醒您"} isOpen={openRedirModal} setOpenRedirModal={setOpenRedirModal} redirURL={redirURL}>跳轉至外部網站</RedirModal>
    </>
    );
}

export default DPInterPage;
