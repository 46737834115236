import React, { useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { TeacherGroupInfo, crtlInfo } from '../../../interfaces/EpModal';
import { Export2Word } from '../tool/helper';

interface Iprop {
    // 定义外部传入的 props 类型
   
}



/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const Loading : React.FunctionComponent<Iprop> = (props) => {
    

    return (
        <>
          <img   className="print-remove" src="/assets/img/ep/file_upload.png" alt="" />
        </>
    );
};
export default Loading;
