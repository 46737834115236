import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExStuPP1 } from '../interfaces/ExStuPP1';
import { IExStuRecordStat } from '../interfaces/ExStuRecordStat';
import { IExStuPP4 } from '../interfaces/ExStuPP4';
import { IExStuPP5 } from '../interfaces/ExStuPP5';
import { IExStuPP6 } from '../interfaces/ExStuPP6';
import ICommonPPAns from '../interfaces/CommonPPAns';

export const getExStuTestCntBySidTest=(sid:number,testtype:string)=>{
    const url=config.server.url+`/api/ExStuRec/testcnt/test/${testtype}/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:{
            TestCnt:number;
            CorrectCnt:number;
            WrongCnt:number;
        }=res.data[0];
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExStuTestCntBySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuRec/testcnt/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:{
            sCountPP1:number,
            sCorrectPP1:number,
            sWrongPP1:number,
            sCountPP4:number,
            sCorrectPP4:number,
            sWrongPP4:number,
            sCountPP5:number,
            sCountPP6:number,
            sCountDP:number,
        }=res.data[0];        
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExStuWordsCntByWordTestSid=(sid:number,testtype:string,word:string)=>{
    const url=config.server.url+`/api/ExStuRec/wordcnt/word/${word}/test/${testtype}/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:{
            TestCnt:number;
        }=res.data[0];
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


export const AddExStuPP1=(
    sid:number,
    question:string,
    stuAnswer:string,
    correct:number  // 1: make correct answer 0:make wrong answer
    )=>{
    const url=config.server.url+`/api/ExStuPP/stupp1`
    return axios.post(url,{
        Sid:sid,
        Question:question,
        StuAnswer:stuAnswer,
        Correct:correct,
        AddTime:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")
    }).then((res)=>{
       console.log(res.data);
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const AddExStuPP4=(
    sid:number,
    question:string,
    answer:string,
    stuAnswer:string,
    correct:number   // 1: make correct answer 0:make wrong answer
    )=>{
    const url=config.server.url+`/api/ExStuPP/stupp4`
    return axios.post(url,{
        Sid:sid,
        Question:question,
        Answer:answer,
        StuAnswer:stuAnswer,
        Correct:correct,
        AddTime:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const AddExStuPP5=(
    sid:number,
    absNo:string,
    stuAbs:string,
    stuAnswer:string
    )=>{
    const url=config.server.url+`/api/ExStuPP/stupp5`
    return axios.post(url,{
        Sid:sid,
        AbsNo:absNo,
        StuAbs:stuAbs,
        StuAnswer:stuAnswer,
        AddTime:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const AddExStuPP6=(
    sid:number,
    absNo:string,
    stuAnswer:string
    )=>{
    const url=config.server.url+`/api/ExStuPP/stupp6`
    return axios.post(url,{
        Sid:sid,
        AbsNo:absNo,
        StuAnswer:stuAnswer,
        AddTime:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const AddExStuDP=(
    sid:number,
    articleNo:string,
    stuAnswer:string
    )=>{
    const url=config.server.url+`/api/ExStuDP`    
    return axios.post(url,{
        Sid:sid,
        ArticleNo:articleNo,
        StuAnswer:stuAnswer,
        //AddTime:new Date().toISOString().slice(0, 19).replace('T', ' ')
        AddTime:new Date().toLocaleString('zh-Hans',{
            hour12:false,
        }).replaceAll("/","-")
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const AddExStuRec=(
    sid:number,
    test:string,
    words:string,
    correct:number,
    error:number,
    second:number
    )=>{
    const url=config.server.url+`/api/ExStuRec`
    return axios.post(url,{
        Sid:sid,
        Test:test,
        Words:words,
        Correct:correct,
        Error:error,
        Second:second
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExStuPP1BySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuPP/stupp1/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:IExStuPP1[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
export const getExStuPP4BySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuPP/stupp4/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:IExStuPP4[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
export const getExStuPP5BySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuPP/stupp5/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:IExStuPP5[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}
export const getExStuPP6BySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuPP/stupp6/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:IExStuPP6[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getStuRecStatBySidTest=(sid:number,test:string)=>{
    const url=config.server.url+`/api/ExStuRec/testCntGroupTime/test/${test}/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:IExStuRecordStat[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExStuAllTestCntBySidGroupTime=(sid:number)=>{
    const url=config.server.url+`/api/ExStuRec/alltestCntGroupTime/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:IExStuRecordStat[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExStuPPAllBySid=(sid:number)=>{
    const url=config.server.url+`/api/ExStuPP/stuppall/sid/${sid.toString()}`
    return axios.get(url).then((res)=>{
        const rest:ICommonPPAns[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


    





