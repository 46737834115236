/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import IPageProps from '../interfaces/page'
import { addAdSession, getAdSessionCnt, getAdSessionTodayCnt, getMyip } from '../services/AdSessionService';

const FooterPage: React.FunctionComponent<IPageProps> = props => {
    const [totalViews, setTotalViews] = useState(0);
    const [todayTotalViews, setTodayTotalViews] = useState(0);

    useEffect(() => {
        if (sessionStorage.getItem("SessionOpen") === null) {
            sessionStorage.setItem("SessionOpen", "1");
            getMyip().then(data => {
                if (data.ip !== '::1') {
                    const ippart=data.ip.split(":")
                    addAdSession(ippart[3]);
                }
            })

        }
        getAdSessionCnt().then(data => {
            setTotalViews(data.Cnt)
        })
        getAdSessionTodayCnt().then(data => {
            setTodayTotalViews(data.Cnt)
        })

    }, [])

    return (
        <footer className="footer bg-primary">
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div className="d-flex align-self-stretch flex-column flex-md-row">
                            <div className="twnread-card d-flex align-items-center me-md-2 flex-fill pointer" onClick={() => (window.open('https://www.twnread.org.tw'))}>
                                <div className="me-3">
                                    <img src="./assets/img/twnreadLogo.png" alt="" />
                                </div>
                                <div>
                                    <h2>財團法人台灣閱讀文化基金會</h2>
                                    <p>542 南投縣草屯鎮中正路567-11號1樓</p>
                                    <p>(049)256-6102 分機 28</p>
                                    <p>0966-705615</p>
                                    <p>roep.twnread@gmail.com</p>
                                </div>
                            </div>
                            <div className="twnread-card pointer my-2 my-md-0 flex-fill d-flex flex-md-column align-items-center justify-content-center justify-content-md-start px-5 px-md-4" onClick={() => (window.open('https://web.intersoft.com.tw/donation/OnlineTWNRead/Default.aspx?rand=637855439235579510&IsGeneralDonate=Y'))}>
                                <img src="./assets/img/donation.svg" className="mt-md-3 me-3 me-md-0 order-md-2" alt="" />
                                <h2 className="order-md-1 text-nowrap mb-0">捐款贊助</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <ul className="nav nav-footer">
                            <li className="nav-item">
                                <a href="/about" type="button" className="nav-link">關於我們</a>
                            </li>
                            <li className="nav-item">
                                <a href="/memoryko" type="button" className="nav-link">柯華葳紀念專頁</a>
                            </li>
                            <li className="nav-item">
                                <a href="/cooperate" type="button" className="nav-link">合作單位</a>
                            </li>
                            <li className="nav-item">
                                <a href="https://www.twnread.org.tw/nss/p/PlanDescription" className="nav-link" target="_blank" rel="noreferrer">數位愛的書庫</a>
                            </li>
                            <li className="nav-item">
                                <a href="/allnews" className="nav-link">最新消息</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" title="功能施工中">後台管理</a>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center border-top py-2">
                            <small className="me-auto">Copyright©數位讀寫網</small>
                            <small className="me-3">總瀏覽數： {totalViews}</small>
                            <small>今日瀏覽： {todayTotalViews}</small>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterPage;