import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExWord } from '../interfaces/ExWords';



export const getAnExWordByLevel=(level:number)=>{
    const url=config.server.url+`/api/ExWords/level/${level}`
    return axios.get(url).then((res)=>{
        const rest:IExWord[]=res.data;
        const retval:IExWord={
            Words:rest[0].Words
        }
        return retval;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getAnExWord=()=>{
    const url=config.server.url+`/api/ExWords/fixnum/1`
    return axios.get(url).then((res)=>{
        const rest:IExWord[]=res.data;
        const retval:IExWord={
            Words:rest[0].Words
        }
        return retval;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

