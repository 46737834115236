import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import { IExStudent } from '../interfaces/ExStudent';
import { IExTeacher } from '../interfaces/ExTeacher';
import { IExGradeClass } from '../interfaces/ExGradeClass';
import { IExStuLearnRec } from '../interfaces/ExStuLearnRec';

//利用學生及密碼查詢學生資料,如果有多筆資料,則回傳第一筆
export const getExStudentByStuNoPwd=(stuno:string,pwd:string)=>{
    const url=config.server.url+`/api/ExStudent/stuno/${stuno}/pwd/${pwd}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        console.log(rest);
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 利用Gmail取得學生登入資訊,如果有多筆資料,則回傳第一筆
export const getExStudentByGmail=(gmail:string)=>{
    const url=config.server.url+`/api/ExStudent/gmail/${gmail}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        if(rest.length>0)
        {
            return rest[0];
        }
        else
        {
            return undefined;
        }
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 利用iSchoolMail取得學生登入資訊,如果有多筆資料,則回傳第一筆
export const getExStudentByiSchool=(ischool:string)=>{
    const url=config.server.url+`/api/ExStudent/ischool/${ischool}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        if(rest.length>0)
        {
            return rest[0];
        }
        else
        {
            return undefined;
        }
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


// 利用Gmail取得老師登入資訊,如果有多筆資料,則回傳第一筆
export const getExTeacherByGmail=(gmail:string)=>{
    const url=config.server.url+`/api/ExTeacher/gmail/${gmail}`
    return axios.get(url).then((res)=>{
        const rest:IExTeacher[]=res.data;
        if(rest.length>0)
        {
            return rest[0];
        }
        else
        {
            return undefined;
        }
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 利用iSchoolMail取得老師登入資訊,如果有多筆資料,則回傳第一筆
export const getExTeacherByiSchool=(ischool:string)=>{
    const url=config.server.url+`/api/ExTeacher/ischool/${ischool}`
    return axios.get(url).then((res)=>{
        const rest:IExTeacher[]=res.data;
        if(rest.length>0)
        {
            return rest[0];
        }
        else
        {
            return undefined;
        }
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 更新學生學習紀錄並回傳
export const getUpdExStuLearnRec=(sid:string)=>{
    const url=config.server.url+`/api/ExStudent/updsellearnrec/sid/${sid}`
    return axios.get(url).then((res)=>{
        const rest:IExStuLearnRec[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


export const getExTeacherByTNoPwd=(tno:string,pwd:string)=>{
    const url=config.server.url+`/api/ExTeacher/tno/${tno}/pwd/${pwd}`
    return axios.get(url).then((res)=>{
        const rest:IExTeacher[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getLoginResp=(acc:string,pwd:string):string=>{
    let result=""
    getExStudentByStuNoPwd(acc,pwd).then(stu=>{
        if(stu===undefined)
        {
            getExTeacherByTNoPwd(acc,pwd).then(te=>{
                if(te!==undefined)
                {
                    result="2,"+te.Tid.toString()+","+te.SchoolNo
                }
            }
            )
        }
        else
        {
            result="1,"+stu.Sid.toString()+","+stu.SchoolNo
        }
    })
    console.log(result)    
    return result
}

export const createExStudent=(
    sid:string,
    stuno:string,
    stuname:string,
    pwd:string,
    schoolno:string,
    city:string,
    area:string,
    schoolname:string,
    grade:string,
    sclass:string,
    gender:string,
    gmail:string,
    ischool:string,
    teacreate:string,
    opento:string,
    seat:string,
    remark:string
    )=>{
    const url=config.server.url+`/api/ExStudent/new`
    return axios.post(url,{
        Sid:sid,
        StuNo:stuno,
        StuName:stuname,
        PWD:pwd,
        SchoolNo:schoolno,
        City:city,
        Area:area,
        SchoolName:schoolname,
        Grade:grade,
        Class:sclass,
        Gender:gender,
        Gmail:gmail,
        iSchoolMail:ischool,
        TeaCreate:teacreate,
        OpenTo:opento,
        Seat:seat,
        Remark:remark
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const createExStudents=(
    stus:IExStudent[]
    )=>{
    const url=config.server.url+`/api/ExStudent/newstus`
    interface newStu {
        sid:string,
        stuno:string,
        stuname:string,
        pwd:string,
        schoolno:string,
        city:string,
        area:string,
        schoolname:string,
        grade:string,
        sclass:string,
        gender:string,
        gmail:string,
        ischool:string,
        teacreate:string,
        opento:string,
        seat:string,
        remark:string
    };
    const newStus:newStu[]=[];
    stus.forEach(stu=>{
        const nstu: newStu=
        {
            sid:stu.Sid.toString(),
            stuno:stu.StuNo,
            stuname:stu.StuName,
            pwd:stu.PWD,
            schoolno:stu.SchoolNo,
            city:stu.City,
            area:stu.Area,
            schoolname:stu.SchoolName,
            grade:stu.Grade,
            sclass:stu.Class,
            gender:stu.Gender,
            gmail:stu.Gmail,
            ischool:stu.iSchoolMail,
            teacreate:(stu.TeaCreate)? "1":"0",
            opento:stu.OpenTo,
            seat:stu.Seat,
            remark:stu.Remark
        };
        newStus.push(nstu);
    });
    return axios.post(url,newStus).then((res)=>{
         return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


export const getExStudentByStuNo=(stuno:string)=>{
    const url=config.server.url+`/api/ExStudent/stuno/${stuno}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getUpdExStuLearnRecBySid=(sid:string)=>{
    const url=config.server.url+`/api/ExStudent/updsellearnrec/sid/${sid}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getMaxStuID=()=>{
    const url=config.server.url+`/api/ExStudent/maxsid`
    return axios.get(url).then((res)=>{
        const rest:{MaxSid:number}[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExTeacherByTNo=(tno:string)=>{
    const url=config.server.url+`/api/ExTeacher/tno/${tno}`
    return axios.get(url).then((res)=>{
        const rest:IExTeacher[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getExTeaStuBySid=(sid:string)=>{
    const url=config.server.url+`/api/ExTeaStu/teacher/sid/${sid}`
    return axios.get(url).then((res)=>{
        const rest:IExTeacher[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getMaxTID=()=>{
    const url=config.server.url+`/api/ExTeacher/maxtid`
    return axios.get(url).then((res)=>{
        const rest:{MaxTid:number}[]=res.data;
        return rest[0];
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const createExTeacher=(
    tid:string,
    teano:string,
    teaname:string,
    schoolno:string,
    city:string,
    area:string,
    schoolname:string,
    pwd:string,
    cell:string,
    email:string
    )=>{
    const url=config.server.url+`/api/ExTeacher/new`
    return axios.post(url,{
        Tid:tid,
        TeaNo:teano,
        TeaName:teaname,
        SchoolNo:schoolno,
        City:city,
        Area:area,
        SchoolName:schoolname,
        PWD:pwd,
        Cell:cell,
        Email:email
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const addExTeaStuBySidTid=(
    sid:string,
    tid:string
    )=>{
    const url=config.server.url+`/api/ExTeaStu/new/sid/${sid}/tid/${tid}`
    return axios.post(url).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const addExTeaStusBySidTid=(
    TeaStus:
    {
        Tid:string,
        Sid:string
    }[]
    )=>{
    const url=config.server.url+`/api/ExTeaStu/newstus`
    return axios.post(url,TeaStus).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const deleteExTeaStuBySidsTid=(
    sids:string,
    tid:string
    )=>{
    const url=config.server.url+`/api/ExTeaStu/delete/sids/${sids}/tid/${tid}`
    return axios.delete(url).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const deleteExStudentBySids=(
    sids:string
    )=>{
    const url=config.server.url+`/api/ExStudent/delete/sids/${sids}`
    return axios.delete(url).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updateExStudent=(
    sid:string,
    schoolno:string,
    city:string,
    area:string,
    schoolname:string,
    grade:string,
    sclass:string
    )=>{
    const url=config.server.url+`/api/ExStudent/update/sid/${sid}`
    return axios.put(url,{
        SchoolNo:schoolno,
        City:city,
        Area:area,
        SchoolName:schoolname,
        Grade:grade,
        Class:sclass
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updateExStuGradeClassBySids=(
    sids:string,
    grade:string,
    sclass:string
    )=>{
    const url=config.server.url+`/api/ExStudent/updategc/sids/${sids}`
    return axios.put(url,{
        Grade:grade,
        Class:sclass
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updateDetailExStudent=(
    sid:string|undefined,
    stuname:string|undefined,
    pwd:string|undefined,
    grade:string|undefined,
    sclass:string|undefined,
    seat:string|undefined,
    gender:string|undefined,
    email:string|undefined,
    remark:string|undefined
    )=>{
    const url=config.server.url+`/api/ExStudent/updetail/sid/${sid}`
    return axios.put(url,{
        StuName:stuname,
        PWD:pwd,
        Grade:grade,
        Class:sclass,
        Seat:seat,
        Gender:gender,
        iSchoolMail:email,
        Remark:remark
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


export const updateExTeacher=(
    tid:string,
    schoolno:string,
    city:string,
    area:string,
    schoolname:string
    )=>{
    const url=config.server.url+`/api/ExTeacher/update/tid/${tid}`
    return axios.put(url,{
        SchoolNo:schoolno,
        City:city,
        Area:area,
        SchoolName:schoolname
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updatePwdExStudent=(
    sid:string,
    pwd:string
    )=>{
    const url=config.server.url+`/api/ExStudent/updatepwd/sid/${sid}`
    return axios.put(url,{
        PWD:pwd
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updatePwdExTeacher=(
    tid:string,
    pwd:string
    )=>{
    const url=config.server.url+`/api/ExTeacher/updatepwd/tid/${tid}`
    return axios.put(url,{
        PWD:pwd
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updateGmailExStudent=(
    sid:string,
    gmail:string
    )=>{
    const url=config.server.url+`/api/ExStudent/updgmail/sid/${sid}`
    return axios.put(url,{
        Gmail:gmail
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updateiSchoolExStudent=(
    sid:string,
    ischoolmail:string
    )=>{
    const url=config.server.url+`/api/ExStudent/updischmail/sid/${sid}`
    return axios.put(url,{
        iSchoolMail:ischoolmail
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const UpdExStuLearnRec=(
    sid:string,
    section:string,
    correct:number,
    wrong:number
    )=>{
    const url=config.server.url+`/api/ExStudent/learnrec/sid/${sid}/section/${section}/correct/${correct}/wrong/${wrong}`
    return axios.put(url).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const UpdAllExStuLearnRec=(
    sid:string
    )=>{
    const url=config.server.url+`/api/ExStudent/alllearnrec/sid/${sid}`
    return axios.put(url).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



export const updateGmailExTeacher=(
    tid:string,
    gmail:string
    )=>{
    const url=config.server.url+`/api/ExTeacher/updgmail/tid/${tid}`
    return axios.put(url,{
        Gmail:gmail
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const updateiSchoolExTeacher=(
    tid:string,
    ischoolmail:string
    )=>{
    const url=config.server.url+`/api/ExTeacher/updischmail/tid/${tid}`
    return axios.put(url,{
        iSchoolMail:ischoolmail
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 取得相同學校內的教師資料
export const getExTeacherBySchoolNo=(snotno:string)=>{
    const url=config.server.url+`/api/ExTeacher/sameschool/schoolno/${snotno}`
    return axios.get(url).then((res)=>{
        const rest:IExTeacher[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 根據開放教師ID取得學生資料
export const getExStudentByTeacherID=(tid:string)=>{
    const url=config.server.url+`/api/ExStudent/opento/teacherid/${tid}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 根據開放教師ID取得學生資料及關鍵字
export const getExStudentByTidKeyword=(tid:string,keyword:string,mode:string)=>{
    const url=config.server.url+`/api/ExStudent/opento/teacherid/${tid}/mode/${mode}/keyword/${keyword}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 根據開放教師ID、開放班級之年班取得學生資料
export const getExStudentByTidGradeClass=(tid:string,grd:string,cls:string)=>{
    const url=config.server.url+`/api/ExStudent/opento/teacherid/${tid}/grade/${grd}/class/${cls}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}


// 根據開放教師ID取得開放班級資料
export const getDistGradeClassExStuByTid=(tid:string)=>{
    const url=config.server.url+`/api/ExStudent/distgrdcls/teacherid/${tid}`
    return axios.get(url).then((res)=>{
        const rest:IExGradeClass[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



// 取得新的學生No
export const getNewStuNo=(tid:string)=>{
    const url=config.server.url+`/api/ExStudent/firststu/tid/${tid}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        if(rest[0]!==undefined)
        {
            let NewStuNo=rest[0].StuNo.trim();           
            const lastNum=Number(NewStuNo.substring(NewStuNo.length-3));
            NewStuNo=(lastNum+1).toString().padStart(3,"0");
            return NewStuNo;
        }
        else
        {
            return "001";
        }
    });
}

// 取得最後學生No
export const getLastStuNo=(tid:string)=>{
    const url=config.server.url+`/api/ExStudent/firststu/tid/${tid}`
    return axios.get(url).then((res)=>{
        const rest:IExStudent[]=res.data;
        if(rest[0]!==undefined)
        {
            let NewStuNo=rest[0].StuNo.trim();           
            const lastNum=Number(NewStuNo.substring(NewStuNo.length-3));
            
            return lastNum;
        }
        else
        {
            return 0;
        }
    });
}

// 變更開放教師
export const updateOpenToExStudent=(
    sid:string,
    opento:string
    )=>{
    const url=config.server.url+`/api/ExStudent/updateopento/sid/${sid}`
    return axios.put(url,{
        OpenTo:opento
    }).then((res)=>{
       return res.data;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



