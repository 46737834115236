/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page'
import FooterPage from '../footer';
import TopNavPage2 from '../topNav2';
import StuPPPage from './StuPP';
import StuPPXPage from './StuPPX';
import StuDPPage from './StuDP';
import StuPirlsPage from './StuPirls';
import StuSDGsPage from './StuSDGs';


const StuLearnRecPage: React.FunctionComponent<IPageProps> = props => {
    const [currentPage, setCurrentPage] = useState(0);
    const [ppRefresh,setPPRefresh]=useState(false);
    const [dpRefresh,setDPRefresh]=useState(false);
    const [pirlsRefresh,setPirlsRefresh]=useState(false);
    const [sdgsRefresh,setSDGsRefresh]=useState(false);
    useEffect(() => {
        console.log('curpage'+currentPage);     

    }, []);

 /*   useEffect(() => {
        console.log('curpage'+currentPage);
        

    }, [currentPage]); */


    return (
        <>
            <TopNavPage2/>
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="h5 text-center">自主學習記錄</div>
                    <div className="nav-wrapper position-relative z-index-2 mt-4">
                        <ul className="nav nav-pills nav-fill flex-wrap flex-md-nowrap" id="dp" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link mb-0 active" id="pp-tab" data-bs-toggle="tab" role="tab" aria-controls="pp" type="button"
                            aria-selected="true" onClick={()=>setCurrentPage(0)}>
                            <i className="ni ni-single-copy-04 me-2"></i>基礎讀寫(PP)
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-0" id="deep-tab" data-bs-toggle="tab" role="tab" aria-controls="deep" type="button"
                            aria-selected="false" onClick={()=>setCurrentPage(1)}>
                            <i className="ni ni-app me-2" ></i>多文本題組
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-0" id="text-tab" data-bs-toggle="tab" role="tab" aria-controls="text" type="button"
                            aria-selected="false" onClick={()=>setCurrentPage(2)}>
                            <i className="ni ni-album-2 me-2"></i>課文主題延伸
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-0" id="sdgs-tab" data-bs-toggle="tab" role="tab" aria-controls="sdgs" type="button"
                            aria-selected="false" onClick={()=>setCurrentPage(3)}>
                            <i className="ni ni-atom me-2"></i>SDGs
                            </a>
                        </li>
                        </ul>
                    </div>
                    {(currentPage===0)? <StuPPXPage setPPRefresh={setPPRefresh} ppRefresh={false} StuID={
                        sessionStorage.getItem("LoginStudent")!==null?JSON.parse(sessionStorage.getItem("LoginStudent")!).StuId:""
                        }/>: null}
                    {(currentPage===1)? <StuDPPage setDPRefresh={setDPRefresh} dpRefresh={false}/>:null}
                    {(currentPage===2)? <StuPirlsPage setPirlsRefresh={setPirlsRefresh} pirlsRefresh={false}/>:null}
                    {(currentPage===3)? <StuSDGsPage setSDGsRefresh={setSDGsRefresh} sdgsRefresh={false}/>:null}
                </div>
            </div>

            <FooterPage/>
        </>
    );
}

export default StuLearnRecPage;